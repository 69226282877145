import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import getImageAsBlob from "./getImage";
import axios from "axios";

export default function ImageCropper({ image, onFileChange, setFieldValue, aspectRatio }) {
  //crop image
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState("");
  useEffect(() => {
    let imageSrc = image;
    console.log(imageSrc);
    if (typeof image === "string") {
      setImageSrc(imageSrc);
    } else {
      var reader = new FileReader();
      reader.onload = function () {
        var dataURL = reader.result;
        setImageSrc(dataURL); // The data URL for the Blob object
      };
      reader.readAsDataURL(imageSrc);
    }

    // setImageSrc(imageSrc);
  }, [image]);
  //functions
  const onCropComplete = useCallback(
    ({ croppedAreaPixels, imageSrc, src, rotation, update }) => {
      getCroppedImage({
        croppedAreaPixels,
        imageSrc,
        src,
        rotation,
        update,
      });
    },
    []
  );

  const getCroppedImage = async ({
    croppedAreaPixels,
    imageSrc,
    src,
    rotation,
  }) => {
    const croppedImage = await getImageAsBlob(
      imageSrc,
      croppedAreaPixels,
      rotation,
      src?.name
    );

    onFileChange && onFileChange(setFieldValue, croppedImage);
  };

  return (
    <div className="image-cropper-main">
      <p>Adjust the image</p>
      <div
        className="image-cropper"
        style={{ position: "relative", height: "400px", width: "100%" }}
      >
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatio || 9 / 16}
          onCropChange={setCrop}
          onCropComplete={(a, p) =>
            onCropComplete({
              croppedAreaPixels: p,
              imageSrc: imageSrc,
              src: image,
              rotation,
            })
          }
        />
      </div>
      <div className="controls">
        <p style={{ marginTop: 14, fontWeight: 600 }}>Zoom</p>
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range"
        />
      </div>
      <div className="controls">
        <p style={{ marginTop: 14, fontWeight: 600 }}>Rotation</p>
        <input
          type="range"
          value={rotation}
          min={0}
          max={360}
          step={1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setRotation(e.target.value);
          }}
          className="zoom-range"
        />
      </div>
    </div>
  );
}
