import React, { useEffect, useState } from "react";
import HeadingMain from "../../assets/elements/HeadingMain";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import MainServices from "../../services/MainServices";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import { urlToFileName } from "../../assets/functions/helpers";
import ReactQuill from "react-quill";
import { LazyLoader } from "../../assets/loaders/index";

export default function Policies() {
  const {
    getAboutus,
    getPrivacyPolicy,
    getTermsAndConditions,
    getReturnPolicy,
    getCookiePolicy,
    updateSettings,
    fileUpload,
  } = MainServices();
  const [addFaqInProgress, setAddFaqInProgress] = useState(false);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [privacyContent, setPrivacyContent] = useState({});
  const [currentFaq, setCurrentFaq] = useState("");
  const [formikValues, setFormikValues] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const formikFieldsSchema = ["name", "content"];
  const [buttonLoading, setButtonLoading] = useState(false);

  //hooks
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();

  //url Params
  const addCategoryFaqSchema = Yup.object().shape({
    content: Yup.string().required(
      t("Category Description") + " " + t("is required")
    ),
  });

  //apis
  useEffect(() => {
   onMount();
    //eslint-disable-next-line
  }, []);

  const onMount= ()=>{
    type === "about" && getAboutusList();
    type === "privacy_policy" && getprivacyPolicyList();
    type === "terms_and_conditions" && getTermsAndConditionsList();
    type === "return_policy" && getReturnPolicyList();
    type === "cookie_policy" && getCookiePolicyList();
  }

  // useEffect(() => {
  //   const formikValues = getFormikValuesForAllLanguages(formikFieldsSchema);
  //   setFormikValues(formikValues);
  //   // eslint-disable-next-line
  // }, []);

  const getAboutusList = async () => {
    setPageLoading(true);
    await getAboutus({})
      .then((res) => {
        if (res && res.success) {
          const privacyContent = res.data || {};
          setPrivacyContent(privacyContent);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getprivacyPolicyList = async () => {
    setPageLoading(true);
    await getPrivacyPolicy({})
      .then((res) => {
        if (res && res.success) {
          const privacyContent = res.data || {};
          setPrivacyContent(privacyContent);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getTermsAndConditionsList = async () => {
    setPageLoading(true);
    await getTermsAndConditions({})
      .then((res) => {
        if (res && res.success) {
          const privacyContent = res.data || {};
          setPrivacyContent(privacyContent);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getReturnPolicyList = async () => {
    setPageLoading(true);
    await getReturnPolicy({})
      .then((res) => {
        if (res && res.success) {
          const privacyContent = res.data || {};
          setPrivacyContent(privacyContent);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getCookiePolicyList = async () => {
    setPageLoading(true);
    await getCookiePolicy({})
      .then((res) => {
        if (res && res.success) {
          const privacyContent = res.data || {};
          setPrivacyContent(privacyContent);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const updateSettingsList = async (values) => {
    setButtonLoading(true);

    const details = {
      name: type,
      content: values.content,
    };
    await updateSettings(
      details,
      privacyContent?.publicId,
      privacyContent?.concurrencyStamp
    )
      .then((res) => {
        console.log("product added res", res);
        if (res.success) {
          notifySuccess("Policy Updated!");
          setButtonLoading(false);
          onMount();
        } else {
          notifyError("Failed!");
          setButtonLoading(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setButtonLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="faq-list-main">
      <HeadingMain text="Policies" />
      {pageLoading ? (
        <LazyLoader />
      ) : (
        <div className="faq-table-main">
          <div className="addBrand-main">
            <p className="heading-text">Please enter details</p>

            <div className="addBrand-input-form">
              <Formik
                initialValues={{
                  // ...formikValues,
                  content: privacyContent.content || "",
                  // ...privacyContent.languagesContent,
                }}
                onSubmit={(values) => {
                  updateSettingsList(values);
                }}
                // validationSchema={addCategoryFaqSchema}
                enableReinitialize
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="form-group" style={{ marginTop: 20 }}>
                      <label className="form-label" for="content">
                        Description
                      </label>
                      <div className="form-control-wrap">
                        <div className="instrument-editor">
                          <ReactQuill
                            onChange={(e) => {
                              setFieldValue("content", e);
                            }}
                            value={values.content}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 
                    {availableLanguages.map((language) => {
                      return (
                        <>
                          <div className="form-group" style={{ marginTop: 20 }}>
                            <label
                              className="form-label"
                              for={"content_" + language.value}
                            >
                              {`${t("Description")} ${t("in")} ${t(
                                language.name
                              )} (${language.symbol})`}
                            </label>
                            <div className="form-control-wrap">
                              <div className="instrument-editor">
                                <ReactQuill
                                  onChange={(e) => {
                                    setFieldValue(
                                      "content_" + language.value,
                                      e
                                    );
                                  }}
                                  value={values["content_" + language.value]}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })} */}

                    <div className="errors-after-sumbit">
                      {errorsOnSubmit.map((error) => (
                        <p>{error}</p>
                      ))}
                    </div>

                    <div className="save-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Save"
                        isLoading={addFaqInProgress}
                      />
                    </div>
                    <div className="save-btn-float"></div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
