import React, { useEffect, useState } from "react";
import "./ProductDetails.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import MainServices from "../../services/MainServices";
import CustomModal from "../../assets/elements/CustomModal";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { Formik } from "formik";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import * as Yup from "yup";
import { ButtonLoader } from "../../assets/loaders";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import UpdateProductPrice from "./UpdateProductPrice";

//Swiper modules
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

//Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import HeadingMain from "../../assets/elements/HeadingMain";
import ReorderImages from "./ReorderImages";

export default function ProductDetails() {
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState({});
  const { getProductDetails } = MainServices();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [reorderModal, setReorderModal] = useState(false);
  const [updatePriceModal, setUpdatePriceModal] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [isUpdateStatusInProgress, setIsUpdateStatusInProgress] =
    useState(false);
  const [currentPrice, setCurrentPrice] = useState({});
  const [productTypeDetails, setProductTypeDetails] = useState();
  const { approveProductByAdmin, getProductTypes } = MainServices();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  //swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  //url linkig
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("productId");

  const schema = {
    changeStatusSchema: Yup.object().shape({
      status: Yup.string().required(t("Status") + " " + t("is required")),
    }),

    rejectedSchema: Yup.object().shape({
      status: Yup.string().required(t("Status") + " " + t("is required")),
      remark: Yup.string().required(t("Remark") + " " + t("is required")),
    }),
  };

  const getProductTypeList = async (filters) => {
    const details = {
      filters: filters || [{ key: "status", eq: "active" }],
    };
    return await getProductTypes(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            // List of language specific type names
            const languageSpecificData = resData?.map(type => {
              // Language specific product-type name
              const languageSpecificName = type.language["label_" + i18n.language] || type.name;
              // Produt-type fields
              const fields = [];

              // Current language identifier
              // Add language identifier if not default language
              const currentLanguageIdentifier = i18n.language?.includes("en") === false ? "" : "_" + i18n.language

              // Iterate fields and add to template
              type.fields?.forEach((fieldObj, index) => {
                const defaultLabel = fieldObj["label" + index]
                const languageSpecificLabel = fieldObj["label" + index + currentLanguageIdentifier];
                fields.push({
                  label: languageSpecificLabel || defaultLabel,
                  englishLabel: defaultLabel,
                  value: "",
                  category: ""
                })
              })

              return {
                name: languageSpecificName,
                id: type.id,
                fields: fields
              }
            })
            return languageSpecificData
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductTypeDetails = async (productTypeId) => {
    if (productTypeId) {
      return await getProductTypeList().then(typeList => {
        const matchingTypeObject = typeList.find(type => type.id == productTypeId);
        return matchingTypeObject
      })
    }
  }

  //apis
  useEffect(() => {
    // console.log("running")
    if (productId)
      getProductDetailsList(productId);
    //eslint-disable-next-line
  }, [i18n.language, updatePriceModal]);

  const getProductDetailsList = async (productId) => {
    if (!productId) return;
    return await getProductDetails(productId)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : {};
            console.log('resData1', resData)
            // To get lanuage specific info on product-type
            const productTypeTemplate = await getProductTypeDetails(resData?.details?.productType)
            console.log("i18n", i18n.language)
            if (i18n.language?.includes("en") === false) {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject = resData?.productLanguages?.find(languageSpecificDetails => languageSpecificDetails.locale === i18n.language);
              languageSpecificDetailsObject && Object.keys(languageSpecificDetailsObject).forEach(key => {
                if (!languageSpecificDetailsObject[key]) {
                  delete languageSpecificDetailsObject[key];
                }
              })
              resData = { ...resData, ...languageSpecificDetailsObject };

              // Language specific labels for product type fields
              let languageSpecificFieldData = { ...resData?.details }
              // Iterate through details object
              for (const [fieldLabelEnglish, fieldValue] of Object.entries(resData?.details)) {
                // Get language specific field label from product-type template
                let languageSpecificFieldLabel = productTypeTemplate?.fields.find(typeObj => typeObj.englishLabel?.toLowerCase() === fieldLabelEnglish.toLocaleLowerCase())?.label
                // Add lanugage specific label and value to temporary object
                // and remove older english label to avoid duplicates
                if (languageSpecificFieldLabel) {
                  languageSpecificFieldData[languageSpecificFieldLabel] = fieldValue
                  delete languageSpecificFieldData[fieldLabelEnglish]
                }

              }
              // Replace details object with language specific product type labels and values
              resData.details = languageSpecificFieldData
            }
            resData.productTypeName = productTypeTemplate?.name
            setProductDetails(resData);
            console.log('productDetails', productDetails)
            setCurrentPrice(resData?.productPrice);
            setProductImages(
              resData.productImages?.map((imageObj) => imageObj.images)
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateProductStatus = async (values) => {
    if (!productId || !values) return;
    setIsUpdateStatusInProgress(true);
    const details = {
      listing_status: values.status
    }
    if (values.status === "rejected")
      //details.remark = values.remark

      await approveProductByAdmin(
        details,
        productId,
        productDetails.concurrencyStamp
      )
        .then((res) => {
          if (res.success) {
            notifySuccess("Status updated!");
            getProductDetailsList(productId);
            modalToggle();
          } else {
            notifyError("Status update failed!");
          }
          setIsUpdateStatusInProgress(false);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  //functions
  const statusOptions = [
    { label: "Approve", value: "approved" },
    { label: "Reject", value: "rejected" },
    { label: "Blocked", value: "blocked" },
    { label: "Hold", value: "pending" },
    { label: "Delete", value: "deleted" },
  ];

  const modalToggle = () => {
    setModal(!modal);
  };

  const toggleReorderModal = () => {
    setReorderModal(!reorderModal);
  };

  const onStatusChange = (selectedStatus) => {
    setSelectedStatus(selectedStatus.target.value);
  };

  return (
    <div className="product-details-main">
      {updatePriceModal && productId && <UpdateProductPrice
        modal={updatePriceModal}
        setModal={setUpdatePriceModal}
        productId={productId}
        productPriceObjectId={productDetails?.productPrice?.publicId}
        currentPrice={productDetails?.productPrice}
      />
      }
      <div className="product-details-headings-main">
        <HeadingMain
          className="product-details-primary-heading"
          text={t("Product Description")}
        />

        <div className="change-status-flex-main">
          {user.role === "admin" ?
            <>
              <PrimaryButton title="Change Status" onClick={modalToggle} />
              <p style={{ textTransform: "capitalize" }}>{t("Status")}: {t(productDetails?.listingStatus)}</p>
            </>
            :
            <p>{t("Status")}: {t(productDetails?.listingStatus)}</p>}


          <Link onClick={() => navigate(-1)}>
            <AiOutlineClose color="#000000" size={18} />
          </Link>
        </div>
      </div>

      <div className="product-details-card">
        <div className="product-details-flex-main">
          <div className="product-details-left-main">
            <div className="swiper_container">
              {productImages && productImages.length > 0 ? (
                <>
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    {productImages?.map((img, index) => (
                      <SwiperSlide key={index}>
                        <img
                          src={img}
                          alt="logo"
                          className="product-desc-image-main"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    {productImages?.map((img, index) => (
                      <SwiperSlide
                        key={index}
                        className="product-desc-thumb-img"
                      >
                        <img src={img} alt="logo" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : (
                <p>{t("No Images Found")}</p>
              )}
            </div>
            {user.role !== "admin" && <div>
              <p onClick={toggleReorderModal} className="reorder-click">
                {t("Click here to rearrange the order of images")}
              </p>
            </div>}
          </div>
          <div className="product-details-right-main">
            <div className="product-details-secondary-heading-main">
              <h1 className="product-details-secondary-heading">
                {productDetails?.title}
              </h1>
              <h5 className="product-details-stock-count">
                150 {t("Instock")}
              </h5>
            </div>
            <div className="solid-border-line"></div>

            <h1 className="product-details-secondary-heading">
              {t("Details")}
            </h1>
            <div
              dangerouslySetInnerHTML={{ __html: productDetails?.description }}
            />

            <div className="solid-border-line"></div>
            <div>
              <div className="product-details-bottom-flex">
                <span>{t("Category")}:</span>
                {productDetails.productCategories &&
                  productDetails.productCategories.length > 0 &&
                  productDetails.productCategories.map((eachCategory) => (
                    <span>{eachCategory?.category?.name}</span>
                  ))}
              </div>
              <div className="product-details-bottom-flex">
                <span>{t("Quantity")}:</span>
                <span>500</span>
              </div>
              <div className="product-details-bottom-flex">
                <span>{t("Brand")}:</span>
                <span>{productDetails?.brand?.name}</span>
              </div>
              <div className="product-details-bottom-flex">
                <span>{t("Color")}:</span>
                <span>{productDetails?.color || ""}</span>
              </div>
             
              <div className="product-details-bottom-flex">
                <span>{t("Product Type")}:</span>
                <span>{productDetails?.productTypeName || ""}</span>
              </div>
              {productDetails?.details && Object.keys(productDetails.details).map(eachKey => {
                if (eachKey === "productType") return null;
               const nestedObject = productDetails.details[eachKey];

                // Check if the value is an object (nested object)
                if (typeof nestedObject === 'object' && nestedObject !== null) {
                  return 
                  // (
                  //   <div className="product-details-bottom-flex" key={eachKey}>
                  //     <span>{eachKey}:</span>
                  //     <ul>
                  //       {Object.entries(nestedObject).map(([nestedKey, nestedValue]) => (
                  //         <li key={nestedKey}>
                  //           <strong>{nestedKey}: </strong>
                  //           {nestedValue}
                          
                  //         </li>
                  //       ))}
                  //     </ul>
                  //   </div>
                  // );
                }

                return (
                  <div className="product-details-bottom-flex" key={eachKey}>

                 
                    <span>{eachKey}:</span>
                    <span>{productDetails.details[eachKey]}</span>
                  </div>
                );
              })}

              {/* {productDetails?.details && Object.keys(productDetails.details).map(eachKey => {
                if(eachKey === "productType") return null;
                return <div className="product-details-bottom-flex">
                  <span>{eachKey}:</span>
                 <span>{productDetails?.details[eachKey]}</span>
              </div>
              })} */}
              <div className="product-details-bottom-flex font-size-24">
                <span>{t("Price")}</span>
                <span>{currentPrice?.sellingPrice}</span>
              </div>
            </div>
            {user.role !== "admin" && <div className="product-details-right-edit-main">
              <Button
                className="product-details-right-edit-btn"
                onClick={() => {
                  setUpdatePriceModal(true);
                }}
              >
                {t("Edit Price")}
              </Button>
              <Button
                className="product-details-right-edit-btn"
                onClick={() => {
                  navigate({
                    pathname: "/products/add-product",
                    search: `?productId=${productId}`,
                  });
                }}
              >
                {t("Edit Details")}
              </Button>
            </div>
            }
          </div>
        </div>
      </div>

      <ReorderImages
        reorderModal={reorderModal}
        toggleReorderModal={toggleReorderModal}
        productImages={productImages}
        t={t}
        publicId={productId}
      />

      <CustomModal
        toggleFunction={modalToggle}
        open={modal}
        size="md"
        title={t("Change product status")}
      >
        <div>
          <Formik
            initialValues={{
              status: "",
              remark: "",
            }}
            onSubmit={(values) => {
              updateProductStatus(values);
            }}
            validationSchema={
              selectedStatus === "rejected"
                ? schema.rejectedSchema
                : schema.changeStatusSchema
            }
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
            }) => (
              <>
                <div>
                  <FormGroup>
                    <Label for="status">{t("Select Status")}</Label>
                    <Input
                      id="status"
                      name="status"
                      type="select"
                      className="user-form-group"
                      value={values.status}
                      onChange={(e) => {
                        handleChange(e);
                        onStatusChange(e);
                      }}
                      onBlur={handleBlur}
                      style={{ textTransform: "capitalize" }}
                    >
                      <option value="">{t("Select Status")}</option>
                      {statusOptions &&
                        statusOptions.length > 0 &&
                        statusOptions.map((item) => {
                          return (
                            <option value={item.value}>{t(item.label)}</option>
                          );
                        })}
                    </Input>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="status"
                    />
                  </FormGroup>
                  {values.status === "rejected" && (
                    <FormGroup>
                      <Label for="remark">{t("Remarks")}</Label>
                      <Input
                        id="remark"
                        name="remark"
                        placeholder={t("Enter Remark")}
                        type="textarea"
                        className="user-form-group"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.remark}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="remark"
                      />
                    </FormGroup>
                  )}
                </div>
                <div class="btn-flex-main" style={{ display: "flex", gap: "1em" }}>
                  <Button
                    type="submit"
                    color="danger"
                    onClick={handleSubmit}
                    className="me-2"
                  >
                    {isUpdateStatusInProgress ? <ButtonLoader /> : (t("Submit"))}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      modalToggle();
                    }}
                  >
                    {t("Close")}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </CustomModal>
    </div>
  );
}
