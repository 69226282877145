import React from "react";
import "./OrderDetails.scss";
import { Form, FormGroup, Input, Button } from "reactstrap";
import { RiHome4Line } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import TableImageOne from "../../assets/images/tableimg1.png";

export default function OrderDetails() {
  return (
    <div className="order-details-main">
      <div>
        <h1 className="order-details-heading-main">Order Details</h1>
      </div>
      <div style={{ display: "flex", gap: "20px", margin: "30px 0px" }}>
        <div className="order-details-right-main">
          <div className="order-id-flex-main">
            <span className="order-id-black-text">Order #112263316163</span>
            <span className="order-id-green-text">
              Delivered on Tue, Aug 17, 2021, 02:27 PM
            </span>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 Billing-address-main">
              <div>
                <div className="order-icon-bg">
                  <RiHome4Line className="order-icon" />
                </div>
              </div>
              <div>
                <h1 className="billing-address-heading">Billing Address</h1>
                <p className="billing-address-text">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 Billing-address-main">
              <div>
                <div className="order-icon-bg">
                  <RiHome4Line className="order-icon" />
                </div>
              </div>
              <div>
                <h1 className="billing-address-heading">Shipping Address</h1>
                <p className="billing-address-text">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 Billing-address-main">
              <div>
                <div className="order-icon-bg">
                  <MdOutlineEmail className="order-icon" />
                </div>
              </div>
              <div>
                <h1 className="billing-address-heading">Email Address</h1>
                <p className="billing-address-text">user@gmail.com</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 Billing-address-main">
              <div>
                <div className="order-icon-bg">
                  <IoCallOutline className="order-icon" />
                </div>
              </div>
              <div>
                <h1 className="billing-address-heading">Mobile Number</h1>
                <p className="billing-address-text">+91 9896852565</p>
              </div>
            </div>
          </div>
          <div className="dotted-line-main"></div>
          <div className="item-card-main">
            <h1 className="item-card-heading">2 Items</h1>
            <div className="item-card-flex">
              <div className="item-card-inner-flex">
                <div className="table-image-main">
                  <img
                    src={TableImageOne}
                    alt="icon not found"
                    className="table-image"
                  />
                </div>
                <span className="item-card-sub-heading">Red Rose</span>
              </div>
              <div className="item-card-sub-heading">150 SAR</div>
            </div>

            <div className="item-card-flex">
              <div className="item-card-inner-flex">
                <div className="table-image-main">
                  <img
                    src={TableImageOne}
                    alt="icon not found"
                    className="table-image"
                  />
                </div>
                <span className="item-card-sub-heading">Yellow Rose</span>
              </div>
              <div className="item-card-sub-heading">150 SAR</div>
            </div>
            <div className="item-card-flex">
              <div className="item-card-inner-flex">
                <div className="table-image-main">
                  <img
                    src={TableImageOne}
                    alt="icon not found"
                    className="table-image"
                  />
                </div>
                <span className="item-card-sub-heading">Pink Rose</span>
              </div>
              <div className="item-card-sub-heading">150 SAR</div>
            </div>
            <div className="item-card-flex">
              <div className="item-card-inner-flex">
                <div className="table-image-main">
                  <img
                    src={TableImageOne}
                    alt="icon not found"
                    className="table-image"
                  />
                </div>
                <span className="item-card-sub-heading">Vanilla Cake</span>
              </div>
              <div className="item-card-sub-heading">150 SAR</div>
            </div>
            <div className="item-card-flex">
              <div className="item-card-inner-flex">
                <div className="table-image-main">
                  <img
                    src={TableImageOne}
                    alt="icon not found"
                    className="table-image"
                  />
                </div>
                <span className="item-card-sub-heading">Choclate Cake</span>
              </div>
              <div className="item-card-sub-heading">150 SAR</div>
            </div>
          </div>
          <div className="dotted-line-main mt-4 mb-4"></div>
          <div className="item-card-main">
            <div className="item-card-flex">
              <span className="total-bill-text-fent">ITEM Total </span>
              <span className="total-bill-text-fent">600 SAR</span>
            </div>
            <div className="item-card-flex">
              <span className="total-bill-text-fent">Packing Charges</span>
              <span className="total-bill-text-fent">0.0</span>
            </div>
            <div className="item-card-flex">
              <div className="total-bill-text-fent">
                Delivery Partner Charges
              </div>
              <div className="total-bill-text-fent">25 SAR</div>
            </div>
            <div className="item-card-flex">
              <div
                className="total-bill-text-fent"
                style={{ color: "#4F9807" }}
              >
                Disscount Applied( PPNUE-83983)
              </div>
              <div className="total-bill-text-fent">-50 SAR</div>
            </div>
            <div className="item-card-flex">
              <div className="total-bill-text-fent">Taxes</div>
              <div className="total-bill-text-fent">100 SAR</div>
            </div>
          </div>
          <div className="dotted-line-main mt-4 mb-4"></div>
          <div className="total-bill-flex">
            <div className="total-bill-inner-flex">
              <span className="total-bill-text-bold">Bill Total</span>
              <span className="total-bill-text-fent">
                Payment: Via Creadit Card
              </span>
            </div>
            <div className="total-bill-text-bold">670 SAR</div>
          </div>
        </div>

        <div className="order-details-left-main">
          <div className="order-details-inner-main">
            <h1 className="order-details-heading-main">Order Action</h1>
            <Form className="row">
              <FormGroup>
                <Input
                  id="clientSource"
                  name="clientSource"
                  type="select"
                  className="order-form-group"
                >
                  <option>Choose an action</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </Input>
              </FormGroup>
            </Form>
            <div className="order-left-btn-flex">
              <Button className="red-trash-btn">Move to trash</Button>
              <Button className="update-btn">Update</Button>
            </div>
          </div>

          <div className="order-details-inner-main">
            <h1 className="order-details-heading-main">Order Notes</h1>
            <div>
              <div className="order-grey-card">
                <p className="order-grey-card-text">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
              <div className="order-left-btn-flex">
                <p className="order-left-btn-text">
                  9th March 2020 at 12:46 pm
                  <span className="order-left-btn-bold-text"> By Admin</span>
                </p>
                <Button className="red-trash-btn">Delete Note</Button>
              </div>
            </div>
            <div>
              <div className="order-grey-card">
                <p className="order-grey-card-text">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
              </div>
              <div className="order-left-btn-flex">
                <p className="order-left-btn-text">
                  9th March 2020 at 12:46 pm By Admin
                </p>
                <Button className="red-trash-btn">Delete Note</Button>
              </div>
            </div>
            <div className="solid-line-main"></div>
            <div>
              <Form className="row mt-3">
                <FormGroup>
                  <label for="add-note" className="order-form-group-label">Add Note</label>
                  <Input
                    id="add-note"
                    name="add-note"
                    type="text-area"
                    className="order-form-group"
                  ></Input>
                </FormGroup>
                <div className="private-select-flex-main">
                  <FormGroup>
                    <Input
                      id="privateSelect"
                      name="privateSelect"
                      type="select"
                      className="private-select-form-group"
                    >
                      <option>Private</option>
                      <option>Public</option>
                      <option>Protected</option>
                    </Input>
                  </FormGroup>
                  <Button className="add-btn">Add</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
