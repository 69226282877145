import React, {useEffect, useState} from "react";
import { Input, Label, FormGroup, Row } from "reactstrap";
import mapicon from "../../assets/images/icons/google-map.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import Map from "./Map";
import { notifyError } from "../elements/Toaster";
import { useTranslation } from "react-i18next";
import "./LocationInMap.scss"


const LocationInMap = ({values, handleChange, setFieldValue, countryList, stateList, handleChangeCountry}) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [currentCoordinates, setCurrentCoordinates] = useState({});
    //const [userSelectedMapDetails, setUserSelectedMapDetails] = useState({});
    const [temporaryHoldUserSelection, setTemporaryHoldUserSelection] =
      useState(null);
  const [locationDetectionAllowed, setLocationDetectionAllowed] = useState();


  const getAndSetCurrentLocationCoordinates = async () => {
    let lat;
    let lng;
    function success(pos) {
      const crd = pos.coords;
      lat = crd.latitude || 0;
      lng = crd.longitude || 0;
      setCurrentCoordinates({ lat, lng });
      setLocationDetectionAllowed(true);
      toggleMapWindow();
    }
    function error() {
      notifyError("Please allow location permission in browser settings!");
      setLocationDetectionAllowed(false);
    }
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  const openMap = () => {
    getAndSetCurrentLocationCoordinates();
  };

  const setNewLocationDetails = () => {
    if (temporaryHoldUserSelection != null) {
      //setUserSelectedMapDetails({ ...temporaryHoldUserSelection });
      //setFormikValues(oldValues => ({...oldValues, ...temporaryHoldUserSelection }));

      setFieldValue("currentaddress", temporaryHoldUserSelection.location);
      setFieldValue("customaddress", temporaryHoldUserSelection.location);
      setFieldValue("location", temporaryHoldUserSelection.location);
      setFieldValue("city", temporaryHoldUserSelection?.city);
      setFieldValue("postalcode", temporaryHoldUserSelection?.postalcode);
      setFieldValue("latitude", temporaryHoldUserSelection?.latitude);
      setFieldValue("longitude", temporaryHoldUserSelection?.longitude);
      let countryId = null;
      if(Array.isArray(countryList)) 
        countryId = countryList.find(countryObj => countryObj.name === temporaryHoldUserSelection.country)?.id;
      if(countryId)
       setFieldValue("country", countryId);
      handleChangeCountry(countryId);
    }
  };

  useEffect(() => {
    if(stateList)
    {
      let stateId = null;
      if(stateList && Array.isArray(stateList))
        stateId = stateList.find(stateObj => stateObj.name === temporaryHoldUserSelection?.state)?.id;
      if(stateId)
        setFieldValue("state", stateId);
    }
  }, [stateList])

  const clearUserSelectedMapLocation = () => {
    //setUserSelectedMapDetails({})
    //setCurrentCoordinates({})
  };

  const toggleMapWindow = () => {
    setModal(!modal);
  };
  
  return (
    <div className="map-inputsection">
      <Row className="g-2">
        {locationDetectionAllowed ? (
          <Modal isOpen={modal} toggle={toggleMapWindow}>
            <ModalHeader toggle={toggleMapWindow}>
              Choose a location
            </ModalHeader>
            <ModalBody>
              <Map
                isVisible={true}
                zoom={15}
                setTemporaryHoldUserSelection={setTemporaryHoldUserSelection}
                latLngCoordinatesObject={currentCoordinates}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  setNewLocationDetails();
                  toggleMapWindow();
                }}
              >
                Ok
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  clearUserSelectedMapLocation();
                  toggleMapWindow();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        <FormGroup>
          <Label for="location">{t("Location")}</Label>
          <div className="input-icon-group location" onClick={openMap}>
            <Input
              id="location"
              name="location"
              type="text"
              disabled
              className="disabled"
              placeholder={t("Your Location")}
              value={
                values.currentaddress ? values.currentaddress + "    " : ""
              }
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "latitude",
                  //userSelectedMapDetails?.latitude
                  values.latitude
                );
                setFieldValue(
                  "longitude",
                  //userSelectedMapDetails?.longitude
                  values.longitude
                );
              }}
            />
            
            <img 
              src={mapicon} 
              alt="icon" 
              focusable 
              className="icon map"
              style={
                document.dir === "ltr"
                  ? {}
                  : { left: "0.5em", right: "unset" }
                } 
              />
            <Input
              id="latitude"
              name="latitude"
              type="text"
              hidden
              value={values.latitude}
              onChange={handleChange}
            />
            <Input
              id="longitude"
              name="longitude"
              type="text"
              hidden
              value={values.longitude}
              onChange={handleChange}
            />
            {/* <div className="map" style={{ display: "none" }}>
                {!formikValues.location && isModifying && values.latitude && values.longitude && <Map
            
                    readOnly={true}
                    setTemporaryHoldUserSelection={
                        setTemporaryHoldUserSelection
                        }
                    latLngCoordinatesObject={{lat: values.latitude, lng: values.longitude}
                    }
                />}
                </div> */}
          </div>
        </FormGroup>
      </Row>
    </div>
  );
};

export default LocationInMap;
