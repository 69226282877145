import React, { useEffect, useState } from "react";
import HeadingMain from "../../assets/elements/HeadingMain";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import axios from "axios";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeCompatibleDroppable from "./StrictModeCompatibleDroppable";
import { nanoid } from 'nanoid';

import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import "./Menu.scss";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import ErrorMessage from "../../assets/elements/ErrorMessage";

import * as Yup from "yup";

import editicon from "../../assets/images/icons/edit-curved.svg";
import infoIcon from "../../assets/images/icons/info.svg";
import deleteicon from "../../assets/images/icons/delete-curved.svg";


export default function Menu(props) {
  const { t, i18n } = useTranslation();
  const {menuNumber, editable, menuObject, updateMenu, deleteMenu} = props;
  let menuName;
  if(menuObject)
    menuName = menuObject["name_"+i18n.language] || menuObject.name;
  const [categories, setCategories] = useState(props.categories || []);
  const [modal, setModal] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const [itemvalues, setItemValues] = useState({});
  const [itemModal, setItemModal] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryInfoModal, setCategoryInfoModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [itemInfoModal, setItemInfoModal] = useState(false);

  const formikFieldsSchema = ["name"];
  const itemFieldSchema = ["name"];

  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();

  useEffect(() => {
    let formikValues = getFormikValuesForAllLanguages(formikFieldsSchema);
    setFormikValues(formikValues);
    let values = getFormikValuesForAllLanguages(itemFieldSchema);
    setItemValues(values);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {    
    typeof(updateMenu) === "function" && updateMenu(categories);
    // eslint-disable-next-line
  }, [categories])

  const getCategories = () => {
    axios.get("menu_items.json", {}).then((res) => {
      if (res) {
        let categories = res?.data || {};
        setCategories(categories?.categories || []);
      }
    });
  };

  const schemas = {
    addCategory: Yup.object().shape({
      name: Yup.string().required("Category Name is required"),
    }),

    addItem: Yup.object().shape({
      name: Yup.string().required("Item name is required"),
    }),
  };

  const onDeleteMenu = () => {
    typeof(deleteMenu) === "function" && deleteMenu(menuNumber-1);
  };

  const onDeleteCategory = (categoryIndex) => {
    let allCategories = [...menuObject.categories];
 
    // Assign empty {} at user clicked index in categories array
    if(allCategories[categoryIndex])
      allCategories[categoryIndex] = {};

    // Filter categories to ignore empty {} values
    // User clicked categroy will be removed in the final categories
    allCategories = allCategories.filter(categoryObj => categoryObj.name)

    setCategories(allCategories);
  };

  const onDeleteItem = (categoryIndex, itemIndex) => {
    let allCategories = [...menuObject.categories];
 
    // Assign empty {} at user clicked index in items array in the particular cateogry
    if(allCategories[categoryIndex])
      if(allCategories[categoryIndex].items)
        allCategories[categoryIndex].items[itemIndex] = {};

    // Filter items in all categories to ignore empty {} values
    // User clicked item will be removed in the final categories
    allCategories = allCategories.map(categoryObj => {
      // Filter items and remove empty object items
      categoryObj.items = categoryObj?.items?.filter(itemObj => itemObj.name)
      return categoryObj
    })

    setCategories(allCategories);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      categories,
      result.source.index,
      result.destination.index
    );

    setCategories(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onSubDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let items = [];
    let parentId = result?.source?.droppableId;
    if (parentId) {
      let parentCategory = categories.find((eachC) => eachC._id === parentId);
      if (parentCategory) {
        items = parentCategory.items;
        let mitems = reorder(
          items,
          result.source.index,
          result.destination.index
        );
        let mcategories = categories.map((eachC) => {
          if (eachC._id === parentId) {
            return {
              ...eachC,
              items: mitems,
            };
          } else {
            return eachC;
          }
        });
        setCategories(mcategories);
      }
    }
  };

  const openAddCategoryModal = (selectedCategory) => {
    selectedCategory ? setSelectedCategory(selectedCategory) : setSelectedCategory("")
    setModal(!modal);
  };
  const onCategoryInfoModal = (selectedCategory, editModal) => {
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
    }
    if (editModal) {
      openAddCategoryModal(selectedCategory);
    }
    setCategoryInfoModal(!categoryInfoModal);
  };
  const onAddItemModal = (selectedItem, category) => {
    selectedItem ? setSelectedItem(selectedItem) : setSelectedItem("")
    category ? setSelectedCategory(category) : setSelectedCategory("")
    setItemModal(!itemModal);
  };

  const onItemInfoModal = (selectedItem, editModal) => {
    if (selectedItem) {
      setSelectedItem(selectedItem);
    }
    if (editModal) {
      onItemAdd(selectedItem);
    }
    setItemInfoModal(!itemInfoModal);
  };

  const onAddCategory = (values) => {
    console.log(values)
    let category = {
      ...values,
      _id: nanoid(),
      items: [],
    };
    let mcategories = [...menuObject.categories];
    // Check for duplicate entries
    for(let categoryObj of mcategories)
    {
      if(categoryObj.name.toLowerCase() === values.name.toLowerCase())
      {
        openAddCategoryModal();
        return
      }
    }
    mcategories.push(category);
    console.log("mcat", mcategories)
    setCategories(mcategories);
    openAddCategoryModal();
  };
  const updateCategory = (values) => {
    let category = {
      ...selectedCategory,
      ...values,
    };
    let mcategories = categories;
    mcategories = mcategories.map((_) => {
      if (selectedCategory._id === _._id) {
        return category;
      }
      return _;
    });
    setCategories(mcategories);
    openAddCategoryModal();
  };

  const onItemAdd = (values) => {
    let currentCategory = values.category || {};
    let currentCategoryItems = [...currentCategory?.items];
    let categoryId = values.category?._id;
    let item = {
      ...values,
      _id: nanoid(),
    };
    delete item.category;

    // Check for duplicate entries
    values.category?.items?.forEach((itemObj) => {
      if(itemObj.name.toLowerCase() === values.name.toLowerCase())
      {
        onAddItemModal();
        item = null;
      }
    })
  
    let newCategory = {
      ...values.category,
      items: [...currentCategoryItems, item],
    };
    let newCategories = categories.map((_) => {
      if (_._id === categoryId) {
        return newCategory;
      }
      return {
        ..._,
      };
    });
    item && setCategories(newCategories);
    onAddItemModal();
  };
  const updateItem = (values) => {
    let allcategories = categories || [];
    let categoryId = values.category;
    console.log("up",values);
    if (categoryId !== selectedItem.category) {
      let selectedCategory =
        allcategories.find((each) => each._id === categoryId) || {};
      let item = {
        ...values,
      };
      delete item.category;
      selectedCategory = {
        ...selectedCategory,
        items: [...selectedCategory.items, item],
      };
      let updatedC =
        allcategories.find((each) => each._id === selectedItem.category) || {};
      let updatedItems = [];
      if (updatedC) {
        updatedItems = updatedC.items;
        updatedItems = updatedItems.filter((_) => _._id !== selectedItem._id);
      }
      updatedC = {
        ...updatedC,
        items: updatedItems,
      };
      allcategories = allcategories.map((_) => {
        if (_._id === categoryId) {
          return selectedCategory;
        }
        if (_._id === selectedItem.category) {
          return updatedC;
        }
        return {
          ..._,
        };
      });
    } else {
      let selectedCategory =
        allcategories.find((each) => each._id === selectedItem.category) || {};
      let item = {
        ...values,
      };
      delete item.category;
      let items = selectedCategory.items || [];
      items = items.map((_) => {
        if (_._id === selectedItem._id) {
          return item;
        }
        return _;
      });
      selectedCategory = {
        ...selectedCategory,
        items,
      };

      allcategories = allcategories.map((_) => {
        if (_._id === selectedItem.category) {
          return selectedCategory;
        }
        return {
          ..._,
        };
      });
    }

    setCategories(allcategories);
    onAddItemModal();
  };

  return (
    <div className="menu-main my-4">
      <div className="categories-add-btn d-flex justify-content-between gap-2">
        <div className="d-flex align-items-center gap-2">
          <HeadingMain text={"Menu"+(menuNumber ? " "+menuNumber : "")+(menuName ? ": "+menuName : "")}></HeadingMain>
          {editable && <img
            src={deleteicon}
            alt="delete"
            class="btn"
            style={{cursor: "pointer"}}
            onClick={() => onDeleteMenu()}
          />}
        </div>
        <div className="d-flex gap-2">
          {editable && <PrimaryButton title={"Add Category"} onClick={() => openAddCategoryModal("")} /> }
        </div>
      </div>
      <div className="categories-and-items">
        {menuObject.categories && menuObject.categories.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeCompatibleDroppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {menuObject.categories.map((category, categoryIndex) => (
                    <Draggable
                      key={category._id}
                      draggableId={category._id}
                      index={categoryIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="each-category p-3 mt-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div class="menu_card_header d-flex justify-content-between align-items-center my-2">
                            <div className="d-flex">
                              <p>
                                {category[`name_${i18n.language}`]
                                  ? category[`name_${i18n.language}`]
                                  : category.name}
                              </p>
                              <div className="action edit d-flex align-items-center mx-4 gap-2">
                                <img
                                  src={infoIcon}
                                  alt="edit"
                                  className="item-icon"
                                  onClick={() => onCategoryInfoModal(category)}
                                />
                                {editable && <img
                                  src={editicon}
                                  alt="edit"
                                  class="item-icon"
                                  onClick={() => openAddCategoryModal(category)}
                                />}
                                {editable && <img
                                  src={deleteicon}
                                  alt="delete"
                                  class="item-icon"
                                  onClick={() => onDeleteCategory(categoryIndex)}
                                />}
                              </div>
                            </div>
                            {editable && <PrimaryButton title={"Add Item"} onClick={() => onAddItemModal("", category)} /> }
                            
                          </div>
                          <div class="menu_item my-2">
                            {category.items && category.items.length > 0 && (
                              <DragDropContext onDragEnd={onSubDragEnd}>
                                <StrictModeCompatibleDroppable
                                  droppableId={category._id}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {category.items.map((subItem, itemIndex) => (
                                        <Draggable
                                          key={subItem._id}
                                          draggableId={subItem._id}
                                          index={itemIndex}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div class="item d-flex justify-content-between align-items-center">
                                                <label class="dish">
                                                  {subItem[
                                                    `name_${i18n.language}`
                                                  ]
                                                    ? subItem[
                                                        `name_${i18n.language}`
                                                      ]
                                                    : subItem.name}
                                                </label>
                                                <div class="action edit d-flex align-items-center mx-4 gap-2">
                                                  <img
                                                    src={infoIcon}
                                                    alt="edit"
                                                    class="item-icon"
                                                    onClick={() =>
                                                      onItemInfoModal({
                                                        ...subItem,
                                                        category: category._id,
                                                      })
                                                    }
                                                  />
                                                  {editable && <img
                                                    src={editicon}
                                                    alt="edit"
                                                    class="item-icon"
                                                    onClick={() =>
                                                      onAddItemModal({
                                                        ...subItem,
                                                        category: category._id,
                                                      })
                                                    }
                                                  />}
                                                  {editable && <img
                                                    src={deleteicon}
                                                    alt="delete"
                                                    class="item-icon"
                                                    onClick={() => onDeleteItem(categoryIndex, itemIndex)}
                                                  />}
                                                </div>
                                              </div>
                                              <hr class="m-2" />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </StrictModeCompatibleDroppable>
                              </DragDropContext>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeCompatibleDroppable>
          </DragDropContext>
        )}
      </div>

      <Modal
        isOpen={modal}
        size="lg"
        centered
        toggle={() => openAddCategoryModal("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => openAddCategoryModal("")}
          className="modal-header-custom"
        >
          {`${t("Add a Category")}`}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="d-flex justify-content-between">
              <p className="heading-text">
                {t("Please enter details in English")}
              </p>
            </div>
            <div className="addCategory-input-form">
              <Formik
                initialValues={{
                  ...formikValues,
                  name: selectedCategory?.name || "",
                  ...(selectedCategory ? selectedCategory : {}),
                }}
                onSubmit={(values) => {
                  console.log(values)
                  console.log(selectedCategory)
                  selectedCategory
                    ? updateCategory(values)
                    : onAddCategory(values);
                }}
                validationSchema={schemas.addCategory}
              >
                {({ values, errors, handleBlur, handleChange, touched }) => (
                  <Form>
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <Label for="category-name">
                            {t("Category Name")}
                          </Label>
                          <Input
                            id="category-name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Enter category name")}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="name"
                          />
                        </Row>
                      </div>
                    </FormGroup>
                    {availableLanguages.map((language) => {
                      return (
                        <>
                          <FormGroup>
                            <div className="input-container">
                              <Row>
                                <Label for="category-name">
                                  {`${t("Category Name")} ${t("in")} ${t(
                                    language.name
                                  )} (${language.symbol})`}
                                </Label>
                                <Input
                                  id="category-name"
                                  name={"name_" + language.value}
                                  value={
                                    values["name_" + language.value]
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={`${t("Enter category name")} ${t(
                                    "in"
                                  )} ${t(language.name)} (${language.symbol})`}
                                />
                              </Row>
                            </div>
                          </FormGroup>
                        </>
                      );
                    })}

                    <div className="save-btn-container">
                      <PrimaryButton type="submit" title="Save" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={categoryInfoModal}
        size="lg"
        centered
        toggle={() => onCategoryInfoModal("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => onCategoryInfoModal("")}
          className="modal-header-custom"
        >
          {`${t("Category Info")}`}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="addCategory-input-form">
              <>
                <FormGroup>
                  <div className="input-container">
                    <Row>
                      <Label for="category-name" className="font-weight-bold">
                        {t("Category Name")}
                      </Label>
                      <Label for="category-name">
                        {selectedCategory?.name}
                      </Label>
                    </Row>
                  </div>
                </FormGroup>
                {availableLanguages.map((language) => {
                  return (
                    <>
                      <FormGroup>
                        <div className="input-container">
                          <Row>
                            <Label for="category-name">
                              {`${t("Category Name")} ${t("in")} ${t(
                                language.name
                              )} (${language.symbol})`}
                            </Label>
                            <Label for="category-name">
                              {
                                selectedCategory[
                                  "name_" + language.value
                                ]
                              }
                            </Label>
                          </Row>
                        </div>
                      </FormGroup>
                    </>
                  );
                })}

                {editable && <div className="save-btn-container d-flex gap-1 justify-content-end">
                  <PrimaryButton
                    title="Edit"
                    onClick={() => {
                      onCategoryInfoModal(selectedCategory, true);
                    }}
                  />
                  <PrimaryButton title="Close" onClick={onCategoryInfoModal} />
                </div>}
              </>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={itemInfoModal}
        size="lg"
        centered
        toggle={() => onItemInfoModal("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => onItemInfoModal("")}
          className="modal-header-custom"
        >
          {`${t("Item Info")}`}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="addCategory-input-form">
              <>
                <FormGroup>
                  <div className="input-container">
                    <Row>
                      <Label for="category-name" className="font-weight-bold">
                        {t("Item Name")}
                      </Label>
                      <Label for="category-name">
                        {selectedItem.name}
                      </Label>
                    </Row>
                  </div>
                </FormGroup>
                {availableLanguages.map((language) => {
                  return (
                    <>
                      <FormGroup>
                        <div className="input-container">
                          <Row>
                            <Label for="category-name">
                              {`${t("Item Name")} ${t("in")} ${t(
                                language.name
                              )} (${language.symbol})`}
                            </Label>
                            <Label for="category-name">
                              {selectedItem["name_" + language.value]}
                            </Label>
                          </Row>
                        </div>
                      </FormGroup>
                    </>
                  );
                })}

                {editable && <div className="save-btn-container d-flex gap-1 justify-content-end">
                  <PrimaryButton
                    title="Edit"
                    onClick={() => {
                      onItemInfoModal(selectedItem, true);
                    }}
                  />
                  <PrimaryButton title="Close" onClick={onItemInfoModal} />
                </div>}
              </>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={itemModal}
        size="lg"
        centered
        toggle={() => onAddItemModal("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => onAddItemModal("")}
          className="modal-header-custom"
        >
          {`${t("Add a Category")}`}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="d-flex justify-content-between">
              <p className="heading-text">
                {t("Please enter details in English")}
              </p>
            </div>
            <div className="addCategory-input-form">
              <Formik
                initialValues={{
                  ...itemvalues,
                  name: "",
                  category: selectedCategory,
                  ...(selectedItem ? selectedItem : {}),
                }}
                onSubmit={(values) => {
                  selectedItem ? updateItem(values) : onItemAdd(values);
                }}
                validationSchema={schemas.addItem}
                key={"item-formik"}
              >
                {({ values, errors, handleBlur, handleChange, touched }) => (
                  <Form>
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <Label for="item-name">{t("Item Name")}</Label>
                          <Input
                            id="item-name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Enter Item name")}
                          ></Input>
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="name"
                          />
                        </Row>
                      </div>
                    </FormGroup>
                    {availableLanguages.map((language) => {
                      return (
                        <>
                          <FormGroup>
                            <div className="input-container">
                              <Row>
                                <Label for="category-name">
                                  {`${t("Item Name")} ${t("in")} ${t(
                                    language.name
                                  )} (${language.symbol})`}
                                </Label>
                                <Input
                                  id="category-name"
                                  name={"name_" + language.value}
                                  value={values["name_" + language.value]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={`${t("Enter Item  name")} ${t(
                                    "in"
                                  )} ${t(language.name)} (${language.symbol})`}
                                />
                              </Row>
                            </div>
                          </FormGroup>
                        </>
                      );
                    })}

                    <div className="save-btn-container">
                      <PrimaryButton type="submit" title="Save" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
