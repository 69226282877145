import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";

const LazyComponent = lazy(() => import("./PromotionalBanners"));

export default function PromotionalBanners() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
