import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders/index.jsx";
const LazyComponent = lazy(() => import("./Enquiries.jsx"));

export default function Reports() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
