const userapis = "https://identity-service.mventus.com/";
const fileapis = "https://file-service.mventus.com/";
const productapsi = "https://product-service.mventus.com/";
const cateringapis = "https://catering-service-test.dezensolutions.com/";
const photographyapis = "https://photoshoot-service-test.dezensolutions.com/";
const orderapis = "https://order-service.mventus.com/";

let apiServices = {
  //file_upload apis
  fileUpload: "/fileapis/file",
  publicFileUpload: "/fileapis/public-file",
  getFile: "/fileapis/file",
  downloadFile: "/fileapis/download-file",
  viewFile: "/fileapis/view-file/",

  //user apis
  saveUser: "/userapis/user",
  getUserProfile: "/userapis/user-profile",
  getUserProfileById: "/userapis/user-profile",
  updateUser: "/userapis/users",
  updateUserStatusById: "/userapis/user-status",
  getUsers: "/userapis/users",
  userSignin: "/userapis/login",
  userRegistration: "/userapis/register",
  userResetPassword: "/userapis/reset-password",
  userChangePassword: "/userapis/change-password",
  otpVerification: "/userapis/verify_mobile_otp",
  resendOtp: "/userapis/resend-otp",
  getGoogleSignin: "/userapis/google-signin",

  getSuppliers: "/userapis/suppliers",
  getSupplierById: "/userapis/supplier",
  updateSupplier: "/userapis/supplier",
  updateVerificationDocumentStatus:
    "/userapis/supplier-verification-status-update",

  userSupplierRegistration: "/userapis/user-register",

  //address apis
  saveAddress: "/userapis/address",
  getUserAddress: "/userapis/user-address",
  getUserAddressById: "/userapis/address",

  //countries && states
  getCountries: "/userapis/countries",
  getAllCountriesPublic: "/userapis/get-public-all-countries",
  getStatesByCountry: "/userapis/states",
  getAllStatesPublic: "/userapis/get-public-all-states",
  updateCountryStatus: "/userapis/country",
  updateStateStatus: "/userapis/state",
  getActiveCountries: "/userapis/get-active-countries",

  //brand apis
  saveBrand: "/productapis/brand",
  getBrands: "/productapis/brand",
  updateBrand: "/productapis/brand",

  //categories apis
  getCategory: "/productapis/category",
  saveCategory: "/productapis/category",
  updateCategory: "/productapis/category",

  //notifications apis
  getNotifications: "/userapis/notifications-list",
  getNotificationsBySupplierId: "/userapis/notifications-by-supplier-Id",
  addNotifications: "/productapis/enquiry-communication",
  updateNotification: "/userapis/notifications",

  //product apis
  saveProduct: "/productapis/product",
  variantProduct: "/productapis/variant-product",
  getProductById: "/productapis/product",
  getProductPriceById: "/productapis/product-price",
  getProducts: "/productapis/product",
  updateProductById: "/productapis/product",
  updateProductPriceById: "/productapis/product-price",
  getProductsBySupplierId: "/productapis/products/supplier",
  getProductDetails: "/productapis/product",
  approveProductByAdmin: "/productapis/approve-product",
  saveProductType: "/productapis/product-type",
  updateProductType: "/productapis/product-type",
  getProductTypes: "/productapis/product-type",
  updateProductTypeStatus: "/productapis/product-type-status-update",
  updateProductVariants: "/productapis/update-product-variants",
  updateBatchQuantity: "/productapis/update-inventory-quantity",

  //batch apis
  getBatchesByProduct: "/productapis/get-batches-by-product",
  updateProductBatchPrice: "/productapis/inventory-price",

  uploadProductsFromZip: "/productapis/save-product-and-image-from-excel",
  // importProducts:"/productapis/save-product-and-image-with-links-from-excel",
  importProducts: "/productapis/product/upload",
  uploadZipFile: "/productapis/file_upload",

  //orders apis
  getOrders: "/productapis/order-list",
  getOrdersByType: "/orderapis/order-list",
  orderItemUpdateStatus: "/orderapis/order-product-status-change",
  getOrderById: "/orderapis/order",
  getOrderProductList: "/orderapis/order-product-list",

    //PDF Genration
  generatePdf: "/orderapis/print-invoice",

  // caterting apis
  saveRestaurant: "/cateringapis/restaurants",
  getRestaurants: "/cateringapis/restaurants",
  getRestaurantDetails: "/cateringapis/restaurant",
  updateRestaurantById: "/cateringapis/restaurants",
  updateRestaurantStatus: "/cateringapis/restaurants-status-update",

  //catering category apis
  getRestaurantCategories: "/cateringapis/category",
  saveRestaurantCategory: "/cateringapis/category",
  updateRestaurantCategory: "/cateringapis/category",
  updateRestaurantCategoryStatus: "/cateringapis/category-status-update",

  //catering type apis
  getRestaurantTypes: "/cateringapis/type",
  saveRestaurantType: "/cateringapis/type",
  updateRestaurantType: "/cateringapis/type",
  updateRestaurantTypeStatus: "/cateringapis/type-status-update",

  //photoshootapis
  savePhotoshoot: "/photographyapis/photoshoot",
  getPhotoshoots: "/photographyapis/photoshoots",
  getPhotoshootDetails: "/photographyapis/photoshoot",
  updatePhotoshootById: "/photographyapis/photoshoot",
  updatePhotoshootPriceById: "/photographyapis/photoshoot-price",
  updatePhotoshootStatus: "/photographyapis/photoshoot-status-update",

  getPhotographyCategories: "/photographyapis/category",
  savePhotographyCategory: "/photographyapis/category",
  updatePhotographyCategory: "/photographyapis/category",
  updatePhotographyCategoryStatus: "/photographyapis/category-status-update",

  //working hours
  getWorkingHours: "/userapis/supplier_working_hours_supplierId",
  saveWorkingHours: "/userapis/supplier_working_hours",
  updateWorkingHours: "/userapis/supplier_working_hours",

  //policiesapis
  getAboutus: "/userapis/public-settings/about",
  getPrivacyPolicy: "/userapis/public-settings/privacy-policy",
  getTermsAndConditions: "/userapis/public-settings/terms-and-conditions",
  getReturnPolicy: "/userapis/public-settings/return-policy",
  getCookiePolicy: "/userapis/public-settings/cookie-policy",
  updateSettings: "/userapis/settings",

  uploadProducts: "/productapis/save_product_excel",

  //enquiries
  addInquires: "/productapis/enquiry",
  getInquires: "/productapis/enquiry",
  getInquiresDetails: "/productapis/enquiry",

   //Promotional Banner
   getPromotionalBanners: "/userapis/get-banners",
   savePromotionalBanners: "/userapis/banners",
   updatePromotionalBanners: "/userapis/update-banners",
   updatePromotionalBannersStatus: "/userapis/banners-status-update",

  //quotation draft
  getQuotationDraft: "/productapis/get_draft_by_enquiry_id",
  addQuotationDraft: "/productapis/quotation_draft",
  updateQuotationDraft: "/productapis/quotation_draft",
  deleteQuotationDraft: "/productapis/quotation-draft-status-update",
  getProducts: "/productapis/public-products-list",

  //quotation
  getQuotationDraftItems: "/productapis/quotation_draft_item_draft",
  quotationDraftItem: "/productapis/quotation_draft_item",
  deleteQuotationDraftItem: "/productapis/delete-quotation-draft-item",
  getQuotationByEnquiryId: "/productapis/get_quotation_by_enquiry_id",
  quotation: "/productapis/quotation",
  quotation: "/productapis/quotation",
  downloadQuotation: "/productapis/print_quotation",

  // user api//
  getUsers: "/userapis/users",

  //faq categories
  getFaqCategories: "/userapis/faq-category",
  addFaqCategories: "/userapis/faq-category",
  updateFaqCategories: "/userapis/faq-category",
  updateFaqCategoriesStatus: "",
};

let temp = "";
Object.keys(apiServices).map((key) => {
  temp = apiServices[key].replace(/\/userapis\//i, userapis);
  temp = temp.replace(/\/fileapis\//i, fileapis);
  temp = temp.replace(/\/productapis\//i, productapsi);
  temp = temp.replace(/\/cateringapis\//i, cateringapis);
  temp = temp.replace(/\/photographyapis\//i, photographyapis);
  temp = temp.replace(/\/orderapis\//i, orderapis);
  apiServices[key] = temp;
});
export default apiServices;
