import useConfig from "./useConfig";

const useFormikDynamicLanguageSchema = () => {
    const config = useConfig();
    const availableLanguages = config.languages.slice(1);

    const getFormikValuesForAllLanguages = (formikFieldSchema) => {
        let formFields = {};
        
        availableLanguages.forEach(language => {
            for(let each of formikFieldSchema) {
                formFields = {...formFields, [each]: ''}
                formFields = {...formFields, [each+"_"+language.value]: ''}
            }
        })
        
        return formFields;
    };

    const getAvailableLanguages = () => {
        return availableLanguages;
    }

    return { getFormikValuesForAllLanguages, getAvailableLanguages };

}

export default useFormikDynamicLanguageSchema;