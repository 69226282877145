export const paramsToFilters = (params, type) => {
  let filters = params ? JSON.parse(decodeURI(params)) : [];
  let mfilters = [];
  filters.map((_) => {
    let filter = {};
    for (const key in _) {
      if (key === "order_product_status" && typeof _[key] === "object") {
        filter = {
          key,
          in: _[key],
        };
      } else {
        filter = {
          key,
          ...(type === "filters"
            ? {
                ...(key === "name" || key === "title" || key === "email"
                  ? { iLike: _[key] }
                  : { eq: _[key] }),
              }
            : type === "categoryFilters"
            ? { eq: _[key] }
            : { direction: _[key] }),
        };
      }

      _[key] && mfilters.push(filter);
      return _[key];
    }
    return _;
  });
  // Only for catering category, service filter
  if(type === "categoryFilter" || type === "serviceFilter") {
    mfilters = params
  }
  else if(type === "cateringSupplierChange")
  {
    mfilters = filters
  }
  return mfilters;
};

export const paramsToFiltersObj = (params) => {
  let filters = params ? JSON.parse(decodeURI(params)) : [];
  let filterObj = {};
  filters.map((_) => {
    for (const key in _) {
      filterObj[key] = _;
    }
    return _;
  });
  return filterObj;
};
export const objToFilters = (data, type) => {
  let filters = [];
  for (const key in data) {
    let filter = {};
    for (const _key in data[key]) {
      filter = {
        key: _key,
        ...(type === "filters"
          ? { ...(_key === "name" || _key === "title" ? { iLike: data[key][_key] } : { eq: data[key][_key] }) }
          : { direction: data[key][_key] }),
      };
      data[key][_key] && filters.push(filter);
    }
  }
  return filters;
};

export const filtersToUri = (filters) => {
  let data = [];
  // Only for catering category, service filter
  if(Array.isArray(filters)) {
    filters.forEach((item) => data.push(item))
  }
  for (const key in filters) {
    for (const _key in filters[key]) {
      filters[key][_key] && data.push(filters[key]);
    }
  }
  let strinfydata = JSON.stringify(data);
  return encodeURIComponent(strinfydata);
};

export const filtersToUrlParams = (filters) => {
  let url = "";
  for (const key in filters) {
    if (filters[key]) {
      url += `&${key}=${filters[key]}`;
    }
  }
  return url;
};
