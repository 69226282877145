import React, { useEffect, useState } from "react";
import "./Catering.scss";
import "./Restaurant.scss";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "../../assets/elements/IconButton";
//import Product from "./Product";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import TableSearch from "../../assets/elements/TableSearch";
import MainServices from "../../services/MainServices";
import {
  filtersToUrlParams,
  filtersToUri,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import useAuth from "../../hooks/useAuth";
import AsyncSelect from "react-select/async";
import Restaurant from "./Restaurant";
import { notifyError } from "../../assets/elements/Toaster";

export default function Catering() {
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const {
    getRestaurantTypes,
    getRestaurantCategories,
    getUsers,
    getRestaurants,
  } = MainServices();
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [filters, setFilters] = useState({});
  const [categoryFilter, setCategoryFilter] = useState({});
  const [serviceFilter, setServiceFilter] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [searchString, setSearchString] = useState("");
  const [sorts, setSorts] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const { user } = useAuth();
  const [suppliers, setSuppliers] = useState([]);
  const [currentRestaurantCount, setCurrentRestaurantCount] = useState();
  //const [selectedSupplier, setSelectedSupplier] = useState("");

  //hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language;
  const location = useLocation();
  const { deBounce } = TimingFunctions();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let categoryFilter = paramsToFilters(
      changedParams?.categoryFilter,
      "categoryFilter"
    );
    let serviceFilter = paramsToFilters(
      changedParams?.serviceFilter,
      "serviceFilter"
    );
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    //let categoryFilterObj = paramsToFiltersObj(changedParams?.categoryFilter);
    //let serviceFilterObj = paramsToFiltersObj(changedParams?.serviceFilter);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    let supplierId = changedParams?.supplier?.publicId || user.user_id;
    setPagination(pagination);
    setFilters(filtersObj);
    setCategoryFilter(categoryFilter);
    setServiceFilter(serviceFilter);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    getRestaurantList({
      page: pagination.page,
      limit: pagination.limit,
      filters,
      categoryFilter,
      serviceFilter,
      sorts,
      supplierId,
    });

    //eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.categoryFilter,
    changedParams.serviceFilter,
    changedParams.sort,
    changedParams.supplier,
  ]);

  const getRestaurantList = async ({
    page,
    limit,
    filters,
    categoryFilter,
    serviceFilter,
    sorts,
    supplierId,
  }) => {
    filters.push({
      key: "status",
      eq: "active",
    });
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      categoryFilter,
      serviceFilter,
      sorting: sorts,
    };
    await getRestaurants(details, supplierId)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            setRestaurants([...restaurants, ...resData]);
            setCurrentRestaurantCount(resData.length);
            setTotalCount(total);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  const getSuppliersList = async ({ page, limit, filters, initial }) => {
    let data = [];
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
    };
    await getUsers(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            resData.unshift({ label: "All", value: "" });
            data = resData;
            initial && setSuppliers(resData);
            //setTotalCount(total);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  //fillters
  const onNameSort = () => {
    let mfilters = filtersToUri(filters);
    let cfilters = categoryFilter;
    let sfilters = serviceFilter;
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      categoryFilter: cfilters,
      serviceFilter: sfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
    setRestaurants([]);
  };
  const onSearch = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e, "name"));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e, type) => {
    let mfilters = filtersToUri(filters);

    if (type === "name") {
      mfilters = filtersToUri({
        ...filters,
        name: { name: e.target.value },
      });
    }
    let cfilters;
    if (type === "category") {
      if (Array.isArray(e)) {
        cfilters = filtersToUri(e.map((categoryObj) => categoryObj.id));
        setCategoryFilter(cfilters);
      }
    }
    let sfilters;
    if (type === "service") {
      if (Array.isArray(e)) {
        sfilters = filtersToUri(e.map((serviceObj) => serviceObj.id));
        setServiceFilter(serviceFilter);
      }
    }
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      categoryFilter: cfilters || categoryFilter,
      serviceFilter: sfilters || serviceFilter,
      //categoryFilter: categoryFilter,
      sort: msorts,
    });
    setRestaurants([]);
    navigate({
      search,
    });
  };

  const loadOptionsForCategory = async (inputValue, callback) => {
    if (!inputValue || inputValue === "All") {
      callback(categoryList);
    }
    let filter = [
      {
        key: "name",
        eq: inputValue,
      },
    ];
    let options = await getCategoryOptions(filter);
    callback(options);
  };

  const loadDebounceOptions = (inputValue, callback) => {
    deBounce(() => loadOptionsForCategory(inputValue, callback), 1000);
  };

  const loadOptionsForBrand = async (inputValue, callback) => {
    if (!inputValue) {
      callback(typeList);
    }
    let filter = [
      {
        key: "name",
        eq: inputValue,
      },
    ];
    let options = await getBrandOptions(filter, false);

    callback(options);
  };
  const loadOptionsForSuppliers = async (inputValue, callback) => {
    if (!inputValue) {
      callback(suppliers);
    }
    let filter = [
      // {
      //   key: "status",
      //   eq: "active",
      // },
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "role",
        eq: "supplier",
      },
    ];
    let options = await getSuppliersList({ filters: filter, initial: false });

    callback(options);
  };

  const getCategoryOptions = async (filters) => {
    filters.push({
      key: "status",
      eq: "active",
    });
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getRestaurantCategories(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((category) => {
              let languageSpecificName = "";
              if (userLanguage !== "en") {
                let languageSpecificDetailsObject = {};
                languageSpecificDetailsObject =
                  category?.category_languages?.find(
                    (languageSpecificDetails) =>
                      languageSpecificDetails.locale === userLanguage
                  );
                languageSpecificName = languageSpecificDetailsObject?.name;
              }
              return {
                ...category,
                label: languageSpecificName || category.name,
                value: category.id,
              };
            });
            resData.unshift({ label: "--All--", value: "" });
            setCategoryList(resData);
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const getBrandOptions = async (filters) => {
    filters.push({
      key: "status",
      eq: "active",
    });

    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getRestaurantTypes(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((brand) => {
              let languageSpecificName = "";
              if (userLanguage !== "en") {
                let languageSpecificDetailsObject = {};
                languageSpecificDetailsObject = brand?.brandLanguages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === userLanguage
                );
                languageSpecificName = languageSpecificDetailsObject?.name;
              }
              return {
                ...brand,
                label: languageSpecificName || brand.name,
                value: brand.id,
              };
            });
            resData.unshift({ label: "--All--", value: "" });
            setTypeList(resData);
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  useEffect(() => {
    let filter = [];
    getCategoryOptions(filter);
    getBrandOptions(filter);
    user.role === "admin" &&
      getSuppliersList({
        filters: [{ key: "role", eq: "supplier" }],
        initial: true,
      });
    // eslint-disable-next-line
  }, [userLanguage]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#f5f2ed",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };

  const customStylesForRoleSelect = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };
  const onNextPage = () => {
    let page = +pagination.page + 1;
    let search = filtersToUrlParams({
      /* page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts, */
      ...changedParams,
      page,
    });
    navigate({ search });
  };

  const onSupplierChange = (selected) => {
    let msorts = filtersToUri(sorts);

    let categoryFilterArray = paramsToFilters(
      categoryFilter,
      "cateringSupplierChange"
    );
    let serviceFilterArray = paramsToFilters(
      serviceFilter,
      "cateringSupplierChange"
    );
    let cfilters = filtersToUri(categoryFilterArray);
    let sfilters = filtersToUri(serviceFilterArray);

    let mfilters = filtersToUri({
      ...filters,
      supplier_id: { supplier_id: selected?.publicId },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      categoryFilter: cfilters,
      serviceFilter: sfilters,
      sort: msorts,
    });
    navigate({ search });
    setRestaurants([]);
  };

  return (
    <div className="product-main">
      <div className="heading">
        <HeadingMain text={t("Restaurants") + `(${totalCount})`} />

        <div className="heading-right-btn">
          {user.role === "admin" && (
            <AsyncSelect
              styles={customStylesForRoleSelect}
              className="select-role async-select"
              placeholder={t("Select Supplier")}
              loadOptions={loadOptionsForSuppliers}
              defaultOptions={suppliers}
              onChange={onSupplierChange}
              name="users"
              cacheOptions
            />
          )}
          {user.role !== "admin" && (
            <IconButton
              onClick={() => {
                if (user?.supplier?.businessName)
                  navigate("/catering/add-restaurant");
                else {
                  notifyError("Please update business profile information!");
                  navigate("/profile");
                }
              }}
              title={t("Add Restaurant")}
              icontype="add"
            />
          )}
        </div>
      </div>
      <div className="product-table-main">
        <div className="table-flex-main">
          <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            searchLoading={searchLoading}
          >
            <div
              className={`filter-grey-main name-sort ${
                sorts?.name ? "sort-hightlight" : ""
              }`}
              onClick={onNameSort}
            >
              <span className="title">{t("Name")} (A-Z)</span>
              <span>
                <BsArrowUp
                  style={{
                    color: sorts?.name?.name === "asc" ? "#d60000" : "#CCBFDD",
                  }}
                />
                <BsArrowDown
                  style={{
                    color: sorts?.name?.name === "desc" ? "#d60000" : "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <AsyncSelect
              className="filter-grey-main async-select"
              loadOptions={loadDebounceOptions}
              styles={customStyles}
              defaultOptions={categoryList}
              placeholder={t("Category")}
              onChange={(selected) => {
                onSearchFilter(selected, "category");
              }}
              name="category"
              cacheOptions
              isMulti
              //value={selectedValue}
              //onInputChange={handleInputChange}
            />
            <AsyncSelect
              className="filter-grey-main async-select"
              loadOptions={loadOptionsForBrand}
              styles={customStyles}
              defaultOptions={typeList}
              placeholder={t("Service Type")}
              onChange={(selected) => {
                onSearchFilter(selected, "service");
              }}
              name="service"
              cacheOptions
              isMulti
              //value={selectedValue}
              //onInputChange={handleInputChange}
            />
          </TableSearch>
        </div>

        <div className="list">
          <InfiniteScroll
            dataLength={restaurants.length}
            next={onNextPage}
            hasMore={currentRestaurantCount > 0}
            loader={
              <h4 className="text-center" style={{ marginTop: "40px" }}>
                Loading...
              </h4>
            }
            endMessage={
              <p className="text-center infinite-load-margin">
                <b>{t("No more results found")} !</b>
              </p>
            }
            scrollThreshold="200px"
          >
            <div className="g-3">
              {restaurants.length > 0 &&
                restaurants.map((restaurantObj, index) => (
                  <Restaurant
                    index={index + 1}
                    eachRestaurant={restaurantObj}
                    userLanguage={userLanguage}
                    key={restaurantObj?.pulicId}
                  />
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
