import React from "react";
import { InputGroup, InputGroupText, Input } from "reactstrap";
import searchicon from "../images/icons/search-normal.svg";
import { useTranslation } from "react-i18next";
import { ButtonLoader } from "../loaders";
import "./TableSearch.scss";

const TableSearch = (props) => {
  const { t } = useTranslation();

  return (
    <div className="table-inner-flex">
      <div className="table-search-width">
        <InputGroup className="table-search-main">
          <InputGroupText
            className="table-search-icon"
            onClick={() =>
              props.setSearchExpandForMobile(!props.searchExpandForMobile)
            }
          >
            <img src={searchicon} alt="search" />
          </InputGroupText>
          <Input
            placeholder={t("Search")}
            className={
              props.searchExpandForMobile
                ? "search-expand"
                : "table-search-input"
            }
            onChange={props.onSearch}
            value={props.searchString}
          />
          {props.searchLoading && (
            <InputGroupText className="table-search-icon">
              <ButtonLoader color="red" />
            </InputGroupText>
          )}
        </InputGroup>
      </div>
      {props.children}
    </div>
  );
};

export default TableSearch;
