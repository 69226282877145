import React from 'react'
import { useTranslation } from 'react-i18next'
import "./HeadingMain.scss"

const HeadingMain = (props) => {
  const { t } = useTranslation()
  return (
    <div className="heading-sticky">
        <h3 className="heading-main">{t(props.text)}</h3>
        {props.children}
    </div>
  )
}

export default HeadingMain