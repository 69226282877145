import React from "react";
import "./Style.scss";
import { Button } from "reactstrap";
import plusicon from "../../assets/images/icons/plus-curved.svg"
import { useTranslation } from "react-i18next";

export default function IconButton(props) {
  const { t } = useTranslation();
  return (
    <Button className="btn-with-icon" onClick={props.onClick} {...props}>
      <span>{props.icon}</span>
      {props.icontype === "add" ? <img src={plusicon} alt="add"/> : ''}
      <span className="btn-with-icon-title">{t(props.title)}</span>
    </Button>
  );
}
