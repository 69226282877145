import React, { useState, useEffect } from "react";
import "./SupplierOrdersList.scss";
import { BsFilter, BsArrowLeftRight } from "react-icons/bs";
import Tab from "../../assets/elements/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TableSearch from "../../assets/elements/TableSearch";
import CustomPagination from "../../assets/elements/Pagination";
import MainServices from "../../services/MainServices";
import CustomTable from "../../assets/elements/Table";
import {
  filtersToUrlParams,
  filtersToUri,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";


export default function SupplierOrdersList() {
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedOrderType, setSelectedOrderType] = useState("All");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState({});
  const [searchString, setSearchString] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const { deBounce } = TimingFunctions();



  const { getOrdersByType } = MainServices();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const status = changedParams?.status;
  const orderType = changedParams?.orderType;

  const tabsItem = [
    {
      label: `${t("All")} (50)`,
      value: "All",
    },
    {
      label: `${t("Pending")} (50)`,
      value: "Pending",
    },
    {
      label: `${t("Completed")} (50)`,
      value: "Completed",
    },
    {
      label: `${t("Return")} (25)`,
      value: "Return",
    },
  ];

  const orderTypeTabItems = [
    {
      label: `${t("Product")}`,
      value: "product",
    },
    // {
    //   label: `${t("Catering")}`,
    //   value: "catering",
    // },
    // {
    //   label: `${t("Photoshoot")}`,
    //   value: "photoshoot",
    // },
  ];

  const supplier_columns = [
    {
      field: "image",
      text: "Images",
      //customStyle: clickAction,
    },
    {
      field: "orderId",
      text: "Order ID",
    },
    {
      field: "createdAt",
      text: "Order Date",
    },
    {
      field: "quantity",
      text: "No. Of Items",
      //customStyle: styledStatus,
    },
    {
      field: "totalItemsPrice",
      text: "Total Order Price",
      //customStyle: dateFormat,
    },
    {
      field: "orderStatusId",
      text: "Order Status",
      //customStyle: actions,
    },
    {
      field: "shippingAddress.city",
      text: "Ship To",
    },
    {
      field: "pendingReason",
      text: "Pending/Return Reason",
    },
    {
      field: "deliveryDate",
      text: "Delivery Date",
    },
    {
      field: "paymentMethod",
      text: "Payment",
    },
    {
      field: "discountPrice",
      text: "Discount",
    },
    {
      field: "transactionRef",
      text: "Tax No",
    },
    {
      field: "",
      text: "Actions",
    },
  ];       

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    console.log("chPUse",changedParams)
    getOrdersList({
      ...changedParams,
      page: pagination.page,
      limit: pagination.limit,
      filters,
      sorts,
    })
    //eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.sort,
    changedParams.status,
    changedParams.orderType
  ]);

  useEffect(() => {
    setSelectedStatus(status);
    //eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    setSelectedOrderType(orderType);
    //eslint-disable-next-line
  }, [orderType]);


  const onSearch = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e, "name"));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e, type) => {
    let mfilters = filtersToUri(filters);
    if (type === "name") {
      mfilters = filtersToUri({
        ...filters,
        name: { name: e.target.value },
      });
    }
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    setOrders([]);
    navigate({
      search,
    });
  };

  const onNameSort = () => {
    
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: pagination.limit,
      sort: msorts,
    });
    navigate({
      search,
    });
    setOrders([]);
  };

  const getOrdersList = async (urlParts) => {
    const details = {
      ...urlParts,
      orderType: urlParts.orderType || "product"
    };
    await getOrdersByType(details)
      .then((res) => {
        if (res) {
          console.log("ordersRes", res)
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            //setProducts([...products, ...resData]);
            setOrders([...resData]);
            setTotalCount(total);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  const onStatusChange = (selectedStatus) => {
    navigate({ search: `?status=${selectedStatus}&orderType=${orderType}` });
  };

  const onOrderTypeChange = (selected) => {
    navigate({ search: `?status=${status}&orderType=${selected}` });
  };

  //pagination functions
  const onPageChange = (page) => {
    setPagination({ ...pagination, page });
    // getSuppliersList({ page, limit: pagination.limit });
  };

  const onRowsChange = (rows) => {
    setPagination({ limit: rows, page: 1 });
  };

  return (
    <div>
      <div className="tab-flex-main">
        {orderTypeTabItems.map((each) => {
          return (
            <Tab
              title={each.label}
              className={selectedOrderType === each.value && "active"}
              onClick={() => onOrderTypeChange(each.value)}
            />
          );
        })}
      </div>
      <div className="tab-flex-main">
        {tabsItem.map((each) => {
          return (
            <Tab
              title={each.label}
              className={selectedStatus === each.value && "active"}
              onClick={() => onStatusChange(each.value)}
            />
          );
        })}
      </div>
      <div className="supplier-order-table-main">
        <div className="table-flex-main">
        <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            searchLoading={searchLoading}
          >
            <div
              className={`filter-grey-main name-sort ${
                sorts?.name ? "sort-hightlight" : ""
              }`}
              onClick={onNameSort}
            >
              <span className="title">{t("Name")} (A-Z)</span>
              <span>
                <BsArrowUp
                  style={{
                    color:
                      sorts?.title?.title === "asc" ? "#d60000" : "#CCBFDD",
                  }}
                />
                <BsArrowDown
                  style={{
                    color:
                      sorts?.title?.title === "desc" ? "#d60000" : "#CCBFDD",
                  }}
                />
              </span>
            </div>
          </TableSearch>
          {/* <TableSearch>
            <div className="filter-grey-main">
              <span className="title">{t("Filters")}</span>
              <span>
                <BsFilter
                  style={{
                    color: "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <div className="filter-grey-main">
              <span className="title">{t("First Name")} (A-Z)</span>
              <span>
                <BsArrowLeftRight
                  style={{ transform: "rotate(90deg)", color: "#CCBFDD" }}
                />
              </span>
            </div>
          </TableSearch> */}
        </div>
        { orders.length > 0 && <CustomTable data={orders} columns={supplier_columns} className="custom-table"/> }
        <CustomPagination
          currentPage={pagination.page}
          rowsPerPage={pagination.limit}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onRowsPerPage={onRowsChange}
        />
      </div>
    </div>
  );
}
