import React, { useState, useEffect } from "react";
import { Input, Label, FormGroup, Row } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import mapicon from "../../assets/images/icons/google-map.svg";
import "./Profile.scss";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import MainServices from "../../services/MainServices";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { notifySuccess, notifyError } from "../../assets/elements/Toaster";
//import { FaEdit } from "react-icons/fa";
import Map from "../../assets/map/Map";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
//import SimpleBar from "simplebar-react";

const updateAddressSchema = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  fulladdress: Yup.string().max(50, "Address should not be more than 50 charaters"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  postalcode: Yup.string().required("Postal code is required"),
});

const AddressSection = (props) => {
  //Apis
  const {
    getUserAddress,
    saveAddress,
    modifyAddress,
    getCountries,
    getStatesByCountry,
  } = MainServices();

  const {user, updateUser, getUserProfile, getAndSetProfileData, currentProfileData, setCurrentProfileData, updateUserContext} = props;

  const { t } = useTranslation();

  //const [existingAddressList, setExistingAddressList] = useState([]);
  //const [existingAddress, setExistingAddress] = useState([]);
  const [isUpdateAddressInProgress, setIsUpdateAddressInProgress] =
    useState(false);
  const [isModifying, setIsModifying] = useState();
  const [existingAddress, setExistingAddress] = useState();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formikValues, setFormikValues] = useState({});

  const [modal, setModal] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState({});
  //const [userSelectedMapDetails, setUserSelectedMapDetails] = useState({});
  const [temporaryHoldUserSelection, setTemporaryHoldUserSelection] =
    useState(null);
  const [locationDetectionAllowed, setLocationDetectionAllowed] = useState();

  /* const formikInitialValues= !isModifying ?
    {
        currentaddress: userSelectedMapDetails.address || "",
        latitude: userSelectedMapDetails.latitude || "",
        longitude: userSelectedMapDetails.longitude || "",
        fulladdress: "",
        country: "" ,
        state: "",
        city: "",
        postalcode: "",
    }
    :
    {
        currentaddress:
        userSelectedMapDetails.address ||
        addressToBeModified?.address ||
        "",
        latitude:
        userSelectedMapDetails.latitude ||
        addressToBeModified?.latitude ||
        "",
        longitude:
        userSelectedMapDetails.longitude ||
        addressToBeModified?.longitude ||
        "",
        fulladdress:
        addressToBeModified?.address ||
        userSelectedMapDetails.address ||
        "",
        country:
        addressToBeModified?.country || "",
        state:
        addressToBeModified.state || "",
        city:
        userSelectedMapDetails.city ||
        addressToBeModified?.city ||
        "",
        postalcode:
        userSelectedMapDetails.postalcode ||
        addressToBeModified?.postalCode ||
        "",

    } */

  // To get country id from list by matching country name from map
  const getCountryIdByName = (name) => {
    if(!name) return
    const countryId = countryList.find(item => item.name === name)?.id
    return countryId
  }
  // To get state id from list by matching state name from map
  const getStateIdByName = (name) => {
    if(!name) return
    const stateId = stateList.find(item => item.name === name)?.id
    return stateId
  }
 
  const formikInitialValues = {
    businessName: formikValues?.businessName || "",
    currentaddress: formikValues?.location || "",
    latitude: formikValues.latitude || "",
    longitude: formikValues.longitude || "",
    fulladdress: formikValues?.address || formikValues?.location || "",
    country: formikValues?.country || getCountryIdByName(formikValues?.country) || "",
    state: formikValues?.state || getStateIdByName(formikValues?.state) || "",
    city: formikValues?.city || "",
    postalcode: formikValues?.postalCode || formikValues?.postalcode || "",
  };
  const getAndSetCountryList = async () => {
    return await getCountries().then((res) => {
      setCountryList(res.data);
      return res.data;
    });
  };

  const getAndSetStateList = async (countryId) => {
    return await getStatesByCountry({ countryId }).then((res) => {
      setStateList(res.data);
      return res.data;
    });
  };

  const getAndSetAddress = async () => {
    if(currentProfileData?.supplier) {
      let supplierInfo = currentProfileData.supplier
      //console.log("Address: ", res);
      //Check if address exists
      if(!supplierInfo?.latitude || !supplierInfo?.longitude) {
        //setIsModifying(false);
        setFormikValues({});
        return;
      }
      const mapLocation = await Map.getMapLocation(
        supplierInfo?.latitude,
        supplierInfo?.longitude
      );
      const supplierAddress = {
        ...supplierInfo, 
        country: parseInt(supplierInfo?.country),
        state: parseInt(supplierInfo?.state),
        location: mapLocation}
      //setExistingAddress(supplierAddress)
      setFormikValues(supplierAddress);
      console.log("SupAdd", supplierAddress)
      setExistingAddress(supplierAddress);
      const countryId = parseInt(supplierAddress.country);
      getAndSetStateList(countryId);
      setIsModifying(true);
 
      /* const addressList = res.data.map(async (item) => {
        const mapLocation = await Map.getMapLocation(
          item.latitude,
          item.longitude
        );
        return {
          ...item,
          location: mapLocation,
        };
      });
      Promise.all(addressList).then((resolvedAddressList) =>
        setExistingAddress(resolvedAddressList)
      ); */
      //const lastIndex = res.data.length - 1;
      //setCurrentAddress(res?.data[lastIndex]);
      //getAndSetCountryList();
      //getAndSetStateList(res?.data[lastIndex]?.countryId)
    }
  };

  const handleChangeCountry = async (countryId) => {
    if (!countryId) {
      setStateList([]);
      return;
    }
    return await getAndSetStateList(countryId);
  };

  const addNewAddress = async (values) => {
    setIsUpdateAddressInProgress(true);
    
    let concurrencyStamp;
    // Retrieve latest concurrency stamp
    await getUserProfile().then(
      (res) => (concurrencyStamp = res.data?.concurrencyStamp)
    );

    const details = {
      name: currentProfileData?.supplier?.name || currentProfileData?.name,
      businessName: values.businessName,
      latitude: values.latitude.toString(),
      longitude: values.longitude.toString(),
      address: values.fulladdress,
      postalCode: values.postalcode,
      city: values.city,
      state: String(values.state),
      country: String(values.country)
    };
    updateUser(details, currentProfileData.publicId, concurrencyStamp)
      .then((res) => {
        if(res.success)
        {
          setIsUpdateAddressInProgress(false);
          getAndSetProfileData();
          getUserProfile().then((res) => {
            setCurrentProfileData(res.data);
            const accessToken = user.token;
            updateUserContext({ ...res.data, token: accessToken });
            notifySuccess("Address updated successfully!");
          });
        }
        else{
          notifyError("Update failed!");
          setIsUpdateAddressInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Update failed!");
        setIsUpdateAddressInProgress(false);
      });
  };

  const updateExistingAddress = async (values) => {
    addNewAddress(values);
  };

  useEffect(() => {
    getAndSetAddress();
    getAndSetCountryList();
    // eslint-disable-next-line
  }, []);

  /* const handleClickAddress = async (selected) => {
    if (!selected) return;
    setFormikValues(selected);
    setAddressToBeModified(selected);
    getAndSetStateList(selected.countryId);
    setIsModifying(true);
  }; */

  /* const handleClickAddNew = () => {
    setIsModifying(false);
    setFormikValues({});
    setAddressToBeModified({});
  }; */

  /* const validateCountrySelection = (countryName) => {
    console.log(countryName)
    let error="select";
    if(!countryName)
        return error
  } */

  const toggleMapWindow = () => {
    setModal(!modal);
  };

  const getAndSetCurrentLocationCoordinates = async () => {
    let lat;
    let lng;
    function success(pos) {
      const crd = pos.coords;
      lat = crd.latitude;
      lng = crd.longitude;
      setCurrentCoordinates({ lat, lng });
      setLocationDetectionAllowed(true);
      toggleMapWindow();
    }
    function error() {
      notifyError("Please allow location permission in browser settings!");
      setLocationDetectionAllowed(false);
    }
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  const openMap = () => {
    getAndSetCurrentLocationCoordinates();
  };

  const setNewLocationDetails = async (setFieldValue) => {
    if (temporaryHoldUserSelection != null) {
      //setUserSelectedMapDetails({ ...temporaryHoldUserSelection });
      setFormikValues({ ...temporaryHoldUserSelection });
      let countryId = null;
      if(Array.isArray(countryList)) 
        countryId = countryList.find(countryObj => countryObj.name?.toLowerCase() === temporaryHoldUserSelection.country?.toLowerCase())?.id;
      if(countryId)
        handleChangeCountry(countryId)
          .then((stateList) => {
            setFieldValue("country", countryId);
            if(stateList)
            {
              let stateId = null;
              if(Array.isArray(stateList))
                stateId = stateList.find(stateObj => stateObj.name?.toLowerCase() === temporaryHoldUserSelection.state?.toLowerCase())?.id;
              if(stateId)
                setFieldValue("state", stateId);
            }
          })
    }
  };

  const clearUserSelectedMapLocation = () => {
    //setUserSelectedMapDetails({})
    //setCurrentCoordinates({})
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={(values) => {
        isModifying ? updateExistingAddress(values) : addNewAddress(values);
      }}
      validationSchema={updateAddressSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
      }) => (
        <>
          <Form>
            <div className="address formbox">
              <div className="textdescription">
                <p className="title">{t("Address")}</p>
                <p>
                  
                </p>
                {/* <div className="existing-preview">
                  <p className="title">Existing Addresses</p>

                  <SimpleBar
                    className="list-scroll"
                    style={{ height: "500px" }}
                  >
                    {existingAddressList.map((item, index) => {
                      return (
                        <div className="address-box" key={item.id}>
                          <FaEdit
                            className="editicon"
                            onClick={() => handleClickAddress(item)}
                          />
                          <p>
                            <b>Location: </b>
                            {item.location}
                          </p>
                          <p>
                            <b>Address:</b> {item.address}
                          </p>
                          <p>
                            <b>City: </b>
                            {item.city}
                          </p>
                          <p>
                            <b>State:</b> {item.state}
                          </p>
                          <p>
                            <b>Country: </b>
                            {item.country}
                          </p>

                          <Badge className="badge" pill>
                            {index + 1}
                          </Badge>
                        </div>
                      );
                    })}
                  </SimpleBar>
                </div> */}
              </div>
              <div className="inputsection">
                <p className="title">
                  {t(isModifying ? "Modify Address" : "Add New Address")}
                </p>
                {/* {isModifying && (
                  <div className="addnew-btn" onClick={handleClickAddNew}>
                    <PrimaryButton title="Add new address" />
                  </div>
                )} */}
                <Row className="g-2">
                  {locationDetectionAllowed ? (
                    <Modal isOpen={modal} toggle={toggleMapWindow}>
                      <ModalHeader toggle={toggleMapWindow}>
                        Choose a location
                      </ModalHeader>
                      <ModalBody>
                        <Map
                          isVisible={true}
                          zoom={15}
                          setTemporaryHoldUserSelection={
                            setTemporaryHoldUserSelection
                          }
                          latLngCoordinatesObject={currentCoordinates}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="primary"
                          onClick={() => {
                            setNewLocationDetails(setFieldValue);
                            toggleMapWindow();
                          }}
                        >
                          Ok
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            clearUserSelectedMapLocation();
                            toggleMapWindow();
                          }}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  ) : (
                    ""
                  )}
                  <FormGroup>
                    <Label for="location">{t("Location")}</Label>
                    <div
                      className="input-icon-group location"
                      onClick={openMap}
                    >
                      <Input
                        id="location"
                        name="location"
                        type="text"
                        disabled
                        className="disabled"
                        placeholder={t("Your Location")}
                        value={
                          values.currentaddress
                            ? values.currentaddress + "    "
                            : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            "latitude",
                            //userSelectedMapDetails?.latitude
                            values.latitude
                          );
                          setFieldValue(
                            "longitude",
                            //userSelectedMapDetails?.longitude
                            values.longitude
                          );
                        }}
                      />
                      <img
                        src={mapicon}
                        alt="icon"
                        focusable
                        className="icon map"
                      />
                      <Input
                        id="latitude"
                        name="latitude"
                        type="text"
                        hidden
                        value={values.latitude}
                        onChange={handleChange}
                      />
                      <Input
                        id="longitude"
                        name="longitude"
                        type="text"
                        hidden
                        value={values.longitude}
                        onChange={handleChange}
                      />
                      {/* <div className="map" style={{ display: "none" }}>
                        {!formikValues.location && isModifying && values.latitude && values.longitude && <Map
                    
                            readOnly={true}
                            setTemporaryHoldUserSelection={
                                setTemporaryHoldUserSelection
                              }
                            latLngCoordinatesObject={{lat: values.latitude, lng: values.longitude}
                            }
                        />}
                      </div> */}
                    </div>
                  </FormGroup>
                </Row>
                <FormGroup>
                  <Label for="fulladdress">{t("Full Address")}</Label>
                  <Input
                    id="fulladdress"
                    name="fulladdress"
                    placeholder={t("Enter your address")}
                    value={values.fulladdress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="fulladdress"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="businessName">{t("Business Name")}</Label>
                  <Input
                    id="businessName"
                    name="businessName"
                    placeholder={t("Enter business name")}
                    value={values.businessName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="businessName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="country">{t("Country")}</Label>
                  <Field
                    className="select form-select"
                    id="country"
                    name="country"
                    value={values.country}
                    as="select"
                    onChange={(e) => {
                      //setFieldValue("country", e.target.value);
                      handleChange(e)
                      handleChangeCountry(e.target.value);
                      //
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("state", "");
                      }
                    }
                    //validate={validateCountrySelection}
                  >
                    <option value="">--Select--</option>
                    {countryList.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="country"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="state">{t("State")}</Label>

                  <Input
                    className="select"
                    id="state"
                    name="state"
                    type="select"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">--Select--</option>
                    {stateList.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="state"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="city">{t("City")}</Label>
                  <Input
                    id="city"
                    name="city"
                    placeholder={t("Enter City Name")}
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage errors={errors} touched={touched} name="city" />
                </FormGroup>
                <FormGroup>
                  <Label for="postalcode">{t("Postal Code")}</Label>
                  <Input
                    id="postalcode"
                    name="postalcode"
                    placeholder={t("Enter Postal Code")}
                    value={values.postalcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage errors={errors} touched={touched} name="postalcode" />
                </FormGroup>
                {/* <FormGroup>
                  <Input
                    id="markdefault"
                    name="markdefault"
                    type="checkbox"
                    defaultValue={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="markdefault">{t("Make as default")}</Label>
                </FormGroup> */}
              </div>
            </div>
            <div className="save-btn-float">
              <PrimaryButton
                type="submit"
                title={isModifying ? "Update" : "Save"}
                isLoading={isUpdateAddressInProgress}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default AddressSection;
