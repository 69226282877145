import React from 'react'
import "./addSupplier.scss"

export default function addSupplier() {
  return (
    <div>
      <p>hello</p>
    </div>
  )
}
