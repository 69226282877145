import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Table } from "reactstrap";
import "./Products.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import IconButton from "../../assets/elements/IconButton";
import HeadingMain from "../../assets/elements/HeadingMain";
import moment from "moment";
import CustomPagination from "../../assets/elements/Pagination";
import TableSearch from "../../assets/elements/TableSearch";
import {
  filtersToUrlParams,
  filtersToUri,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";

import MainServices from "../../services/MainServices";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import uploadicon from "../../assets/images/icons/upload.svg";
import addicon from "../../assets/images/icons/add-button.svg";
import Error from "../../assets/elements/ErrorMessage";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import { ButtonLoader } from "../../assets/loaders";
import TimingFunctions from "../../assets/functions/TimingFunctions";

const fileSchema = Yup.object().shape({
  upload_file: Yup.mixed().required("File is Required"),
});

export default function Chartofaccount() {
  const { deBounce } = TimingFunctions();
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(3);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const { user } = useAuth();
  const [auditmaster, setAuditmaster] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [endDate, setEndDate] = useState(new Date());
  const [products, setProducts] = useState([]);
  const [searchString, setSearchString] = useState("");
  const location = useLocation();
  const [filters, setFilters] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  //url Params
  const params = new URLSearchParams(location.search);

  const changedParams = Object.fromEntries(params);

  const {
    getProducts,
    getBrands,
    getProductsBySupplierId,
    getCategories,
    getUsers,
    uploadProducts,
    zipUpload,
    uploadProductsFromZip,
    importProducts,
  } = MainServices();
  const [searchLoading, setSearchLoading] = useState(false);
  const [sorts, setSorts] = useState({});
  const [uploadProductFile, setUploadProductFile] = useState({});
  const [addFile, setAddFileModal] = useState(false);
  const { t } = useTranslation();
  const handleDocumentUploadClick = () => {
    documentRef.current?.click();
  };
  const [uploadLoading, setUploadLoding] = useState(false);
  const [imported, setImported] = useState(1);

  const documentRef = useRef();
  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let categoryFilters = paramsToFilters(
      changedParams?.categoryFilters,
      "categoryFilters"
    );
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let categoryFiltersObj = paramsToFiltersObj(changedParams?.categoryFilters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    let supplierId = changedParams?.supplier?.publicId || user.user_id;
    setPagination(pagination);
    setFilters(filtersObj);
    setCategoryFilters(categoryFiltersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);

    user.role === "admin" && !changedParams.supplier
      ? getProductsList({
          page: pagination.page,
          limit: pagination.limit,
          filters,
          categoryFilters,
          sorts,
        })
      : getSupplierProductList({
          page: pagination.page,
          limit: pagination.limit,
          filters,
          categoryFilters,
          sorts,
          supplierId,
        });

    //eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.categoryFilters,
    changedParams.sort,
    changedParams.supplier,
    imported,
  ]);

  const getProductsList = async ({
    page,
    limit,
    filters,
    categoryFilters,
    sorts,
  }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters: [...filters],
      categoryFilters,
      sorting: sorts,
    };
    await getProducts(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            setProducts(resData);
            setTotalCount(total);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };
  const onSearch = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e, "title"));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e, type) => {
    let mfilters = filtersToUri(filters);
    if (type === "title") {
      mfilters = filtersToUri({
        ...filters,
        title: { title: e.target.value },
      });
    }
    let cfilters = filtersToUri(categoryFilters);
    if (type === "category") {
      cfilters = filtersToUri({
        id: { id: e.value },
      });
    }
    if (type === "brand") {
      mfilters = filtersToUri({
        ...filters,
        brandId: { brandId: e.value },
      });
    }
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      categoryFilters: cfilters,
      //categoryFilters: categoryFilters,
      sort: msorts,
    });
    setProducts([]);
    navigate({
      search,
    });
  };

  const getSupplierProductList = async ({
    page,
    limit,
    filters,
    categoryFilters,
    sorts,
    supplierId,
  }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      categoryFilters,
      sorting: sorts,
    };
    await axios
      .all([getProductsBySupplierId(details, supplierId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            console.log("resData", resData);
            setProducts(resData);
            setTotalCount(total);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  const getSuppliersList = async ({ page, limit, filters, initial }) => {
    let data = [];
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
    };
    await getUsers(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            resData.unshift({ label: "All", value: "" });
            data = resData;
            initial && setSuppliers(resData);
            //setTotalCount(total);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  // const importProductsFromZip = async (fileName) => {
  //   const details = {
  //     fileName,
  //   };
  //   await uploadProductsFromZip(details)
  //     .then((res) => {
  //       if (res) {
  //         let resData = res;
  //         let message = res.message;
  //         if (resData.success) {
  //           onAddFile();
  //           onPageChange(1);
  //           setImported(Math.random() * 10);
  //           setUploadLoding(false);
  //           notifySuccess("Products imported successfully!");
  //         } else {
  //           setUploadLoding(false);
  //           notifyError(message);
  //         }
  //       } else {
  //         setUploadLoding(false);
  //         notifyError("Error Occured while importing!");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notifyError("Error Occured while importing!");
  //       setUploadLoding(false);
  //     });
  // };

  // const onUploadProducts = async (values) => {
  //   setUploadLoding(true);
  //   await zipUpload(values.upload_file)
  //     .then((res) => {
  //       if (res) {
  //         let resData = res;
  //         let message = res.message;
  //         if (resData.success) {
  //           let data = resData?.data?.doc ? resData?.data?.doc : [];
  //           if (data.length > 0) {
  //             const fileName = data[0]?.filename || "";
  //             if (fileName) {
  //               importProductsFromZip(fileName);
  //             } else {
  //               setUploadLoding(false);
  //               notifyError("Error Occured while importing!");
  //             }
  //           } else {
  //             setUploadLoding(false);
  //             notifyError("Error Occured while importing!");
  //           }
  //           console.log(resData);
  //         } else {
  //           setUploadLoding(false);
  //           notifyError(message);
  //         }
  //       } else {
  //         setUploadLoding(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       notifyError("Error Occured while importing!");
  //       setUploadLoding(false);
  //     });
  // };

  const onPageChange = (page) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      searchString,
      ...(changedParams?.supplier ? { supplier: changedParams.supplier } : {}),
    });
    navigate({ search });
  };
  const onRowsChange = (limit) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit,
      filters: mfilters,
      sort: msorts,
      searchString,
      ...(changedParams?.supplier ? { supplier: changedParams.supplier } : {}),
    });
    navigate({ search });
  };

  const onSort = (values) => {
    const { title, direction } = values;

    let mfilters = filtersToUri(filters);
    let cfilters = filtersToUri(categoryFilters);
    let msorts = filtersToUri({
      // ...sorts,
      [title]: { [title]: direction },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      categoryFilters: cfilters,
      sort: msorts,
      searchString,
      ...(changedParams?.supplier ? { supplier: changedParams.supplier } : {}),
    });
    navigate({
      search,
    });
    setProducts([]);
  };
  const SortingList = [
    {
      label: "Sort Newest",
      value: "desc",
    },
    {
      label: "Sort Oldest",
      value: "asc",
    },
  ];

  const onAddFile = (selected) => {
    if (selected) {
      setUploadProductFile(selected);
    } else {
      setUploadProductFile("");
    }
    setAddFileModal(!addFile);
  };

  // const onUploadProducts = async (values) => {
  //   setUploadLoding(true);
  //   await importProducts(values.upload_file)
  //     .then((res) => {
  //       if (res) {
  //         let resData = res;
  //         if (resData.success) {
  //           notifySuccess("Product upload job initiated successfully.");
  //           onAddFile();
  //           onPageChange(1);
  //           setImported(Math.random() * 10);
  //           setUploadLoding(false);
  //         } else {
  //           setUploadLoding(false);
  //         }
  //       } else {
  //         setUploadLoding(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setUploadLoding(false);
  //     });
  // };
  const importProductsFromZip = async (fileName) => {
    const details = {
      fileName,
    };
    await uploadProductsFromZip(details)
      .then((res) => {
        if (res) {
          let resData = res;
          let message = res.message;
          if (resData.success) {
            onAddFile();
            onPageChange(1);
            setImported(Math.random() * 10);
            setUploadLoding(false);
            notifySuccess("Products imported successfully!");
          } else {
            setUploadLoding(false);
            notifyError(message);
          }
        } else {
          setUploadLoding(false);
          notifyError("Error Occured while importing!");
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError("Error Occured while importing!");
        setUploadLoding(false);
      });
  };

  const onUploadProducts = async (values) => {
    setUploadLoding(true);
    await zipUpload(values.upload_file)
      .then((res) => {
        if (res) {
          let resData = res;
          let message = res.message;
          if (resData.success) {
            let data = resData?.data?.doc ? resData?.data?.doc : [];
            if (data.length > 0) {
              const fileName = data[0]?.filename || "";
              if (fileName) {
                importProductsFromZip(fileName);
              } else {
                setUploadLoding(false);
                notifyError("Error Occured while importing!");
              }
            } else {
              setUploadLoding(false);
              notifyError("Error Occured while importing!");
            }
            console.log(resData);
          } else {
            setUploadLoding(false);
            notifyError(message);
          }
        } else {
          setUploadLoding(false);
        }
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error Occured while importing!");
        setUploadLoding(false);
      });
  };
  return (
    <div className="product-main">
      <div className="d-flex justify-content-between">
        <div>
          <HeadingMain text="Products" />
          {/* <div style={{ color: "gray" }}>Manage Your Account Chart</div> */}
        </div>

        <div className="d-flex btn-3 gap-2">
          {user?.role === "supplier" && (
            <>
              <IconButton
                onClick={() => onAddFile("")}
                title={"Import Products"}
              />
              <IconButton
                title="Add Product"
                onClick={() => navigate("/products/add-product")}
                icontype="add"
              ></IconButton>
            </>
          )}
        </div>
      </div>
      <div className="product-table-main">
        <div className="table-flex-main">
          <TableSearch
            //setSearchExpandForMobile={setSearchExpandForMobile}
            //searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            //searchLoading={searchLoading}
          ></TableSearch>
          <Input
            id="status"
            name="status"
            type="select"
            class="user-form-group"
            //  value={values.label}
            onChange={(e) => {
              onSort({ title: "createdAt", direction: `${e.target.value}` });
            }}
            //  onBlur={handleBlur}

            style={{ textTransform: "capitalize", width: "200px" }}
          >
            {SortingList &&
              SortingList.length > 0 &&
              SortingList.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
          </Input>
        </div>
        {products && products.length > 0 ? (
          <div className="content">
            <div className="table-main">
              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head">
                  <tr>
                    <th>Sr</th>
                    <th>Product Title</th>
                    <th>Product Category</th>
                    <th>Max Price</th>
                    <th>Selling Price</th>
                    <th>Cost Price</th>
                    <th> Shipping Charges</th>
                    <th>Discount</th>
                    <th>Delivery Charges</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("products", products)}
                  {products.map((each, index) => {
                    return (
                      <tr>
                        <td>
                          {index + 1 + (pagination.page - 1) * pagination.limit}
                        </td>
                        <td>{each?.title || "-"}</td>
                        <td>
                          {each.productCategories?.map(
                            (item) => item?.category?.name
                          )}
                        </td>
                        <td>{each?.inventory?.maxPrice || 0}</td>
                        <td>{each?.inventory?.sellingPrice || 0}</td>
                        <td>{each?.inventory?.costPrice || 0}</td>
                        <td>{each?.shippingCharge || 0}</td>
                        <td>{each?.discount || 0}</td>
                        <td>{each?.shippingCharge || 0}</td>

                        <td>
                          <span
                            onClick={() =>
                              navigate({
                                pathname: "/products/ProductDescription",
                                search: `?productId=${each.publicId}`,
                              })
                            }
                            // onClick={() => navigate("/products/ProductDescription")}
                            style={{ color: "#56E20A", cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={pagination.page}
                rowsPerPage={pagination.limit}
                totalCount={totalCount}
                onPageChange={onPageChange}
                onRowsPerPage={onRowsChange}
              />
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Products Found</div>
        )}
      </div>
      <Modal
        centered
        show={addFile}
        onHide={onAddFile}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import Products
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              upload_file: "",
            }}
            validationSchema={fileSchema}
            onSubmit={(values) => {
              onUploadProducts(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div
                  id="uploaddocument"
                  name="uploaddocument"
                  className="input-icon-group uploaddocument-group"
                >
                  <img src={uploadicon} alt="upload" />
                  <p>
                    {t(
                      "Browse and chose the files you want to upload from your computer"
                    )}
                  </p>
                  {values.upload_file && (
                    <div className="upload-preview">
                      <span>{values?.upload_file?.name}</span>
                    </div>
                  )}
                  <img
                    src={addicon}
                    alt="Add"
                    onClick={handleDocumentUploadClick}
                    className="addicon"
                  />
                  <input
                    name="upload_file"
                    ref={documentRef}
                    accept=".zip"
                    type="file"
                    onChange={(e) =>
                      setFieldValue("upload_file", e.target.files[0])
                    }
                    onBlur={handleBlur}
                    hidden
                  />
                </div>
                <Error errors={errors} touched={touched} name="upload_file" />
                <div className="mt-3">
                  <div className="d-flex gap-2 justify-content-end">
                    <Button className="back-icon-btn-main" onClick={onAddFile}>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button
                      className="btn-with-icon"
                      type="submit"
                      disabled={uploadLoading}
                    >
                      {uploadLoading ? <ButtonLoader /> : "Submit"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
