import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import AuthLayout from "../layouts/AuthLayout";
import Layout from "../layouts/Layout";
import Login from "../../containers/login";
import OtpVerification from "../../containers/login/otpVerification";
import ForgotPassword from "../../containers/forgotPassword";
import Register from "../../containers/register";
import DashBoard from "../../containers/dashboard";
import * as Storage from "../../store/LocalStorage";
import Products from "../../containers/product/Products";
import AddProduct from "../../containers/product/AddProduct";
import ProductDetails from "../../containers/product/ProductDetails";
import Users from "../../containers/users/Users";
import PromotionalBanners from "../../containers/promotionalBanners";
import FaqCategory from "../../containers/categoryFaq";
import AddClient from "../../containers/users/AddClient";
import AddSupplier from "../../containers/users/AddSuplier";
import Coupons from "../../containers/vouchers/Vouchers";
import AddCoupons from "../../containers/vouchers/AddVoucher";
import Orders from "../../containers/orders/Orders";
import OrderDetails from "../../containers/orders/OrderDetails";
import Settings from "../../containers/settings/Settings";
import Profile from "../../containers/profile/Profile";
import useConfig from "../../hooks/useConfig";
import MainServices from "../../services/MainServices";
import { useState } from "react";
import { LazyLoader } from "../../assets/loaders";
import AddAddress from "../../containers/users/AddAddress";
import Category from "../../containers/category";
import AddService from "../../containers/Services/AddService";
import AddDesignation from "../../containers/Designation/AddDesignation";
import Brand from "../../containers/brand";
import ProductType from "../../containers/productType";
import Countries from "../../containers/countries";
import States from "../../containers/states/StateList";
import UserDetails from "../../containers/users/UserDetails";
import User from "../../containers/User";
import User2 from "../../containers/User/User2";
import FAQList from "../../containers/faq/FAQList";
import CategoryFaq from "../../containers/categoryFaq";
import Catering from "../../containers/catering/Catering";
import AddRestaurant from "../../containers/catering/AddRestaurant";
import Photography from "../../containers/photography";
import AddPhotography from "../../containers/photography/AddPhotography";
import ChatBox from "../../containers/chatBox";
import Enquiries from "../../containers/enquiries";
import EnquiryDetails from "../../containers/enquiries/EnquiryDetails";
import CreateQuotation from "../../containers/quotation";
import QuotationOverview from "../../containers/quotation/QuotationOverview";

import {
  RestaurantCategory,
  RestaurantType,
  RestaurantDetails,
} from "../../containers/catering";
import {
  PhotographyCategory,
  PhotographyDetails,
} from "../../containers/photography";
import Policies from "../../containers/policies";
import AddPolicy from "../../containers/policies/AddPolicies";
import VendorWeek from "../../containers/vendor";
import Invoices from "../../containers/invoices"
import Reports from "../../containers/Reports";
import Services from "../../containers/Services";
import Inventory from "../../containers/supplier";
import AddSupplierr from "../../containers/supplier/addSupplier";
import Designation from "../../containers/Designation";
import Staff from "../../containers/staff";
import ProductDescription from "../../containers/product/ProductDescription";
import AddEmployee2 from "../../containers/staff/AddEmployee2";
import ProfileUser from "../../containers/ProfileUser";
import Customers from "../../containers/Customers";
import OrderDetailsInvoice from "../../containers/orders/OrderInvoice";
export default function CustomRoutes() {
  const { user, updateUserContext } = useAuth();
  const { getUserProfile } = MainServices();
  const [loading, setLoading] = useState(false);

  const config = useConfig();
  const navigate = useNavigate();

  useEffect(() => {
    checkSession();
    // eslint-disable-next-line
  }, []);

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    session = JSON.parse(session);

    if (session) {
      updateUserContext(session);
      setLoading(false);
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  const getProfile = async (token) => {
    await getUserProfile()
      .then(async (response) => {
        let resData = response;
        if (resData.success) {
          resData = resData?.data || {};
          resData = {
            ...resData,
            token,
          };
          await updateUserContext(resData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return loading ? (
    <LazyLoader />
  ) : user?.token ? (
    !user?.isMobileVerified ? (
      <Routes>
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/*" element={<OtpVerification />} />
      </Routes>
    ) : (
      <AuthLayout>
        <Routes>
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/*" element={<DashBoard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/catering" element={<Catering />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add-product" element={<AddProduct />} />
          <Route path="/products/add-Service" element={<AddService/>} />
          <Route path="/category" element={<Category />} />
          <Route path="/products/ProductDescription" element={<ProductDescription/>} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/enquiries" element={<Enquiries />} />
          <Route path="/promotionalBanner" element={<PromotionalBanners />} />
          <Route path="/faqCategory" element={<FaqCategory />} />
          <Route path="/enquiries/enquiry-details" element={<EnquiryDetails />} />
          <Route path="/enquiries/create-quotation" element={<CreateQuotation />} />
          <Route path="/enquiries/quotation-details" element={<QuotationOverview />} />
          <Route path="/Customers" element={<Customers />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Staff" element={<Staff />} />
          <Route path="/user" element={<User />} />
          <Route path="/User2" element={<User2 />} />
          <Route path="/brand" element={<Brand />} />
          
          <Route path="/Staff/AddEmployee2" element={<AddEmployee2 />} />
          <Route path="/Supplier" element={<Inventory />} />
          <Route path="/Supplier/add-supplier" element={<AddSupplierr />} />
          <Route path="/Designation" element={<Designation />} />
          <Route path="/ProfileUser" element={<ProfileUser />} />
          <Route path="/orders/order-details-invoice" element={<OrderDetailsInvoice />} />
          <Route path="/designation/add-Designation" element={<AddDesignation/>} />
          
          <Route
            path="/products/product-details"
            element={<ProductDetails />}
          />
          <Route
            path="/catering/restaurant-details"
            element={<RestaurantDetails />}
          />
          <Route
            path="/photography/photography-details"
            element={<PhotographyDetails />}
          />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/coupons/add-coupon" element={<AddCoupons />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/order-details" element={<OrderDetails />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/chat" element={<ChatBox />} />
          {user.role === "supplier" && (
            <>
              <Route
                path="/vendor-week-availability"
                element={<VendorWeek />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/catering/add-restaurant"
                element={<AddRestaurant />}
              />
              <Route
                path="/photography/add-photography"
                element={<AddPhotography />}
              />
            </>
          )}
          {user.role === "admin" && (
            <>
              <Route path="/users/add-client" element={<AddClient />} />
              <Route path="/users/add-supplier" element={<AddSupplier />} />
              <Route
                path="/users/add-client/add-address"
                element={<AddAddress />}
              />
              <Route path="/users/user-details" element={<UserDetails />} />
              <Route path="/users" element={<Users />} />
              <Route path="/countries" element={<Countries />} />
              <Route path="/states" element={<States />} />
              <Route path="/category" element={<Category />} />
              <Route path="/brand" element={<Brand />} />
              <Route path="/product-type" element={<ProductType />} />
              <Route path="/faq-list" element={<FAQList />} />
              <Route path="/category-faq" element={<CategoryFaq />} />
              <Route
                path="/restaurant-category"
                element={<RestaurantCategory />}
              />
              <Route path="/restaurant-type" element={<RestaurantType />} />
              <Route
                path="/photography-category"
                element={<PhotographyCategory />}
              />
              <Route path="/policies" element={<Policies />} />
              <Route path="/policies/add" element={<AddPolicy />} />
            </>
          )}
        </Routes>
      </AuthLayout>
    )
  ) : (
    <Layout>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/*" element={<Login />} />
      </Routes>
    </Layout>
  );
}
