import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/images/Mventus Logo (4).svg";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import {
  useLocation,
  useNavigate,
  NavLink as RouterLink,
} from "react-router-dom";
import MainServices from "../../services/MainServices";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../assets/elements/Toaster";
import useConfig from "../../hooks/useConfig";
import * as Storage from "../../store/LocalStorage";
import useAuth from "../../hooks/useAuth";

const validationSchema = Yup.object().shape({
  otp0: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
  otp1: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
  otp2: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
  otp3: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
  otp4: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
  otp5: Yup.string()
    .matches(/^\d$/, "Must be a single digit")
    .required("Required"),
});

const OtpVerification = () => {
  const { otpVerification, resendOtp } = MainServices();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const [buttonLoading, setButtonLoading] = useState(false);
  const location = useLocation();
  const empContact = new URLSearchParams(location.search).get("mobileNumber");
  const emailAddress = new URLSearchParams(location.search).get("emailAddress");
  const pageType = new URLSearchParams(location.search).get("pageType");
  const { sessionKey } = useConfig();
  const { updateUserContext, user, logout } = useAuth();

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (pageType === "login") {
      onResendOtp();
    }
  }, []);

  const onResendOtp = async () => {
    setButtonLoading(true);
    const details = {
      mobileNumber: user?.mobileNumber ? user.mobileNumber : empContact,
    };
    await axios
      .all([resendOtp(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess("Otp Sent Successfully To Registered Mobile Number");
            setButtonLoading(false);
          } else {
            let errors = resData?.error || {};
            if (errors.message) {
              notifyWarn(errors?.message);
            }
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in resending OTP");
      });
  };
  const handleOtpChange = (index, value) => {
    // const {name,value}=e.target;
    // setOtp(prev=>({
    //   ...prev,
    //   [name]:value

    // }))

    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index > 0 && value === "") {
        document.getElementById(`otp${index - 1}`).focus();
      } else if (index < 5 && value !== "") {
        document.getElementById(`otp${index + 1}`).focus();
      }
    }
  };

  const OtpVerification = async (values) => {
    let otpNumber =
      values.otp0 +
      values.otp1 +
      values.otp2 +
      values.otp3 +
      values.otp4 +
      values.otp5;
    setButtonLoading(true);
    const details = {
      mobileNumber: user?.mobileNumber ? user.mobileNumber : empContact,
      otp: otpNumber,
    };
    await axios
      .all([otpVerification(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            if (pageType === "login") {
              let session = Storage.get(sessionKey);
              session = JSON.parse(session);
              session.isMobileVerified = true;
              Storage.set(sessionKey, JSON.stringify(session));
              updateUserContext(session);
              // navigate("/dashboard");
               navigate("/orders");
            } else {
              navigate("/login");
            }
            notifySuccess("User Verified Successfully");
            setButtonLoading(false);
          } else {
            let errors = resData?.error || {};
            if (errors?.message) {
              notifyWarn(errors.message);
            }
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in User Verification");
      });
  };

  const handleResend = () => {
    // Add logic to resend OTP
    setTimer(60);
    onResendOtp();
  };

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100 login-main">
      <Card
        className="mx-auto p-4"
        style={{
          maxWidth: "400px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
          backgroundColor: "white",
          border: "none",
          borderRadius: "12px",
        }}
      >
        <div className=" d-flex justify-content-center mb-5">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{ maxWidth: "50%" }}
          />
        </div>
        <div className=" mb-2 mt-4">
          <h4> OTP Verification</h4>
          <p className="para">
            Enter the 6-digit OTP sent to your{" "}
            {user?.mobileNumber ? user?.mobileNumber : empContact} and email{" "}
            {user?.email ? user.email : emailAddress}
          </p>
        </div>

        <Formik
          initialValues={{
            otp0: "",
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            OtpVerification(values);
          }}
        >
          {({ handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-center mb-3">
                <Field
                  name="otp0"
                  type="text"
                  id="otp0"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp0}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(0, e.target.value);
                  }}
                />
                <Field
                  name="otp1"
                  type="text"
                  id="otp1"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp1}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(1, e.target.value);
                  }}
                />
                <Field
                  name="otp2"
                  type="text"
                  id="otp2"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp2}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(2, e.target.value);
                  }}
                />
                <Field
                  name="otp3"
                  type="text"
                  id="otp3"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp3}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(3, e.target.value);
                  }}
                />
                <Field
                  name="otp4"
                  type="text"
                  id="otp4"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp4}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(4, e.target.value);
                  }}
                />
                <Field
                  name="otp5"
                  type="text"
                  id="otp5"
                  className="form-control mx-2 text-center"
                  maxLength="1"
                  value={values.otp5}
                  style={{
                    maxWidth: "56px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(-1);
                    handleChange(e);
                    handleOtpChange(5, e.target.value);
                  }}
                />
              </div>
              <div
                className="d-flex align-items-center flex-column flex-md-row justify-content-end mb-3"
                style={{ fontSize: "14px" }}
              >
                <span className="text-nowrap">{timer} sec</span>
                <Button
                  className="text-center link text-nowrap bg-transparent p-0 border-0 link-primary text-primary resend-button"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => handleResend()}
                  disabled={timer > 0}
                >
                  &nbsp;Resend it?
                </Button>
              </div>

              <div className="mb-4 gap-2 d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  onClick={onLogout}
                  style={{
                    fontWeight: 500,
                    color: "#176fe6",
                    cursor: "pointer",
                  }}
                >
                  Click here to login
                </p>
                <PrimaryButton
                  type="submit"
                  className="btn btn-lg btn-primary"
                  title="Submit"
                  style={{ fontSize: "14px" }}
                  isLoading={buttonLoading}
                ></PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

export default OtpVerification;
