import { React, useState } from "react";
import "./AddClient.scss";
import { Form, Input, Label, FormGroup } from "reactstrap";
import { CiSquarePlus } from "react-icons/ci";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import IconButton from "../../assets/elements/IconButton";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import { useNavigate } from "react-router-dom";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import closeicon from "../../assets/images/icons/close.svg";

export default function AddClient() {
  const [state, setState] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formikFieldsSchema = ["fullName", "number", "email", "password"];
  const {getAvailableLanguages, getFormikValuesForAllLanguages} = useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const formikValues = getFormikValuesForAllLanguages(formikFieldsSchema);

  return (
    <div className="client-main">
      <HeadingMain text="Add New Client">
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      <div className="client-topcard-main">
        <h1 className="client-heading">{t("Basic Details")}</h1>
        <Form className="row">
          <div className=" col-12 col-md-6 col-lg-6">
            <FormGroup>
              <Label for="firstName">{t("First Name")}</Label>
              <Input
                id="firstName"
                name="firstName"
                placeholder={t("Enter client's first name")}
                type="text"
                className="user-form-group"
              />
            </FormGroup>
            {availableLanguages.map(language => {
              return <>
                <FormGroup>
                  <Label for="firstName">{`${t("First Name")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                  <Input
                    name={"firstName_"+language.value}
                    placeholder={`${t("Enter client's first name")} ${t("in")} ${t(language.name)} (${language.symbol})`}
                    type="text"
                    className="user-form-group"
                  />
                </FormGroup>
              </>
            })}
          </div>

          <div className=" col-12 col-md-6 col-lg-6">
            <FormGroup>
              <Label for="lastName">{t("Last Name")}</Label>
              <Input
                id="lastName"
                name="lastName"
                placeholder={t("Enter client's last name")}
                type="text"
                className="user-form-group"
              />
            </FormGroup>
            {availableLanguages.map(language => {
              return <>
                <FormGroup>
                  <Label for="lastName">{`${t("Last Name")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                  <Input
                    name={"lastName_"+language.value}
                    placeholder={`${t("Enter client's last name")} ${t("in")} ${t(language.name)} (${language.symbol})`}
                    type="text"
                    className="user-form-group"
                  />
                </FormGroup>
              </>
            })}
            <br />
          </div>

          <FormGroup className=" col-12 col-md-6 col-lg-3">
            <Label for="mobileNo">{t("Mobile Number")}</Label>
            <Input
              id="mobileNo"
              name="mobileNo"
              placeholder="9890979655"
              type="number"
              className="user-form-group"
            />
          </FormGroup>

          <FormGroup className=" col-12 col-md-6 col-lg-3">
            <Label for="email">{t("Email Address")}</Label>
            <Input
              id="email"
              name="email"
              placeholder={t("Enter client's email address")}
              type="email"
              className="user-form-group"
            />
          </FormGroup>

          <FormGroup className=" col-12 col-md-6 col-lg-3">
            <Label for="gender">{t("Gender")}</Label>
            <Input
              id="gender"
              name="gender"
              type="select"
              className="user-form-group"
            >
              <option>{t("select an option")}</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </Input>
          </FormGroup>

          <FormGroup className="col-12 col-md-6 col-lg-3">
            <Label for="dob">{t("Date Of Birth")}</Label>
            <Input
              id="dob"
              name="dob"
              placeholder="DD-MM-YY"
              type="date"
              className="user-form-group"
            />
          </FormGroup>
        </Form>
      </div>
      <div class="class-clientcard-flex">
        <div className="client-bottomcard-main">
          <h1 className="client-heading">
            {t("Important Client Information")}
          </h1>
          <p>
            {t(
              "Important client information will only be visible to you and team members"
            )}
            .
          </p>
          <Form>
            <FormGroup>
              <Label for="clientInfo">{t("Client Information")}</Label>
              <Input
                id="clientInfo"
                name="clientInfo"
                placeholder="i.e"
                type="text"
                className="user-form-group"
              />
            </FormGroup>
            
            {availableLanguages.map(language => {
              return <>
                <FormGroup>
                  <Label for="clientInfo">{`${t("Client Information")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                  <Input
                    id="clientInfo"
                    name={"clientInfo_"+language.value}
                    placeholder="i.e"
                    type="text"
                    className="user-form-group"
                  />
                </FormGroup>
              </>
            })}
            <FormGroup switch>
              <Input
                type="switch"
                checked={state}
                onClick={() => {
                  setState(!state);
                }}
              />
              <Label check>{t("Display on all orders")}</Label>
            </FormGroup>
          </Form>
        </div>

        <div className="client-bottomcard-main">
          <h1 className="client-heading ">{t("Additional Information")}</h1>
          <Form>
            <FormGroup>
              <Label for="bphoneNo">{t("Additional Phone Number")}</Label>
              <Input
                id="bphoneNo"
                name="bphoneNo"
                placeholder="9890979655"
                type="number"
                className="user-form-group"
              />
            </FormGroup>

            <FormGroup>
              <Label for="clientSource">{t("Client Source")}</Label>
              <Input
                id="clientSource"
                name="clientSource"
                type="select"
                className="user-form-group"
              >
                <option>i.e Brand, Shop, Stock</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </Input>
            </FormGroup>

            <IconButton
              title={t("Add Client Address")}
              icon={
                <CiSquarePlus
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                  }}
                />
              }
              onClick={() => navigate("/users/add-client/add-address")}
            />
          </Form>
        </div>
      </div>
      <div className="save-btn-float">
        <PrimaryButton title="Save" />
      </div>
    </div>
  );
}
