import { React, useState, useEffect } from "react";
import "./Users.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import IconButton from "../../assets/elements/IconButton";
import Tab from "../../assets/elements/Tab";
import { useTranslation } from "react-i18next";
import TableSearch from "../../assets/elements/TableSearch";
import HeadingMain from "../../assets/elements/HeadingMain";
import MainServices from "../../services/MainServices";
import moment from "moment";
import CustomPagination from "../../assets/elements/Pagination";
import CustomTable from "../../assets/elements/Table";
import { Input } from "reactstrap";
import {
  filtersToUri,
  filtersToUrlParams,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import deleteicon from "../../assets/images/icons/delete-curved.svg";

const tabsItem = [
  {
    label: "SUPPLIERS",
    value: "supplier",
  },
  {
    label: "CUSTOMERS",
    value: "user",
  },
];

export default function Users() {
  const [userType, setuserType] = useState("supplier");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState({});
  const [searchString, setSearchString] = useState("");
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { getUsers } = MainServices();
  const { deBounce } = TimingFunctions();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    console.log("filters-", changedParams)
    let filters = paramsToFilters(changedParams?.filters, "filters");
    console.log("filters-changed", filters)
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    console.log("sorts", sorts)
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    console.log("sorts-changed", sortObj)
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    if (!filters || !filters.length > 0) {
      filters = [{ key: "role", eq: "supplier" }];
      filtersObj = { role: { role: "supplier" } };
    }
    setuserType(changedParams?.user);
    setPagination(pagination);
    setSelectedStatus(filtersObj?.status?.status || "active");
    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    getSuppliersList({
      page: pagination.page,
      limit: pagination.limit,
      filters,
      sort: sorts,
    });
    //eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.sort,
    changedParams.filters,
    changedParams.user,
  ]);

  const getSuppliersList = async ({ page, limit, filters, sort}) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      sorting:[...sort,{key:"createdAt",direction:"desc"}]
    };
    await getUsers(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            console.log("users: ", resData);

            setUsers(resData);
            setTotalCount(total);
            setSearchLoading(false);
          }
        } else {
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  //pagination functions
  const onPageChange = (page) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({
      search,
    });
  };

  //filters
  const onUserTypeChange = (userType) => {
    setTotalCount(0);
    let mfilters = filtersToUri({
      ...filters,
      role: { role: userType },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({
      search,
    });
  };

  const onNameSort = () => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({
      search,
    });
  };

  const onSearch = (e) => {
    deBounce(() => onSearchFilter(e));
    setSearchLoading(true);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      user: userType,
    });
    navigate({
      search,
    });
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <AiOutlineEye
          style={{
            color: "#FE5858",
            height: "24px",
            width: "24px",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate({
              pathname: "/users/user-details",
              search: `?user=${row.publicId}`,
            })
          }
        />
        <img src={deleteicon} alt="edit" className="action-icon" />
      </div>
    );
  };

  const dateFormat = (row) => {
    if (!row) return;
    return moment(row?.updatedAt).format("MMM Do YYYY");
  };

  const clickAction = (row) => {
    if (!row) return;
    return (
      <div
        onClick={() =>
          navigate({
            pathname: "/users/user-details",
            search: `?user=${row.publicId}`,
          })
        }
        className="username"
        style={{ textTransform: "capitalize" }}
      >
        {row.name}
      </div>
    );
  };

  const styledStatus = (row) => {
    if (!row) return;
    return (
      <div className="status">
        <p
          className={
            row.status === "deleted" ? "inactive" : row.status.toLowerCase()
          }
        >
          {row.status === "deleted" ? "inactive" : t(row.status.charAt(0).toUpperCase()+row.status.slice(1))}
        </p>
      </div>
    );
  };
  const supplier_columns = [
    {
      field: "name",
      text: "Supplier Name",
      customStyle: clickAction,
    },
    {
      field: "mobileNumber",
      text: "Mobile Number",
    },
    {
      field: "email",
      text: "Email Address",
    },
    {
      field: "status",
      text: "Status",
      customStyle: styledStatus,
    },
    {
      field: "updatedAt",
      text: "Updated At",
      customStyle: dateFormat,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const customer_columns = [
    {
      field: "name",
      text: "Customer Name",
      customStyle: clickAction,
    },
    {
      field: "mobileNumber",
      text: "Mobile Number",
    },
    {
      field: "email",
      text: "Email Address",
    },
    {
      field: "product",
      text: "Product",
    },
    {
      field: "orderedAt",
      text: "Order Date",
      customStyle: dateFormat,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  return (
    <div className="users-main">
      <HeadingMain text="Users" />
      <div className="tab-flex-main">
        {tabsItem.map((each) => {
          return (
            <Tab
              title={userType === each.value ? t(each.label)+"("+totalCount+")" : each.label }
              className={userType === each.value && "active"}
              onClick={() => onUserTypeChange(each.value)}
            />
          );
        })}
      </div>
      <div className="user-table-main">
        <div className="table-flex-main">
          <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            searchLoading={searchLoading}
          >
            <div
              className={`filter-grey-main name-sort ${
                sorts?.name ? "sort-hightlight" : ""
              }`}
              onClick={onNameSort}
            >
              <span className="title">{t("Name")} (A-Z)</span>
              <span>
                <BsArrowUp
                  style={{
                    color: sorts?.name?.name === "asc" ? "#d60000" : "#CCBFDD",
                  }}
                />
                <BsArrowDown
                  style={{
                    color: sorts?.name?.name === "desc" ? "#d60000" : "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <div>
              <Input
                className="select"
                onChange={onStatusChange}
                value={selectedStatus}
                type="select"
              >
                <option value="">{t("Select Status")}</option>
                <option value="pending">{t("Pending")} </option>
                <option value="active">{t("Active")} </option>
                <option value="approved">{t("Approved")} </option>
                <option value="rejected">{t("Rejected")} </option>
                <option value="inactive">{t("Inactive")} </option>
                <option value="blocked">{t("Blocked")} </option>
              </Input>
            </div>
          </TableSearch>

          {userType === "supplier" ? (
            <div className="add-user-btn-margin">
              <IconButton
                title={t("Add Supplier")}
                onClick={() => navigate("/users/add-supplier")}
                icontype="add"
              >
              </IconButton>
            </div>
          ) : (
            ""
          )}
        </div>
        {users && users.length > 0 ? (
          <div>
            {userType === "supplier" ? (
              <CustomTable data={users} columns={supplier_columns} />
            ) : (
              <CustomTable data={users} columns={customer_columns} />
            )}
            <CustomPagination
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              totalCount={totalCount}
              onPageChange={onPageChange}
              onRowsPerPage={onRowsChange}
            />
          </div>
        ) : (
          <div className="table-text-main">
            <p>{t(`No ${userType.charAt(0).toUpperCase() + userType.slice(1)} Found`)}!</p>
          </div>
        )}
      </div>
    </div>
  );
}
