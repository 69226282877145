import { React } from "react";
import useAuth from "../../hooks/useAuth";
import AdminOrdersList from "./AdminOrdersList";
import SupplierOrdersList from "./SupplierOrdersList";
import "./Orders.scss";
import HeadingMain from "../../assets/elements/HeadingMain";

export default function Orders() {
  const { user } = useAuth();

  return (
    <div className="order-main">
      {user.role === "admin" && <AdminOrdersList />}
      {user.role === "supplier" && 
        <>
          <HeadingMain text="Orders" />
          <SupplierOrdersList />
        </>}
    </div>
  );
}
