import FetchServices from "./FetchServices";
import apiService from "./ApiServices";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const Services = {
    //files Functions
    fileUpload: function (file) {
      let API = apiService.fileUpload;
      var bodyFormData = new FormData();
      Array.isArray(file)
        ? file.forEach((eachFile) => bodyFormData.append("file", eachFile))
        : file && bodyFormData.append("file", file);
      let type = "POST";
      return AxiosService(
        API,
        bodyFormData,
        true,
        type,
        "",
        undefined,
        true
      ).then((res) => {
        return res;
      });
    },
    zipUpload: function (file) {
      let API = apiService.uploadZipFile;
      var bodyFormData = new FormData();
      Array.isArray(file)
        ? file.forEach((eachFile) => bodyFormData.append("filelocal", eachFile))
        : file && bodyFormData.append("filelocal", file);
      let type = "POST";
      return AxiosService(
        API,
        bodyFormData,
        true,
        type,
        "",
        undefined,
        true
      ).then((res) => {
        return res;
      });
    },
    publicFileUpload: function (file) {
      let API = apiService.publicFileUpload;
      var bodyFormData = new FormData();
      Array.isArray(file)
        ? file.forEach((eachFile) => bodyFormData.append("file", eachFile))
        : file && bodyFormData.append("file", file);
      let type = "POST";
      return AxiosService(
        API,
        bodyFormData,
        false,
        type,
        "",
        undefined,
        true
      ).then((res) => {
        return res;
      });
    },
    getFile: function (details) {
      let API = apiService.getFile + `/${details.fileId}`;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    downloadFile: function (id) {
      let API = apiService.downloadFile + "/" + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    viewFile: function (id) {
      let API = apiService.viewFile + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type, "", null, "", true).then(
        (res) => {
          return res;
        }
      );
    },

    //User functions
    userSignin: function (details) {
      let API = apiService.userSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    userRegistration: function (details) {
      let API = apiService.userRegistration;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },
    userSupplierRegistration: function (details) {
      let API = apiService.userSupplierRegistration;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    userResetPassword: function (details) {
      let API = apiService.userResetPassword;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    userChangePassword: function (details, token) {
      let API = apiService.userChangePassword;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type, "", token).then((res) => {
        return res;
      });
    },

    otpVerification: function (details) {
      let API = apiService.otpVerification;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    resendOtp: function (details) {
      let API = apiService.resendOtp;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    getGoogleSignin: function (details) {
      let API = apiService.getGoogleSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },

    saveUser: function (details, token) {
      let API = apiService.saveUser;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type, "", token).then((res) => {
        return res;
      });
    },
    //inquires
    addInquires: function (details, token) {
      let API = apiService.addInquires;
      console.log("API", API);
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type, "", token).then((res) => {
        return res;
      });
    },
    getUserProfile: function (token) {
      let API = apiService.getUserProfile;

      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type, "", token).then((res) => {
        return res;
      });
    },

    getUserProfileById: function (id) {
      let API = apiService.getUserProfileById + "/" + id;

      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateUser: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateUser + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateUserStatusById: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateUserStatusById + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateSupplier: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateSupplier + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateVerificationDocumentStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateVerificationDocumentStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    getUsers: function (details) {
      let API = apiService.getUsers;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    //suppliers API's
    getSuppliers: function (details) {
      let API = apiService.getSuppliers;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getSupplierById: function (id) {
      let API = apiService.getSupplierById + "/" + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    // address
    saveAddress: function (details) {
      let API = apiService.saveAddress;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    modifyAddress: function (details, publicId, concurrencyStamp) {
      let API = apiService.saveAddress + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    getUserAddress: function (details) {
      let API = apiService.getUserAddress;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getUserAddressById: function (id) {
      let API = apiService.getUserAddressById + "/" + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    //countries && States
    getCountries: function (details) {
      let API = apiService.getCountries;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getAllCountriesPublic: function (details) {
      let API = apiService.getAllCountriesPublic;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getStatesByCountry: function (details) {
      let API = apiService.getStatesByCountry + "/" + details.countryId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getActiveCountries: function (details) {
      let API = apiService.getActiveCountries;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getAllStatesPublic: function (details) {
      let API = apiService.getAllStatesPublic;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateCountryStatus: function (details) {
      let API = apiService.updateCountryStatus + "/" + details.countryId;

      let payload = {
        status: details.status,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateStateStatus: function (details) {
      let API = apiService.updateStateStatus + "/" + details.stateId;

      let payload = {
        status: details.status,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    //brands
    getBrands: function (details) {
      let API = apiService.getBrands;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    saveBrand: function (details, token) {
      let API = apiService.saveBrand;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateBrand: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateBrand + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //categories
    getCategories: function (details) {
      let API = apiService.getCategory;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    saveCategory: function (details) {
      let API = apiService.saveCategory;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateCategory: function (details, public_id, concurrency_stamp) {
      if (!public_id) {
        return;
      }
      let API = apiService.updateCategory + "/" + public_id;
      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrency_stamp).then(
        (res) => {
          return res;
        }
      );
    },

    //products
    saveProduct: function (details) {
      let API = apiService.saveProduct;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    variantProduct: function (details) {
      let API = apiService.variantProduct;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    uploadProductsFromZip: function (details) {
      let API = apiService.uploadProductsFromZip;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateProductById: function (details, publicId, concurrencyStamp) {
      let API = apiService.updateProductById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateProductPriceById: function (details, publicId, concurrencyStamp) {
      let API = apiService.updateProductPriceById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    getProductById: function (id) {
      let API = apiService.getProductById + "/" + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProductPriceById: function (id) {
      let API = apiService.getProductPriceById + "/" + id;
      let payload = {};
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProducts: function (details) {
      let API = apiService.getProducts;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProductsBySupplierId: function (details, supplier_id) {
      if (!supplier_id) {
        return;
      }
      let API = apiService.getProductsBySupplierId + "/" + supplier_id;
      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getProductDetails: function (publicId) {
      if (!publicId) {
        return;
      }
      let API = apiService.getProductDetails + "/" + publicId;
      let type = "GET";

      return AxiosService(API, {}, true, type).then((res) => {
        return res;
      });
    },

    approveProductByAdmin: function (details, publicId, concurrency_stamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.approveProductByAdmin + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrency_stamp).then(
        (res) => {
          return res;
        }
      );
    },
    saveProductType: function (details) {
      let API = apiService.saveProductType;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateProductType: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateProductType + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    getProductTypes: function (details) {
      let API = apiService.getProductTypes;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateProductTypeStatus: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateProductTypeStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    updateBatchQuantity: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateBatchQuantity + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    updateProductVariants: function (details, publicId) {
      let API = apiService.updateProductVariants + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getOrders: function (details) {
      let API = apiService.getOrders;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getOrderById: function (details, orderId) {
      let API = apiService.getOrderById + "/" + orderId;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getOrdersByType: function (details, urlEnd) {
      let API = apiService.getOrdersByType;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getOrderProductList: function (details) {
      let API = apiService.getOrderProductList;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    orderItemUpdateStatus: function (details, orderId, concurrencyStamp) {
      let API = apiService.orderItemUpdateStatus + "/" + orderId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //PDF Generation
    generatePdf: function (details, lang) {
      let API = apiService.generatePdf + `?lang=${lang}`;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    // Catering API's
    saveRestaurant: function (details) {
      let API = apiService.saveRestaurant;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getRestaurants: function (details) {
      let API = apiService.getRestaurants;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getRestaurantDetails: function (publicId) {
      if (!publicId) {
        return;
      }
      let API = apiService.getRestaurantDetails + "/" + publicId;
      let type = "GET";

      return AxiosService(API, {}, true, type).then((res) => {
        return res;
      });
    },
    updateRestaurantById: function (details, publicId, concurrencyStamp) {
      let API = apiService.updateRestaurantById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateRestaurantStatus: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateRestaurantStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    saveRestaurantCategory: function (details) {
      let API = apiService.saveRestaurantCategory;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getRestaurantCategories: function (details) {
      let API = apiService.getRestaurantCategories;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateRestaurantCategory: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateRestaurantCategory + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateRestaurantCategoryStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateRestaurantCategoryStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    // Catering Type API's
    saveRestaurantType: function (details) {
      let API = apiService.saveRestaurantType;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getRestaurantTypes: function (details) {
      let API = apiService.getRestaurantTypes;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateRestaurantType: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateRestaurantType + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updateRestaurantTypeStatus: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateRestaurantTypeStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    // Photograhpy API's
    savePhotoshoot: function (details) {
      let API = apiService.savePhotoshoot;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getPhotoshoots: function (details) {
      let API = apiService.getPhotoshoots;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getPhotoshootDetails: function (publicId) {
      if (!publicId) {
        return;
      }
      let API = apiService.getPhotoshootDetails + "/" + publicId;
      let type = "GET";

      return AxiosService(API, {}, true, type).then((res) => {
        return res;
      });
    },
    updatePhotoshootStatus: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updatePhotoshootStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updatePhotoshootById: function (details, publicId, concurrencyStamp) {
      let API = apiService.updatePhotoshootById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updatePhotoshootPriceById: function (details, publicId, concurrencyStamp) {
      let API = apiService.updatePhotoshootPriceById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    savePhotographyCategory: function (details) {
      let API = apiService.savePhotographyCategory;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getPhotographyCategories: function (details) {
      let API = apiService.getPhotographyCategories;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updatePhotographyCategory: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updatePhotographyCategory + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    updatePhotographyCategoryStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      if (!publicId) {
        return;
      }
      let API = apiService.updatePhotographyCategoryStatus + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //Promotional Banners
    getPromotionalBanners: function (details) {
      let API = apiService.getPromotionalBanners;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    savePromotionalBanners: function (details) {
      let API = apiService.savePromotionalBanners;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updatePromotionalBanners: function (details, public_id, concurrency_stamp) {
      if (!public_id) {
        return;
      }
      let API = apiService.updatePromotionalBanners + "/" + public_id;
      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrency_stamp).then(
        (res) => {
          return res;
        }
      );
    },

    updatePromotionalBannersStatus: function (
      details,
      public_id,
      concurrency_stamp
    ) {
      if (!public_id) {
        return;
      }
      let API = apiService.updatePromotionalBannersStatus + "/" + public_id;
      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrency_stamp).then(
        (res) => {
          return res;
        }
      );
    },

    //update Settings
    updateSettings: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateSettings + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //policies api
    getAboutus: function (details) {
      let API = apiService.getAboutus;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getPrivacyPolicy: function (details) {
      let API = apiService.getPrivacyPolicy;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getTermsAndConditions: function (details) {
      let API = apiService.getTermsAndConditions;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getReturnPolicy: function (details) {
      let API = apiService.getReturnPolicy;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getCookiePolicy: function (details) {
      let API = apiService.getCookiePolicy;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    //working hours
    getWorkingHours: function (details, supplier) {
      let API = apiService.getWorkingHours + "/" + supplier;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    saveWorkingHours: function (details) {
      let API = apiService.saveWorkingHours;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateWorkingHours: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateWorkingHours + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
    getNotifications: function (details) {
      let API = apiService.getNotifications;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getNotificationsBySupplierId: function (details, supplier_id) {
      if (!supplier_id) {
        return;
      }
      let API = apiService.getNotificationsBySupplierId + "/" + supplier_id;
      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    addNotifications: function (details) {
      let API = apiService.addNotifications;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateNotification: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateNotification + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //batch api
    getBatchesByProduct: function (details, productId) {
      let API = apiService.getBatchesByProduct + "/" + productId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateProductBatchPrice: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateProductBatchPrice + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    uploadProducts: function (file) {
      let API = apiService.uploadProducts;
      var bodyFormData = new FormData();
      Array.isArray(file)
        ? file.forEach((eachFile) => bodyFormData.append("file", eachFile))
        : file && bodyFormData.append("file", file);
      let type = "POST";
      return AxiosService(
        API,
        bodyFormData,
        true,
        type,
        "",
        undefined,
        true
      ).then((res) => {
        return res;
      });
    },
    importProducts: function (file) {
      let API = apiService.importProducts;
      var bodyFormData = new FormData();
      Array.isArray(file)
        ? file.forEach((eachFile) => bodyFormData.append("product", eachFile))
        : file && bodyFormData.append("product", file);
      let type = "POST";
      return AxiosService(
        API,
        bodyFormData,
        true,
        type,
        "",
        undefined,
        true
      ).then((res) => {
        return res;
      });
    },
    getInquires: function (details) {
      let API = apiService.getInquires;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getInquiresDetails: function (details, publicId) {
      let API = apiService.getInquiresDetails + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    
    getUsers: function (details) {
      let API = apiService.getUsers;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    //Quotation draft apis
    getQuotationDraft: function (details, publicId) {
      let API = apiService.getQuotationDraft + "/" + publicId;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    addQuotationDraft: function (details, token) {
      let API = apiService.addQuotationDraft;
      let payload = {
        ...details,
      };

      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateQuotationDraft: function (details, publicId, concurrencyStamp) {
      let API = apiService.updateQuotationDraft + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    deleteQuotationDraft: function (details, publicId, concurrencyStamp) {
      let API = apiService.deleteQuotationDraft + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //public product api
    getProducts: function (details, token) {
      let API = apiService.getProducts;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type, token).then((res) => {
        return res;
      });
    },

    //quotation item api
    getQuotationDraftItems: function (details, token) {
      let API = apiService.getQuotationDraftItems;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type, token).then((res) => {
        return res;
      });
    },

    addQuotationDraftItem: function (details, token) {
      let API = apiService.quotationDraftItem;
      let payload = {
        ...details,
      };

      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateQuotationDraftItem: function (details, publicId, concurrencyStamp) {
      let API = apiService.quotationDraftItem + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    deleteQuotationDraftItem: function (details, publicId, concurrencyStamp) {
      let API = apiService.deleteQuotationDraftItem + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    //quotaion apis
    getQuotationByEnquiryId: function (details, publicId) {
      let API = apiService.getQuotationByEnquiryId + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    downloadQuotation: function (details) {
      let API = apiService.downloadQuotation;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    addQuotation: function (details) {
      let API = apiService.quotation;
      let payload = {
        ...details,
      };

      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateQuotation: function (details, publicId, concurrencyStamp) {
      let API = apiService.quotation + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },

    downloadQuotation: function (details, token) {
      let API = apiService.downloadQuotation;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type, token).then((res) => {
        return res;
      });
    },
    //faq categories
    getFaqCategories: function (details) {
      let API = apiService.getFaqCategories;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    addFaqCategories: function (details) {
      let API = apiService.addFaqCategories;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateFaqCategories: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = apiService.updateFaqCategories + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";

      return AxiosService(API, payload, true, type, concurrencyStamp).then(
        (res) => {
          return res;
        }
      );
    },
  };
  return Services;
}
