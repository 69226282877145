import { React, useEffect, useState, useRef } from "react";
import "./AddDesignation.scss";
import { Form, Input, Label, FormGroup, Row } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { BsCamera, BsCameraVideo } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import IconButton from "../../assets/elements/IconButton";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MainServices from "../../services/MainServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import closeicon from "../../assets/images/icons/close.svg";
import { ChromePicker } from "react-color";
import AsyncSelect from "react-select/async";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import "react-dropdown-tree-select/dist/styles.css";
import { components } from "react-select";
import { urlToFileName } from "../../assets/functions/helpers";
import ImageCropper from "../../assets/functions/imageCropper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import useAuth from "../../hooks/useAuth";

const productTypesTemplateDummy = [
  {
    productType: "phone",
    fields: [
      { label: "os", value: "", category: "technical specification" },
      { label: "ram", value: "", category: "technical specification" },
      { label: "texture", value: "", category: "technical specification" },
      { label: "ASIN", value: "", category: "additional information" },
    ],
  },

  {
    productType: "cake",
    fields: [
      { label: "color", value: "", category: "technical specification" },
      { label: "flavor", value: "", category: "technical specification" },
      { label: "weight", value: "", category: "technical specification" },
      { label: "floor", value: "", category: "additional information" },
    ],
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default function AddProduct() {
  const [productImageFiles, setProductImageFiles] = useState([]);
  const hiddenImageUploader = useRef(null);
  const hiddenVideoUploader = useRef(null);
  const [isAddProductInProgress, setIsAddProductInProgress] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  // const [typeList, setTypeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userStatesLoaded, setUserStatesLoaded] = useState();
  const [productTypesTemplate, setProductTypesTemplate] = useState([]);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const publicId = new URLSearchParams(location.search).get("productId");
  let isEditing = publicId ? true : false;
  const [isCurrentProductLoaded, setIsCurrentProductLoaded] = useState(false);

  const formikFieldsSchema = ["title", "description", "content", "terms"];
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const [formikValues, setFormikValues] = useState(
    getFormikValuesForAllLanguages(formikFieldsSchema)
  );
  const [currentImage, setCurrentImage] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [cropImageModal, setCropImageModal] = useState(false);

  //hooks
  const { t, i18n } = useTranslation();
  const { deBounce } = TimingFunctions();

  const {
    saveProduct,
    getProductById,
    updateProductById,
    fileUpload,
    getCategories,
    getBrands,
    getProductTypes,
    getCountries,
    getStatesByCountry,
    getAllStatesPublic,
  } = MainServices();

  const { user } = useAuth();

  //functions
  const handleChooseVideo = (e) => {
    // const fileUploaded = event.target.files[0];
    // setProductImageFiles(fileUploaded);
    //console.log("images", productImageFiles);
    //setProductImageFiles(oldFiles => [...oldFiles, ...e.target.files]);
  };
  const handleImageUploaderClick = () => {
    hiddenImageUploader.current.click();
  };
  const handleVideoUploaderClick = () => {
    hiddenVideoUploader.current.click();
  };

  const addProductSchema = Yup.object().shape({
    category: Yup.array()
      .min(1, t("Select a category"))
      .required(t("Select a category")),
    countries: Yup.array()
      .min(1, t("Select atleast one country"))
      .required(t("Select atleast one country")),
    states: Yup.array()
      .min(1, t("Select atleast one city"))
      .required(t("Select atleast one city")),
    title: Yup.string().required(`${t("Product Name")} ${t("is required")}`),
    description: Yup.string().required(
      `${t("A short description")} ${t("is required")}`
    ),
    image: Yup.mixed().required(`${t("Image")} ${t("is required")}`),
    brandId: Yup.mixed().required(`${t("Select a brand")}`),
    maxPrice: Yup.number().required(`${t("Max Price")} ${t("is required")}`),
    sellingPrice: Yup.number()
      .positive()
      .required(`${t("Selling Price")} ${t("is required")}`),
    //retailPrice: Yup.number().required("Retail price is required"),
    costPrice: Yup.number().required(`${t("Cost Price")} ${t("is required")}`),
    shippingCharge: Yup.number().required(
      `${t("Shipping Charge")} ${t("is required")}`
    ),
    deliveryCharge: Yup.number().required(
      `${t("Delivery Charge")} ${t("is required")}`
    ),
    colors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`${t("Color Name")} ${t("is required")}`),
      })
    ),
    //brandDescription: Yup.string().required("Brand Description is Required"),
  });

  const handleChangeProductType = (e, setFieldValue) => {
    const selectedValue = parseInt(e.target.value);

    const selectedProductTypeObject = productTypesTemplate.find(
      (item) => item.id === selectedValue
    );

    if (!selectedProductTypeObject) {
      setFieldValue("productTypeFieldList", []);
      setFieldValue("productType", "");
      return;
    }
    setFieldValue("productType", selectedValue);
    let fieldObjects = selectedProductTypeObject["fields"];

    setFieldValue("productTypeFieldList", fieldObjects);
  };

  const handleAddDynamic = (values, setFieldValue) => {
    // For new ID
    let IdForNewElement = 0;
    let lengthOfFields = values.productDynamicFieldList.length;
    if (lengthOfFields > 0)
      IdForNewElement =
        Number(values.productDynamicFieldList[lengthOfFields - 1]?.id) + 1;
    setFieldValue("productDynamicFieldList", [
      ...values.productDynamicFieldList,
      { id: String(IdForNewElement), label: "", value: "" },
    ]);
    /* const dynamicField = (
      <div className="dynamicfield" key={lastElementKey}>
        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-3">
          <Input
            placeholder="Enter Label"
            type="text"
            className="product-form-group"
            //value={values.test}
            onChange={(e) => setFieldValue("label_"+lastElementKey, e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-3">
          <Input
            placeholder="Enter Value"
            type="text"
            className="product-form-group"
            onChange={(e) => setFieldValue("value_"+lastElementKey, e.target.value)}
          />
        </FormGroup>
        <img src={deleteicon} alt="delete" onClick={() => handleDeleteField(String(lastElementKey), setFieldValue)}/>
    </div>)
    setDynamicFields(oldValues => [...oldValues, dynamicField]) */
  };

  const handleSave = async (values) => {
    setIsAddProductInProgress(true);
    setErrorsOnSubmit([]);
    console.log(values);
    if (!productImageFiles.length) {
      setErrorsOnSubmit(["Please upload valid product image"]);
      setIsAddProductInProgress(false);
      return;
    }
    let imagesUploadResponse = await fileUpload(productImageFiles);
    console.log("imgR----", imagesUploadResponse);
    const responseToArray = imagesUploadResponse?.fileName?.split(",");

    if (!imagesUploadResponse.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Image upload failed: File too large",
        ]);
      setIsAddProductInProgress(false);
      return;
    }

    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    // Preparing languages field for multi-language color inputs
    /* languages.forEach(item => {
      let locale = item.locale;
      item.color = []
      values.colors?.forEach(colorObj => {
        let colorForCurrentLocale = colorObj["name_"+locale];
        item.color = [...item?.color, {name: colorForCurrentLocale, colorCode: colorObj.colorCode}]
      })
    }) */

    // Preparing languages field for multi-language dynamic type field, dynamic field inputs
    languages.forEach((item) => {
      let locale = item.locale;
      item.details = {};
      values.productTypeFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });

      values.productDynamicFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });
    });

    const detailsAPIField = [
      ...values.productDynamicFieldList,
      ...values.productTypeFieldList,
    ].map((eachField) => {
      if (!eachField.label || !eachField.value) return ["", ""];
      return [eachField.label, eachField.value];
    });
    const detailsInValidFromat = Object.fromEntries(
      detailsAPIField.filter((item) => item[0] && item[1])
    );

    const details = {
      title: values.title,
      maxPrice: values.maxPrice,
      costPrice: values.costPrice,
      sellingPrice: values.sellingPrice,
      shippingCharge: values.shippingCharge,
      //deliveryCharge: values.deliveryCharge,
      content: values.content,
      terms: values.terms,
      description: values.description,
      image: responseToArray[0],
      productImages: responseToArray,
      brandId: parseInt(values.brandId?.value),
      languages: languages,
      category: values.category.map((item) => item.value),
      city: values.states.map((item) => item.value),
      cityName: values.states.map((item) => item.label),
      country: values.countries.map((item) => item.value),
      countryName: values.countries.map((item) => item.label),
      //colors: [...new Set(values.colors)],
      color: "red",
      colorCode: values.colors[0]?.colorCode,
      details: { ...detailsInValidFromat, productType: values.productType },
    };
    saveProduct(details)
      .then(async (res) => {
        console.log("product added res", res);
        if (res.success) {
          notifySuccess("New Product Added!");
          setIsAddProductInProgress(false);
          navigate({
            pathname: "/products/product-details",
            search: `?productId=${res.publicId}`,
          });
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsAddProductInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsAddProductInProgress(false);
        console.log(error);
      });
  };

  const handleUpdate = async (values) => {
    setErrorsOnSubmit([]);
    setIsAddProductInProgress(true);

    if (!productImageFiles.length) {
      setErrorsOnSubmit(["Please upload valid product image"]);
      setIsAddProductInProgress(false);
      return;
    }
    let existingImageURLs = productImageFiles.filter(
      (file) => typeof file === "string"
    );
    existingImageURLs = existingImageURLs.map((_) => urlToFileName(_));
    let newImageFiles = productImageFiles.filter(
      (file) => typeof file !== "string"
    );
    //console.log("newImg", newImageFiles);
    let imagesUploadResponse = "";
    let responseToArray = existingImageURLs;
    if (newImageFiles.length) {
      imagesUploadResponse = await fileUpload(newImageFiles);
      responseToArray = responseToArray.concat(
        imagesUploadResponse.fileName?.split(",")
      );
    }

    if (newImageFiles.length > 0 && !imagesUploadResponse?.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Image upload failed: File too large",
        ]);
      setIsAddProductInProgress(false);
      return;
    }

    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    // Preparing languages field for multi-language dynamic type field, dynamic field inputs
    languages.forEach((item) => {
      let locale = item.locale;
      item.details = {};
      values.productTypeFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });

      values.productDynamicFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });
    });

    const detailsAPIField = [
      ...values.productDynamicFieldList,
      ...values.productTypeFieldList,
    ].map((eachField) => {
      if (!eachField.label || !eachField.value) return ["", ""];
      return [eachField.label, eachField.value];
    });
    const detailsInValidFromat = Object.fromEntries(
      detailsAPIField.filter((item) => item[0] && item[1])
    );

    const details = {
      title: values.title,
      maxPrice: parseInt(values.maxPrice),
      costPrice: parseInt(values.costPrice),
      sellingPrice: parseInt(values.sellingPrice),
      shippingCharge: parseInt(values.shippingCharge),
      //deliveryCharge: parseInt(values.deliveryCharge),
      content: values.content,
      terms: values.terms,
      description: values.description,
      image: responseToArray[0],
      productImages: responseToArray,
      brandId: parseInt(values.brandId?.value),
      category: values.category.map((item) => item.value),
      city: values.states.map((item) => item.value),
      cityName: values.states.map((item) => item.label),
      country: values.countries.map((item) => item.value),
      countryName: values.countries.map((item) => item.label),
      //colors: [...new Set(values.colors)],
      languages: languages,
      color: "red",
      //colorCode: values.colors[0],
      //details: formikValues.details,
      details: { ...detailsInValidFromat, productType: values.productType },
    };

    let latestConcurrencyStamp = await getProductById(publicId).then(
      (res) => res.data.concurrencyStamp
    );
    updateProductById(details, publicId, latestConcurrencyStamp)
      .then(async (res) => {
        if (res.success) {
          console.log("res", res);
          let productId = res.publicId;
          let priceDetails = {
            productId: productId,
            maxPrice: values.maxPrice,
            costPrice: values.costPrice,
            sellingPrice: values.sellingPrice,
            shippingCharge: values.shippingCharge,
            deliveryCharge: values.deliveryCharge,
          };
          let latestConcurrencyStamp = await getProductById(publicId).then(
            (res) => res.data.concurrencyStamp
          );
          notifySuccess("Product Updated!");
          setIsAddProductInProgress(false);
          navigate({
            pathname: "/products/product-details",
            search: `?productId=${publicId}`,
          });
          /* updateProductPriceById(priceDetails, productId, latestConcurrencyStamp)
            .then(res => {
              if(res.success){
                notifySuccess("New Product Added!");
                setIsAddProductInProgress(false);
                navigate({
                  pathname: "/products/product-details",
                  search: `?productId=${res.publicId}`,
                });
              }
              else {
                notifyError("Error occurred while updating price!");
                setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
                setIsAddProductInProgress(false);
              }
            })
            .catch(error => {
              notifyError("Error occurred while updating price!");
              setErrorsOnSubmit((oldValues) => [...oldValues, error]);
              setIsAddProductInProgress(false);
            }) */
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setIsAddProductInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Update Failed");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsAddProductInProgress(false);
      });
  };

  //get categories

  const GroupHeading = (props, setFieldValue, values) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <components.GroupHeading
          {...props}
          onClick={() => {
            //console.log(props);
            //setFieldValue("category", [...values.category, props.data]);
          }}
          style={{
            cursor: "pointer",
            color: "#D60000",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: 18,
          }}
        />
      </div>
    );
  };

  const getCategoryList = async (filters) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getCategories(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((category) => {
              let options = category?.categories || [];
              options = options.map((_) => ({
                ..._,
                label: _.name,
                value: _.id,
              }));

              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                category?.category_languages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                label: languageSpecificDetailsObject?.name || category.name,
                value: category.id,
                ...(options.length > 0 ? { options } : {}),
              };
            });
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const loadOptionsForCategory = async (inputValue, callback) => {
    if (!inputValue) {
      callback(categoryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    let options = await getCategoryList(filter);
    callback(options);
  };

  const loadOptionsForStates = async (inputValue, callback) => {
    if (!inputValue) {
      callback(stateList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    // let options = await getAllStates(filter);
    let options = stateList.filter((stateObj) =>
      stateObj?.label?.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(options);
  };

  const loadOptionsForCountries = async (inputValue, callback) => {
    if (!inputValue) {
      callback(countryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    // let options = await getAllStates(filter);
    let options = await getAllCountries(filter);
    //let options = countryList.filter(countryObj => countryObj?.label?.toLowerCase().includes(inputValue.toLowerCase()))
    callback(options);
  };

  const loadDebounceOptionsForCategory = (inputValue, callback) => {
    deBounce(() => loadOptionsForCategory(inputValue, callback), 1000);
  };

  const loadDebounceOptionsForStates = (inputValue, callback) => {
    deBounce(() => loadOptionsForStates(inputValue, callback), 1000);
  };

  const loadDebounceOptionsForCountries = (inputValue, callback) => {
    deBounce(() => loadOptionsForCountries(inputValue, callback), 1000);
  };

  const getExistingProductData = () => {
    getProductById(publicId)
      .then(async (res) => {
        if (res.success) {
          const productData = {
            ...res.data,
            maxPrice: res.data?.productPrice?.maxPrice,
            sellingPrice: res.data?.productPrice?.sellingPrice,
            costPrice: res.data?.productPrice?.costPrice,
            deliveryCharge: res.data?.productPrice?.deliveryCharge,
            shippingCharge: res.data?.productPrice?.shippingCharge,
            brandId: {
              label: res.data?.brand?.name,
              value: res.data?.brand?.id || res.data.brandId,
            },
            category: [
              {
                label: res.data.productCategories[0]?.category?.name,
                value: res.data.productCategories[0]?.category?.id,
              },
            ],
            colors: [{ name: res.data?.color, colorCode: "#D60000" }],
            image: res.data?.productImages.length > 0 ? "image" : "",
            productType: res.data?.details["productType"],
            productTypeFieldList: [],
            productDynamicFieldList: [],
          };

          // To populate states and countries

          let allStatesAndCountries = await getAllStatesPublicList();

          // To populate countries
          let countryIDListFromResponse = res.data?.country || [];
          let selectedCountries = [];
          allStatesAndCountries.forEach((stateCountryObj) => {
            let selectedCountry = null;
            let countryOfCurrentStateObj = stateCountryObj?.countries[0] || {};
            let indexOfMatchingCountryInResponse =
              countryIDListFromResponse.indexOf(countryOfCurrentStateObj?.id);
            if (indexOfMatchingCountryInResponse !== -1) {
              selectedCountry = {};
              selectedCountry.label = countryOfCurrentStateObj.name;
              selectedCountry.value = countryOfCurrentStateObj.id;
              delete countryIDListFromResponse[
                indexOfMatchingCountryInResponse
              ];
            }
            selectedCountry && selectedCountries.push(selectedCountry);
          });
          productData.countries = selectedCountries;
          // Call getStates API to make states(in selected countries) available for selection
          getAllStatesByCountries(selectedCountries);

          // To populate cities
          let stateIDListFromResponse = res.data?.city || [];
          let selectedStates = [];
          allStatesAndCountries.forEach((stateObj) => {
            let selectedState = null;
            let indexOfMatchingStateResponse = stateIDListFromResponse.indexOf(
              stateObj?.id
            );
            if (indexOfMatchingStateResponse !== -1) {
              selectedState = {};
              selectedState.label = stateObj.name;
              selectedState.value = stateObj.id;
              delete stateIDListFromResponse[indexOfMatchingStateResponse];
            }
            selectedState && selectedStates.push(selectedState);
          });
          productData.states = selectedStates;

          // To populate multi-language fields(fixed fields)
          if (res.data?.productLanguages) {
            let productLanguages = res.data?.productLanguages;
            availableLanguages.forEach((language) => {
              let languageSpecificObject = productLanguages.find(
                (languageObject) => languageObject.locale === language.value
              );
              formikFieldsSchema.forEach((formikField) => {
                productData[formikField + "_" + language.value] =
                  languageSpecificObject[formikField];
                //productData[formikField] = productLanguages
              });
            });
          }

          // Wait to populate product type fields
          const productTypesTemplate = await getProductTypeList([
            { key: "status", eq: "active" },
          ]);
          // Get matching product-type object
          const typeObject = productTypesTemplate.find(
            (type) => type.id == res.data?.details?.productType
          );

          // To populate product-type fields labels and their values
          if (res.data?.details?.productType && typeObject) {
            typeObject.fields.forEach((fieldObj) => {
              let fieldLabel = fieldObj["englishLabel"];
              const fieldObject = {
                label: fieldLabel,
                value:
                  res.data?.details[fieldLabel] ||
                  res.data?.details[fieldLabel.toLowerCase()],
                category: "",
              };
              // To populate product-type field values for all lanugages
              availableLanguages.forEach((languageObj) => {
                const languageSpecificDetailsObject =
                  res.data?.productLanguages.find(
                    (languageSpecificDetailsObject) =>
                      languageSpecificDetailsObject.locale === languageObj.value
                  );
                const languageSpecificFieldValue =
                  languageSpecificDetailsObject?.details[fieldLabel] ||
                  languageSpecificDetailsObject?.details[
                    fieldLabel.toLowerCase()
                  ];
                if (languageSpecificFieldValue) {
                  fieldObject["value_" + languageObj.value] =
                    languageSpecificFieldValue;
                }
              });
              productData.productTypeFieldList.push(fieldObject);
              // Remove product-type field to avoid duplication in dynamic fields
              // Delete english label when langauge is non-english
              // to avoid mismatching of fetched labels with language specific product-type labels
              if (i18n.language?.includes("en") === false)
                fieldLabel = fieldObj["englishLabel"];
              delete res.data?.details[fieldLabel];
              delete res.data?.details[fieldLabel.toLowerCase()];
            });
          }

          // Remove type id as it is not needed on UI
          // It is stored for matching product-type name
          delete res.data?.details.productType;

          // To populate multi-language dynamic fields
          if (res.data?.details && Object.keys(res.data?.details).length > 0) {
            let details = res.data?.details;
            /* if (details.productType) {
              const selectedProductTypeObject = productTypesTemplate.find(
                (item) => item.productType === details.productType
              );
              if (!selectedProductTypeObject) {
              } else {
                //console.log("se", selectedProductTypeObject);
                productData.productType = details.productType;
                let options = selectedProductTypeObject["fields"];
                //console.log("se2", selectedProductTypeObject);
                //console.log(options);
                // Populate dynamic type fields for non-english languages
                availableLanguages.forEach((language) => {
                  const languageSpecificDetailsObject =
                    res.data?.productLanguages.find(
                      (languageSpecificDetailsObject) =>
                        languageSpecificDetailsObject.locale === language.value
                    );
                  options.forEach((option) => {
                    if (option.label in details) {
                      //option.value = details[option.label];
                      option["value_" + language.value] =
                        languageSpecificDetailsObject?.details[option.label];

                      //delete details[option.label];
                    }
                  });
                  //option["value_"+language.value] = "lang_"+language.value
                });
                // Populate dynamic type fields for english language
                options.forEach((option) => {
                  if (option.label in details) {
                    option.value = details[option.label];
                    //option["value_"+language.value] = languageSpecificDetailsObject?.details[option.label];

                    // Delete to avoid duplication at dynamic fields
                    delete details[option.label];
                  }
                });
                //productData.productTypeFieldList = options;
              }
            }
            // Delete to avoid duplication at dynamic fields
            delete details?.productType;
            if (Object.keys(details).length === 0) {
              productData.productDynamicFieldList = [
                { id: "0", label: "", value: "" },
                { id: "1", label: "", value: "" },
              ];
            } */
            for (let key in details) {
              // For new ID
              let IdForNewElement = 0;
              let lengthOfFields = productData?.productDynamicFieldList?.length;
              if (lengthOfFields > 0)
                IdForNewElement =
                  Number(
                    productData.productDynamicFieldList[lengthOfFields - 1]?.id
                  ) + 1;

              let objectWithLanguageSpecificValues = {
                id: String(IdForNewElement),
                label: key,
              };
              availableLanguages.forEach((language) => {
                const languageSpecificDetailsObject =
                  res.data?.productLanguages.find(
                    (languageSpecificDetailsObject) =>
                      languageSpecificDetailsObject.locale === language.value
                  );
                objectWithLanguageSpecificValues = {
                  ...objectWithLanguageSpecificValues,
                  ["value_" + language.value]:
                    languageSpecificDetailsObject?.details
                      ? languageSpecificDetailsObject?.details[key]
                      : "",
                };
              });
              const objectWithAllLanguages = {
                ...objectWithLanguageSpecificValues,
                value: details[key],
              };
              /* let objectWithLanguageSpecificValues = {
                id: String(IdForNewElement), label: key, value: details[key]
              } */

              productData.productDynamicFieldList = [
                ...productData.productDynamicFieldList,
                { ...objectWithAllLanguages },
              ];
            }
          } else {
            // If no dynamic fields are present, provide two empty fields
            productData.productDynamicFieldList = [
              { id: "0", label: "", value: "" },
              { id: "1", label: "", value: "" },
            ];
          }

          setProductImageFiles(
            res.data?.productImages?.map((imgObject) => imgObject.images)
          );
          setFormikValues(productData);
          setIsCurrentProductLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductTypeList = async (filters) => {
    const details = {
      filters: filters || [{ key: "status", eq: "active" }],
    };
    return await getProductTypes(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            // Preparing product types template to render on UI
            const typesTemplate = resData?.map((type) => {
              // Language specific product-type name
              const languageSpecificName =
                type.language["label_" + i18n.language] || type.name;

              // Produt-type fields
              const fields = [];

              // Current language identifier
              // Add language identifier if not default language
              const currentLanguageIdentifier =
                i18n.language?.includes("en") === false ? "" : i18n.language;

              // Iterate fields and add to template
              type.fields?.forEach((fieldObj) => {
                const defaultLabel = fieldObj["label"];
                const languageSpecificLabel =
                  fieldObj["label_" + currentLanguageIdentifier];
                fields.push({
                  label: languageSpecificLabel || defaultLabel,
                  englishLabel: defaultLabel,
                  value: "",
                  category: "",
                });
              });

              const typeObj = {
                label: languageSpecificName,
                id: type.id,
                fields: fields,
              };
              return typeObj;
            });
            setProductTypesTemplate(
              typesTemplate.filter((item) => item != undefined)
            );
            return typesTemplate.filter((item) => item != undefined);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* const getCountryList = async (pageNumber) => {
    const details = {
      pageNumber: pageNumber || 1,
    };
    return await getCountries(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            return resData;
          }
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateListByCountry = async (countryId, filter) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      countryId,
      filters: filter
    };
    return await getStatesByCountry(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            let formatForAsyncSelect = resData.map(stateObj => {
              return {
                label: stateObj?.name,
                value: stateObj?.id
              }
            })
            return formatForAsyncSelect;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }; 

  const getStatesByUserCountry = async () => {
    return await getStatesList(parseInt(user?.supplier?.country))
      .then(res => {
        //setFormikValues(oldValues => ({...oldValues, states: [...res]}) )
        res = res || []
        return res;
      })
  } */

  const removeStatesOfDeselectedCountries = (
    setFieldValue,
    currentStates,
    availableStates
  ) => {
    let newStates = currentStates.filter((formikStateObj) => {
      let isSelectedStateAvailable = false;
      availableStates.forEach((stateObj) => {
        if (formikStateObj.value === stateObj.value) {
          isSelectedStateAvailable = true;
        }
      });
      return isSelectedStateAvailable;
    });
    setFieldValue("states", newStates);
  };

  const getAllStatesPublicList = async () => {
    return getAllStatesPublic().then((res) => {
      if (res.success) {
        return res.data || [];
      }
    });
  };

  const getAllStatesByCountries = async (countries) => {
    let allStates = [];
    setStateList(() => []);
    for (let countryObj of countries) {
      const statesByCountry = await getAllStates({
        countryId: countryObj?.value,
      });
      allStates = [...allStates, ...statesByCountry];
      setStateList((oldList) => [...oldList, ...statesByCountry]);
    }
    return allStates;
  };

  const getAllStates = async (filter) => {
    let details = {
      pageNumber: 1,
      pageSize: 10,
      ...filter,
    };
    return await getStatesByCountry(details).then(async (res) => {
      if (res) {
        let resData = res;
        if (resData.success) {
          let total = resData?.totalCount || 0;
          resData = resData.data ? resData.data : [];
          let formatForAsyncSelect = resData.map((stateObj) => {
            return {
              label: stateObj?.name,
              value: stateObj?.id,
            };
          });
          return formatForAsyncSelect;
        }
      }
    });
    // To get all countries
    /* let allCountries = []
    for(let pageNumber=1; pageNumber<=3; pageNumber++)
    {
      let countries = await getCountryList(pageNumber);
      allCountries = allCountries.concat(countries);
    }
    setCountryList(allCountries);

    // To get all states from all countries
     let allStates = []
    for(let countryObj of allCountries)
    {
      let states = await getStatesList(countryObj.id, filter);
      allStates = allStates.concat(states);
    }
    // Sort states in alphabetical order
    // using compareFunction which compares labels in state objects
    allStates.sort(
      (stateObjCurrent, stateObjNext) => 
        stateObjCurrent.label >= stateObjNext.label ? 1 : -1
    );
    return allStates; */
  };

  const getAllCountries = async (filter) => {
    // To get all countries
    let details = {
      pageSize: 10, //filter ? 100: 10,
      pageNumber: 1,
      filter,
    };
    return await getCountries(details).then(async (res) => {
      if (res) {
        let resData = res;
        if (resData.success) {
          let total = resData?.totalCount || 0;
          resData = resData.data ? resData.data : [];
          let formatForAsyncSelect = resData.map((countryObj) => {
            return {
              label: countryObj?.name,
              value: countryObj?.id,
            };
          });
          return formatForAsyncSelect;
        }
      }
    });
    // To get all states from all countries
  };

  useEffect(() => {
    if (isEditing) {
      getExistingProductData();
    }
    /* getStatesByUserCountry()
      .then((res) => {
        setUserStatesLoaded(true);
        setFormikValues( oldValues => ({...oldValues, states: [...res]}) )
      }); */
    //getAllStates().then(list => setStateList(list || []));
    getAllCountries().then((list) => setCountryList(list));
    // eslint-disable-next-line
  }, []);

  // Get language specific values when langauge is changed by user
  useEffect(() => {
    getCategoryList([{ key: "status", eq: "active" }]).then((list) =>
      setCategoryList(list)
    );
    getBrandList([{ key: "status", eq: "active" }]).then((list) =>
      setBrandList(list)
    );
    getProductTypeList([{ key: "status", eq: "active" }]);
    // eslint-disable-next-line
  }, [i18n.language]);

  // brand  Async selectfunctions
  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback(brandList);
    }
    let filter = [
      {
        key: "status",
        eq: "active",
      },
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getBrandList(filter);

    callback(options);
  };

  const loadDebounceOptions = (inputValue, callback) => {
    deBounce(() => loadOptions(inputValue, callback), 1000);
  };

  //get Brand Options
  const getBrandList = async (filters) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getBrands(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((brand) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                brand?.brandLanguages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                ...brand,
                label: languageSpecificDetailsObject?.name || brand.name,
                value: brand.id,
              };
            });
            return resData;
          }
        } else {
          console.log("Brands Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  //async select style
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#EDF0F7",
      // padding: "6px 5px",
      marginBottom: "12px",
      paddingLeft: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      zIndex: "2",
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#f5f2ed" : "white",
        color: "#000000",
        cursor: "pointer",
        padding: "10px 10px 10px 20px",
        //textTransform: "capitalize",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "5px",
    }),
  };

  const handleChooseImage = (e) => {
    setCurrentImage(e.target.files[0] || "");
    e.target.files[0] && setCropImageModal(true);
  };

  const onGetCroppedImage = (setFieldValue, image) => {
    setCroppedImage(image);
  };

  const onCropImageModal = () => {
    setCropImageModal((prev) => !prev);
    setCurrentImage("");
  };

  const onCropSave = () => {
    setProductImageFiles((oldFiles) => [...oldFiles, croppedImage]);
    onCropImageModal();
  };

  return (
    <div className="product-main">
      <HeadingMain text={isEditing ? "Edit Product" : "Add Designation"}>
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      {(isEditing ? isCurrentProductLoaded : true) && (
        <Formik
          initialValues={{
            title: formikValues.title,
            image: "",
            colors: [
              { name: "", colorCode: "#D60000", displaySelector: false },
            ],
            color: "red",
            colorCode: "#D60000",
            brandId: "",
            category: [],
            countries: [],
            states: [],
            maxPrice: "",
            costPrice: "",
            sellingPrice: "",
            shippingCharge: "",
            deliveryCharge: "",
            description: "",
            productImages: [],
            productType: "",
            productTypeName: "",
            productTypeFieldList: [],
            productDynamicFieldList: [
              { id: "0", label: "", value: "" },
              { id: "1", label: "", value: "" },
            ],
            ...formikValues,
            // To match user language if a label exists for that language
            // brandId: formikValues?.brandId?.value ? brandList.find(brand => brand.id === formikValues?.brandId?.value) : "",
          }}
          onSubmit={(values) => {
            isEditing ? handleUpdate(values) : handleSave(values);
          }}
          validationSchema={addProductSchema}
          //enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="user-topcard-main">
                <h1 className="topcard-heading">{t("Designation")}</h1>
                <Form className="row">
                 
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title">{t("Designation")}</Label>
                    <Input
                      id="title"
                      name="title"
                      placeholder={t("Enter")}
                      type="text"
                      className="product-form-group"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="title"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-9">
                    <Label for="title">{t("Description")}</Label>
                    <Input
                      id="title"
                      name="title"
                      placeholder={t("Category Name")}
                      type="text"
                      className="product-form-group"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="title"
                    />
                  </FormGroup>
                  {/* <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="category">{t("Select Product Category")}</Label>
                    <AsyncSelect
                      id="category"
                      name="category"
                      defaultOptions={categoryList}
                      isMulti
                      onChange={(selectedCategories) =>
                        setFieldValue("category", selectedCategories)
                      }
                      loadOptions={loadDebounceOptionsForCategory}
                      className="select-dropdown"
                      styles={customStyles}
                      components={{
                        GroupHeading: (props) =>
                          GroupHeading(props, setFieldValue, values),
                      }}
                      value={values.category}
                      onBlur={() => setFieldTouched("category", true)}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="category"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="category">{t("Select Product Category")}</Label>
                    <AsyncSelect
                      id="category"
                      name="category"
                      defaultOptions={categoryList}
                      isMulti
                      onChange={(selectedCategories) =>
                        setFieldValue("category", selectedCategories)
                      }
                      loadOptions={loadDebounceOptionsForCategory}
                      className="select-dropdown"
                      styles={customStyles}
                      components={{
                        GroupHeading: (props) =>
                          GroupHeading(props, setFieldValue, values),
                      }}
                      value={values.category}
                      onBlur={() => setFieldTouched("category", true)}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="category"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="category">{t("Select Product Category")}</Label>
                    <AsyncSelect
                      id="category"
                      name="category"
                      defaultOptions={categoryList}
                      isMulti
                      onChange={(selectedCategories) =>
                        setFieldValue("category", selectedCategories)
                      }
                      loadOptions={loadDebounceOptionsForCategory}
                      className="select-dropdown"
                      styles={customStyles}
                      components={{
                        GroupHeading: (props) =>
                          GroupHeading(props, setFieldValue, values),
                      }}
                      value={values.category}
                      onBlur={() => setFieldTouched("category", true)}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="category"
                    />
                  </FormGroup> */}

                  {/* <FormGroup className="col-12 col-sm-6 short-description">
                    <Label for="description" className="title">
                      {t("Short Description")}
                      <p>{values.description?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="description"
                      as="textarea"
                      className="textarea"
                      value={values.description}
                      onChange={handleChange}
                      maxLength={250}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="description"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 short-description">
                          <Label for="description" className="title">
                            {`${t("Short Description")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["description_" + language.value]
                                ?.length || 0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"description_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["description_" + language.value]}
                            onChange={handleChange}
                            place={`${t("Enter short description")} ${t(
                              "in"
                            )} ${t(language.name)} (${language.symbol})`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })} */}
                </Form>
              </div>
           

             
       
             
              <div className="errors-after-sumbit">
                {errorsOnSubmit?.map((error) => (
                  <p>{error}</p>
                ))}
              </div>
             
              <div
                className="save-btn-float"
                onClick={isAddProductInProgress ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Submit"}
                  isLoading={isAddProductInProgress}
                />
              </div>
              <div
                className="save-btn-float"
                onClick={isAddProductInProgress ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Back"}
                  isLoading={isAddProductInProgress}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
}
