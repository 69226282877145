import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import HeadingMain from "../../assets/elements/HeadingMain";
//import { useTranslation } from 'react-i18next';
import "./User2.scss";
import File from "../../assets/images/pdf-file.svg";
import { Form, Input, Label, FormGroup, Row } from "reactstrap";
import ProfileImage from "../../assets/images/Profile-image.svg";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik } from "formik";
import Map from "../../assets/map/Map";
import closeicon from "../../assets/images/icons/close.svg";
import MainServices from "../../services/MainServices";
import ViewDocumentLarge from "./ViewDocumentLarge";
import { useLocation, useNavigate } from "react-router-dom";
import UserStatusChange from "./UserStatusChange";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { useTranslation } from "react-i18next";
import { PiChatText } from "react-icons/pi";
import useAuth from "../../hooks/useAuth";

import axios from "axios";
import { LazyLoader } from "../../assets/loaders";

const User = () => {
  const [crDocument, setCrDocument] = useState();
  const [vatDocument, setVatDocument] = useState();
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [viewDocumentInLarge, setViewDocumentInLarge] = useState(false);
  const [documentInCurrentView, setDocumentInCurrentView] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [userAddress, setUserAddress] = useState();
  const [statusClicked, setStatusClicked] = useState(false);
  const [isUpdateStatusInProgress, setIsUpdateStatusInProgress] = useState();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("user");
  const { t, i18n } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const userId = new URLSearchParams(location.search).get("userId");

  const {
    viewFile,
    getUserProfileById,
    getUserAddressById,
    updateUserStatusById,
  } = MainServices();

  let { name, mobileNumber, email } = userDetails || {};
  
  let isEditing = publicId ? true : false;
  let {
    address = "-" + t("Not provided") + "-",
    businessName = "-" + t("Not provided") + "-",
    latitude,
    longitude,
    postalCode,
    state,
    city,
    country,
    mapLocation = "-" + t("Not provided") + "-",
  } = userAddress || {};

  useEffect(() => {
    if (userId) {
      getUserProfile(userId);
    } else {
      getUserProfile(user.user_id);
    }
  }, []);

  const getCrDocumetFile = (id) => {
    if (!id) return;

    const documentUrl = `https://balloons-dezen.s3.ap-south-1.amazonaws.com/${id}`;

    setCrDocument({ url: documentUrl, type: "external-link" });
  };

  const getVatDocumetFile = (id) => {
    if (!id) return;

    const documentUrl = `https://balloons-dezen.s3.ap-south-1.amazonaws.com/${id}`;

    setVatDocument({ url: documentUrl, type: "external-link" });
  };

  const getSupplierDocuments = (supplier) => {
    if (!supplier) return;
    getCrDocumetFile(supplier.crDocumentId);
    getVatDocumetFile(supplier.vatDocumentId);
  };

  const getSupplierDetails = async (publicId) => {
    if (!publicId) return;
    await getUserProfileById(publicId).then((res) => {
      setUserDetails({ ...res.data });

      getSupplierDocuments(res.data.supplier);
    });
    await getUserAddressById(publicId).then(async (res) => {
      if (res.data.length === 0) return;

      const mapLocation = await Map.getMapLocation(
        res.data[0].latitude,
        res.data[0].longitude
      );
      setUserAddress({ ...res.data[0], mapLocation });
    });
  };

  const getUserProfile = async (id) => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getUserProfileById(id)])
      .then((res) => {
        if (res[0]) {
          const resData = res[0];
          if (resData.success) {
            let userData = resData?.data ? resData.data : {};
            setUserDetails(userData);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch((err) => {
        setPageLoading(false);
        console.log(err);
      });
  };

  const openViewDocument = (document) => {
    setDocumentInCurrentView(document);
    setViewDocumentInLarge(true);
  };

  const updateUserStatus = async (values) => {
    if (!publicId || !values) return;
    setIsUpdateStatusInProgress(true);

    await updateUserStatusById(
      { status: values.status },
      publicId,
      userDetails.concurrencyStamp
    ).then((res) => {
      if (res.success) {
        notifySuccess("Status updated!");
        getSupplierDetails(publicId);
        setStatusClicked(false);
      } else {
        notifyError("Status update failed!");
      }
      setIsUpdateStatusInProgress(false);
    });
  };

  useEffect(() => {
    getSupplierDetails(publicId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="user-details-main">
      {statusClicked && (
        <UserStatusChange
          open={true}
          publicId={userDetails?.publicId}
          setStatusClicked={setStatusClicked}
          updateUserStatus={updateUserStatus}
          userCurrentStatus={userDetails.status}
          isUpdateStatusInProgress={isUpdateStatusInProgress}
        />
      )}
      {viewDocumentInLarge && (
        <ViewDocumentLarge
          open={true}
          setViewDocumentInLarge={setViewDocumentInLarge}
          document={documentInCurrentView}
        />
      )}

      <div className="heading">
        <HeadingMain
          //className="product-details-primary-heading"
          text={t("")}
        />
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </div>

      <div className="details-container" style={{backgroundColor:'#EDF0F7'}}>
        <div
          className="status-to-right"
          style={i18n.dir() === "rtl" ? { left: "0", right: "unset" } : {}}
        >
          <div className="sticky-div">
            <div className="section status">
              {/* <div
                className="chat-icon"
                onClick={() => {
                  navigate({
                    pathname: user?.role === "supplier" ? "/chat" : "/settings",
                    search: `?supplierId=${publicId}&status=Notifications`,
                  });
                }}
              >
                <PiChatText />
              </div> */}

              <div className="current-status">
                <span
                  className={
                    userDetails?.status === "deleted"
                      ? "inactive"
                      : userDetails?.status?.toLowerCase()
                  }
                >
                  {t(
                    userDetails?.status === "deleted"
                      ? "inactive"
                      : userDetails?.status
                  )}
                </span>
              </div>
              <div
                className="change-btn"
                onClick={() => {
                  setStatusClicked(true);
                }}
              >
                <PrimaryButton title="Change Status" />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          
        
          
        </div>
        <div className="product-main" style={{background: ''}}>
      <HeadingMain text={isEditing ? "Edit Product" : "User Details :"}>
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>

      {pageLoading ? (
        <LazyLoader />
      ) : (
        <Formik
          initialValues={{
            name: userDetails.name,
            mobileNumber: userDetails.mobileNumber,
            email: userDetails.email,
            address: userDetails?.supplier?.address,
            city: userDetails?.supplier?.city,
            state: userDetails?.supplier?.state,
            country: userDetails?.supplier?.country,
            postalCode: userDetails?.supplier?.postalCode,
            licenseNo: userDetails?.supplier?.licenseNo,
          }}
          onSubmit={(values) => {}}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <div className="user-topcard-main">
                <Form className="row">
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="d-flex">
                      <img
                        src={ProfileImage}
                        alt="ProfileImage"
                        className="ProfileImage"
                      />
                      <p
                        className="ChangeImage mx-2"
                        style={{
                          width: "auto",
                          height: "18px",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          letterSpacing: "0em",
                          textAlign: "left",
                          marginTop: "30px",
                        }}
                      >
                        Change Profile Image
                      </p>
                    </div>
                  </FormGroup>

                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label
                      for="title"
                      className="headings1"
                      style={{ color: "#A7A8AB" }}
                    >
                      Full Name
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="name"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="mobileNumber" className="headings1">
                      Contact Number
                    </Label>
                    <Input
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="+91 00000 00000"
                      type="text"
                      className="product-form-group"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="mobileNumber"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="email" className="headings1">
                      Email Address
                    </Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder=""
                      type="text"
                      className="product-form-group"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="email"
                    />
                  </FormGroup>
                </Form>
              </div>

              <div className="user-topcard-main">
                <div className="heading d-flex justify-content-between">
                  <h1 className="topcard-heading">Address</h1>
                  <h2
                    className="Edit"
                    style={{
                      color: "#17469C",
                      width: "auto",
                      height: "18px",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                    }}
                  >
                    Edit
                  </h2>
                </div>
                <Form className="row">
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="address" className="headings1">
                      "Building No, Block, House No, "
                    </Label>
                    <Input
                      id="title"
                      name="address"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="address"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title" className="headings1">
                      Street
                    </Label>
                    <Input
                      id="title"
                      name="street"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="street"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title" className="headings1">
                      Landmark
                    </Label>
                    <Input
                      id="title"
                      name="landmark"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.landmark}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="landmark"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title" className="headings1">
                      Districts
                    </Label>
                    <Input
                      id="title"
                      name="district"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.district}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="district"
                    />
                  </FormGroup>
                </Form>

                <Form className="row">
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="city" className="headings1">
                      City
                    </Label>
                    <Input
                      id="city"
                      name="city"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="city"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="state" className="headings1">
                      State
                    </Label>
                    <Input
                      id="state"
                      name="state"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="state"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="country" className="headings1">
                      Country
                    </Label>
                    <Input
                      id="country"
                      name="country"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="country"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title" className="headings1">
                      Zipcode
                    </Label>
                    <Input
                      id="title"
                      name="postalCode"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="postalCode"
                    />
                  </FormGroup>
                </Form>
              </div>

              <div className="user-topcard-main">
                <div className="heading d-flex justify-content-between">
                  <h1 className="topcard-heading">Legal Information</h1>
                  <h2
                    className="Edit"
                    style={{
                      color: "#17469C",
                      width: "auto",
                      height: "18px",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                    }}
                  >
                    Edit
                  </h2>
                </div>
                <Form className="row">
                  {/* <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="title" className="headings1">
                      VAT Number
                    </Label>
                    <Input
                      id="title"
                      name="title"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="title"
                    />
                  </FormGroup> */}

                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Label for="licenseNo" className="headings1">
                      License Number
                    </Label>
                    <Input
                      id="licenseNo"
                      name="licenseNo"
                      placeholder="name here"
                      type="text"
                      className="product-form-group"
                      value={values.licenseNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="licenseNo"
                    />
                  </FormGroup>
                </Form>
              </div>
              <div className="user-topcard-main">
                <div className="heading d-flex justify-content-between">
                  <h1 className="topcard-heading">Verification Document</h1>
                </div>

                <Form className="row">
                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-6">
                    <p>
                      <button
                        className="btn-document border-0"
                        style={{
                          width: "auto",
                          height: "60px",

                          borderRadius: "8px",
                          gap: "12px",
                          backgroundColor: "#EDF0F7",
                        }}
                      >
                        <div className="d-flex p-2">
                          <img
                            src={File}
                            alt=""
                            className="document mt-0 mx-2"
                          />
                          <p
                            className="document pt-2"
                            style={{
                              width: "auto",
                              height: "15px",
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15px",
                              letterSpacing: "0em",
                              textAlign: "left",
                            }}
                          >
                            {userDetails?.supplier?.vatDocumentIdFile}
                          </p>
                        </div>
                      </button>
                    </p>

                    {userDetails?.supplier?.vatDocumentIdFile ? (
                      userDetails?.supplier?.vatDocumentIdFile.includes(
                        ".pdf"
                      ) ? (
                        <div
                          className="previewdoc d-flex align-items-center justify-content-center"
                          style={{ border: "1px solid black" }}
                        >
                          <object
                            data={userDetails?.supplier?.vatDocumentIdFile}
                            className="previewdoc"
                          >
                            alt={userDetails?.supplier?.vatDocumentIdFile}
                          </object>
                        </div>
                      ) : userDetails?.supplier?.vatDocumentIdFile.includes(
                          ".docx"
                        ) ? (
                        <a
                          href={userDetails?.supplier?.vatDocumentIdFile}
                          target="_blank"
                        >
                          Download
                        </a>
                      ) : (
                        <img
                          src={userDetails?.supplier?.vatDocumentIdFile}
                          alt="CR"
                          className="previewdoc"
                          style={{ width: 300, height: 200 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Form>
              </div>

              <div className="user-topcard-main">
                <div className="heading d-flex justify-content-between">
                  <h1 className="topcard-heading" style={{ color: "#A7A8AB" }}>
                    Upload Document
                  </h1>
                  <h2
                    className="Edit"
                    style={{
                      color: "#17469C",
                      width: "auto",
                      height: "18px",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                    }}
                  >
                    Edit
                  </h2>
                </div>
                <Form className="row">
                  <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <Label for="title" className="headings1">
                      Cr Document
                    </Label>
                    <p>
                      <button
                        className="btn-document border-0"
                        style={{
                          width: "auto",
                          height: "60px",

                          borderRadius: "8px",
                          gap: "12px",
                          backgroundColor: "#EDF0F7",
                        }}
                      >
                        <div className="d-flex p-2">
                          <img
                            src={File}
                            alt=""
                            className="document mt-0 mx-2"
                          />
                          <p
                            className="document pt-2"
                            style={{
                              width: "auto",
                              height: "15px",
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15px",
                              textAlign: "left",
                            }}
                          >
                            {userDetails?.supplier?.crDocumentId}
                          </p>
                        </div>
                      </button>
                    </p>

                    {userDetails?.supplier?.crDocumentIdFile ? (
                      userDetails?.supplier?.crDocumentIdFile.includes(
                        ".pdf"
                      ) ? (
                        <div
                          className="previewdoc d-flex align-items-center justify-content-center"
                          style={{ border: "1px solid black" }}
                        >
                          <object
                            data={userDetails?.supplier?.crDocumentIdFile}
                            className="previewdoc"
                          >
                            alt={userDetails?.supplier?.crDocumentId}
                          </object>
                        </div>
                      ) : userDetails?.supplier?.crDocumentIdFile.includes(
                          ".docx"
                        ) ? (
                        <a
                          href={userDetails?.supplier?.crDocumentIdFile}
                          target="_blank"
                        >
                          Download
                        </a>
                      ) : (
                        <img
                          src={userDetails?.supplier?.crDocumentIdFile}
                          alt="CR"
                          className="previewdoc"
                          style={{ width: 300, height: 200 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </FormGroup>

                  <FormGroup className="col-12 col-sm-6 col-md-4 col-lg-6">
                    <Label for="title" className="headings1">
                      Vat Document
                    </Label>
                    <p>
                      <button
                        className="btn-document border-0"
                        style={{
                          width: "auto",
                          height: "60px",

                          borderRadius: "8px",
                          gap: "12px",
                          backgroundColor: "#EDF0F7",
                        }}
                      >
                        <div className="d-flex p-2">
                          <img
                            src={File}
                            alt=""
                            className="document mt-0 mx-2"
                          />
                          <p
                            className="document pt-2"
                            style={{
                              width: "auto",
                              height: "15px",
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15px",
                              letterSpacing: "0em",
                              textAlign: "left",
                            }}
                          >
                            {userDetails?.supplier?.vatDocumentId}
                          </p>
                        </div>
                      </button>
                    </p>

                    {userDetails?.supplier?.vatDocumentIdFile ? (
                      userDetails?.supplier?.vatDocumentIdFile.includes(
                        ".pdf"
                      ) ? (
                        <div
                          className="previewdoc d-flex align-items-center justify-content-center"
                          style={{ border: "1px solid black" }}
                        >
                          <object
                            data={userDetails?.supplier?.vatDocumentIdFile}
                            className="previewdoc"
                          >
                            alt={userDetails?.supplier?.vatDocumentIdFile}
                          </object>
                        </div>
                      ) : userDetails?.supplier?.vatDocumentIdFile.includes(
                          ".docx"
                        ) ? (
                        <a
                          href={userDetails?.supplier?.vatDocumentIdFile}
                          target="_blank"
                        >
                          Download
                        </a>
                      ) : (
                        <img
                          src={userDetails?.supplier?.vatDocumentIdFile}
                          alt="CR"
                          className="previewdoc"
                          style={{ width: 300, height: 200 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Form>
              </div>

              <div className="errors-after-sumbit">
                {errorsOnSubmit?.map((error) => (
                  <p>{error}</p>
                ))}
              </div>
              {/* <div
                className="save-btn-float"
                onClick={isAddProductInProgress ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Save"}
                  isLoading={isAddProductInProgress}
                />
              </div>
              <div
                className="save-btn-float"
                onClick={isAddProductInProgress ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Save"}
                  isLoading={isAddProductInProgress}
                />
              </div> */}
            </>
          )}
        </Formik>
      )}
    </div>
        
        
       
      </div>

      {/* <div className="save-btn-float" onClick={toggle}>
                <PrimaryButton title="Save" />
            </div> */}
    </div>
  );
};

export default User;
