import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import "./ChatBox.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import { Button } from "reactstrap";
import { FaTelegram } from "react-icons/fa";
import addicon from "../../assets/images/icons/add-button.svg";
import MainServices from "../../services/MainServices";
import axios from "axios";
import moment from "moment";
import useAuth from "../../hooks/useAuth";

export default function Message() {
  const { user, updateUserContext } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const { getInquiresDetails, addNotifications, fileUpload } = MainServices();
  const [enquiriesCommunication, setEnquiriesCommunication] = useState([]);
  const [enquiries, setEnquiries] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  //hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language;
  const location = useLocation();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const publicId = new URLSearchParams(location.search).get("publicId");

  useEffect(() => {
    getNotificationsList();
  }, []);

  const getNotificationsList = async (page, limit) => {
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      sorting: [{ key: "createdAt", direction: "asc" }],
    };
    await axios
      .all([getInquiresDetails(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let notification = resData.data ? resData.data : [];
            setEnquiriesCommunication(notification.enquiryCommunications || []);
            setEnquiries(notification);
           
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Notification");
      });
  };
  
  const addNotification = async (values) => {
    const details = {
      message: values.message,
      enquiryId: publicId,
      // enquiryStatus: "Open",
      date: new Date(),
      // enquiry: "Sample enquiry",
      quotationId: publicId,
      // quotation: "Sample quotation",
    };
  
    await axios
      .all([addNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getNotificationsList();
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Adding Notification");
      });
  };

  // const uploadFiles = async (values) => {
  //   if (values.documents && values.documents.length > 0) {
  //     let documents = values.documents;
  //     await axios
  //       .all([fileUpload(documents)])
  //       .then(function (res) {
  //         if (res) {
  //           console.log(res);
  //           let resData = res[0].data;
  //           console.log(resData);
  //           let filesReferencesList = [];
  //           resData.map((eachResponse) => {
  //             if (eachResponse.filename) {
  //               filesReferencesList.push(eachResponse.filename);
  //             }
  //             return true;
  //           });
  //           addNotification(values, filesReferencesList);
  //         }
  //       })
  //       .catch(function (res) {
  //         console.log("An error occurred in file upload");
  //       });
  //   } else {
  //     addNotification(values, "");
  //   }
  // };

  return (
    <div className="chat-main">
      {/* <HeadingMain text="ChatConnect" /> */}
      {user.role === "supplier" && (
        <div className="chat-box">
          <Formik
            initialValues={{
              date: "",
              message: "",
            }}
            onSubmit={(values, { resetForm }) => {
              addNotification(values);
              resetForm();
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form className="form" onSubmit={handleSubmit}>
               
                {
                enquiriesCommunication &&
                enquiriesCommunication.length > 0 ? (
                  enquiriesCommunication?.map(
                    (eachCommunication, index) => {
                      return (
                        <div key={index}>
                          {eachCommunication.source === "supplier" ? (
                            <>
                              {eachCommunication.message && (
                                <div className="chat outgoing">
                                  <div className="details">
                                    <p>
                                      {eachCommunication.message}
                                      {/* {eachCommunication.document} */}
                                      {eachCommunication.datetime ? (
                                        <span className="datetime">
                                          {eachCommunication.datetime}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {eachCommunication.message && (
                                <div className="chat incoming">
                                  <div className="details">
                                    <p>
                                      {eachCommunication.message}
                                      {/* {eachCommunication.document} */}
                                      {eachCommunication.datetime ? (
                                        <span className="datetime-outgoing">
                                          {eachCommunication.datetime}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="no-enquiries">No Communications Found</p>
                )}
               
                <div className="msg-bottom">
                  <div className="input-group">
                    {/* <img
                      src={addicon}
                      alt="Add"
                      onClick={handleImageClick}
                      className="addicon"
                    />
                    <input
                      name="documents"
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    /> */}
                    <div className="text-area">
                      <textarea
                        name="message"
                        placeholder="Type a message here"
                        className="form-control"
                        value={values.message}
                        onChange={handleChange}
                        rows="1"
                        style={{ resize: "none" }}
                      ></textarea>
                    </div>
                    <div>
                      <Button type="submit" className="send-btn">
                        <FaTelegram className="tele-icon" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {user.role === "admin" && (
        <div className="chat-box">
          <Formik
            initialValues={{
              date: "",
              message: "",
            }}
            onSubmit={(values, { resetForm }) => {
              addNotification(values);
              resetForm();
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form className="form" onSubmit={handleSubmit}>
               
                {
                enquiriesCommunication &&
                enquiriesCommunication.length > 0 ? (
                  enquiriesCommunication?.map(
                    (eachCommunication, index) => {
                      return (
                        <div key={index}>
                          {eachCommunication.source === "admin" ? (
                            <>
                              {eachCommunication.message && (
                                <div className="chat incoming">
                                  <div className="details">
                                    <p>
                                      {eachCommunication.message}
                                      {/* {eachCommunication.document} */}
                                      {eachCommunication.datetime ? (
                                        <span className="datetime">
                                          {eachCommunication.datetime}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {eachCommunication.message && (
                                <div className="chat outgoing">
                                  <div className="details">
                                    <p>
                                      {eachCommunication.message}
                                      {/* {eachCommunication.document} */}
                                      {eachCommunication.datetime ? (
                                        <span className="datetime-outgoing">
                                          {eachCommunication.datetime}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="no-enquiries">No Communications Found</p>
                )}
               
                <div className="msg-bottom">
                  <div className="input-group">
                    {/* <img
                      src={addicon}
                      alt="Add"
                      onClick={handleImageClick}
                      className="addicon"
                    />
                    <input
                      name="documents"
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    /> */}
                    <div className="text-area">
                      <textarea
                        name="message"
                        placeholder="Type a message here"
                        className="form-control"
                        value={values.message}
                        onChange={handleChange}
                        rows="1"
                        style={{ resize: "none" }}
                      ></textarea>
                    </div>
                    <div>
                      <Button type="submit" className="send-btn">
                        <FaTelegram className="tele-icon" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
