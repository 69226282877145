import "./Settings.scss";
import { Form, Input, Label, FormGroup } from "reactstrap";
import Tab from "../../assets/elements/Tab";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeadingMain from "../../assets/elements/HeadingMain";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import MainServices from "../../services/MainServices";
import {
  filtersToUrlParams,
  filtersToUri,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import ChatUi from "../../assets/elements/ChatUi";
import { Formik } from "formik";
import axios from "axios";
import { Button } from "reactstrap";
import { FaTelegram } from "react-icons/fa";
import addicon from "../../assets/images/icons/add-button.svg";
import moment from "moment";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const status = new URLSearchParams(location.search).get("status");
  const supplierId = new URLSearchParams(location.search).get("supplierId");
  const [selectedStatus, setSelectedStatus] = useState("Account Setup");
  // const { t } = useTranslation();
  const { t } = useTranslation();
  const { user } = useAuth();

  const { getUsers, getNotifications,addNotifications} = MainServices();

  const [suppliers, setSuppliers] = useState([]);
  const [totalCount, setTotalCount] = useState(3);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  // const notification = [
  //     { type: 'Login', message: 'By user', createdAt: 'date1' },
  //     { type: 'Logout', message: 'After a Successful login', createdAt: 'date2' },
  //     { type: 'Session', message: 'Added Products', createdAt: 'date3' },
  // ]
  const [notifications, setnotificatons] = useState([]);
  const [supplier, setSupplier] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    if (selectedStatus === "Notifications") {
      let filters = paramsToFilters(changedParams?.filters, "filters");

      // let sorts = paramsToFilters(changedParams?.sort, "sort");
      // let sortObj = paramsToFiltersObj(changedParams?.sort);
      let filtersObj = paramsToFiltersObj(changedParams?.filters);

      let pagination = {
        limit: changedParams?.limit || 10,
        page: changedParams?.page || 1,
      };
      //  let supplierId = changedParams?.supplier?.publicId || user.user_id;
      setSupplier(changedParams?.supplier ? changedParams?.supplier : "");
      console.log("status", changedParams.status);
      setPagination(pagination);
      setFilters(filtersObj);
      // setSorts(sortObj);
      //  setSearchString(filtersObj?.name?.name);
      let msuppliers = suppliers || [];
      msuppliers.map((each) => {
        if (each?.publicId === supplierId) {
          setSelectedSupplier(each);
        }
      });
      console.log(suppliers);
      getNotificationsList(supplierId);
    }
  }, [selectedStatus, supplierId]);

  useEffect(() => {
    if (selectedStatus === "Notifications") {
      user.role === "admin" &&
        getSuppliersList({
          filters: [{ key: "role", eq: "supplier" }],
          initial: true,
        });

      // eslint-disable-next-line
    }
  }, [selectedStatus]);

  const getNotificationsList = async (supplierId) => {
    const details = {
      pageSize: 50,
      pageNumber: 1,
      ...(supplierId
        ? { filters: [{ key: "notificationId", eq: supplierId }] }
        : {}),
      sorting: [{ key: "createdAt", direction: "asc" }],
    };
    await getNotifications(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            resData = resData.map((eachNotification, index) => ({
              ...eachNotification,
              id: index + 1,
              datetime: moment(eachNotification.createdAt).format(
                "MM/DD/YYYY  h:mm a"
              ),
              date: moment(eachNotification.createdAt).format("MM/DD/YYYY"),
            }));
            console.log("notifications:", resData);
            setnotificatons(resData);
            setTotalCount(total);
          }
        }
        //  setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        //  setSearchLoading(false);
      });
  };

  const getSupplierNotificationsList = async ({
    page,
    limit,
    filters,

    supplierId,
  }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,

      filters: supplierId
        ? [{ key: "notificationId", eq: supplierId }]
        : filters,
    };

    await getNotifications(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            setnotificatons(resData);
            setTotalCount(total);
          }
        }
        //  setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        //  setSearchLoading(false);
      });
  };

  const onSupplierChange = async (selected) => {
    if (selected) {
      const supplierId = selected.value;
      navigate({
        search: `?supplierId=${supplierId}&status=${selectedStatus}`,
      });
    }
  };

  const customStylesForRoleSelect = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };

  const loadOptionsForSuppliers = async (inputValue, callback) => {
    if (!inputValue) {
      const initialSuppliers = await getSuppliersList({
        page: 1,
        limit: 50,
        filters: [],
        initial: true,
      });
      callback(initialSuppliers);
    } else {
      const filter = [
        {
          key: "name",
          iLike: inputValue,
        },
        {
          key: "role",
          eq: "supplier",
        },
      ];

      const suppliersByInput = await getSupplierNotificationsList({
        page: 1,
        limit: 10,
        filters: filter,
        initial: false,
      });
      callback(suppliersByInput);
    }
  };

  const getSuppliersList = async ({ page, limit, filters, initial }) => {
    let data = [];
    const details = {
      pageSize: 100,
      pageNumber: page || 1,
      filters,
    };
    await getUsers(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              if (supplierId === _?.publicId) {
                setSelectedSupplier({
                  ..._,
                  label: _.name,
                  value: _.publicId,
                });
              }
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            resData.unshift({ label: "All", value: "" });
            data = resData;
            initial && setSuppliers(resData);

            //setTotalCount(total);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  
  const addNotification = async (values) => {
    const details = {
      documents: [],
      message: values.message,
      notificationType: "supplier",
      notificationId: selectedSupplier?.value || user.user_id,
    };
    await axios
      .all([addNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getNotificationsList(supplierId);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Adding Notification");
      });
  };

  const tabsItem = [
    {
      label: "ACCOUNT SETUP",
      value: "Account Setup",
    },
    {
      label: "NOTIFICATIONS",
      value: "Notifications",
    },
    {
      label: "ORDER SETTING",
      value: "Order Setting",
    },
    {
      label: "STOCK SETTING",
      value: "Stock Setting",
    },
  ];

  const onStatusChange = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    //  navigate({ search: `?status=${selectedStatus}` });
  };

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  return (
    <div className="setting-main">
      <HeadingMain text="Settings" />
      <div className="tab-flex-main">
        {tabsItem.map((each) => {
          return (
            <Tab
              title={each.label}
              className={selectedStatus === each.value && "active"}
              onClick={() => onStatusChange(each.value)}
            />
          );
        })}
      </div>

      {selectedStatus === "Account Setup" && (
        <div>
          <div className="setting-topcard-main">
            <div>
              <h1 className="setting-heading">{t("Business info")}</h1>
              <p className="setting-text">
                {t(
                  "Your business name is displayed across many areas including your online booking profile, sales receipts, and messages to clients"
                )}
              </p>
            </div>
            <Form>
              <FormGroup>
                <Label for="businessName">{t("Business name")}</Label>
                <Input
                  id="businessName"
                  name="businessName"
                  placeholder={t("Product Name")}
                  type="text"
                  className="setting-form-group"
                />
              </FormGroup>
            </Form>
          </div>

          <div className="setting-topcard-main">
            <h1 className="setting-heading">
              {t("Time and calendar settings")}
            </h1>
            <Form>
              <div className="row">
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="timeZone">{t("Time zone")}</Label>
                  <Input
                    id="timeZone"
                    name="timeZone"
                    type="select"
                    className="setting-form-group"
                  >
                    <option>(GMT +150) Kolkata</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </Input>
                </FormGroup>
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="timeFormat">{t("Time format")}</Label>
                  <Input
                    id="timeFormat"
                    name="timeFormat"
                    type="select"
                    className="setting-form-group"
                  >
                    <option>12 hours (i.e 9:00pm)</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </Input>
                </FormGroup>
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="weekStart">{t("Week start")}</Label>
                  <Input
                    id="weekStart"
                    name="weekStart"
                    type="select"
                    className="setting-form-group"
                  >
                    <option>{t("Monday")}</option>
                    <option>{t("Tuesday")}</option>
                    <option>{t("Wednesday")}</option>
                    <option>{t("Thursday")}</option>
                    <option>{t("Friday")}</option>
                    <option>{t("Saturday")}</option>
                    <option>{t("Sunday")}</option>
                  </Input>
                </FormGroup>
              </div>
            </Form>
          </div>

          <div className="setting-topcard-main">
            <h1 className="setting-heading">{t("Language settings")}</h1>
            <Form>
              <div className="row">
                <FormGroup className=" col-12 col-md-6 col-lg-6">
                  <Label for="timeZone">
                    {t("Client notification language")}
                  </Label>
                  <Input
                    id="timeZone"
                    name="timeZone"
                    type="select"
                    className="setting-form-group"
                  >
                    <option>English(English)</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </Input>
                </FormGroup>
                <FormGroup className=" col-12 col-md-6 col-lg-6">
                  <Label for="timeFormat">
                    {t("Default language for your team")}
                  </Label>
                  <Input
                    id="timeFormat"
                    name="timeFormat"
                    type="select"
                    className="setting-form-group"
                  >
                    <option>UK(English)</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </Input>
                </FormGroup>
              </div>
            </Form>
          </div>

          <div className="setting-topcard-main">
            <div>
              <h1 className="setting-heading">{t("Business info")}</h1>
              <p className="setting-text">
                {t(
                  "Add your company website and social media links for sharing with clients"
                )}
              </p>
            </div>
            <Form>
              <div className="row">
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="website">{t("Website")}</Label>
                  <Input
                    id="website"
                    name="website"
                    type="text"
                    placeholder="www.yourwebsite.com"
                    className="setting-form-group"
                  ></Input>
                </FormGroup>
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="facebookPage">{t("Facebook page")}</Label>
                  <Input
                    id="facebookPage"
                    name="facebookPage"
                    type="text"
                    placeholder="www.facebook.com/youself"
                    className="setting-form-group"
                  ></Input>
                </FormGroup>
                <FormGroup className=" col-12 col-md-6 col-lg-4">
                  <Label for="instagramPage">{t("Instagram page")}</Label>
                  <Input
                    id="instagramPage"
                    name="instagramPage"
                    type="text"
                    placeholder="www.instagram.com/youself"
                    className="setting-form-group"
                  ></Input>
                </FormGroup>
              </div>
            </Form>
          </div>

          <div className="save-btn-float">
            <PrimaryButton title="Save" />
          </div>
        </div>
      )}
      {selectedStatus === "Notifications" && (
        <div>
          <div className="product-main">
            <div className="heading">
              <HeadingMain text={t("Notifications")} />

              <div className="heading-right-btn">
                {user.role === "admin" && (
                  <AsyncSelect
                    styles={customStylesForRoleSelect}
                    className="select-role async-select col-3"
                    placeholder={t("Select Supplier")}
                    loadOptions={loadOptionsForSuppliers}
                    defaultOptions={suppliers}
                    onChange={onSupplierChange}
                    value={selectedSupplier}
                    name="users"
                    cacheOptions
                  />
                )}
              </div>
            </div>

            <div className="product-table-main">
              <Formik
                initialValues={{
                  documents: "",
                  message: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  addNotification(values);
                  resetForm();
                  // uploadFiles(values);
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form className="form" onSubmit={handleSubmit}>
                    <ChatUi notifications={notifications} />

                    <div className="msg-bottom">
                      <div className="input-group">
                        <img
                          src={addicon}
                          alt="Add"
                          onClick={handleImageClick}
                          className="addicon"
                        />
                        <input
                          name="documents"
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <div className="text-area">
                          <textarea
                            name="message"
                            placeholder="Type a message here"
                            className="form-control"
                            value={values.message}
                            onChange={handleChange}
                            rows="1"
                            style={{ resize: "none" }}
                          ></textarea>
                        </div>
                        <div>
                          <Button type="submit" className="send-btn">
                            <FaTelegram className="tele-icon" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      {selectedStatus === "Order Status" && <div></div>}
      {selectedStatus === "Stock Setting" && <div></div>}
    </div>
  );
}
