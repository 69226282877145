import React, { useEffect, useState } from "react";
import "./EnquiryDetails.scss";
import Back_Arrow from "../../assets/images/icons/arrow-left.svg";
import { useLocation, useNavigate } from "react-router-dom";
import MainService from "../../services/MainServices";
import { useTranslation } from "react-i18next";
import viewicon from "../../assets/images/icons/viewicon.svg";
import {
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import moment from "moment";
import { LazyLoader } from "../../assets/loaders";
import IconButton from "../../assets/elements/IconButton";
import { Button, Table } from "reactstrap";
import MessageIcon from "../../assets/images/messageIcon.svg";
import { Modal } from "react-bootstrap";
import Message from "./ChatBox";

export default function InquireyDetails() {
  const navigate = useNavigate();
  const { getInquiresDetails } = MainService();
  const { t, i18n } = useTranslation();
  const [enquiries, setEnquiries] = useState([]);
  const [enquiriesCommunication, setEnquiriesCommunication] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const publicId = new URLSearchParams(location.search).get("publicId");
  const changedParams = Object.fromEntries(params);
  const [enquiryMessageModal, setEnquiryMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  // useEffect(() => {
  //   // console.log("running")
  //   if (publicId) getEnquiriesList(publicId);
  //   //eslint-disable-next-line
  // },);
  // useEffect(()=>{
  //  if(publicId){
  //   getEnquiriesList();
  //  }
  // })
  // useEffect(()=>{
  //   getEnquiriesList();
  // })
  useEffect(() => {
    if (publicId) {
      getEnquiriesList();
    }
  }, []);

  const getEnquiriesList = async () => {
    setPageLoading(true);
    const details = {
      // filters: [{ key: "publicId", eq: `${publicId}` }],
    };
    await getInquiresDetails(details, publicId)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            const total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            setEnquiries(resData);
            setEnquiriesCommunication(resData);
            setTotalCount(total);
            setSearchLoading(false);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onMessageModal = (enquiryId) => {
    setSelectedMessage(enquiryId);
    setEnquiryMessageModal(!enquiryMessageModal);
  };

  // useEffect(() => {
  //   let filters = paramsToFilters(changedParams?.filters, "filters");
  //   let sorts = paramsToFilters(changedParams?.sort, "sort");
  //   let sortObj = paramsToFiltersObj(changedParams?.sort);
  //   let filtersObj = paramsToFiltersObj(changedParams?.filters);
  //   let pagination = {
  //     limit: changedParams?.limit || 10,
  //     page: changedParams?.page || 1,
  //   };
  //   getEnquiriesList({
  //     page: pagination.page,
  //     limit: pagination.limit,
  //     filters,
  //     sort: sorts,
  //   });
  // }, [
  //   changedParams.limit,
  //   changedParams.page,
  //   changedParams.filters,
  //   changedParams.sort,
  //   i18n.language,
  // ]);

  return (
    <div>
      {pageLoading ? (
        <LazyLoader />
      ) : (
        <>
          <div className="view-party-main">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 mb-3">
                <div className="box-squar pointer" onClick={() => navigate(-1)}>
                  <img src={Back_Arrow} alt="backArrow" />
                </div>
                <div className="heading">
                  {enquiries.enquiryId || "No Enquiry Details"}
                </div>
              </div>
              {enquiries.quotationStatus === "submitted" && (
                <div>
                  <Button
                    className="create-quotation-btn-main"
                    onClick={() =>
                      navigate({
                        pathname: "/enquiries/quotation-details",
                        search: `?enquiryId=${publicId}`,
                      })
                    }
                  >
                    View Quotation
                  </Button>
                </div>
              )}
            </div>
            <div className="view-party-actual">
              <div class="view-party-side-heading d-flex">
                <div class="general-details">General Details</div>

                <div
                  class="message-icon p-1"
                  onClick={() => onMessageModal()}
                  style={{ cursor: "pointer" }}
                >
                  <img src={MessageIcon} alt="Message" />
                </div>
              </div>
              <div className="view-party-each-row">
                <div>Enquiry Id</div>
                <div>{enquiries.enquiryId || "-"}</div>
              </div>
              <div className="view-party-each-row">
                <div>Date</div>
                <div>
                  {moment(enquiries?.createdAt).format("DD-MM-YYYY") || "-"}
                </div>
              </div>
              <div className="view-party-each-row">
                <div>Status</div>
                <div>{enquiries?.status || "-"}</div>
              </div>
              <br />
              <div className="view-party-side-heading">History</div>
              {/* <table class="custom-table">
                <tr>
                  <td>Date</td>
                  <td>
                    {moment(
                      enquiries[0]?.enquiryCommunication?.createdAt
                    ).format("DD-MM-YYYY HH:mm:ss") || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{enquiries[0]?.enquiryCommunication?.status || "-"}</td>
                </tr>
                <tr>
                  <td>Message</td>
                  <td>{enquiries[0]?.enquiryCommunication?.message || "-"}</td>
                </tr>
                <tr>
                  <td>Quotation</td>
                  <td>
                    {enquiries[0]?.enquiryCommunication?.quotation || "-"}
                  </td>
                </tr>
              </table> */}
              <br />

              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head">
                  <tr>
                    <th>Sr</th>
                    <th>Message</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Quotation</th>
                  </tr>
                </thead>
                {enquiries &&
                  enquiries.enquiryCommunications &&
                  enquiries.enquiryCommunications.length > 0 &&
                  enquiries.enquiryCommunications.map((each, index) => (
                    <tbody>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{each.message || "-"}</td>
                        <td>{each.enquiryStatus || "-"}</td>
                        <td>
                          {moment(each.createdAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          ) || "-"}
                        </td>
                        <td>
                          <div className="d-flex gap-3 align-items-center">
                            <img
                              src={viewicon}
                              style={{
                                height: "20px",
                                width: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate({
                                  pathname: "/enquiries/quotation-details",
                                  search: `?enquiryId=${each.enquiryId}`,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            </div>

            <Modal
              centered
              show={enquiryMessageModal}
              onHide={onMessageModal}
              className="chat-modal-main"
              selected={selectedMessage}
              size="lg"
            >
              <Modal.Header closeButton className="add-department-modal-header">
                <Modal.Title className="add-department-modal-title">
                  Chat Connect
                </Modal.Title>
              </Modal.Header>

              {enquiryMessageModal && (
                <Modal.Body className="add-department-modal-body">
                  <Message />
                </Modal.Body>
              )}
            </Modal>
          </div>
        </>
      )}
    </div>
  );
}
