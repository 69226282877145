import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useTranslation } from "react-i18next";

import "./Pagination.scss";

export default function CustomPagination({
  currentPage,
  rowsPerPage,
  totalCount,
  onPageChange,
  onRowsPerPage,
}) {
  const [pageList, setPageList] = useState(1);
  const [selectedRows, setSelectedRows] = useState(rowsPerPage);
  const options = [
    { label: "10", value: 10 },
    ...(totalCount > 10 ? [{ label: "20", value: 20 }] : []),
    ...(totalCount > 20 ? [{ label: "30", value: 30 }] : []),
    ...(totalCount > 30 ? [{ label: "40", value: 40 }] : []),
    ...(totalCount > 40 ? [{ label: "50", value: 50 }] : []),
  ];

  let page = +currentPage;
  let limit = Math.ceil(totalCount / rowsPerPage);

  const { t } = useTranslation();

  let count = page * rowsPerPage < totalCount ? rowsPerPage * page : totalCount;

  const onPage = (number) => {
    onPageChange && onPageChange(number);
  };

  const onPrevious = () => {
    if (pageList > 1) {
      setPageList(pageList - 1);
      if (page > 1) {
        onPageChange && onPageChange(page - 1);
      }
    }
  };

  const onNextPage = () => {
    if (pageList + 5 <= limit) {
      setPageList(pageList + 1);
      if (page < limit) {
        onPageChange && onPageChange(page + 1);
      }
    }
  };

  const onRowsChange = (e) => {
    setSelectedRows(e.target.value);
    onRowsPerPage && onRowsPerPage(e.target.value);
  };

  const onFisrt = () => {
    onPageChange && onPageChange(1);
    setPageList(1);
  };
  const onLast = () => {
    onPageChange && onPageChange(limit);
    setPageList(limit - 4);
  };

  return (
    <div className="balloons-pagination d-flex justify-content-space-between">
      <div className="d-flex rows-per-page gap-2">
        <div className="rows-select">
          <select
            className="form-control form-select"
            id="fv-topics"
            name="status"
            data-placeholder="Row per Page"
            required
            value={selectedRows}
            onChange={onRowsChange}
          >
            {options.map((each) => (
              <option label={each.label} value={each.value}></option>
            ))}
          </select>
        </div>
        <div className="rows-info mt-1 mx-2">
          <span className="mx-1">{t("Showing")}</span>
          <span className="mx-1 font-weight-bold">{count}</span>
          <span className="mx-1 ">of</span>
          <span className="mx-1 font-weight-bold">{totalCount}</span>
        </div>
      </div>

      <Pagination className="pagination-main">
        <PaginationItem
          disabled={page === 1 || pageList === 1}
          onClick={onFisrt}
        >
          <PaginationLink first />
        </PaginationItem>
        <PaginationItem
          disabled={page === 1 || pageList === 1}
          onClick={() => onPrevious()}
        >
          <PaginationLink previous />
        </PaginationItem>
        {limit <= 5
          ? [...Array(limit)].map((_, index) => {
              return (
                <PaginationItem
                  className={`page-item ${page === index + 1 ? "active" : ""}`}
                  onClick={() => onPage(index + 1)}
                >
                  <PaginationLink>{index + 1}</PaginationLink>
                </PaginationItem>
              );
            })
          : [...Array(5)].map((_, index) => {
              return (
                <PaginationItem
                  className={`page-item ${
                    page === pageList + index ? "active" : ""
                  }`}
                  onClick={() => onPage(pageList + index)}
                >
                  <PaginationLink>{pageList + index}</PaginationLink>
                </PaginationItem>
              );
            })}

        <PaginationItem
          disabled={page === limit || pageList + 5 > limit}
          onClick={() => onNextPage()}
        >
          <PaginationLink next />
        </PaginationItem>
        <PaginationItem
          disabled={page === limit || pageList + 5 > limit}
          onClick={onLast}
        >
          <PaginationLink last />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
