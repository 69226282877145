import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
//import { GoogleMapsAPI } from "./apiKey";
import "./Map.scss";
/* import { Loader } from '@googlemaps/js-api-loader';
const loader = new Loader({
  apiKey: process.env.REACT_APP_REACT_APP_GoogleMapsAPI,
  version: "weekly",
  libraries: ["places"]
});

const mapOptions = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 4
};

loader
  .load()
  .then((google) => {
    new google.maps.Map(document.getElementById("map"), mapOptions);
  })
  .catch(e => {
    // do something
  }); */


Geocode.setApiKey(process.env.REACT_APP_GoogleMapsAPI);
//Geocode.enableDebug();

function Map(props) {
  const {
    isVisible,
    readOnly,
    latLngCoordinatesObject, 
    setTemporaryHoldUserSelection,
  } = props;
  latLngCoordinatesObject.lat = Number(latLngCoordinatesObject.lat)
  latLngCoordinatesObject.lng = Number(latLngCoordinatesObject.lng)
  
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };
  const [mapPosition, setMapPosition] = useState({
    lat: latLngCoordinatesObject.lat,
    lng: latLngCoordinatesObject.lng,
  });
  /* const [markerPosition, setMarkerPosition] = useState({
    lat: latLngCoordinatesObject.lat,
    lng: latLngCoordinatesObject.lng,
  }); */

  const [address, setAddress] = useState("");
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GoogleMapsAPI,
  });
  /* useEffect(() => {
    let pos = latLngCoordinatesObject
      ? latLngCoordinatesObject
      : {
          lat: 20.5937,
          lng: 78.9629,
        };
    setMapPosition(pos);
    setMarkerPosition(pos);
    // eslint-disable-next-line
  }, []); */

  const getAndSetLocationDetails = (newLat, newLng) => {
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, pincode;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                pincode = response.results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }
        !readOnly && setTemporaryHoldUserSelection({
          location: address,
          latitude: newLat,
          longitude: newLng,
          city,
          state,
          postalcode: pincode,
          country,
        })

        setMapPosition({ lat: newLat, lng: newLng });
        //setMarkerPosition({ lat: newLat, lng: newLng });
        setAddress(address ? address : "");
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    getAndSetLocationDetails(latLngCoordinatesObject.lat, latLngCoordinatesObject.lng)
    // eslint-disable-next-line
  }, [])

  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    getAndSetLocationDetails(newLat, newLng);
  };

  const OnLocationclick = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    getAndSetLocationDetails(newLat, newLng);
  };

  const onChange = (event) => {
    isVisible && OnLocationclick(event);
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        getAndSetLocationDetails(latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  return isLoaded ? (
    <div className="map-container">
      <div
        container
        direction={"column"}
        style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", justifyContent: "center" }}
        spacing={2}
      >
        {!readOnly && (
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            className="map-input"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
        <GoogleMap
          mapContainerStyle={{
            height: "450px",
            width: "100%",
          }}
          options={options}
          zoom={props?.zoom || 5}
          center={{ lat: mapPosition.lat,  lng: mapPosition.lng }}
          onClick={readOnly ? null : onChange}
        >
        <Marker
            draggable={!readOnly}
            onDragEnd={readOnly ? null : onMarkerDragEnd}
            position={{lat: mapPosition.lat, lng: mapPosition.lng}}
        />
          
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
}

Map.getMapLocation = async (lat, lng) => {
  if(!lat || !lng) return
  let location = "";
  return Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      location = address;
      return location
    },
  );
}

export default Map;
