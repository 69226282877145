import React from "react";
import uploadicon from "../../assets/images/icons/upload.svg";
import addicon from "../../assets/images/icons/add-button.svg";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import "./UploadEachDocument.scss";

const UploadEachDocument = (props) => {
  const handleDocumentUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    documentRef.current?.click();
  };
  const { t } = useTranslation();
  const {
    handleChangeDocument,
    setFieldValue,
    documentFile,
    fileName,
    acceptType,
    isShow,
  } = props;
  const documentRef = useRef();

  const file = documentFile;

  const renderView = () => {
    if (typeof file === "string") {
      return <img src={file} alt="logo" className="preview" />;
    }
    if (file.type.includes("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={fileName}
          className="preview"
        />
      );
    } else if (file.type.includes("application/pdf")) {
      return (
        <object data={URL.createObjectURL(file)} className="preview">
          {fileName}
        </object>
      );
    } else {
      return <p>{file.name}</p>;
    }
  };

  return (
    <div
      id="uploaddocument"
      name="uploaddocument"
      className="input-icon-group uploaddocument-group"
    >
      <img src={uploadicon} alt="upload" />
      <p>
        {t("Browse and chose the files you want to upload from your computer")}
      </p>
      <p>
        {`${t("Add")} ${fileName.toUpperCase()} ${
          acceptType?.includes("image") ? t("image") : t("document")
        }`}
      </p>
      {file && isShow && (
        <div className="upload-preview">
          {renderView()}
          <span>{fileName.toUpperCase()}</span>
        </div>
      )}
      <img
        src={addicon}
        alt="Add"
        onClick={handleDocumentUploadClick}
        className="addicon"
      />
      <input
        name={fileName}
        ref={documentRef}
        accept={acceptType}
        type="file"
        onChange={
          setFieldValue
            ? (e) => handleChangeDocument(e, setFieldValue)
            : handleChangeDocument
        }
        hidden
      />
    </div>
  );
};

export default UploadEachDocument;
