import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "reactstrap";
import "./Table.scss";

export default function CustomTable({
  columns,
  data,
  className,
  hasSub,
  subclassName,
  subColumns,
}) {
  const [subToggle, setSubToggle] = useState({});
  const [subSubToggle, setSubSubToggle] = useState({});
  const { t } = useTranslation();

  //Table head
  const Th = (cell) => {
    return cell.customHeadClass ? (
      <th className={cell.customHeadClass}>
        {cell.customHead ? cell.customHead(cell) : t(cell.text)}
      </th>
    ) : (
      <th>{cell.customHead ? cell.customHead(cell) : t(cell.text)}</th>
    );
  };

  //Table data
  const Td = (cell, row, toggleFunction) => {
    // To handle field that refers to an object property
    let nestedFieldValue = "";
    if (typeof cell?.field === "string" && cell?.field?.includes(".")) {
      let strings = cell.field.split(".");
      let objectName = strings[0];
      let objectProperty = strings[1];
      nestedFieldValue = row[objectName]?.[objectProperty];
    }
    return (
      <td
        onClick={() => {
          toggleFunction(row.public_id);
        }}
        style={{
          cursor:
            row?.subData?.length > 0 || row?.sub_categories?.length > 0
              ? "pointer"
              : "",
        }}
      >
        {cell.customStyle
          ? cell.customStyle(row)
          : t(nestedFieldValue || row[cell.field])}
      </td>
    );
  };

  const onSubToggle = (id) => {
    let toggle = {};
    toggle[id] = subToggle[id] ? false : true;
    setSubToggle(toggle);
  };

  const onSubSubToggle = (id) => {
    console.log(id);
    let subtoggle = {};
    subtoggle[id] = subSubToggle[id] ? false : true;
    setSubSubToggle(subtoggle);
  };

  return (
    <Table responsive borderless className={className}>
      <thead>
        <tr>{(columns || []).map((_) => Th(_))}</tr>
      </thead>
      <tbody>
        {(data || []).map((_) => (
          <>
            <tr>{(columns || []).map((__) => Td(__, _, onSubToggle))}</tr>
            {subToggle[_.public_id] &&
              hasSub &&
              _.subData &&
              _.subData.length > 0 && (
                <>
                  {(_.subData || []).map((eachSub) => (
                    <>
                      <tr>
                        {(subColumns || []).map((eachSubCol) =>
                          Td(eachSubCol, eachSub, onSubSubToggle)
                        )}
                      </tr>
                      {subSubToggle[eachSub.public_id] &&
                        hasSub &&
                        eachSub.sub_categories &&
                        eachSub.sub_categories.length > 0 && (
                          <>
                            {(eachSub.sub_categories || []).map((eachSub) => (
                              <>
                                <tr>
                                  {console.log(subColumns)}
                                  {(subColumns || []).map((eachSubCol) =>
                                    Td(eachSubCol, eachSub)
                                  )}
                                </tr>
                              </>
                            ))}
                          </>
                        )}
                    </>
                  ))}
                </>
              )}
          </>
        ))}
      </tbody>
    </Table>
  );
}
