import React, { useEffect, useState } from "react";

import "./App.scss";
import Main from "./Main";

import axios from "axios";

import { Route, HashRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ConfigProvider } from "./context/Config";
import { useTranslation } from "react-i18next";
import Toaster from "./assets/elements/Toaster";


function App() {
  const [configFetched, setConfigFetched] = useState(false);
  const [config, setConifg] = useState();
  const { i18n } = useTranslation();
  document.getElementsByTagName("html")[0].dir = i18n.dir();

  useEffect(() => {
    axios
      .get("config/appConfig.json")
      .then(function (res) {
        var appconfig = res.data;
        setConifg(appconfig);
        setConfigFetched(true);
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred config in appjs");
      });
  }, []);

  return (
    config &&
    configFetched && (
      <ConfigProvider>
        <AuthProvider>
          <Router>
            <Toaster />
            <Main />
           
          </Router>
        </AuthProvider>
      </ConfigProvider>
    )
  );
}

export default App;
