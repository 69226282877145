import React from "react";
import { ErrorMessage } from "formik";

export default function Error({ errors, touched, name }) {
  return (
    errors[name] &&
    touched[name] && (
      <span className="error">
        <ErrorMessage name={name} />
      </span>
    )
  );
}
