import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import HeadingMain from "../../assets/elements/HeadingMain";
//import { useTranslation } from 'react-i18next';
import "./UserDetails.scss";
import Map from "../../assets/map/Map";
import closeicon from "../../assets/images/icons/close.svg";
import MainServices from "../../services/MainServices";
import ViewDocumentLarge from "./ViewDocumentLarge";
import { useLocation, useNavigate } from "react-router-dom";
import UserStatusChange from "./UserStatusChange";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { useTranslation } from "react-i18next";
import { PiChatText } from "react-icons/pi";
import useAuth from "../../hooks/useAuth";

const UserDetails = () => {
  const [crDocument, setCrDocument] = useState();
  const [vatDocument, setVatDocument] = useState();
  const [viewDocumentInLarge, setViewDocumentInLarge] = useState(false);
  const [documentInCurrentView, setDocumentInCurrentView] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [userAddress, setUserAddress] = useState();
  const [statusClicked, setStatusClicked] = useState(false);
  const [isUpdateStatusInProgress, setIsUpdateStatusInProgress] = useState();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("user");
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    viewFile,
    getUserProfileById,
    getUserAddressById,
    updateUserStatusById,
  } = MainServices();

  let { name, mobileNumber, email } = userDetails || {};

  let {
    address = "-" + t("Not provided") + "-",
    businessName = "-" + t("Not provided") + "-",
    latitude,
    longitude,
    postalCode,
    state,
    city,
    country,
    mapLocation = "-" + t("Not provided") + "-",
  } = userAddress || {};

  const getCrDocumetFile = (id) => {
    if (!id) return;

    const documentUrl = `https://balloons-dezen.s3.ap-south-1.amazonaws.com/${id}`;

    setCrDocument({ url: documentUrl, type: "external-link" });
  };

  const getVatDocumetFile = (id) => {
    if (!id) return;

    const documentUrl = `https://balloons-dezen.s3.ap-south-1.amazonaws.com/${id}`;

    setVatDocument({ url: documentUrl, type: "external-link" });
  };

  const getSupplierDocuments = (supplier) => {
    if (!supplier) return;
    getCrDocumetFile(supplier.crDocumentId);
    getVatDocumetFile(supplier.vatDocumentId);
  };

  const getSupplierDetails = async (publicId) => {
    if (!publicId) return;
    await getUserProfileById(publicId).then((res) => {
      setUserDetails({ ...res.data });

      getSupplierDocuments(res.data.supplier);
    });
    await getUserAddressById(publicId).then(async (res) => {
      if (res.data.length === 0) return;

      const mapLocation = await Map.getMapLocation(
        res.data[0].latitude,
        res.data[0].longitude
      );
      setUserAddress({ ...res.data[0], mapLocation });
    });
  };

  const openViewDocument = (document) => {
    setDocumentInCurrentView(document);
    setViewDocumentInLarge(true);
  };

  const updateUserStatus = async (values) => {
    if (!publicId || !values) return;
    setIsUpdateStatusInProgress(true);

    await updateUserStatusById(
      { status: values.status },
      publicId,
      userDetails.concurrencyStamp
    ).then((res) => {
      if (res.success) {
        notifySuccess("Status updated!");
        getSupplierDetails(publicId);
        setStatusClicked(false);
      } else {
        notifyError("Status update failed!");
      }
      setIsUpdateStatusInProgress(false);
    });
  };

  useEffect(() => {
    getSupplierDetails(publicId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="user-details-main">
      {statusClicked && (
        <UserStatusChange
          open={true}
          publicId={userDetails?.publicId}
          setStatusClicked={setStatusClicked}
          updateUserStatus={updateUserStatus}
          userCurrentStatus={userDetails.status}
          isUpdateStatusInProgress={isUpdateStatusInProgress}
        />
      )}
      {viewDocumentInLarge && (
        <ViewDocumentLarge
          open={true}
          setViewDocumentInLarge={setViewDocumentInLarge}
          document={documentInCurrentView}
        />
      )}

      <div className="heading">
        <HeadingMain
          //className="product-details-primary-heading"
          text={t("Supplier Details")}
        />
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </div>

      <div className="details-container">
        <div
          className="status-to-right"
          style={i18n.dir() === "rtl" ? { left: "0", right: "unset" } : {}}
        >
          <div className="sticky-div">
            <div className="section status">
              <div
                className="chat-icon"
                onClick={() => {
                  navigate({
                    pathname: user?.role === "supplier" ? "/chat" : "/settings",
                    search: `?supplierId=${publicId}&status=Notifications`,
                  });
                }}
              >
                <PiChatText />
              </div>

              <div className="current-status">
                <span
                  className={
                    userDetails?.status === "deleted"
                      ? "inactive"
                      : userDetails?.status?.toLowerCase()
                  }
                >
                  {t(
                    userDetails?.status === "deleted"
                      ? "inactive"
                      : userDetails?.status
                  )}
                </span>
              </div>
              <div
                className="change-btn"
                onClick={() => {
                  setStatusClicked(true);
                }}
              >
                <PrimaryButton title="Change Status" />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <p className="title">{t("Personal Details")}:</p>
          <p className="subtitle">{t("Supplier Name")}</p>
          <p>{businessName}</p>
          <p className="subtitle">{t("Supplier Description")}</p>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
        </div>
        <div className="line"></div>
        <div className="section">
          <p className="title">{t("Contact Details")}</p>
          <p className="subtitle">{t("Owner Name")}</p>
          <p>{name}</p>
          <p className="subtitle">{t("Mobile Number")}</p>
          <p>{mobileNumber}</p>
          <p className="subtitle">{t("Email Address")}</p>
          <p>{email}</p>
          <p className="subtitle">{t("Website")}</p>
          <p>www.sweetshop.com</p>
        </div>
        <div className="line"></div>
        <div className="section documents">
          <p className="title">{t("Documents")}</p>
          {!crDocument && !vatDocument ? (
            <p>User hasn't uploaded any documents yet.</p>
          ) : (
            <>
              {crDocument && (
                <div className="each-document">
                  <div className="view">
                    <Button
                      className="btn"
                      onClick={() => openViewDocument(crDocument)}
                    >
                      {t("View")}
                    </Button>
                  </div>
                  {crDocument.type === "external-link" ? (
                    crDocument?.url.includes(".pdf") ? (
                      <div
                        className="previewdoc d-flex align-items-center justify-content-center"
                        style={{ border: "1px solid black" }}
                      >
                        <p
                          className="text-center text-bold m-0"
                          style={{ fontWeight: "bold" }}
                        >
                          Pdf Document
                        </p>
                      </div>
                    ) : (
                      <img
                        src={crDocument.url}
                        alt={crDocument.name}
                        className="previewdoc"
                      />
                    )
                  ) : (
                    <object
                      data={URL.createObjectURL(crDocument)}
                      className="previewdoc"
                    >
                      {crDocument.name}
                    </object>
                  )}
                  <div>
                    <p className="title">CR {t("document")}</p>
                  </div>
                </div>
              )}
              {vatDocument && (
                <div className="each-document">
                  <div className="view">
                    <Button
                      className="btn"
                      onClick={() => openViewDocument(vatDocument)}
                    >
                      {t("View")}
                    </Button>
                  </div>
                  {vatDocument.type === "external-link" ? (
                    vatDocument?.url.includes(".pdf") ? (
                      <div
                        className="previewdoc d-flex align-items-center justify-content-center"
                        style={{ border: "1px solid black" }}
                      >
                        <p
                          className="text-center text-bold m-0"
                          style={{ fontWeight: "bold" }}
                        >
                          Pdf Document
                        </p>
                      </div>
                    ) : (
                      <img
                        src={vatDocument.url}
                        alt={vatDocument.name}
                        className="previewdoc"
                      />
                    )
                  ) : (
                    <object
                      data={URL.createObjectURL(vatDocument)}
                      className="previewdoc"
                    >
                      {vatDocument.name}
                    </object>
                  )}
                  <div>
                    <p className="title">VAT {t("document")}</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="line"></div>
        <div className="section address">
          <div className="text">
            <p className="title">{t("Physical Address")}</p>
            {userAddress ? (
              <>
                <p className="subtitle">{t("Location in Map")}:</p>
                <p>{mapLocation}</p>
                <p className="subtitle">{t("Full Address")}:</p>
                <p>{address}</p>
                <p className="subtitle">{t("City")}:</p>
                <p>{city}</p>
                <p className="subtitle">{t("State")}:</p>
                <p>{state}</p>
                <p className="subtitle">{t("Country")}:</p>
                <p>{country}</p>
                <p className="subtitle">{t("Postal Code")}:</p>
                <p>{postalCode}</p>
              </>
            ) : (
              <p>-{t("Not provided")}-</p>
            )}
          </div>
          <div className="map">
            {latitude && longitude && (
              <Map
                isVisible={true}
                zoom={15}
                readOnly={true}
                latLngCoordinatesObject={{ lat: latitude, lng: longitude }}
              />
            )}
          </div>
        </div>
      </div>

      {/* <div className="save-btn-float" onClick={toggle}>
                <PrimaryButton title="Save" />
            </div> */}
    </div>
  );
};

export default UserDetails;
