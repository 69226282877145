import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";
const LazyComponent = lazy(() => import("./CreateQuotation.jsx"));

export default function Profile() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
