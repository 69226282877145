import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Restaurant.scss";
import moreoptionsicon from "../../assets/images/icons/more-options.svg";
import editicon from "../../assets/images/icons/edit-curved.svg";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../assets/elements/DeleteModal";
import MainServices from "../../services/MainServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import { Col } from "reactstrap";

//import UpdateProductPrice from "./UpdateProductPrice";

export default function Restaurant({ index, eachRestaurant, userLanguage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [actionToggle, setActionToggle] = useState(false);
  const actionRef = useRef(null);
  const { user } = useAuth();
  const current = eachRestaurant;
  const [deleteModal, setDeleteModal] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isDeleted, setIsDeleted] = useState();
  const { updateRestaurantStatus } = MainServices();
  
  if(userLanguage !== "en")
  {
    let languageSpecificDetailsObject = {};
    languageSpecificDetailsObject = eachRestaurant?.productLanguages?.find(languageSpecificDetails => languageSpecificDetails.locale === userLanguage);
    console.log("lsO", languageSpecificDetailsObject)
    languageSpecificDetailsObject && Object.keys(languageSpecificDetailsObject).forEach(key => {
      if(!languageSpecificDetailsObject[key])
      {
        delete languageSpecificDetailsObject[key];
      }
    })
    eachRestaurant = {...eachRestaurant, ...languageSpecificDetailsObject}
  }

  useEffect(() => {
    function actionHandle(event) {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setActionToggle(false);
      }
    }
    document.addEventListener("mousedown", actionHandle);
    return () => {
      document.removeEventListener("mousedown", actionHandle);
    };
    // eslint-disable-next-line
  }, [actionRef]);

  const onActionToggle = () => {
    setActionToggle((prev) => !prev);
  };

  const onProductNavigate = () => {
    navigate({
      pathname: "/catering/add-restaurant",
      search: `?publicId=${eachRestaurant.publicId}`,
    });
  };

  const handleDeleteModalToggle = () => {
    setIsLoading(false);
    setDeleteModal(!deleteModal);
  };

  const deleteRestaurant = async () => {
    setIsLoading(true);
    const details = {
      status: "inactive",
    };
    await updateRestaurantStatus(
      details,
      eachRestaurant.publicId,
      eachRestaurant.concurrencyStamp
    )
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            setIsLoading(false);
            handleDeleteModalToggle("");
            notifySuccess("Deleted Successfully!");
            setIsDeleted(true);
          }
        } else {
          setIsLoading(false);
          notifyError("Error occured!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        notifyError("Error occured!");
      });
  }

  return (
    <Col sm="12" md="6" lg="4" xxl="3" style={isDeleted && {display: "none"}}>
      <div 
        className="restaurant-each-product" 
        style={{marginBottom: "1em"}}
      >
        <div
          className="product-img"
          onClick={() =>
            navigate({
              pathname: "/catering/restaurant-details",
              search: `?publicId=${eachRestaurant.publicId}`,
            })
          }
        >
          <img src={current?.restaurantImages[0]?.images} alt="Restaurant" />
        </div>
        <div className="action-list-icon" onClick={onActionToggle}>
          <img src={moreoptionsicon} alt="more" />
        </div>
        {actionToggle && (
          <div className="actions-list" ref={actionRef}>
            {user.role === "supplier" && 
              <>
              <div className="action edit" onClick={onProductNavigate}>
                <span>{t("Edit")}</span>
                <img src={editicon} alt="edit" className="action-icon" />
              </div>
              <hr className="actions-hr" />
              </>
            }
            <div class="action delete" onClick={handleDeleteModalToggle}>
              <span>{t("Delete")}</span>
              <img src={deleteicon} alt="edit" className="action-icon" />
            </div>
          </div>
        )}

        <div className="name-and-actions">
          <h6
            className="product-name"
            onClick={() =>
              navigate({
                pathname: "/products/product-details",
                search: `?productId=${eachRestaurant.publicId}`,
              })
            }
          >
            {eachRestaurant.name}
          </h6>
        </div>
        {eachRestaurant.description && (
          <div className="product-description">
            <p>{eachRestaurant.description}</p>
          </div>
        )}
        {user.role === "admin" && (
          <div className="product-details">
            <p className="category-type">Category</p>
            <p className="category-details">Chinese</p>
          </div>
        )}
        <div className="product-details">
          <p className="category-type">Type</p>
          <p className="category-details">Dining</p>
        </div>
        <div className="product-details">
          <p className="category-type">City</p>
          <p className="category-details">{eachRestaurant?.cityName}</p>
        </div>
        <div className="product-details">
          <p className="category-type">Country</p>
          <p className="category-details">{eachRestaurant?.countryName}</p>
        </div>
        <DeleteModal
          handleDeleteButton={handleDeleteModalToggle}
          deleteModal={deleteModal}
          currentRow={eachRestaurant}
          cancelButtonModal={handleDeleteModalToggle}
          deleteRow={deleteRestaurant}
          loading={isLoading}
        />
      </div>
    </Col>
  );
};