import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";
const LazyComponent = lazy(() => import("./Photography.jsx"));
const LazyComponentCategory = lazy(() => import("./PhotographyCategory.jsx"));
const LazyComponentDetails = lazy(() => import("./PhotographyDetails.jsx"));


export default function Photography() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}

export const PhotographyCategory = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponentCategory />
    </Suspense>
  );
}

export const PhotographyDetails = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponentDetails />
    </Suspense>
  );
}
