import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeCompatibleDroppable from "./StrictModeCompatibleDroppable";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import MainServices from "../../services/MainServices";
import { notifySuccess, notifyError } from "../../assets/elements/Toaster";
import "./ReorderImages.scss";
import { urlToFileName } from "../../assets/functions/helpers";

const ReorderImages = ({
  reorderModal,
  toggleReorderModal,
  publicId,
  productImages,
  t,
}) => {
  const [updatedImages, setUpdatedImages] = useState([]);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState();
  const { getProductById, updateProductById } = MainServices();

  useEffect(() => {
    setUpdatedImages(productImages);
    // eslint-disable-next-line
  }, [productImages?.length]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(updatedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setUpdatedImages(items);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "aqua" : "lightgrey",
    width: "fit-content",
    padding: "1em",
    overflow: "auto",
  });

  /* const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '1em',
    margin: `0 8px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'red' : 'grey',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  }); */

  const updateImagesOrder = async () => {
    setIsUpdateInProgress(true);

    let latestConcurrencyStamp = await getProductById(publicId)
      .then((res) => res.data.concurrencyStamp)
      .catch((error) => console.log(error));
    console.log(updatedImages);
    let images = updatedImages?.length
      ? updatedImages.map((_) => urlToFileName(_))
      : [];
    console.log(images);
    const details = {
      productImages: images,
    };
    updateProductById(details, publicId, latestConcurrencyStamp)
      .then((res) => {
        if (res.success) {
          notifySuccess("Updated Successfully!");
          setIsUpdateInProgress(false);
          /* navigate({
            pathname: "/products/product-details",
            search: `?productId=${res.publicId}`,
        }); */
        } else {
          notifyError("Failed!");
          //setErrorsOnSubmit((oldValues) => oldValues.concat( res.details?.map(item => item.name+" "+item.message) ) );
          setIsUpdateInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Update Failed");
        //setErrorsOnSubmit((oldValues) => [...oldValues, error] );
        setIsUpdateInProgress(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={reorderModal}
        size="lg"
        centered
        toggle={() => toggleReorderModal("")}
      >
        <ModalHeader
          toggle={() => toggleReorderModal("")}
          className="modal-header-custom"
        >
          {t("Reorder Images")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="reorder-main">
            <div className="content">
              {productImages?.length > 0 && (
                <>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <StrictModeCompatibleDroppable droppableId="list">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          className="images-div"
                        >
                          {updatedImages.map((item, index) => {
                            console.log(item);
                            return (
                              <Draggable
                                key={item + index}
                                draggableId={item + index}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={"each-div"}
                                    //style={getItemStyle(snapshot.isDragging,
                                    //provided.draggableProps.style)}
                                  >
                                    <p>{index + 1}</p>
                                    <img src={item} alt="product" />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeCompatibleDroppable>
                  </DragDropContext>
                </>
              )}

              <div className="reorder-input-form">
                {/* <Formik
                        initialValues={{
                        ...formikValues,
                        brandName: currentBrand.name || "",
                        brandDescription: currentBrand.description || "",

                        languages: [],
                        }}
                        onSubmit={(values) => {
                        currentBrand ? updateBrandList(values) : reorder(values);
                        }}
                        validationSchema={reorderSchema}
                        enableReinitialize
                    >
                        {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        touched,
                        handleSubmit,
                        }) => (
                        <Form>
                            <FormGroup>
                            <div className="input-container">
                                <Row>
                                <Label for="brand-name">{t("Brand Name")}</Label>
                                <Input
                                    id="brand-name"
                                    name="brandName"
                                    value={values.brandName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter brand name"
                                />
                                <ErrorMessage
                                    errors={errors}
                                    touched={touched}
                                    name="brandName"
                                />
                                </Row>
                            </div>
                            </FormGroup>
                            {availableLanguages.map((language) => {
                            return (
                                <>
                                <FormGroup>
                                    <div className="input-container">
                                    <Row>
                                        <Label
                                        for={"brand-name" + language.value}
                                        >{`${t("Brand Name")} ${t("in")} ${t(
                                        language.name
                                        )} (${language.symbol})`}</Label>
                                        <Input
                                        id="brand-name"
                                        name={"brandName_" + language.value}
                                        value={values["brandName_" + language.value]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`${t("Enter brand name")} ${t(
                                            "in"
                                        )} ${t(language.name)} (${language.symbol})`}
                                        />
                                    </Row>
                                    </div>
                                </FormGroup>
                                </>
                            );
                            })}
                            <FormGroup>
                            <div className="input-container">
                                <Row>
                                <div className="input-title">
                                    <Label for="brand-description">
                                    {t("Brand Description")}
                                    </Label>
                                    <p>{descriptionCountArray[0] || 0}/100</p>
                                </div>

                                <Field
                                    className="input-textarea"
                                    name="brandDescription"
                                    id="brand-description"
                                    as="textarea"
                                    cols="30"
                                    rows="5"
                                    value={values.brandDescription}
                                    onChange={(e) => {
                                    handleChange(e);
                                    const newDescriptionCountArray = [
                                        ...descriptionCountArray,
                                    ];
                                    newDescriptionCountArray[0] =
                                        e.target.value.length;
                                    setDescriptionCountArray(
                                        newDescriptionCountArray
                                    );
                                    }}
                                    maxLength={100}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    touched={touched}
                                    name="brandDescription"
                                />
                                </Row>
                            </div>
                            </FormGroup>
                            {availableLanguages.map((language, index) => {
                            return (
                                <>
                                <FormGroup>
                                    <div className="input-container">
                                    <Row>
                                        <div className="input-title">
                                        <Label for="brand-description">
                                            {`${t("Brand Description")} ${t("in")} ${t(
                                            language.name
                                            )} (${language.symbol})`}
                                        </Label>
                                        <p>
                                            {descriptionCountArray[index + 1] || 0}/100
                                        </p>
                                        </div>

                                        <Field
                                        className="input-textarea"
                                        name={"brandDescription_" + language.value}
                                        id="brand-description"
                                        as="textarea"
                                        cols="30"
                                        rows="5"
                                        value={
                                            values["brandDescription_" + language.value]
                                        }
                                        onChange={(e) => {
                                            handleChange(e);
                                            const newDescriptionCountArray = [
                                            ...descriptionCountArray,
                                            ];
                                            newDescriptionCountArray[index + 1] =
                                            e.target.value.length;
                                            setDescriptionCountArray(
                                            newDescriptionCountArray
                                            );
                                        }}
                                        maxLength={100}
                                        placeholder={`${t(
                                            "Enter brand description"
                                        )} ${t("in")} ${t(language.name)} (${
                                            language.symbol
                                        })`}
                                        />
                                    </Row>
                                    </div>
                                </FormGroup>
                                </>
                            );
                            })}
                            <FormGroup>
                            <div className="input-container">
                                <Row>
                                <Label>{t("Upload Brand Logo")}</Label>
                                <UploadEachDocument
                                    handleChangeDocument={handleChangeLogo}
                                    documentFile={logoFile}
                                    fileName="logo"
                                    onlyImage={true}
                                    accept="image/*"
                                />
                                </Row>
                            </div>
                            </FormGroup>

                            <div className="errors-after-sumbit">
                            {errorsOnSubmit.map((error) => (
                                <p>{error}</p>
                            ))}
                            </div>

                            <div className="save-btn-container">
                            <PrimaryButton
                                type="submit"
                                title="Save"
                                isLoading={reorderInProgress}
                            />
                            </div>
                            <div className="save-btn-float"></div>
                        </Form>
                        )}
                    </Formik> */}
              </div>
            </div>
            <div className="save-btn-container">
              <PrimaryButton
                type="submit"
                title="Update"
                isLoading={isUpdateInProgress}
                // onClick={updateImagesOrder}
              />
            </div>
            <div className="save-btn-float"></div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ReorderImages;
