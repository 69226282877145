import { React, useEffect, useState, useRef } from "react";
//import "./AddProduct.scss";
import { Form, Input, Label, FormGroup, Row, Button } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { BsCamera, BsCameraVideo } from "react-icons/bs";
import { RxAvatar } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import "react-quill/dist/quill.snow.css";
import MainServices from "../../services/MainServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import closeicon from "../../assets/images/icons/close.svg";
import { ChromePicker } from "react-color";
import AsyncSelect from "react-select/async";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import "react-dropdown-tree-select/dist/styles.css";
import { components } from "react-select";
import { urlToFileName } from "../../assets/functions/helpers";
import CustomModal from "../../assets/elements/CustomModal";
import ImageCropper from "../../assets/functions/imageCropper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LocationInMap from "../../assets/map/LocationInMap";

export default function AddPhotography() {
  const hiddenImageUploader = useRef(null);
  const hiddenProfileImageUploader = useRef(null);
  const hiddenVideoUploader = useRef(null);
  const [isLoading, setIsLoading] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const publicId = new URLSearchParams(location.search).get("publicId");
  let isEditing = publicId ? true : false;
  const [isCurrentPhotoshootLoaded, setIsCurrentPhotoshootLoaded] =
    useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const formikFieldsSchema = ["name", "description", "location"];
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const [formikValues, setFormikValues] = useState(
    getFormikValuesForAllLanguages(formikFieldsSchema)
  );
  const [currentImage, setCurrentImage] = useState("");
  const [currentImageType, setCurrentImageType] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [cropImageModal, setCropImageModal] = useState(false);

  const types = ["photos", "videos", "both"];

  //hooks
  const { t, i18n } = useTranslation();
  const { deBounce } = TimingFunctions();

  const {
    savePhotoshoot,
    fileUpload,
    getCountries,
    getStatesByCountry,
    getPhotographyCategories,
    getPhotoshootDetails,
    updatePhotoshootById,
  } = MainServices();

  //functions
  const addPhotographySchema = Yup.object().shape({
    category: Yup.array()
      .min(1, t("Select a category"))
      .required(t("Select a category")),
    name: Yup.string().required(`${t("Restaurant Name")} ${t("is required")}`),
    description: Yup.mixed().required(
      `${t("Description")} ${t("is required")}`
    ),
    image: Yup.mixed().required(`${t("Image")} ${t("is required")}`),
    profile: Yup.mixed().required(`${t("Profile Image")} ${t("is required")}`),
    location: Yup.mixed().required(`${t("Address")} ${t("is required")}`),
    country: Yup.string().required(`${t("Country")} ${t("is required")}`),
    city: Yup.string().required(`${t("City")} ${t("is required")}`),
    latitude: Yup.number().required("Pick a location"),
    longitude: Yup.number().required("Pick a location"),
    maxPrice: Yup.number().required(`${t("Max Price")} ${t("is required")}`),
    sellingPrice: Yup.number()
      .positive()
      .required(`${t("Selling Price")} ${t("is required")}`),
    costPrice: Yup.number().required(`${t("Cost Price")} ${t("is required")}`),
  });

  const getAndSetCountryList = () => {
    getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getAndSetStateList = (countryId) => {
    getStatesByCountry({ countryId }).then((res) => {
      setStateList(res.data);
    });
  };

  const handleChangeCountry = (countryId) => {
    //const countryId = e.target.value;
    if (!countryId) {
      setStateList([]);
      return;
    }
    getAndSetStateList(countryId);
  };

  const handleSave = async (values) => {
    console.log(values);
    setIsLoading(true);
    setErrorsOnSubmit([]);
    console.log(values);
    if (!values.photoshootImages.length) {
      setErrorsOnSubmit(["Please upload valid image"]);
      setIsLoading(false);
      return;
    }
    let imagesUploadResponse = await fileUpload(values.photoshootImages).catch(
      (error) => setIsLoading(false)
    );
    let profileUploadResponse = await fileUpload(values.profile).catch(
      (error) => setIsLoading(false)
    );
    console.log("imgR----", imagesUploadResponse);
    const responseToArray = imagesUploadResponse?.fileName?.split(",");

    if (!imagesUploadResponse.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Image upload failed: File too large",
        ]);
      // console.log("imgREs", imagesUploadResponse);
      setIsLoading(false);
      return;
    }
    if (!profileUploadResponse?.success) {
      //notifyError("Image Not Provided!");
      if (profileUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          profileUploadResponse?.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Profile Image upload failed: File too large",
        ]);
      setIsLoading(false);
      return;
    }

    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    const country_name = countryList?.find(
      (countryObj) => countryObj.id == values.country
    )?.name;
    const city_name = stateList?.find(
      (stateObj) => stateObj.id == values.city
    )?.name;

    const details = {
      name: values.name,
      description: values.description,
      lat: values.latitude,
      lang: values.longitude,
      address: values.currentaddress,
      location: values.location,
      country: Number(values.country),
      country_name,
      city: Number(values.city),
      city_name,
      category: values.category?.map((categoryObj) => categoryObj.value),
      profile: profileUploadResponse?.fileName,
      photoshootImages: responseToArray,
      languages,
      maxPrice: values.maxPrice,
      costPrice: values.costPrice,
      sellingPrice: values.sellingPrice,
      status: "active",
    };
    console.log("details", details);
    savePhotoshoot(details)
      .then(async (res) => {
        console.log("rest-add-res", res);
        if (res.success) {
          notifySuccess("New Photoshoot Added!");
          setIsLoading(false);
          navigate({
            pathname: "/photography/photography-details",
            search: `?publicId=${res.publicId}`,
          });
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleUpdate = async (values) => {
    console.log(values);
    setIsLoading(true);
    setErrorsOnSubmit([]);
    console.log(values);
    if (!values.photoshootImages.length) {
      setErrorsOnSubmit(["Please upload valid product image"]);
      setIsLoading(false);
      return;
    }
    let existingImageURLs = values.photoshootImages.filter(
      (file) => typeof file === "string"
    );
    existingImageURLs = existingImageURLs.map((_) => urlToFileName(_));
    let newImageFiles = values.photoshootImages.filter(
      (file) => typeof file !== "string"
    );
    //console.log("newImg", newImageFiles);
    let imagesUploadResponse = "";
    let responseToArray = existingImageURLs;
    if (newImageFiles.length) {
      imagesUploadResponse = await fileUpload(newImageFiles).catch(() =>
        setIsLoading(false)
      );
      responseToArray = responseToArray.concat(
        imagesUploadResponse.fileName?.split(",")
      );
    }

    if (newImageFiles.length > 0 && !imagesUploadResponse?.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      //console.log("imgREs", imagesUploadResponse);
      setIsLoading(false);
      return;
    }
    let profile = values.profile;
    if(typeof values.profile !==  "string")
    {
      let profileUploadResponse = await fileUpload(values.profile).catch(() =>
        setIsLoading(false)
      );
      if(!profileUploadResponse.success)
      {
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Profile Image upload failed",
        ]);
        setIsLoading(false);
        return;
      }
      profile = await profileUploadResponse?.fileName;
    }
    console.log("profile", profile)
    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    const country_name = countryList?.find(
      (countryObj) => countryObj.id == values.country
    )?.name;
    const city_name = stateList?.find(
      (stateObj) => stateObj.id == values.city
    )?.name;

    const details = {
      name: values.name,
      description: values.description,
      lat: values.latitude,
      lang: values.longitude,
      address: values.currentaddress,
      location: values.location,
      country: Number(values.country),
      country_name,
      city: Number(values.city),
      city_name,
      category: values.category?.map((categoryObj) => categoryObj.value),
      profile,
      photoshootImages: responseToArray,
      languages,
      maxPrice: values.maxPrice,
      costPrice: values.costPrice,
      sellingPrice: values.sellingPrice,
      status: "active",
    };
    console.log("details", details);
    let latestConcurrencyStamp = await getPhotoshootDetails(publicId).then(
      (res) => res.data.concurrencyStamp
    );
    updatePhotoshootById(details, publicId, latestConcurrencyStamp)
      .then(async (res) => {
        console.log("rest-add-res", res);
        if (res.success) {
          notifySuccess("Photoshoot Updated!");
          setIsLoading(false);
          navigate({
            pathname: "/photography/photography-details",
            search: `?publicId=${publicId}`,
          });
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsLoading(false);
        console.log(error);
      });
  };

  //get categories

  const GroupHeading = (props, setFieldValue, values) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <components.GroupHeading
          {...props}
          onClick={() => {
            //console.log(props);
            setFieldValue("category", [...values.category, props.data]);
          }}
          style={{
            cursor: "pointer",
            color: "#D60000",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: 18,
          }}
        />
      </div>
    );
  };

  const getCategoriesListOptions = async (filters, isFirst) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getPhotographyCategories(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((category) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                category?.category_languages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                label: languageSpecificDetailsObject?.name || category.name,
                value: category.id,
              };
            });
            isFirst && setCategoryList(resData);
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const loadOptionsForCategory = async (inputValue, callback) => {
    if (!inputValue) {
      callback(categoryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    let options = await getCategoriesListOptions(filter);
    callback(options);
  };

  const loadDebounceOptionsForCategory = (inputValue, callback) => {
    deBounce(() => loadOptionsForCategory(inputValue, callback), 1000);
  };

  const getExistingPhotoshootData = async () => {
    getPhotoshootDetails(publicId)
      .then(async (res) => {
        if (res.success) {
          const photoshootData = {
            ...res.data,
            city_name: res?.data?.cityName,
            country_name: res?.data?.countryName,
            latitude: res?.data?.lat,
            longitude: res?.data?.lang,
            photoshootImages:
              res?.data?.photoshootImages?.map((obj) => obj.images) || [],
            image: res?.data?.photoshootImages[0]?.images,
            category: [],
            location: res?.data?.location,
            currentaddress: res?.data?.address,
            //image: res.data?.productImages.length > 0 ? "image" : "",
          };

          getAndSetStateList(res?.data?.country);
          let categoryList = await getCategoriesListOptions(
            [{ key: "status", eq: "active" }],
            true
          );
          res?.data?.category?.forEach((categoryId) => {
            console.log("ca", categoryList);
            let matchingCategory = categoryList.find((categoryObj) => {
              return categoryObj.value == categoryId;
            });
            photoshootData.category.push(matchingCategory);
          });

          // To populate multi-language fields(fixed fields)
          if (res.data?.photoshootLanguages) {
            let photoshootLanguages = res.data?.photoshootLanguages;
            availableLanguages.forEach((language) => {
              let languageSpecificObject = photoshootLanguages.find(
                (languageObject) => languageObject.locale === language.value
              );
              formikFieldsSchema.forEach((formikField) => {
                photoshootData[formikField + "_" + language.value] =
                  languageSpecificObject[formikField];
                //photoshootData[formikField] = productLanguages
              });
            });
          }

          /* setRestaurantImageFiles(
            res.data?.restaurantImages?.map((imgObject) => imgObject.images)
          ); */
          setFormikValues(photoshootData);
          setIsCurrentPhotoshootLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategoriesListOptions([{ key: "status", eq: "active" }], true);
    getAndSetCountryList();
    if (isEditing) {
      getExistingPhotoshootData();
    }
    /* getBrandOptions([{ key: "status", eq: "active" }], true);
    if (isEditing) {
      getExistingPhotoshootData();
    } */
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategoriesListOptions([{ key: "status", eq: "active" }], true);
    // eslint-disable-next-line
  }, [i18n.language]);

  //async select style
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#f5f2ed",
      padding: "6px 5px",
      marginBottom: "12px",
      paddingLeft: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      zIndex: "2",
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#f5f2ed" : "white",
        color: "#000000",
        cursor: "pointer",
        padding: "10px 10px 10px 20px",
        //textTransform: "capitalize",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "5px",
    }),
  };

  const handleChooseVideo = (e) => {
    // const fileUploaded = event.target.files[0];
    // setProductImageFiles(fileUploaded);
    //console.log("images", productImageFiles);
    //setProductImageFiles(oldFiles => [...oldFiles, ...e.target.files]);
  };
  const handleProfileImageUploaderClick = () => {
    hiddenProfileImageUploader.current.click();
  };
  const handleImageUploaderClick = () => {
    hiddenImageUploader.current.click();
  };
  const handleVideoUploaderClick = () => {
    hiddenVideoUploader.current.click();
  };

  const handleChooseImage = (e) => {
    setCurrentImage(e.target.files[0] || "");
    if (e.target.files[0]) setCropImageModal(true);
  };

  const onGetCroppedImage = (setFieldValue, image) => {
    setCroppedImage(image);
  };

  const onCropImageModal = (set) => {
    setCropImageModal(false);
    setCurrentImage("");
    setCurrentImageType("");
  };

  const onCropSave = (oldImages, setFieldValue) => {
    const newImages = [...oldImages, croppedImage];
    setFieldValue("photoshootImages", newImages);
    onCropImageModal();
  };

  return (
    <div className="add-photography-main product-main">
      <HeadingMain text={isEditing ? "Edit Photography" : "Add Photography"}>
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      {(isEditing ? isCurrentPhotoshootLoaded : true) && (
        <Formik
          initialValues={{
            name: "",
            image: "",
            category: [],
            city: "",
            country: "",
            profile: "",
            photoshootImages: [],
            currentaddress: "",
            location: "",
            longitude: "",
            latitude: "",
            description: "",
            maxPrice: "",
            costPrice: "",
            sellingPrice: "",
            ...formikValues,
            // To match user language if a label exists for that language
            // brandId: formikValues?.brandId?.value ? brandList.find(brand => brand.id === formikValues?.brandId?.value) : "",
          }}
          onSubmit={(values) => {
            isEditing ? handleUpdate(values) : handleSave(values);
          }}
          validationSchema={addPhotographySchema}
          //enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="uploaders">
                <div className="container">
                  <div className="file-uploader">
                    <div
                      className="product-file-uploader"
                      onClick={handleProfileImageUploaderClick}
                      onDrag={handleChooseImage}
                    >
                      <div className="camera-icon-bg">
                        <RxAvatar className="camera-icon" />
                      </div>
                    </div>
                    <input
                      type="file"
                      name="image"
                      ref={hiddenProfileImageUploader}
                      onChange={(e) => {
                        //handleChange(e);
                        setCurrentImageType("profile");
                        handleChooseImage(e);
                        setFieldValue("profile", e.target.files[0]?.name || "");
                      }}
                      accept="image/*"
                      hidden
                    />
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name="profile"
                    />
                    <h1 className="file-uploader-heading">{t("Profile Photo")}</h1>
                    <p className="file-uploader-text">
                      {t("Click above to upload profile photo")}
                    </p>
                  </div>
                  {values.profile && (
                    <div className="uploaded-images">
                      <div
                        className="image-box"
                      >
                        {typeof values.profile === "string" ? (
                          <img
                            className="view-image"
                            src={values.profile}
                            alt="product"
                          />
                        ) : (
                          <img
                            className="view-image"
                            src={URL.createObjectURL(values.profile)}
                            alt="product"
                          />
                        )}

                        <img
                          src={deleteicon}
                          alt="delete"
                          style={{
                            objectFit: "contain",
                            width: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFieldValue("profile", "");
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  )}
                  <br />
                </div>
              </div>
              <div className="uploaders">
                <div className="container">
                  <div className="file-uploader">
                    <div
                      className="product-file-uploader"
                      onClick={handleImageUploaderClick}
                      onDrag={handleChooseImage}
                    >
                      <div className="camera-icon-bg">
                        <BsCamera className="camera-icon" />
                      </div>
                    </div>
                    <input
                      type="file"
                      name="image"
                      ref={hiddenImageUploader}
                      onChange={(e) => {
                        //handleChange(e);
                        setCurrentImageType("");
                        handleChooseImage(e);
                        setFieldValue("image", e.target.files[0]?.name || "");
                      }}
                      accept="image/*"
                      hidden
                    />
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name="image"
                    />
                    <h1 className="file-uploader-heading">{t("Photos")}</h1>
                    <p className="file-uploader-text">
                      {t("Click above to upload photos")}
                    </p>
                  </div>
                  {values.photoshootImages?.length > 0 && (
                    <div className="uploaded-images">
                      {values.photoshootImages.map((file, index) => {
                        if (!file) return null;
                        return (
                          <div
                            key={
                              typeof file === "string"
                                ? file + index
                                : file.name + index + file.lastModified
                            }
                            className="image-box"
                          >
                            {typeof file === "string" ? (
                              <img
                                className="view-image"
                                src={file}
                                alt="product"
                              />
                            ) : (
                              <img
                                className="view-image"
                                src={URL.createObjectURL(file)}
                                alt="product"
                              />
                            )}

                            <img
                              src={deleteicon}
                              alt="delete"
                              style={{
                                objectFit: "contain",
                                width: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let newImageFiles = [
                                  ...values.photoshootImages,
                                ];
                                newImageFiles[index] = null;
                                newImageFiles = newImageFiles.filter(
                                  (file) => file !== null
                                );
                                if (newImageFiles.length === 0)
                                  setFieldValue("image", "");
                                setFieldValue(
                                  "photoshootImages",
                                  newImageFiles
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {currentImage && (
                    <Modal
                      isOpen={cropImageModal}
                      toggle={onCropImageModal}
                      size="lg"
                    >
                      <ModalHeader toggle={onCropImageModal}>
                        Crop Image
                      </ModalHeader>
                      <ModalBody>
                        {currentImage && (
                          <ImageCropper
                            image={currentImage}
                            onFileChange={onGetCroppedImage}
                            setFieldValue={setFieldValue}
                            imageUpdate={false}
                            aspectRatio={16 / 16}
                          />
                        )}

                        <div className="modal-btn-div">
                          <PrimaryButton
                            title="Save"
                            onClick={() => {
                                if(currentImageType === "profile")
                                {
                                  setFieldValue("profile", croppedImage);
                                  onCropImageModal();
                                  return;
                                }
                                else
                                  onCropSave(values.photoshootImages, setFieldValue)
                              }
                            }
                          />
                          <PrimaryButton
                            title="Cancel"
                            onClick={onCropImageModal}
                          />
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
                <div className="line" />
                <div className="container">
                  <div className="file-uploader">
                    <div
                      className="product-file-uploader"
                      onClick={handleVideoUploaderClick}
                      onDrag={handleChooseVideo}
                    >
                      <div className="camera-icon-bg">
                        <BsCameraVideo className="camera-icon" />
                      </div>
                    </div>
                    <input
                      type="file"
                      name="image"
                      ref={hiddenVideoUploader}
                      onChange={(e) => {
                        //handleChange(e);
                        handleChooseVideo(e);
                        //console.log("imgE", e);
                        //console.log("imgUp", hiddenImageUploader);
                        //console.log("ee", e)
                        //setFieldValue("image", e.target.files[0]?.name || "");
                      }}
                      accept="video/*"
                      hidden
                      multiple
                    />
                    <h1 className="file-uploader-heading">{t("Videos")}</h1>
                    <p className="file-uploader-text">
                      {t("Click above to upload videos")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="user-topcard-main">
                <h1 className="topcard-heading">{t("Basic Details")}</h1>
                <Form className="row">
                  {/* <Row>
                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="category">
                        {t("Select Category")}
                      </Label>
                      <AsyncSelect
                        defaultOptions={categoryList}
                        isMulti
                        onChange={(selectedCategories) =>
                          setFieldValue("category", selectedCategories)
                        }
                        loadOptions={loadDebounceOptionsForCategory}
                        className="select-dropdown"
                        styles={customStyles}
                        components={{
                          GroupHeading: (props) =>
                            GroupHeading(props, setFieldValue, values),
                        }}
                        value={values.category}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="category"
                      />
                      <br />
                    </FormGroup>

                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="services">
                        {t("Select Services")}
                      </Label>
                      <AsyncSelect
                        defaultOptions={categoryList}
                        isMulti
                        onChange={(selectedCategories) =>
                          setFieldValue("services", selectedCategories)
                        }
                        loadOptions={loadDebounceOptionsForCategory}
                        className="select-dropdown"
                        styles={customStyles}
                        components={{
                          GroupHeading: (props) =>
                            GroupHeading(props, setFieldValue, values),
                        }}
                        value={values.services}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="services"
                      />
                      <br />
                    </FormGroup>
                  </Row> */}
                  <Row>
                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="category">{t("Select Category")}</Label>
                      <AsyncSelect
                        defaultOptions={categoryList}
                        isMulti
                        onChange={(selectedCategories) =>
                          setFieldValue("category", selectedCategories)
                        }
                        loadOptions={loadDebounceOptionsForCategory}
                        className="select-dropdown"
                        styles={customStyles}
                        components={{
                          GroupHeading: (props) =>
                            GroupHeading(props, setFieldValue, values),
                        }}
                        value={values.category}
                        onBlur={() => setFieldTouched("category", true)}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="category"
                      />
                      <br />
                    </FormGroup>
                  </Row>

                  <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <Label for="name">{t("Name")}</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder={t("Enter") + " " + t("Name")}
                      type="text"
                      className="product-form-group"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="name"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <Label for="name">{`${t("Name")} ${t("in")} ${t(
                            language.name
                          )} (${language.symbol})`}</Label>
                          <Input
                            id="name"
                            name={"name_" + language.value}
                            placeholder={
                              t("Enter") +
                              " " +
                              t("Name") +
                              " " +
                              t("in") +
                              " " +
                              t(language.name)
                            }
                            type="text"
                            className="product-form-group"
                            value={values["name_" + language.value]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <div>
                    <br />
                  </div>
                  <FormGroup className="col-12 col-sm-6 short-description">
                    <Label for="description" className="title">
                      {t("Description")}
                      <p>{values.description?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="description"
                      as="textarea"
                      className="textarea"
                      value={values.description}
                      onChange={handleChange}
                      maxLength={250}
                      placeholder={`${t("Enter")} ${t("Description")}`}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="description"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 short-description">
                          <Label for="description" className="title">
                            {`${t("Description")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["description_" + language.value]
                                ?.length || 0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"description_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["description_" + language.value]}
                            onChange={handleChange}
                            placeholder={`${t("Enter")} ${t("Description")} ${t(
                              "in"
                            )} ${t(language.name)}`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <div>
                    <br />
                  </div>
                  <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <Label for="type">{t("Type")}</Label>
                    <select
                      name="type"
                      className="product-form-group select"
                      placeholder={t("--Select--")}
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {types.map((each) => {
                        return (
                          <option key={each} vlaue={each}>
                            {t(each)}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="type"
                    />
                  </FormGroup>
                </Form>
              </div>
              <div className="user-topcard-main">
                <h1 className="topcard-heading">{t("Address Details")}</h1>
                <Form className="row">
                  <LocationInMap
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    countryList={countryList}
                    stateList={stateList}
                    handleChangeCountry={handleChangeCountry}
                  />
                  {(
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="latitude"
                    />
                  ) || (
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="longitude"
                    />
                  )}
                  <div>
                    <br />
                  </div>
                  <FormGroup className="col-12 col-sm-6 short-description location">
                    <Label for="location" className="title">
                      {t("Enter Address")}
                      <p>{values.location?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="location"
                      as="textarea"
                      className="textarea"
                      value={values.location}
                      onChange={handleChange}
                      maxLength={250}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="location"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 short-description">
                          <Label for="location" className="title">
                            {`${t("Enter Address")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["location_" + language.value]?.length ||
                                0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"location_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["location_" + language.value]}
                            onChange={handleChange}
                            place={`${t("Enter Enter Address")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="country">{t("Country")}</Label>
                    <select
                      className="select product-form-group"
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={(e) => {
                        //setFieldValue("country", e.target.value);
                        handleChange(e);
                        handleChangeCountry(e.target.value);
                        //
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("city", "");
                      }}
                    >
                      <option value="">--Select--</option>
                      {countryList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="country"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="city">{t("City")}</Label>

                    <select
                      className="select product-form-group"
                      id="city"
                      name="city"
                      type="select"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">--Select--</option>
                      {stateList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="city"
                    />
                  </FormGroup>
                </Form>
              </div>
              <div className="class-usercard-flex">
                <div
                  className="product-bottomcard-main"
                  style={isEditing ? { display: "none" } : {}}
                >
                  <h1 className="user-heading">{t("Price Per Hour")}</h1>
                  <Form>
                    <div className="row">
                      <FormGroup className="col-12 col-md-6">
                        <Label for="sellingPrice">{t("Selling Price")}</Label>
                        <Input
                          id="sellingPrice"
                          name="sellingPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={
                            values.sellingPrice > -1 ? values.sellingPrice : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="sellingPrice"
                        />
                      </FormGroup>
                      <FormGroup className="col-12 col-md-6">
                        <Label for="tax">{t("Tax")}</Label>
                        <Input
                          id="tax"
                          name="tax"
                          placeholder={t("No Tax")}
                          type="number"
                          className="product-form-group"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <h1 className="user-heading">{t("Retail Sales")}</h1>
                      {/* <p className="user-text">
                        {t("Allow sales of this product at checkout")}.
                      </p> */}
                    </div>
                    <div className="row">
                      <FormGroup className="col-12 col-md-6">
                        <Label for="costPrice">{t("Cost Price")}</Label>
                        <Input
                          id="costPrice"
                          name="costPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={values.costPrice > -1 ? values.costPrice : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="costPrice"
                        />
                      </FormGroup>
                      <FormGroup className="col-12 col-md-6">
                        <Label for="maxPrice">{t("Max Price")}</Label>
                        <Input
                          id="maxPrice"
                          name="maxPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={values.maxPrice > -1 ? values.maxPrice : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="maxPrice"
                        />
                      </FormGroup>
                    </div>

                    {/* <FormGroup>
                    <Label for="tax">{t("Tax")}</Label>
                    <Input
                      id="tax"
                      name="tax"
                      placeholder={t("No Tax")}
                      type="number"
                      className="product-form-group"
                    />
                  </FormGroup> */}
                    {/* <div className="mt-4">
                      <h1 className="user-heading">
                        {t("Team member commission")}
                      </h1>
                      <p className="user-text">
                        {t(
                          "Calculate team member commission when the product is sold"
                        )}
                        .
                      </p>
                    </div> */}
                  </Form>
                </div>
              </div>
              <br />
              <div className="errors-after-sumbit">
                {errorsOnSubmit?.map((error) => (
                  <p>{error}</p>
                ))}
              </div>
              <div
                className="save-btn-float"
                onClick={isLoading ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Save"}
                  isLoading={isLoading}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
}
