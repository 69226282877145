import React from "react";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
//import ErrorMessage from "../../assets/elements/ErrorMessage";
import UploadEachDocument from "./UploadEachDocument";

const UploadDocuments = (props) => {
  const { t } = useTranslation();
  const { handleChangeDocument, documentFiles, existingDocuments } = props;
  console.log(existingDocuments);

  const renderView = (file, name) => {
    console.log(file);
    if (typeof file === "string") {
      if (file.toUpperCase().includes(".PDF") && (file.toUpperCase().includes("HTTP")) ) {
        return (
          <object
            data={file} //URL.createObjectURL(config.fileBasicPath + file)}
            className="previewdoc"
          >
            alt={name}
          </object>
        );
      } else if (
        file.toUpperCase().includes(".PNG") ||
        file.toUpperCase().includes(".JPG")
      ) {
        return <img src={file} alt={name} className="previewdoc" />;
      } else {
        return <p>{t(name)}</p>;
      }
    } else {
      return <p>{t(name)}</p>;
    }
  };

  return (
    <div className="documents formbox">
      <div className="textdescription">
        <p className="title">
          {t("Upload government documents to create an account")}
        </p>
        <p>({t("CR, VAT, agreement exp. date")})</p>
        <div className="existing-preview">
          {existingDocuments.map((item) => {
            if (!item.file) return null;
            return (
              <div key={item.name}>
                <p key={item.name} className="title">
                  {item.name} {t("document")}
                </p>
                {renderView(item.file, item.name)}
              </div>
            );
          })}
        </div>
      </div>
      <div className="inputsection">
        <FormGroup>
          <p className="title">{t("Upload Documents")}</p>
          <UploadEachDocument
            handleChangeDocument={handleChangeDocument}
            documentFile={documentFiles["cr"]}
            fileName="cr"
            acceptType=""
            isShow={true}
          />
          <UploadEachDocument
            handleChangeDocument={handleChangeDocument}
            documentFile={documentFiles["vat"]}
            fileName="vat"
            acceptType=""
            isShow={true}
          />
          {/* <ErrorMessage
            errors={errors}
            touched={touched}
            name="uploaddocuments"
          /> */}
        </FormGroup>
      </div>
    </div>
  );
};

export default UploadDocuments;
