import React, { useState, useEffect } from "react";
import "./StateList.scss";
import { BsFilter, BsArrowUp,BsArrowDown } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import TableSearch from "../../assets/elements/TableSearch";
import CustomPagination from "../../assets/elements/Pagination";
import HeadingMain from "../../assets/elements/HeadingMain";
import MainServices from "../../services/MainServices";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../assets/elements/Table";
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import {
  filtersToUri,
  filtersToUrlParams,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { notifySuccess } from "../../assets/elements/Toaster";
import editicon from "../../assets/images/icons/edit-curved.svg";

export default function CountryList() {
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchString, setSearchString] = useState("");
  const [mounted, setMounted] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [sorts, setSorts] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [countryId, setCountryId] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();
  const { t } = useTranslation();
  //get apis
  const { getCountries, getStatesByCountry, updateStateStatus } =
    MainServices();
  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setCountryId(changedParams?.countryId);
    setPagination(pagination);
    setSelectedStatus(filtersObj?.status?.status || "active");
    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    filters =
      filters && filters.length > 0
        ? filters
        : [{ key: "status", eq: "active" }];
    (mounted || changedParams.countryId) &&
      getStatesList({
        countryId: changedParams.countryId,
        page: pagination.page,
        limit: pagination.limit,
        filters,
        sort: sorts,
      });
    !mounted &&
      getCountryList({
        page: 1,
        limit: 100,
        countryFilters: [{ key: "status", eq: "active" }],
      });
    //eslint-disable-next-line
  }, [
    changedParams.limit,
    changedParams.page,
    changedParams.sort,
    changedParams.filters,
    changedParams.countryId
  ]);

  const getCountryList = async ({ page, limit, countryFilters }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters: countryFilters,
    };
    await getCountries(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((eachCountry) => {
              return {
                ...eachCountry,
                label: eachCountry.name,
                value: eachCountry.id,
              };
            });
            if (resData.length > 0) {
              let search = filtersToUrlParams({
                ...filters,
                countryId: resData[0].id,
                page: 1,
                limit: 10,
              });
              navigate({
                search,
              });
            }
            setCountries(resData);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStatesList = async ({ countryId, page, limit, filters, sort }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      countryId,
      sorting: sort,
    };
    await getStatesByCountry(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            setTotalCount(total);
            setStates(resData);
            setSearchLoading(false);
          }
        } else {
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  const updateStatus = async () => {
    setStatusLoading(true);
    const details = {
      status: selectedState.status === "active" ? "inactive" : "active",
      stateId: selectedState.id,
    };
    await updateStateStatus(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            notifySuccess("State Status Updated");
            setStatusLoading(false);
             let mfilters = filtersToUri({
               ...filters,
               status: { status: details.status },
             });
            let msorts = filtersToUri(sorts);
            let search = filtersToUrlParams({
              page: pagination.page,
              limit: pagination.limit,
              filters: mfilters,
              sort: msorts,
              countryId
            });
            navigate({ search });
            setStatusModal((prev) => !prev);
          }
        } else {
          setStatusLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setStatusLoading(false);
      });
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main" onClick={() => toggleStatusModal(row)}>
        <img src={editicon} alt="edit" className="action-icon" />
      </div>
    );
  };

  const statusStyle = (row) => {
    if (!row) return;
    return (
      <p
        className={
          row.status === "active"
            ? "status-style-active"
            : "status-style-inactive"
        }
      >
        {t(row.status)}
      </p>
    );
  }

  const columns = [
    {
      field: "name",
      text: "State",
    },
    {
      field: "status",
      text: "Status",
      customStyle: statusStyle,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  //pagination functions
  const onPageChange = (page) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      countryId
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      sort: msorts,
      countryId
    });
    navigate({
      search,
    });
  };

  //filters
  const onNameSort = () => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      countryId
    });
    navigate({
      search,
    });
  };

  const onSearch = (e) => {
    deBounce(() => onSearchFilter(e));
    setSearchLoading(true);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
      countryId,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    setSelectedStatus(e.target.value);
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
      countryId,
    });
    navigate({
      search,
    });
  };
  const onCountryChange = (e) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      countryId: e.target.value,
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  const toggleStatusModal = (selected) => {
    if (selected) setSelectedState(selected);
    else setSelectedState("");
    setStatusModal((prev) => !prev);
  };

  return (
    <div className="states-list-main">
      <HeadingMain text="States" />
      <div className="country-table-main">
        <div className="table-flex-main">
        <TableSearch 
          setSearchExpandForMobile={setSearchExpandForMobile}
          searchExpandForMobile={searchExpandForMobile}
          onSearch={onSearch}
          searchString={searchString}
          searchLoading={searchLoading}
        >
            <div className="filter-grey-main">
              <span className="title">{t("Filters")}</span>
              <span>
                <BsFilter
                  style={{
                    color: "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <div
              className={`filter-grey-main name-sort ${
                sorts?.name ? "sort-hightlight" : ""
              }`}
              onClick={onNameSort}
            >
              <span className="title">{t("Name")} (A-Z)</span>
              <span>
                <BsArrowUp
                  style={{
                    color: sorts?.name?.name === "asc" ? "#d60000" : "#CCBFDD",
                  }}
                />
                <BsArrowDown
                  style={{
                    color: sorts?.name?.name === "desc" ? "#d60000" : "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <div>
              <Input
                className="select"
                onChange={onCountryChange}
                value={countryId}
                type='select'
              >
                <option value="">{t("Select Country")}</option>
                {countries &&
                  countries.length > 0 &&
                  countries.map((_) => (
                    <option value={_.value} key={_.value}>
                      {_.label}
                    </option>
                  ))}
              </Input>
            </div>
            <div>
              <Input
                className="select"
                onChange={onStatusChange}
                value={selectedStatus}
                type='select'
              >
                <option>{t("Select Status")}</option>
                <option value="active">{t("Active")} </option>
                <option value="inactive">{t("Inactive")} </option>
              </Input>
            </div>
          </TableSearch>
        </div>
        {states && states.length > 0 ? (
          <>
            <CustomTable data={states} columns={columns} />
            <CustomPagination
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              totalCount={totalCount}
              onPageChange={onPageChange}
              onRowsPerPage={onRowsChange}
            />
          </>
        ) : (
          <div className="no-data-div">
            <p>{t("No States found")}</p>
          </div>
        )}
      </div>
      <Modal
        isOpen={statusModal}
        toggle={toggleStatusModal}
        className="status-modal"
      >
        <ModalHeader toggle={toggleStatusModal}>
          <HeadingMain text="Change status" />
        </ModalHeader>
        <ModalBody>
          <p>
            {t(`Do you want to ${selectedState.status === "inactive" ? "activate" : "deactivate"} the status of`)} <b>{selectedState.name}</b>
          </p>
        </ModalBody>
        <div className="modal-btns">
          <PrimaryButton
            title={selectedState.status === "inactive" ? "Activate" : "Deactivate"}
            style={selectedState.status === "inactive" ? {backgroundColor: "green"} : {}}
            onClick={updateStatus}
            isLoading={statusLoading}
          />
           {/* <PrimaryButton
            color="secondary"
            onClick={toggleStatusModal}
            title={t("Close")}
          />  */}
        </div>
      </Modal>
    </div>
  );
}
