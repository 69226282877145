import React from "react";
import "./SideBar.scss";
import SidebarLogo from "../../assets/images/icons/sidebar_logo.svg";
import { NavLink as RouterLink } from "react-router-dom";
import { Grid } from "react-bootstrap-icons";
import { BsBarChartLine, BsGrid } from "react-icons/bs";
import { FaUserCheck, FaUsers, FaAddressCard } from "react-icons/fa";

import { LuTableProperties, LuBellRing, LuClipboardEdit } from "react-icons/lu";
import { LiaCheckDoubleSolid } from "react-icons/lia";
import { RxMixerHorizontal } from "react-icons/rx";
import { AiTwotoneReconciliation } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";

import ordersicon from "../../assets/images/icons/orders.svg";
import dashboardicon from "../../assets/images/icons/dashboard.svg";
import productsicon from "../../assets/images/icons/products.svg";
import vouchericon from "../../assets/images/icons/voucher.svg";
import usersicon from "../../assets/images/icons/users.svg";
import settingsicon from "../../assets/images/icons/settings.svg";

import { BiCategoryAlt, BiPhotoAlbum } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa";
import { FaGlobeAmericas } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
import { TbBrandSentry } from "react-icons/tb";
import { IoRestaurantOutline, IoFastFoodOutline } from "react-icons/io5";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { RiQuestionnaireLine } from "react-icons/ri";
import { GiMeal } from "react-icons/gi";
import LogoIcon from "../../assets/images/icons/Group1.svg";

import useAuth from "./../../hooks/useAuth";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { FormGroup, Input, Label } from "reactstrap";

export default function Sidebar() {
  const {
    user: { role, isSales },
  } = useAuth();
  return (
    //   <SimpleBar className="sidebar-main" autoHide={false}>
    //   <div className="sidebar-logo">
    //     <img src={SidebarLogo} alt="Sidebar Logo" />
    //   </div>
    //   <div className="sidebar-menu-list">
    //     {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="dashboard" className="each-icon" to="/dashboard">
    //           <img src={dashboardicon} alt="dashboard" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink
    //           id="orders"
    //           className="each-icon"
    //           to={{
    //             pathname: "/orders",
    //             search: "?orderType=product&order_product_status=All",
    //           }}
    //         >
    //           <img src={ordersicon} alt="orders" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="products" className="each-icon" to="/products">
    //           <img src={productsicon} alt="products" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {role === "admin" && (
    //       <div className="each-icon-div">
    //         <RouterLink id="Stock" className="each-icon" to="/stock">
    //           <HiOutlineQuestionMarkCircle className="icon" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {role === "admin" && (
    //       <div className="each-icon-div">
    //         <RouterLink
    //           id="users"
    //           className="each-icon"
    //           to={{
    //             pathname: "/users",
    //             search: `?user=supplier&status=PENDING`,
    //           }}
    //         >
    //           <img src={usersicon} alt="users" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="billing" className="each-icon" to="/billing">
    //           <img src={vouchericon} alt="billing" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="vouchers" className="each-icon" to="/coupons">
    //           <img src={vouchericon} alt="voucher" />
    //         </RouterLink>
    //       </div>
    //     )}

    //     {isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="sales" className="each-icon" to="/sales">
    //           <img src={vouchericon} alt="sales" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     {isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="customers" className="each-icon" to="/customers">
    //           <img src={vouchericon} alt="customers" />
    //         </RouterLink>
    //       </div>
    //     )}
    //      {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="audittrial" className="each-icon" to="/audittrial">
    //           {/* <img src={audittrialicon} alt="dashboard" /> */}
    //         </RouterLink>
    //       </div>
    //     )}
    //     {role === "admin" && (
    //       <>
    //         <div className="each-icon-div">
    //           <RouterLink id="categories" className="each-icon" to="/category">
    //             <BiCategoryAlt className="icon" />
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink id="brand" className="each-icon" to="/brand">
    //             <TbBrandSentry className="icon" />
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink id="Payment" className="each-icon" to="/payment">
    //             {/* <MdOutlinePayment className="icon" /> */}
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink
    //             id="product-type"
    //             className="each-icon"
    //             to="/product-type"
    //           >
    //             <TbBrandSentry className="icon" />
    //           </RouterLink>
    //         </div>
    //       </>
    //     )}

    //     {role === "admin" && (
    //       <>
    //         <div className="each-icon-div">
    //           <RouterLink
    //             id="countries"
    //             className="each-icon"
    //             to={{ pathname: "/countries", search: `&status=active` }}
    //           >
    //             <FaGlobeAmericas className="icon" />
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink
    //             id="states"
    //             className="each-icon"
    //             to={{ pathname: "/states", search: `&status=active` }}
    //           >
    //             <BiCurrentLocation className="icon" />
    //           </RouterLink>
    //         </div>
    //       </>
    //     )}
    //     {role === "admin" && (
    //       <>
    //         <div lassName="each-icon-div">
    //           <RouterLink
    //             id="Faq-Category"
    //             className="each-icon"
    //             to="/category-faq"
    //           >
    //             <HiOutlineQuestionMarkCircle className="icon" />
    //           </RouterLink>
    //         </div>

    //         <div className="each-icon-div">
    //           <RouterLink id="FAQ" className="each-icon" to="/faq-list">
    //             <RiQuestionnaireLine className="icon" />
    //           </RouterLink>
    //         </div>

    //         <div className="each-icon-div">
    //           <RouterLink id="Banners" className="each-icon" to="/banners">
    //             <img src={ordersicon} alt="banners" />
    //           </RouterLink>
    //         </div>

    //         {/* <div className="each-icon-div">
    //           <RouterLink
    //             id="Restaurant Category"
    //             className="each-icon"
    //             to="/restaurant-category"
    //           >
    //             <GiMeal className="icon" />
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink
    //             id="Restaurant Type"
    //             className="each-icon"
    //             to="/restaurant-type"
    //           >
    //             <IoFastFoodOutline className="icon" />
    //           </RouterLink>
    //         </div>
    //         <div className="each-icon-div">
    //           <RouterLink
    //             id="Photography Category"
    //             className="each-icon"
    //             to="/photography-category"
    //           >
    //             <BiPhotoAlbum className="icon" />
    //           </RouterLink>
    //         </div> */}
    //       </>
    //     )}
    //     {!isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink
    //           id="settings"
    //           className="each-icon"
    //           to={{ pathname: "/settings", search: `&status=Account Setup` }}
    //         >
    //           <img src={settingsicon} alt="settings" />
    //         </RouterLink>
    //       </div>
    //     )}
    //     <div className="each-icon-div">
    //       <RouterLink
    //         id="print"
    //         className="each-icon"
    //         to={{ pathname: "/bulk-printing" }}
    //       >
    //         {/* <img src={printicon} alt="print" /> */}
    //       </RouterLink>
    //     </div>
    //     {role === "supplier" && !isSales && (
    //       <div className="each-icon-div">
    //         <RouterLink id="profile" className="each-icon" to="/profile">
    //           <FaRegAddressCard className="icon" />
    //         </RouterLink>
    //       </div>
    //     )}
    //   </div>
    // </SimpleBar>

    <SimpleBar className="sidebar-main" autoHide={false}>
      <div className="sidebar-logo">
        {/* <img src={chevronRightArrow} /> */}
        <img src={LogoIcon} alt="Group1.svg" />
      </div>
      <hr />
      <div className="sidebar-menu-list">
        {!isSales && (
          <div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "white",
                textAlign: "left",
              }}
            >
              MENU
            </div>
          </div>
        )}
        {/* {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/dashboard"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <BsGrid />
                </div>
                <div className="mx-2">Dashboard</div>
              </div>
            </RouterLink>
          </div>
        )} */}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/orders"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <BsBarChartLine />
                </div>
                <div className="mx-2">Orders</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/products"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LiaCheckDoubleSolid />
                </div>
                <div className="mx-2">Products</div>
              </div>
            </RouterLink>
          </div>
        )}
        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="category"
              className="each-icon Active"
              to="/category"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <RxMixerHorizontal />
                </div>
                <div className="mx-2">Category</div>
              </div>
            </RouterLink>
          </div>
        )}
        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink id="dashboard" className="each-icon Active" to="/brand">
              <div className="d-flex mx-2">
                <div className="grid">
                  <RxMixerHorizontal />
                </div>
                <div className="mx-2">Brands</div>
              </div>
            </RouterLink>
          </div>
        )}
        {/* {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/Services"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <RxMixerHorizontal />
                </div>
                <div className="mx-2">Services</div>
              </div>
            </RouterLink>
          </div>
        )} */}
        {/* {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/dashboard"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <AiTwotoneReconciliation />
                </div>
                <div className="mx-2">Deliveries</div>
              </div>
            </RouterLink>
          </div>
        )} */}
        {/* {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/invoices"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <TbFileInvoice />
                </div>
                <div className="mx-2">Invoice</div>
              </div>
            </RouterLink>
          </div>
        )} */}
        {/* {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/Reports"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LuClipboardEdit />
                </div>
                <div className="mx-2">Reports</div>
              </div>
            </RouterLink>
          </div>
        )} */}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/enquiries"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LuClipboardEdit />
                </div>
                <div className="mx-2">Enquiries</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/promotionalBanner"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LuClipboardEdit />
                </div>
                <div className="mx-2">Banners</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/faqCategory"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LuClipboardEdit />
                </div>
                <div className="mx-2">Faq Categories</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "white",
                textAlign: "left",
              }}
            >
              MASTER
            </div>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/Customers"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <FaUserCheck />
                </div>
                <div className="mx-2">Customers</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/Supplier"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <LuTableProperties />
                </div>
                <div className="mx-2">Supplier</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink id="dashboard" className="each-icon Active" to="/Staff">
              <div className="d-flex mx-2">
                <div className="grid">
                  <FaUsers />
                </div>
                <div className="mx-2">Staff</div>
              </div>
            </RouterLink>
          </div>
        )}

        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/countries"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <FaAddressCard />
                </div>
                <div className="mx-2">Countries</div>
              </div>
            </RouterLink>
          </div>
        )}
        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/states"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <FaAddressCard />
                </div>
                <div className="mx-2">States</div>
              </div>
            </RouterLink>
          </div>
        )}

        {!isSales && (
          <div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "white",
                textAlign: "left",
              }}
            >
              SETTINGS
            </div>
          </div>
        )}
        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/User?user='supplier'"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <FaAddressCard />
                </div>
                <div className="mx-2">User</div>
              </div>
            </RouterLink>
          </div>
        )}

        {!isSales && (
          <div className="d-flex align-items-center justify-content-center mb-1">
            <RouterLink id="setting" className="each-icon" to="/setting">
              <div className=" d-flex align-items-center justify-content-center ">
                <RouterLink
                  id="dashboard"
                  className="each-icon Active"
                  to="/ProfileUser"
                >
                  <div className="d-flex mx-2">
                    <div className="grid">
                      <CgProfile />
                    </div>
                    <div className="mx-2">Profile</div>
                  </div>
                </RouterLink>
              </div>
            </RouterLink>
          </div>
        )}
        {role === "admin" && (
          <div className=" d-flex align-items-center justify-content-center ">
            <RouterLink
              id="dashboard"
              className="each-icon Active"
              to="/Policies"
            >
              <div className="d-flex mx-2">
                <div className="grid">
                  <RxMixerHorizontal />
                </div>
                <div className="mx-2">Policies</div>
              </div>
            </RouterLink>
          </div>
        )}
        {!isSales && (
          <div className="d-flex align-items-center justify-content-center mb-1">
            <RouterLink id="setting" className="each-icon" to="/setting">
              <div className=" d-flex align-items-center justify-content-center ">
                <RouterLink
                  id="dashboard"
                  className="each-icon Active"
                  to="/chat"
                >
                  <div className="d-flex mx-2">
                    <div className="grid">
                      <LuBellRing />
                    </div>
                    <div className="mx-2">Notifications</div>
                  </div>
                </RouterLink>
              </div>
            </RouterLink>
          </div>
        )}

        {isSales && (
          <div className="d-flex align-items-center justify-content-center mb-1">
            <RouterLink id="billing" className="each-icon" to="/billing">
              <img src={dashboardicon} alt="dashboard" /> <span>Billing</span>
            </RouterLink>
          </div>
        )}
        {isSales && (
          <div className="d-flex align-items-center justify-content-center mb-1">
            <RouterLink id="customers" className="each-icon" to="/customers">
              <img src={dashboardicon} alt="dashboard" /> <span>Customers</span>
            </RouterLink>
          </div>
        )}
        {isSales && (
          <div className="d-flex align-items-center justify-content-center mb-1">
            <RouterLink id="sales" className="each-icon" to="/sales">
              <img src={dashboardicon} alt="dashboard" /> <span>Sales</span>
            </RouterLink>
          </div>
        )}
      </div>
    </SimpleBar>
  );
}
