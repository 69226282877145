import { React, useEffect, useState, useRef } from "react";
import "./AddProduct.scss";
import styles from "./AddProduct.scss";
import { Form, Input, Label, FormGroup, Button, Row } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { BsCamera, BsCameraVideo } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import IconButton from "../../assets/elements/IconButton";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MainServices from "../../services/MainServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import closeicon from "../../assets/images/icons/close.svg";
import { ChromePicker } from "react-color";
import AsyncSelect from "react-select/async";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import "react-dropdown-tree-select/dist/styles.css";
import { components } from "react-select";
import { urlToFileName } from "../../assets/functions/helpers";
import ImageCropper from "../../assets/functions/imageCropper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import useAuth from "../../hooks/useAuth";

import MultiLevelSelect from "./CustomSelect";

const productTypesTemplateDummy = [
  {
    productType: "phone",
    fields: [
      { label: "os", value: "", category: "technical specification" },
      { label: "ram", value: "", category: "technical specification" },
      { label: "texture", value: "", category: "technical specification" },
      { label: "ASIN", value: "", category: "additional information" },
    ],
  },

  {
    productType: "cake",
    fields: [
      { label: "color", value: "", category: "technical specification" },
      { label: "flavor", value: "", category: "technical specification" },
      { label: "weight", value: "", category: "technical specification" },
      { label: "floor", value: "", category: "additional information" },
    ],
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default function AddProduct() {
  const [productImageFiles, setProductImageFiles] = useState([]);
  const hiddenImageUploader = useRef(null);
  const hiddenVideoUploader = useRef(null);
  const [isAddProductInProgress, setIsAddProductInProgress] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  // const [typeList, setTypeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userStatesLoaded, setUserStatesLoaded] = useState();
  const [productTypesTemplate, setProductTypesTemplate] = useState([]);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const publicId = new URLSearchParams(location.search).get("productId");
  let isEditing = publicId ? true : false;
  const [isCurrentProductLoaded, setIsCurrentProductLoaded] = useState(false);

  const formikFieldsSchema = ["title", "description", "content", "terms"];
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const [formikValues, setFormikValues] = useState(
    getFormikValuesForAllLanguages(formikFieldsSchema)
  );
  const [currentImage, setCurrentImage] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [cropImageModal, setCropImageModal] = useState(false);

  //hooks
  const { t, i18n } = useTranslation();
  const { deBounce } = TimingFunctions();

  const [showFields, setShowFields] = useState(false);

  

  const handleAddWarrantyClick = (productWarranty, setFieldValue) => {
    let warranties=productWarranty || [] ;
    warranties.push(  {
      warranty: "",
      warrantyPrice: "",
      description: "",
    })
    setFieldValue("productWarranty", warranties)
    
   
  };



  const {
    saveProduct,
    getProductById,
    updateProductById,
    fileUpload,
    getCategories,
    getBrands,
    getProductTypes,
    getCountries,
    getStatesByCountry,
    getAllStatesPublic,
  } = MainServices();

  const { user } = useAuth();

  //functions
  const handleChooseVideo = (e) => {
    // const fileUploaded = event.target.files[0];
    // setProductImageFiles(fileUploaded);
    //console.log("images", productImageFiles);
    //setProductImageFiles(oldFiles => [...oldFiles, ...e.target.files]);
  };
  const handleImageUploaderClick = () => {
    hiddenImageUploader.current.click();
  };
  const handleVideoUploaderClick = () => {
    hiddenVideoUploader.current.click();
  };

  const addProductSchema = Yup.object().shape({
    category: Yup.array()
      .min(1, t("Select a category"))
      .required(t("Select a category")),
    title: Yup.string().required(`${t("Product Name")} ${t("is required")}`),
    image: Yup.mixed().required(`${t("Image")} ${t("is required")}`),
    brandId: Yup.mixed().required(`${t("Select a brand")}`),
    maxPrice: Yup.number().required(`${t("Max Price")} ${t("is required")}`),
    sellingPrice: Yup.number()
      .positive()
      .required(`${t("Selling Price")} ${t("is required")}`),
    costPrice: Yup.number().required(`${t("Cost Price")} ${t("is required")}`),
  });

  const updateProductSchema = Yup.object().shape({
    category: Yup.array()
      .min(1, t("Select a category"))
      .required(t("Select a category")),

    title: Yup.string().required(`${t("Product Name")} ${t("is required")}`),
    image: Yup.mixed().required(`${t("Image")} ${t("is required")}`),
    brandId: Yup.mixed().required(`${t("Select a brand")}`),
  });

  const handleChangeProductType = (e, setFieldValue) => {
    const selectedValue = parseInt(e.target.value);
    const selectedProductTypeObject = productTypesTemplate.find(
      (item) => item.id === selectedValue
    );

    if (!selectedProductTypeObject) {
      setFieldValue("productTypeFieldList", []);
      setFieldValue("productType", "");
      return;
    }
    setFieldValue("productType", selectedValue);
    let fieldObjects = selectedProductTypeObject["fields"];

    setFieldValue("productTypeFieldList", fieldObjects);
  };

  const handleAddDynamic = (values, setFieldValue) => {
    // For new ID
    let IdForNewElement = 0;
    let lengthOfFields = values.productDynamicFieldList.length;
    if (lengthOfFields > 0)
      IdForNewElement =
        Number(values.productDynamicFieldList[lengthOfFields - 1]?.id) + 1;
    setFieldValue("productDynamicFieldList", [
      ...values.productDynamicFieldList,
      { id: String(IdForNewElement), label: "", value: "" },
    ]);
    /* const dynamicField = (
      <div className="dynamicfield" key={lastElementKey}>
        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-3">
          <Input
            placeholder="Enter Label"
            type="text"
            className="product-form-group"
            //value={values.test}
            onChange={(e) => setFieldValue("label_"+lastElementKey, e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-3">
          <Input
            placeholder="Enter Value"
            type="text"
            className="product-form-group"
            onChange={(e) => setFieldValue("value_"+lastElementKey, e.target.value)}
          />
        </FormGroup>
        <img src={deleteicon} alt="delete" onClick={() => handleDeleteField(String(lastElementKey), setFieldValue)}/>
    </div>)
    setDynamicFields(oldValues => [...oldValues, dynamicField]) */
  };

  const handleSave = async (values) => {
    setIsAddProductInProgress(true);
    setErrorsOnSubmit([]);
    console.log("values", values);
    // if (!productImageFiles.length) {
    //   setErrorsOnSubmit(["Please upload valid product image"]);
    //   setIsAddProductInProgress(false);
    //   return;
    // }

    let imagesUploadResponse = await fileUpload(productImageFiles);
    console.log("imgR----", imagesUploadResponse);
    const responseToArray = imagesUploadResponse?.fileName?.split(",");

    // if (!imagesUploadResponse.success) {
    //   //notifyError("Image Not Provided!");
    //   if (imagesUploadResponse?.type === "field-validation")
    //     setErrorsOnSubmit((oldValues) => [
    //       ...oldValues,
    //       imagesUploadResponse.details?.map(
    //         (item) => item.name + " " + item.message
    //       ),
    //     ]);
    //   else
    //     setErrorsOnSubmit((oldValues) => [
    //       ...oldValues,
    //       "Image upload failed: File too large",
    //     ]);
    //   setIsAddProductInProgress(false);
    //   return;
    // }

    const languages = [];
    console.log("PRRRRRRRRR", availableLanguages);
    availableLanguages.forEach((language) => {
      console.log("PRRRRRRRRR", language);
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
          terms: "any string",
        };
        console.log("NEWOBGGG", newObj);
      });
      languages.push(newObj);
      console.log("CHECKINGG", languages);
    });

    // Preparing languages field for multi-language color inputs
    /* languages.forEach(item => {
      let locale = item.locale;
      item.color = []
      values.colors?.forEach(colorObj => {
        let colorForCurrentLocale = colorObj["name_"+locale];
        item.color = [...item?.color, {name: colorForCurrentLocale, colorCode: colorObj.colorCode}]
      })
    }) */

    // Preparing languages field for multi-language dynamic type field, dynamic field inputs
    languages.forEach((item) => {
      let locale = item.locale;
      item.details = {};
      values.productTypeFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });

      values.productDynamicFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });
    });

    const detailsAPIField = [
      ...values.productDynamicFieldList,
      ...values.productTypeFieldList,
    ].map((eachField) => {
      if (!eachField.label || !eachField.value) return ["", ""];
      return [eachField.label, eachField.value];
    });
    const detailsInValidFromat = Object.fromEntries(
      detailsAPIField.filter((item) => item[0] && item[1])
    );

    let variantsData = values?.variants_data || [];
    variantsData = await Promise.all(
      variantsData.map(async (eachV) => {
        if (eachV.product_images && eachV.product_images.length > 0) {
          try {
            let variantImageResponse = await fileUpload(eachV.product_images);
            const imageArray = variantImageResponse?.fileName?.split(",") || [];
            return {
              ...eachV,
              product_images: imageArray,
            };
          } catch (error) {
            console.error("Error uploading variant image:", error);
            return eachV;
          }
        } else {
          return eachV;
        }
      })
    );
    let variants = values.variants || [];
    variants = variants.map((each) => {
      return each ? each.toLowerCase() : null;
    });
    variants = variants.filter((each) => each !== null);

    const details = {
      title: values.title,
      maxPrice: values.maxPrice,
      costPrice: values.costPrice,
      sellingPrice: values.sellingPrice,
      shippingCharge: values.shippingCharge,
      // warranty: values.warranty,
      //deliveryCharge: values.deliveryCharge,
      content: values.content,
      //terms: values.terms,
      ///    terms:"any string",
      //  languages0terms :"languages0terms",
      description: values.description,
      image: responseToArray[0],
      productImages: responseToArray,
      brandId: parseInt(values.brandId?.value),
      languages: languages,
      category: values.category.map((item) => item.value),
      city: values.states.map((item) => item.value),
      cityName: values.states.map((item) => item.label),
      country: values.countries.map((item) => item.value),
      countryName: values.countries.map((item) => item.label),
      //colors: [...new Set(values.colors)],
      color: "red",
      colorCode: values.colors[0]?.colorCode,
      details: { ...detailsInValidFromat, productType: values.productType },
      variants: variants,
      warranty: values.warranty,
      variantDetails: variantsData,

      productWarranty: values.productWarranty,
      // supplier_name:"supplier1"
    };
    saveProduct(details)
      .then(async (res) => {
        console.log("product added res", res);
        if (res.success) {
          notifySuccess("New Product Added!");
          setIsAddProductInProgress(false);
          if (values?.variants_data && values?.variants_data?.length) {
            navigate({
              pathname: "/products",
            });
          } else {
            navigate({
              pathname: "/products/ProductDescription",
              search: `?productId=${res.publicId}`,
            });
          }
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsAddProductInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsAddProductInProgress(false);
        console.log(error);
      });
  };

  const handleUpdate = async (values) => {
    setErrorsOnSubmit([]);
    setIsAddProductInProgress(true);

    if (!productImageFiles.length) {
      setErrorsOnSubmit(["Please upload valid product image"]);
      setIsAddProductInProgress(false);
      return;
    }
    let existingImageURLs = productImageFiles.filter(
      (file) => typeof file === "string"
    );
    existingImageURLs = existingImageURLs.map((_) => urlToFileName(_));
    let newImageFiles = productImageFiles.filter(
      (file) => typeof file !== "string"
    );
    //console.log("newImg", newImageFiles);
    let imagesUploadResponse = "";
    let responseToArray = existingImageURLs;
    if (newImageFiles.length) {
      imagesUploadResponse = await fileUpload(newImageFiles);
      responseToArray = responseToArray.concat(
        imagesUploadResponse.fileName?.split(",")
      );
    }

    if (newImageFiles.length > 0 && !imagesUploadResponse?.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Image upload failed: File too large",
        ]);
      setIsAddProductInProgress(false);
      return;
    }

    const languages = [];
    console.log("FFFFFF11111", availableLanguages);

    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
          terms: "any string",
        };
      });
      languages.push(newObj);
      console.log("FFFFFF", languages);
    });

    // Preparing languages field for multi-language dynamic type field, dynamic field inputs
    languages.forEach((item) => {
      let locale = item.locale;
      item.details = {};

      values.productTypeFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];

        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });

      console.log("languages", languages);

      values.productDynamicFieldList?.forEach((fieldObj) => {
        const label = fieldObj.label;
        let labelForCurrentLocale = label;
        let valueForCurrentLocale = fieldObj["value_" + locale];
        if (valueForCurrentLocale)
          item.details = {
            ...item?.details,
            [labelForCurrentLocale]: valueForCurrentLocale,
          };
      });
      item.content = "";
    });

    const detailsAPIField = [
      ...values.productDynamicFieldList,
      ...values.productTypeFieldList,
    ].map((eachField) => {
      if (!eachField.label || !eachField.value) return ["", ""];
      return [eachField.label, eachField.value];
    });
    const detailsInValidFromat = Object.fromEntries(
      detailsAPIField.filter((item) => item[0] && item[1])
    );

    {
      console.log("values", values);
    }

    const details = {
      title: values.title,
      // content: values.content,
      //terms: values.terms,
      // languages0terms: "languages0terms",
      // terms: "any string",
      description: values.description,
      image: responseToArray[0],
      productImages: responseToArray,
      brandId: parseInt(values.brandId?.value),
      category: (values.category.map((item) => item.value) || []).filter(
        (each) => each
      ),
      city: values.states.map((item) => item.value),
      cityName: values.states.map((item) => item.label),
      country: values.countries.map((item) => item.value),
      countryName: values.countries.map((item) => item.label),
      //colors: [...new Set(values.colors)],
      languages: languages,
      color: "red",
      warranty: values.warranty,
      //colorCode: values.colors[0],
      //details: formikValues.details,
      details: { ...detailsInValidFromat, productType: values.productType },
    };
    let latestConcurrencyStamp = await getProductById(publicId).then(
      (res) => res.data.concurrencyStamp
    );
    updateProductById(details, publicId, latestConcurrencyStamp)
      .then(async (res) => {
        if (res.success) {
          console.log("res", res);
          let productId = res.publicId;
          let priceDetails = {
            productId: productId,
            maxPrice: values.maxPrice,
            costPrice: values.costPrice,
            sellingPrice: values.sellingPrice,
            shippingCharge: values.shippingCharge,
            deliveryCharge: values.deliveryCharge,
            warranty: values.warranty,
          };
          let latestConcurrencyStamp = await getProductById(publicId).then(
            (res) => res.data.concurrencyStamp
          );
          notifySuccess("Product Updated!");
          setIsAddProductInProgress(false);
          navigate({
            pathname: "/products/ProductDescription",
            search: `?productId=${publicId}`,
          });
          /* updateProductPriceById(priceDetails, productId, latestConcurrencyStamp)
            .then(res => {
              if(res.success){
                notifySuccess("New Product Added!");
                setIsAddProductInProgress(false);
                navigate({
                  pathname: "/products/product-details",
                  search: `?productId=${res.publicId}`,
                });
              }
              else {
                notifyError("Error occurred while updating price!");
                setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
                setIsAddProductInProgress(false);
              }
            })
            .catch(error => {
              notifyError("Error occurred while updating price!");
              setErrorsOnSubmit((oldValues) => [...oldValues, error]);
              setIsAddProductInProgress(false);
            }) */
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setIsAddProductInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Update Failed");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsAddProductInProgress(false);
      });
  };

  //get categories

  const GroupHeading = (props, setFieldValue, values) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <components.GroupHeading
          {...props}
          onClick={() => {
            //console.log(props);
            //setFieldValue("category", [...values.category, props.data]);
          }}
          style={{
            cursor: "pointer",
            color: "#D60000",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: 18,
          }}
        />
      </div>
    );
  };

  const getCategoryList = async (filters) => {
    const details = {
      // pageSize: 10,
      pageSize: 100,
      pageNumber: 1,
      filters,
    };
    let results = await getCategories(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            console.log("categories List", resData);
            resData = resData.data ? resData.data : [];

            resData = resData.map((category) => {
              let options = category?.categories || [];

              options = options.map((_) => {
                let subOptions = _?.sub_categories || [];
                subOptions = subOptions.map((__) => {
                  return {
                    ...__,
                    label: __.name,
                    value: __.id,
                  };
                });
                return {
                  ..._,
                  label: _.name,
                  value: _.id,
                  ...(subOptions && subOptions.length > 0
                    ? { children: subOptions }
                    : {}),
                };
              });

              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                category?.category_languages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                label: languageSpecificDetailsObject?.name || category.name,
                value: category.id,
                ...(options.length > 0 ? { children: options } : {}),
              };
            });
            resData.sort((a, b) => {
              if (a.label < b.label) return -1;
              if (a.label > b.label) return 1;
              return 0;
            });
            console.log("categories List", resData);

            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const loadOptionsForCategory = async (inputValue, callback) => {
    if (!inputValue) {
      callback(categoryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    let options = await getCategoryList(filter);
    console.log(options);
    callback(options);
  };

  const loadOptionsForStates = async (inputValue, callback) => {
    if (!inputValue) {
      callback(stateList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    // let options = await getAllStates(filter);
    let options = stateList.filter((stateObj) =>
      stateObj?.label?.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(options);
  };

  const loadOptionsForCountries = async (inputValue, callback) => {
    if (!inputValue) {
      callback(countryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    // let options = await getAllStates(filter);
    let options = await getAllCountries(filter);
    //let options = countryList.filter(countryObj => countryObj?.label?.toLowerCase().includes(inputValue.toLowerCase()))
    callback(options);
  };

  const loadDebounceOptionsForCategory = (inputValue, callback) => {
    deBounce(() => loadOptionsForCategory(inputValue, callback), 1000);
  };

  const loadDebounceOptionsForStates = (inputValue, callback) => {
    deBounce(() => loadOptionsForStates(inputValue, callback), 1000);
  };

  const loadDebounceOptionsForCountries = (inputValue, callback) => {
    deBounce(() => loadOptionsForCountries(inputValue, callback), 1000);
  };

  const getExistingProductData = () => {
    getProductById(publicId)
      .then(async (res) => {
        if (res.success) {
          const productData = {
            ...res.data,
            maxPrice: res.data?.productPrice?.maxPrice,
            sellingPrice: res.data?.productPrice?.sellingPrice,
            costPrice: res.data?.productPrice?.costPrice,
            deliveryCharge: res.data?.productPrice?.deliveryCharge,
            shippingCharge: res.data?.productPrice?.shippingCharge,

            brandId: {
              label: res.data?.brand?.name,
              value: res.data?.brand?.id || res.data.brandId,
            },
            category: [
              {
                label: res.data.productCategories[0]?.category?.name,
                value: res.data.productCategories[0]?.category?.id,
              },
            ],
            colors: [{ name: res.data?.color, colorCode: "#D60000" }],
            image: res.data?.productImages.length > 0 ? "image" : "",
            productType: res.data?.details && res.data?.details["productType"],
            productTypeFieldList: [],
            productDynamicFieldList: [],
          };

          // To populate states and countries

          let allStatesAndCountries = await getAllStatesPublicList();

          // To populate countries
          let countryIDListFromResponse = res.data?.country || [];
          let selectedCountries = [];
          allStatesAndCountries.forEach((stateCountryObj) => {
            let selectedCountry = null;
            let countryOfCurrentStateObj = stateCountryObj?.countries[0] || {};
            let indexOfMatchingCountryInResponse =
              countryIDListFromResponse.indexOf(countryOfCurrentStateObj?.id);
            if (indexOfMatchingCountryInResponse !== -1) {
              selectedCountry = {};
              selectedCountry.label = countryOfCurrentStateObj.name;
              selectedCountry.value = countryOfCurrentStateObj.id;
              delete countryIDListFromResponse[
                indexOfMatchingCountryInResponse
              ];
            }
            selectedCountry && selectedCountries.push(selectedCountry);
          });
          productData.countries = selectedCountries;
          // Call getStates API to make states(in selected countries) available for selection
          getAllStatesByCountries(selectedCountries);

          // To populate cities
          let stateIDListFromResponse = res.data?.city || [];
          let selectedStates = [];
          allStatesAndCountries.forEach((stateObj) => {
            let selectedState = null;
            let indexOfMatchingStateResponse = stateIDListFromResponse.indexOf(
              stateObj?.id
            );
            if (indexOfMatchingStateResponse !== -1) {
              selectedState = {};
              selectedState.label = stateObj.name;
              selectedState.value = stateObj.id;
              delete stateIDListFromResponse[indexOfMatchingStateResponse];
            }
            selectedState && selectedStates.push(selectedState);
          });
          productData.states = selectedStates;

          // To populate multi-language fields(fixed fields)
          if (res.data?.productLanguages) {
            let productLanguages = res.data?.productLanguages;
            availableLanguages.forEach((language) => {
              let languageSpecificObject = productLanguages.find(
                (languageObject) => languageObject.locale === language.value
              );
              if (languageSpecificObject) {
                formikFieldsSchema.forEach((formikField) => {
                  productData[formikField + "_" + language.value] =
                    languageSpecificObject[formikField];
                  //productData[formikField] = productLanguages
                });
              }
            });
          }

          // Wait to populate product type fields
          const productTypesTemplate = await getProductTypeList([
            { key: "status", eq: "active" },
          ]);
          // Get matching product-type object
          const typeObject = productTypesTemplate.find(
            (type) => type.id == res.data?.details?.productType
          );

          // To populate product-type fields labels and their values
          if (res.data?.details?.productType && typeObject) {
            typeObject.fields.forEach((fieldObj) => {
              let fieldLabel = fieldObj["englishLabel"];
              const fieldObject = {
                label: fieldLabel,
                value:
                  res.data?.details[fieldLabel] ||
                  res.data?.details[fieldLabel.toLowerCase()],
                category: "",
              };
              // To populate product-type field values for all lanugages
              availableLanguages.forEach((languageObj) => {
                const languageSpecificDetailsObject =
                  res.data?.productLanguages.find(
                    (languageSpecificDetailsObject) =>
                      languageSpecificDetailsObject.locale === languageObj.value
                  );
                const languageSpecificFieldValue =
                  languageSpecificDetailsObject?.details[fieldLabel] ||
                  languageSpecificDetailsObject?.details[
                    fieldLabel.toLowerCase()
                  ];
                if (languageSpecificFieldValue) {
                  fieldObject["value_" + languageObj.value] =
                    languageSpecificFieldValue;
                }
              });
              productData.productTypeFieldList.push(fieldObject);
              // Remove product-type field to avoid duplication in dynamic fields
              // Delete english label when langauge is non-english
              // to avoid mismatching of fetched labels with language specific product-type labels
              if (i18n.language?.includes("en") === false)
                fieldLabel = fieldObj["englishLabel"];
              delete res.data?.details[fieldLabel];
              delete res.data?.details[fieldLabel.toLowerCase()];
            });
          }

          // Remove type id as it is not needed on UI
          // It is stored for matching product-type name
          res.data?.details?.productType &&
            delete res.data?.details?.productType;

          // To populate multi-language dynamic fields
          if (res.data?.details && Object.keys(res.data?.details).length > 0) {
            let details = res.data?.details;
            /* if (details.productType) {
              const selectedProductTypeObject = productTypesTemplate.find(
                (item) => item.productType === details.productType
              );
              if (!selectedProductTypeObject) {
              } else {
                //console.log("se", selectedProductTypeObject);
                productData.productType = details.productType;
                let options = selectedProductTypeObject["fields"];
                //console.log("se2", selectedProductTypeObject);
                //console.log(options);
                // Populate dynamic type fields for non-english languages
                availableLanguages.forEach((language) => {
                  const languageSpecificDetailsObject =
                    res.data?.productLanguages.find(
                      (languageSpecificDetailsObject) =>
                        languageSpecificDetailsObject.locale === language.value
                    );
                  options.forEach((option) => {
                    if (option.label in details) {
                      //option.value = details[option.label];
                      option["value_" + language.value] =
                        languageSpecificDetailsObject?.details[option.label];

                      //delete details[option.label];
                    }
                  });
                  //option["value_"+language.value] = "lang_"+language.value
                });
                // Populate dynamic type fields for english language
                options.forEach((option) => {
                  if (option.label in details) {
                    option.value = details[option.label];
                    //option["value_"+language.value] = languageSpecificDetailsObject?.details[option.label];

                    // Delete to avoid duplication at dynamic fields
                    delete details[option.label];
                  }
                });
                //productData.productTypeFieldList = options;
              }
            }
            // Delete to avoid duplication at dynamic fields
            delete details?.productType;
            if (Object.keys(details).length === 0) {
              productData.productDynamicFieldList = [
                { id: "0", label: "", value: "" },
                { id: "1", label: "", value: "" },
              ];
            } */
            for (let key in details) {
              // For new ID
              let IdForNewElement = 0;
              let lengthOfFields = productData?.productDynamicFieldList?.length;
              if (lengthOfFields > 0)
                IdForNewElement =
                  Number(
                    productData.productDynamicFieldList[lengthOfFields - 1]?.id
                  ) + 1;

              let objectWithLanguageSpecificValues = {
                id: String(IdForNewElement),
                label: key,
              };
              availableLanguages.forEach((language) => {
                const languageSpecificDetailsObject =
                  res.data?.productLanguages.find(
                    (languageSpecificDetailsObject) =>
                      languageSpecificDetailsObject.locale === language.value
                  );
                objectWithLanguageSpecificValues = {
                  ...objectWithLanguageSpecificValues,
                  ["value_" + language.value]:
                    languageSpecificDetailsObject?.details
                      ? languageSpecificDetailsObject?.details[key]
                      : "",
                };
              });
              const objectWithAllLanguages = {
                ...objectWithLanguageSpecificValues,
                value: details[key],
              };
              /* let objectWithLanguageSpecificValues = {
                id: String(IdForNewElement), label: key, value: details[key]
              } */

              productData.productDynamicFieldList = [
                ...productData.productDynamicFieldList,
                { ...objectWithAllLanguages },
              ];
            }
          } else {
            // If no dynamic fields are present, provide two empty fields
            productData.productDynamicFieldList = [
              { id: "0", label: "", value: "" },
              { id: "1", label: "", value: "" },
            ];
          }

          let variants = res?.data?.variants || [];

          variants.forEach((eachVariant) => {
            if (!res?.data?.details[eachVariant]) {
              productData.productDynamicFieldList.push({
                id: eachVariant,
                label: eachVariant,
                value: "",
              });
            }
          });
          setProductImageFiles(
            res.data?.productImages?.map((imgObject) => imgObject.images)
          );
          setFormikValues(productData);
          setIsCurrentProductLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductTypeList = async (filters) => {
    const details = {
      filters: filters || [{ key: "status", eq: "active" }],
    };
    return await getProductTypes(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            // Preparing product types template to render on UI
            const typesTemplate = resData?.map((type) => {
              // Language specific product-type name
              const languageSpecificName =
                type.language["label_" + i18n.language] || type.name;

              // Produt-type fields
              const fields = [];

              // Current language identifier
              // Add language identifier if not default language
              const currentLanguageIdentifier =
                i18n.language?.includes("en") === false ? "" : i18n.language;

              // Iterate fields and add to template
              type.fields?.forEach((fieldObj) => {
                const defaultLabel = fieldObj["label"];
                const languageSpecificLabel =
                  fieldObj["label_" + currentLanguageIdentifier];
                fields.push({
                  label: languageSpecificLabel || defaultLabel,
                  englishLabel: defaultLabel,
                  value: "",
                  category: "",
                });
              });

              const typeObj = {
                label: languageSpecificName,
                id: type.id,
                fields: fields,
              };
              return typeObj;
            });
            setProductTypesTemplate(
              typesTemplate.filter((item) => item != undefined)
            );
            return typesTemplate.filter((item) => item != undefined);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* const getCountryList = async (pageNumber) => {
    const details = {
      pageNumber: pageNumber || 1,
    };
    return await getCountries(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            return resData;
          }
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateListByCountry = async (countryId, filter) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      countryId,
      filters: filter
    };
    return await getStatesByCountry(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            let formatForAsyncSelect = resData.map(stateObj => {
              return {
                label: stateObj?.name,
                value: stateObj?.id
              }
            })
            return formatForAsyncSelect;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }; 

  const getStatesByUserCountry = async () => {
    return await getStatesList(parseInt(user?.supplier?.country))
      .then(res => {
        //setFormikValues(oldValues => ({...oldValues, states: [...res]}) )
        res = res || []
        return res;
      })
  } */

  const removeStatesOfDeselectedCountries = (
    setFieldValue,
    currentStates,
    availableStates
  ) => {
    let newStates = currentStates.filter((formikStateObj) => {
      let isSelectedStateAvailable = false;
      availableStates.forEach((stateObj) => {
        if (formikStateObj.value === stateObj.value) {
          isSelectedStateAvailable = true;
        }
      });
      return isSelectedStateAvailable;
    });
    setFieldValue("states", newStates);
  };

  const getAllStatesPublicList = async () => {
    return getAllStatesPublic().then((res) => {
      if (res.success) {
        return res.data || [];
      }
    });
  };

  const getAllStatesByCountries = async (countries) => {
    let allStates = [];
    setStateList(() => []);
    for (let countryObj of countries) {
      const statesByCountry = await getAllStates({
        countryId: countryObj?.value,
      });
      allStates = [...allStates, ...statesByCountry];
      setStateList((oldList) => [...oldList, ...statesByCountry]);
    }
    return allStates;
  };

  const getAllStates = async (filter) => {
    let details = {
      pageNumber: 1,
      pageSize: 10,
      ...filter,
    };
    return await getStatesByCountry(details).then(async (res) => {
      if (res) {
        let resData = res;
        if (resData.success) {
          let total = resData?.totalCount || 0;
          resData = resData.data ? resData.data : [];
          let formatForAsyncSelect = resData.map((stateObj) => {
            return {
              label: stateObj?.name,
              value: stateObj?.id,
            };
          });
          return formatForAsyncSelect;
        }
      }
    });
    // To get all countries
    /* let allCountries = []
    for(let pageNumber=1; pageNumber<=3; pageNumber++)
    {
      let countries = await getCountryList(pageNumber);
      allCountries = allCountries.concat(countries);
    }
    setCountryList(allCountries);

    // To get all states from all countries
     let allStates = []
    for(let countryObj of allCountries)
    {
      let states = await getStatesList(countryObj.id, filter);
      allStates = allStates.concat(states);
    }
    // Sort states in alphabetical order
    // using compareFunction which compares labels in state objects
    allStates.sort(
      (stateObjCurrent, stateObjNext) => 
        stateObjCurrent.label >= stateObjNext.label ? 1 : -1
    );
    return allStates; */
  };

  const getAllCountries = async (filter) => {
    // To get all countries
    let details = {
      pageSize: 10, //filter ? 100: 10,
      pageNumber: 1,
      filter,
    };
    return await getCountries(details).then(async (res) => {
      if (res) {
        let resData = res;
        if (resData.success) {
          let total = resData?.totalCount || 0;
          resData = resData.data ? resData.data : [];
          let formatForAsyncSelect = resData.map((countryObj) => {
            return {
              label: countryObj?.name,
              value: countryObj?.id,
            };
          });
          return formatForAsyncSelect;
        }
      }
    });
    // To get all states from all countries
  };

  useEffect(() => {
    if (isEditing) {
      getExistingProductData();
    }
    /* getStatesByUserCountry()
      .then((res) => {
        setUserStatesLoaded(true);
        setFormikValues( oldValues => ({...oldValues, states: [...res]}) )
      }); */
    //getAllStates().then(list => setStateList(list || []));
    getAllCountries().then((list) => setCountryList(list));
    // eslint-disable-next-line
  }, []);

  // Get language specific values when langauge is changed by user
  useEffect(() => {
    getCategoryList([{ key: "status", eq: "active" }]).then((list) =>
      setCategoryList(list)
    );
    getBrandList([{ key: "status", eq: "active" }]).then((list) =>
      setBrandList(list)
    );
    getProductTypeList([{ key: "status", eq: "active" }]);
    // eslint-disable-next-line
  }, []);

  // brand  Async selectfunctions
  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback(brandList);
    }
    let filter = [
      {
        key: "status",
        eq: "active",
      },
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getBrandList(filter);
    callback(options);
  };

  const loadDebounceOptions = (inputValue, callback) => {
    deBounce(() => loadOptions(inputValue, callback), 1000);
  };

  //get Brand Options
  const getBrandList = async (filters) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getBrands(details)
      .then((res) => {
        if (res) {
          let resData = res;

          if (resData.success) {
            console.log("brands List", resData);
            resData = resData.data ? resData.data : [];
            resData = resData.map((brand) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                brand?.brandLanguages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                ...brand,
                label: languageSpecificDetailsObject?.name || brand.name,
                value: brand.id,
              };
            });
            return resData;
          }
        } else {
          console.log("Brands Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  //async select style
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#EDF0F7",
      marginBottom: "12px",
      paddingLeft: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      zIndex: "2",
    }),

    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#f5f2ed" : "white",
        color: "#000000",
        cursor: "pointer",
        padding: "10px 10px 10px 20px",
        //textTransform: "capitalize",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "5px",
    }),
  };

  const handleChooseImage = (e) => {
    setCurrentImage(e.target.files[0] || "");
    e.target.files[0] && setCropImageModal(true);
  };

  const handleSelectImage = (e) => {
    e?.preventDefault();
    setCurrentImage(e.dataTransfer.files[0] || "");
    e?.dataTransfer?.files[0] && setCropImageModal(true);
  };

  const handleDragOver = (e) => {
    e?.preventDefault();
  };

  const onGetCroppedImage = (setFieldValue, image) => {
    setCroppedImage(image);
  };

  const onCropImageModal = () => {
    setCropImageModal((prev) => !prev);
    setCurrentImage("");
  };

  const onCropSave = () => {
    setProductImageFiles((oldFiles) => [...oldFiles, croppedImage]);
    onCropImageModal();
  };

  function generateVariantsDetails(variants_data, variants_details_data, set) {
    let variants = variants_data || [];
    variants = variants.filter((each) => each);
    let variants_details = variants_details_data || {};
    variants.map((eachV) => {
      if (!variants_details[eachV]) {
        variants_details = {
          ...variants_details,
          [eachV]: [],
        };
      }
    });
    let var_detail = {};

    Object.keys(variants_details).length &&
      Object.keys(variants_details).map((key) => {
        if (variants.includes(key)) {
          var_detail[key] = variants_details[key];
        }
      });
    set("variants_details", var_detail);
  }

  function generateCombinations(arrays, currentCombination, index, result) {
    if (index === arrays.length) {
      let combinedObject = currentCombination.reduce(
        (acc, obj) => ({ ...acc, ...obj }),
        {}
      );
      result.push({
        variant: combinedObject,
        isSamePrice: true,
        sellingPrice: 0,
        costPrice: 0,
        maxPrice: 0,
        shippingCharge: 0,
        tax: 0,
        deliveryCharge: 0,
        product_images: [],
      });
      return;
    }
    let length = arrays[index].length || 0;
    for (let i = 0; i < length; i++) {
      currentCombination.push(arrays[index][i]);
      generateCombinations(arrays, currentCombination, index + 1, result);
      currentCombination.pop();
    }
  }

  function getAllCombinations(variants_details, set) {
    const result = [];
    let arrays = [];
    Object.keys(variants_details).length &&
      Object.keys(variants_details).map((eachKey) => {
        let array = variants_details[eachKey] || [];
        array = array.map((each) => {
          if (each) {
            return {
              [eachKey.toLowerCase()]: each,
            };
          } else return null;
        });
        array = array.filter((each) => each);
        if (array.length) arrays.push(array);
      });
    if (arrays.length) {
      generateCombinations(arrays, [], 0, result);
    }
    set("variants_data", result);
  }

  return (
    <div className="product-main">
      <HeadingMain text={isEditing ? "Edit Product" : "Add New Product"}>
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      {(isEditing ? isCurrentProductLoaded : true) && (
        <Formik
          initialValues={{
            title: formikValues.title,
            image: "",
            colors: [
              { name: "", colorCode: "#D60000", displaySelector: false },
            ],
            color: "red",
            colorCode: "#D60000",
            brandId: "",
            category: [],
            countries: [],
            states: [],
            maxPrice: "",
            costPrice: "",
            sellingPrice: "",
            shippingCharge: "",
            deliveryCharge: "",
            description: "",
            productImages: [],
            productType: "",
            productTypeName: "",
            productTypeFieldList: [],
            productDynamicFieldList: [
              { id: "0", label: "", value: "" },
              { id: "1", label: "", value: "" },
            ],
            variants: [],
            variants_details: {},
            variants_data: [],
            productWarranty: [
             

            ],
            ...formikValues,
            // To match user language if a label exists for that language
            // brandId: formikValues?.brandId?.value ? brandList.find(brand => brand.id === formikValues?.brandId?.value) : "",
          }}
          onSubmit={(values) => {
            console.log("values", values);
            isEditing ? handleUpdate(values) : handleSave(values);
          }}
          validationSchema={isEditing ? updateProductSchema : addProductSchema}
          //enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="user-topcard-main">
             
                <div className="uploaders ">
                  <div className="container">
                    <div className=" add-product">
                      <div
                        className="product-file-uploader"
                        onClick={handleImageUploaderClick}
                        onDragOver={handleDragOver}
                        onDrop={handleSelectImage}
                      >
                        <div>Drag and drop a file or select add Image</div>
                      </div>
                      <input
                        type="file"
                        name="image"
                        ref={hiddenImageUploader}
                        onChange={(e) => {
                          //handleChange(e);
                          handleChooseImage(e);
                          setFieldValue("image", e.target.files[0]?.name || "");
                          e.target.value = null;
                        }}
                        accept="image/*"
                        hidden
                      />
                      <ErrorMessage
                        touched={touched}
                        errors={errors}
                        name="image"
                      />
                      <h1 className="file-uploader-heading">
                        {t("Product Photos")}
                      </h1>
                      <p className="file-uploader-text">
                        {t("Click above to upload photos")}
                      </p>
                    </div>

                    {productImageFiles?.length > 0 && (
                      <div className="uploaded-images">
                        {productImageFiles.map((file, index) => {
                          if (!file) return null;
                          return (
                            <div
                              key={
                                typeof file === "string"
                                  ? file + index
                                  : file.name + index + file.lastModified
                              }
                              className="image-box"
                            >
                              {typeof file === "string" ? (
                                <img
                                  className="view-image"
                                  src={file}
                                  alt="product"
                                />
                              ) : (
                                <img
                                  className="view-image"
                                  src={URL.createObjectURL(file)}
                                  alt="product"
                                />
                              )}

                              <img
                                src={deleteicon}
                                alt="delete"
                                style={{
                                  objectFit: "contain",
                                  width: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let newImageFiles = [...productImageFiles];
                                  newImageFiles[index] = null;
                                  newImageFiles = newImageFiles.filter(
                                    (file) => file !== null
                                  );
                                  if (newImageFiles.length === 0)
                                    setFieldValue("image", "");
                                  setProductImageFiles(newImageFiles);
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {currentImage && (
                      <Modal
                        isOpen={cropImageModal}
                        toggle={onCropImageModal}
                        size="lg"
                      >
                        <ModalHeader toggle={onCropImageModal}>
                          Crop Image
                        </ModalHeader>
                        <ModalBody>
                          {currentImage && (
                            <ImageCropper
                              image={currentImage}
                              onFileChange={onGetCroppedImage}
                              setFieldValue={setFieldValue}
                              imageUpdate={false}
                              aspectRatio={16 / 16}
                            />
                          )}

                          <div className="modal-btn-div">
                            <PrimaryButton
                              title="Cancel"
                              onClick={onCropImageModal}
                              color="danger"
                              style={{ backgroundColor: "#ff0000" }}
                            />
                            <PrimaryButton title="Save" onClick={onCropSave} />
                          </div>
                        </ModalBody>
                      </Modal>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <h1 className="topcard-heading">{t("Basic Details")}</h1>
                  {/* <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle clg11"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Dropdown button
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <Form>
                  <Row>
                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="brandId" className="text_1">
                        {t("Select product Category")}
                      </Label>

                      <MultiLevelSelect
                        options={categoryList}
                        onChange={(selected) => {
                          setFieldValue("category", selected);
                        }}
                        loadOptions={loadDebounceOptionsForCategory}
                        multi={true}
                        value={values.category}
                        placeholder="Categories"
                        isParentClickable={true}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="category"
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="title" className="text_1">
                        {t("Product Name")}
                      </Label>
                      <Input
                        id="title"
                        name="title"
                        placeholder={t("Enter Product Name")}
                        type="text"
                        className="product-form-group"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="title"
                      />
                    </FormGroup>

                    <FormGroup>
                      {availableLanguages.map((language) => (
                        <div key={language.value}>
                          <Label
                            for={`title_${language.value}`}
                            className="text_1"
                          >
                            {t("Product Name")} {t("in")} {t(language.title)} (
                            {language.symbol})
                          </Label>
                          <Input
                            id={`title_${language.value}`}
                            name={`title_${language.value}`}
                            placeholder={t(
                              `Enter Product Name ${t("in")} ${t(
                                language.title
                              )} (${language.symbol})`
                            )}
                            type="text"
                            className="product-form-group"
                            value={values[`title_${language.value}`]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name={`title_${language.value}`}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </Row>
                </Form>
              </div>
              <div className="user-topcard-main">
                <div className="header">
                  <h1 className="topcard-heading">{t("Product Details")}</h1>
                </div>
                <Form>
                  <Row>
                    <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="brandId" className="text_1">
                        {t("Brand")}
                      </Label>
                      <AsyncSelect
                        loadOptions={loadDebounceOptions}
                        styles={customStyles}
                        defaultOptions={brandList}
                        placeholder="Select..."
                        onChange={(selected) =>
                          setFieldValue("brandId", selected)
                        }
                        name="brandId"
                        value={values.brandId}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="brandId"
                      />
                    </FormGroup>

                    <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="type" className="text_1">
                        {t("Product Type")}
                      </Label>
                      <Input
                        id="type"
                        name="type"
                        type="select"
                        className="product-form-group"
                        onChange={(e) =>
                          handleChangeProductType(e, setFieldValue)
                        }
                        value={values.productType}
                      >
                        <option value="">--{t("Select")}--</option>
                        {productTypesTemplate.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="title" className="text_1">
                        {t("Power (BHP)")}
                      </Label>
                      <Input
                        id="power"
                        name="power"
                        placeholder={t("")}
                        type="text"
                        className="product-form-group"
                        value={values.power}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="power"
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    {values.colors?.length > 0 && (
                      <div className="color-selectors col-lg-3 clg1">
                        {values.colors.map((color, index) => {
                          if (!color) return null;
                          return (
                            <>
                              <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-12">
                                <Label
                                  for="title"
                                  style={{ marginBottom: "8px" }}
                                  className="text_1"
                                >
                                  {t("Product Color")}
                                </Label>
                                <div className="color-box">
                                  <Input
                                    className="color-name"
                                    name={`colors.${index}.name`}
                                    placeholder={t("Enter Name of Color")}
                                    value={color.name}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      const newArray = [...values.colors];
                                      newArray[index].name = e.target.value;
                                      setFieldValue("colors", newArray);
                                    }}
                                  />
                                  <div
                                    className="color-selector"
                                    name="color-selector"
                                    style={{
                                      backgroundColor: color.colorCode,
                                    }}
                                    onClick={() => {
                                      const newColors = values.colors.map(
                                        (item, cIndex) => {
                                          if (index === cIndex) return item;
                                          return {
                                            ...item,
                                            displaySelector: false,
                                          };
                                        }
                                      );
                                      newColors[index].displaySelector =
                                        !newColors[index].displaySelector;
                                      setFieldValue("colors", newColors);
                                    }}
                                  />
                                  <img
                                    src={deleteicon}
                                    alt="delete"
                                    style={
                                      document.dir === "ltr"
                                        ? { marginRight: "-1.5em" }
                                        : { marginLeft: "-1.5em" }
                                    }
                                    onClick={() => {
                                      const newArray = [...values.colors];
                                      newArray[index] = null;
                                      setFieldValue(
                                        "colors",
                                        newArray.filter((item) => item !== null)
                                      );
                                    }}
                                  />
                                  {color.displaySelector ? (
                                    <div className="custom-picker">
                                      <div className="for-position">
                                        <ChromePicker
                                          color={color.colorCode}
                                          onChangeComplete={(e) => {
                                            const newColors = [
                                              ...values.colors,
                                            ];
                                            newColors[index].colorCode = e.hex;
                                            setFieldValue("colors", newColors);
                                          }}
                                        />
                                        <div
                                          className="close"
                                          onClick={() => {
                                            const newColors = values.colors.map(
                                              (item, cIndex) => {
                                                return {
                                                  ...item,
                                                  displaySelector: false,
                                                };
                                              }
                                            );
                                            setFieldValue("colors", newColors);
                                          }}
                                        >
                                          Close
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {errors.colors &&
                                  touched.colors &&
                                  touched.colors[index] &&
                                  errors.colors[index] && (
                                    <span className="error">
                                      {errors.colors[index].name ||
                                        errors.colors[index]}
                                    </span>
                                  )}
                              </FormGroup>

                              {/* <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-12">
                                <Label for="title" className="text_1">
                                  {t("Warranty")}
                                </Label>
                                <Input
                                  id="warranty"
                                  name="warranty"
                                  placeholder={t("")}
                                  type="text"
                                  className="product-form-group"
                                  value={values.warranty}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  touched={touched}
                                  name="warranty"
                                />
                              </FormGroup> */}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </Row>
                  {(values.productDynamicFieldList?.length > 0 ||
                    values.productTypeFieldList.length > 0) && (
                    <p className="dynamicfields-title">
                      {t("Additional Details")}
                    </p>
                  )}
                  {values.productTypeFieldList &&
                    values.productTypeFieldList.length > 0 &&
                    values.productTypeFieldList.map((item, index) => {
                      return (
                        <>
                          <div className="dynamicfield" key={item.id}>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                              <FormGroup>
                                <Input
                                  name={item.id}
                                  type="text"
                                  className="product-form-group disabled"
                                  // Get the language specific field label from template
                                  // Language specific data is replaced in productTypesTemplate
                                  // when languae is chnaged
                                  value={
                                    productTypesTemplate.find(
                                      (type) => type.id == values.productType
                                    )?.fields[index]?.label || item.label
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3 values-div">
                              <FormGroup>
                                <Input
                                  id={item.label}
                                  name={item.label}
                                  placeholder={t("Enter") + " " + item.label}
                                  type="text"
                                  className="product-form-group"
                                  value={item.value || ""}
                                  onChange={(e) => {
                                    let newProuductTypeFieldList = [
                                      ...values.productTypeFieldList,
                                    ];
                                    newProuductTypeFieldList[index].value =
                                      e.target.value;
                                    /* newProuductTypeFieldList = values.productTypeFieldList.map(each => {
                                    return {
                                      ...each,
                                      value: each.label === e.target.name ? e.target.value : each.value
                                    }
                                  }) */
                                    setFieldValue(
                                      "productTypeFieldList",
                                      newProuductTypeFieldList
                                    );
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <br />
                        </>
                      );
                    })}
                  {values.productDynamicFieldList &&
                    values.productDynamicFieldList?.length > 0 &&
                    values.productDynamicFieldList.map((item, index) => {
                      return (
                        <>
                          <div className="dynamicfield" key={item.id}>
                            <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-3">
                              <Input
                                name={`productDynamicFieldList.${index}.label`}
                                placeholder={
                                  t("Enter label") + " " + (index + 1)
                                }
                                type="text"
                                className="product-form-group"
                                value={item.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3 values-div">
                              <div className="delete-container">
                                <FormGroup className="input">
                                  <Input
                                    name={`productDynamicFieldList.${index}.value`}
                                    placeholder={
                                      t("Enter value") + " " + (index + 1)
                                    }
                                    type="text"
                                    className="product-form-group"
                                    value={item.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </FormGroup>
                                <img
                                  className="delete-icon"
                                  name={item.id}
                                  src={deleteicon}
                                  alt="delete"
                                  onClick={(e) => {
                                    let newProuductDynamicFieldList = [];
                                    newProuductDynamicFieldList =
                                      values.productDynamicFieldList.filter(
                                        (each, i) => i !== index
                                      );
                                    setFieldValue(
                                      "productDynamicFieldList",
                                      newProuductDynamicFieldList
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                        </>
                      );
                    })}
                </Form>
                <div className="add-dynamic">
                  <IconButton
                    title={t("Add Dynamic")}
                    icontype="add"
                    onClick={() => handleAddDynamic(values, setFieldValue)}
                  ></IconButton>
                </div>
              </div>

              <div className="user-topcard-main ">
                <div className="header">
                  <h1 className="topcard-heading">{t("Variants")}</h1>
                </div>
                <div className="color-selector-header">
                  <Label for="color-selector">{t("Variants")}</Label>
                  {!publicId && (
                    <IconButton
                      title="Add"
                      icontype="add"
                      onClick={() => {
                        setFieldValue("variants", [...values.variants, ""]);
                      }}
                    ></IconButton>
                  )}
                </div>
                {values.variants?.length > 0 && (
                  <div className="color-selectors">
                    {values.variants.map((variant, index) => {
                      return (
                        <>
                          <FormGroup className="col-12 col-md-4">
                            <div className="color-box">
                              <Input
                                className="color-name"
                                name={`variants.${index}`}
                                placeholder={
                                  t("Enter Name of variant") + " " + (index + 1)
                                }
                                value={variant}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={publicId ? true : false}
                              />
                              {!publicId && (
                                <img
                                  src={deleteicon}
                                  alt="delete"
                                  style={
                                    document.dir === "ltr"
                                      ? { marginRight: "-1.5em" }
                                      : { marginLeft: "-1.5em" }
                                  }
                                  onClick={() => {
                                    const newArray = [...values.variants];
                                    newArray[index] = null;
                                    setFieldValue(
                                      "variants",
                                      newArray.filter((item) => item !== null)
                                    );
                                  }}
                                />
                              )}
                            </div>
                            {errors.variants &&
                              touched.variants &&
                              touched.variants[index] &&
                              errors.variants[index] && (
                                <span className="error">
                                  {errors.variants[index]}
                                </span>
                              )}
                          </FormGroup>
                          {/* {availableLanguages.map((language) => {
                            return (
                              <div className="col-12 col-md-4 color-box">
                                <Input
                                  className="color-name"
                                  name={`varaints.${index}.variant`}
                                  placeholder={`${t("Enter Name of Variant")} ${
                                    index + 1
                                  } ${t("in")} ${t(language.variant)} (${
                                    language.symbol
                                  })`}
                                  value={variant["variant_" + language.value]}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const newArray = [...values.variants];
                                    newArray[index][
                                      "variant_" + language.value
                                    ] = e.target.value;
                                    setFieldValue("variants", newArray);
                                  }}
                                />
                              </div>
                            );
                          })} */}
                          <br />
                        </>
                      );
                    })}
                    {!publicId && (
                      <div className="d-flex justify-content-end">
                        <PrimaryButton
                          title="Submit Variants"
                          onClick={() => {
                            let varaints = values.variants || [];
                            generateVariantsDetails(
                              varaints,
                              values?.variants_details,
                              setFieldValue
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                {Object.keys(values.variants_details).length > 0 ? (
                  <div className="color-selectors">
                    <div className="color-selector-header">
                      <h4 for="color-selector">{t("Variants Details")}</h4>
                    </div>
                    {Object.keys(values.variants_details).map((key) => {
                      return (
                        <>
                          <div className="color-selector-header">
                            <Label for="color-selector">{key}:</Label>
                            <IconButton
                              title="Add"
                              icontype="add"
                              onClick={() => {
                                setFieldValue(`variants_details.${key}`, [
                                  ...values.variants_details[key],
                                  "",
                                ]);
                              }}
                            ></IconButton>
                          </div>
                          {values.variants_details[key]?.length > 0 && (
                            <div className="color-selectors">
                              {values.variants_details[key]?.map(
                                (each, index) => {
                                  return (
                                    <>
                                      <FormGroup className="col-12 col-md-4">
                                        <div className="color-box">
                                          <Input
                                            className="color-name"
                                            name={`variants_details.${key}.${index}`}
                                            placeholder={
                                              t(key) + " " + (index + 1)
                                            }
                                            value={each}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                          <img
                                            src={deleteicon}
                                            alt="delete"
                                            style={
                                              document.dir === "ltr"
                                                ? { marginRight: "-1.5em" }
                                                : { marginLeft: "-1.5em" }
                                            }
                                            onClick={() => {
                                              const newArray = [
                                                ...values.variants_details[key],
                                              ];
                                              newArray[index] = null;
                                              setFieldValue(
                                                `variants_details.${key}`,
                                                newArray.filter(
                                                  (item) => item !== null
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                      </FormGroup>

                                      <br />
                                    </>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                    <div className="d-flex justify-content-end">
                      <PrimaryButton
                        title="Submit"
                        onClick={() =>
                          getAllCombinations(
                            values.variants_details,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {values.variants_data && values.variants_data.length ? (
                  <div className="variants_data">
                    <h4 className="">Variants Combinations</h4>
                    {values.variants_data &&
                      values.variants_data.length > 0 &&
                      values?.variants_data.map((eachV, index) => (
                        <div className="each-combination">
                          <div className="d-flex gap-2">
                            <h6>{index + 1}.</h6>
                            {Object.keys(eachV.variant).length &&
                              Object.keys(eachV.variant).map((key) => (
                                <div>
                                  {key}: {eachV.variant[key]}
                                </div>
                              ))}
                            <img
                              src={deleteicon}
                              alt="delete-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let v_data = values.variants_data || [];
                                v_data = v_data.filter(
                                  (el, ind) => ind !== index
                                );
                                setFieldValue("variants_data", v_data);
                              }}
                            />
                          </div>
                          <div className="pricing-details mt-2">
                            <FormGroup className="d-flex gap-2">
                              <Label>Same Price as Product</Label>
                              <input
                                checked={eachV.isSamePrice}
                                type="checkbox"
                                onChange={(e) => {
                                  setFieldValue(
                                    `variants_data.${index}.isSamePrice`,
                                    e.target.checked
                                  );
                                }}
                              />
                            </FormGroup>

                            <div className="file-uploader">
                              <input
                                type="file"
                                name="image"
                                onChange={(e) => {
                                  let images = [...eachV?.product_images] || [];

                                  let file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.onload = function () {
                                    const image = new Image();
                                    image.src = reader.result;

                                    image.onload = function () {
                                      // Example: Change aspect ratio to 16:9
                                      const newAspectRatio = 12 / 16;
                                      const newWidth =
                                        image.height * newAspectRatio;

                                      const canvas =
                                        document.createElement("canvas");
                                      canvas.width = newWidth;
                                      canvas.height = image.height;

                                      const context = canvas.getContext("2d");
                                      context.drawImage(
                                        image,
                                        0,
                                        0,
                                        newWidth,
                                        image.height
                                      );

                                      canvas.toBlob((blob) => {
                                        const modifiedFile = new File(
                                          [blob],
                                          file.name,
                                          { type: file.type }
                                        );
                                        images.push(modifiedFile);
                                        setFieldValue(
                                          `variants_data.${index}.product_images`,
                                          images || []
                                        );
                                      }, file.type);
                                    };
                                  };
                                  reader.readAsDataURL(file);
                                }}
                                accept="image/*"
                              />
                              <h1 className="file-uploader-heading">
                                {t("Product Photos")}
                              </h1>
                              <p className="file-uploader-text">
                                {t("Click above to upload photos")}
                              </p>
                            </div>
                            {eachV?.product_images?.length > 0 && (
                              <div className="uploaders ">
                                <div className="container">
                                  <div className="uploaded-images d-flex gap-2 justify-content-start">
                                    {eachV?.product_images.map(
                                      (file, imageIndex) => {
                                        if (!file) return null;
                                        return (
                                          <div
                                            key={
                                              typeof file === "string"
                                                ? file + index
                                                : file.name +
                                                  index +
                                                  file.lastModified
                                            }
                                            className="image-box"
                                          >
                                            {typeof file === "string" ? (
                                              <img
                                                className="view-image"
                                                src={file}
                                                alt="product"
                                              />
                                            ) : (
                                              <img
                                                className="view-image"
                                                src={URL.createObjectURL(file)}
                                                alt="product"
                                                style={{
                                                  aspectRatio: "12/16",
                                                  objectFit: "contain",
                                                }}
                                              />
                                            )}

                                            <img
                                              src={deleteicon}
                                              alt="delete"
                                              style={{
                                                objectFit: "contain",
                                                width: "15px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                let newImageFiles = [
                                                  ...eachV.product_images,
                                                ];
                                                newImageFiles[imageIndex] =
                                                  null;
                                                newImageFiles =
                                                  newImageFiles.filter(
                                                    (file) => file !== null
                                                  );
                                                setFieldValue(
                                                  `variants_data.${index}.product_images`,
                                                  newImageFiles
                                                );
                                              }}
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {!eachV.isSamePrice && (
                              <div>
                                <div className="row">
                                  <FormGroup className="col-12 col-md-6">
                                    <Label for="sellingPrice">
                                      {t("Selling Price")}
                                    </Label>
                                    <Input
                                      id="sellingPrice"
                                      name={`variants_data.${index}.sellingPrice`}
                                      placeholder="AED | 150"
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.sellingPrice}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      name="eachV.sellingPrice"
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-12 col-md-6">
                                    <Label for="tax">{t("Tax")}</Label>
                                    <Input
                                      id="tax"
                                      name={`variants_data.${index}.tax`}
                                      placeholder={t("Tax")}
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.tax}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </div>
                                <div className="row">
                                  <FormGroup className="col-12 col-md-6 col-lg-3">
                                    <Label for="costPrice">
                                      {t("Cost Price")}
                                    </Label>
                                    <Input
                                      id="costPrice"
                                      name={`variants_data.${index}.costPrice`}
                                      placeholder="AED | 150"
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.costPrice}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      name="eachV.costPrice"
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-12 col-md-6 col-lg-3">
                                    <Label for="maxPrice">
                                      {t("Max Price")}
                                    </Label>
                                    <Input
                                      id="maxPrice"
                                      name={`variants_data.${index}.maxPrice`}
                                      placeholder="AED | 150"
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.maxPrice}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      name="eachV.maxPrice"
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-12 col-md-6 col-lg-3">
                                    <Label for="shippingCharge">
                                      {t("Shipping Charge")}
                                    </Label>
                                    <Input
                                      id="shippingCharge"
                                      name={`variants_data.${index}.shippingCharge`}
                                      placeholder="AED | 150"
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.shippingCharge}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      name="eachV.shippingCharge"
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-12 col-md-6 col-lg-3">
                                    <Label for="deliveryCharge">
                                      {t("Delivery Charge")}
                                    </Label>
                                    <Input
                                      id="deliveryCharge"
                                      name={`variants_data.${index}.deliveryCharge`}
                                      placeholder="AED | 150"
                                      type="number"
                                      className="product-form-group"
                                      value={eachV.deliveryCharge}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      name="eachV.deliveryCharge"
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  ""
                )}

                <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-12">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex gap-3 align-items-center">
                      <p
                        className={styles.titleWarranty}
                        style={{ paddingTop: "15px" }}
                      >
                        {t("Warranties")}
                      </p>
                      <Button
                        onClick={()=>handleAddWarrantyClick(values.productWarranty, setFieldValue)}
                        className={styles.addButton}
                      >
                        {values.productWarranty.length
                          ? "Add Another Warranty"
                          : "+ Add Warranties"}
                      </Button>
                    </div>
                    
                  </div>

                  



                  {values.productWarranty.map((_, index) => (
                    <FormGroup key={index} className="col-12 mb-3">
                      <Row>
                        <FormGroup className="col-3">
                          <Label for={`productWarranty.${index}.warranty`} className="text_1">
                            {t("Warranty")}
                          </Label>
                          <Input
                            id={`productWarranty.${index}.warranty`}
                            name={`productWarranty.${index}.warranty`}
                            placeholder={t("Enter warranty")}
                            type="text"
                            className={styles.productFormGroup}
                            value={_.warranty}
                            onChange={ handleChange 
                            }
                            onBlur={handleBlur}
                          />
                         {errors.productWarranty && errors.productWarranty[index] && errors.productWarranty[index].warranty && <span className="error">{errors.productWarranty[index].warranty}</span>}
                        </FormGroup>

                        <FormGroup className="col-3">
                          <Label
                            for={`productWarranty.${index}.warrantyPrice`}
                            className="text_1"
                          >
                            {t("Warranty Price")}
                          </Label>
                          <Input
                            id={`productWarranty.${index}.warrantyPrice`}
                            name={`productWarranty.${index}.warrantyPrice`}
                            placeholder={t("Enter price")}
                            type="number"
                            className={styles.productFormGroup}
                            value={_.warrantyPrice}
                            onChange={ handleChange 
                            }
                            onBlur={handleBlur}
                          />
                          {errors.productWarranty && errors.productWarranty[index] && errors.productWarranty[index].warrantyPrice && <span className="error">{errors.productWarranty[index].warrantyPrice}</span>}

                        </FormGroup>

                        <FormGroup className="col-12">
                          <Label
                            for={`productWarranty.${index}.description`}
                            className="text_1"
                          >
                            {t("Terms And Conditions")}
                          </Label>
                          <Input
                            id={`productWarranty.${index}.description`}
                            name={`productWarranty.${index}.description`}
                            placeholder={t("Enter description")}
                            type="textarea"
                            className={styles.productFormGroup}
                            value={_.description}
                            onChange={ handleChange 
                            }
                            onBlur={handleBlur}
                          />
                          {errors.productWarranty && errors.productWarranty[index] && errors.productWarranty[index].description && <span className="error">{errors.productWarranty[index].description}</span>}

                        </FormGroup>
                      </Row>
                      <Button
                      onClick={() =>{
                        let warranties = values.productWarranty || [];
                        warranties.splice(index,1)
                        setFieldValue("productWarranty", warranties)
                      }
                        
                      } 
                      color="danger"
                      className={styles.removeButton}
                    >
                      Remove
                    </Button>
                    </FormGroup>

                  ))}
                </FormGroup>
              </div>

              <div className="user-topcard-main topcard-description ">
                <div className="header">
                  <h1 className="topcard-heading">
                    {t("Product Description")}
                  </h1>
                </div>
                <Form className="row">
                  <div className="description-form-group">
                    <ReactQuill
                      className="quill-custom"
                      theme="snow"
                      onChange={(html) => {
                        setFieldValue("description", html);
                      }}
                      value={values.description}
                      modules={quillModules}
                      formats={quillFormats}
                      //bounds={'.app'}
                      //placeholder={this.props.placeholder}
                    />
                  </div>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <div className="user-topcard-main topcard-description mt-5">
                          <div className="header">
                            <h1 className="topcard-heading">{`${t(
                              " Description"
                            )} ${t("in")} ${t(language.name)} (${
                              language.symbol
                            })`}</h1>
                          </div>
                          <Form className="row">
                            <div className="description-form-group">
                              <ReactQuill
                                className="quill-custom"
                                theme="snow"
                                onChange={(html) => {
                                  setFieldValue(
                                    "description_" + language.value,
                                    html
                                  );
                                }}
                                value={values["description_" + language.value]}
                                modules={quillModules}
                                formats={quillFormats}
                              />
                            </div>
                          </Form>
                        </div>
                      </>
                    );
                  })}
                </Form>
              </div>

              <div className="class-usercard-flex clg9 row">
                {/* <div className="col-6 ">
                  <div
                    className="product-bottomcard-main clg2 col-12"
                    style={isEditing ? { display: "none" } : {}}
                  >
                    <h1 className="user-heading">{t("Inventory")}</h1>
                    <p className="user-text">
                      {t(
                        "Manage stock levels of this product through Mventus."
                      )}
                      .
                    </p>
                    <Form>
                      <div className="row">
                        <FormGroup className="col-12 col-md-12">
                          <Label for="sellingPrice" className="text_1">
                            {t("SKU (Stock Keeping Unit)")}
                          </Label>
                          <Input
                            id="sellingPrice"
                            name="sellingPrice"
                            placeholder="Product Name"
                            type="number"
                            className="product-form-group"
                            value={
                              values.sellingPrice > -1
                                ? values.sellingPrice
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="sellingPrice"
                          />
                        </FormGroup>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2
                          className="user-heading"
                          style={{ color: "#56E20A" }}
                        >
                          {t("Generate SKU automatically")}
                        </h2>
                        <div className="d-flex btn-3">
                          <IconButton
                            title="Add Product"
                            onClick={() => navigate("/products/add-product")}
                            icontype="add"
                          ></IconButton>
                        </div>
                      </div>
                      <div>
                        <h1 className="user-heading">{t("Stock Quantity")}</h1>
                      </div>
                      <div className="row">
                        <FormGroup className="col-12 col-md-12 col-lg-12">
                          <Label for="costPrice" className="text_1">
                            {t("Current stock quantity")}
                          </Label>
                          <Input
                            id="costPrice"
                            name="costPrice"
                            placeholder="0"
                            type="number"
                            className="product-form-group"
                            value={
                              values.costPrice > -1 ? values.costPrice : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="costPrice"
                          />
                        </FormGroup>
                      </div>
                      <h1 className="user-heading">
                        {t("Low stock and reordering")}
                      </h1>
                      <p className="user-text text_2">
                        {t(
                          "Mventus will automatically notify you and pre-fill the reorder quantity set for future stock orders."
                        )}
                        .
                        <span style={{ color: "##56E20A" }}>
                          {t("Learn more")}
                        </span>
                      </p>
                      <div className="row">
                        <FormGroup className="col-12 col-md-6 col-lg-6">
                          <Label for="costPrice" className="text_1">
                            {t("Low stock level")}
                          </Label>
                          <Input
                            id="costPrice"
                            name="costPrice"
                            placeholder="0"
                            type="number"
                            className="product-form-group"
                            value={
                              values.costPrice > -1 ? values.costPrice : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="costPrice"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-6 col-lg-6">
                          <Label for="costPrice" className="text_1">
                            {t("Reorder quantity")}
                          </Label>
                          <Input
                            id="costPrice"
                            name="costPrice"
                            placeholder="0"
                            type="number"
                            className="product-form-group"
                            value={
                              values.costPrice > -1 ? values.costPrice : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="costPrice"
                          />
                        </FormGroup>
                      </div>
                      <p className="user-text">
                        {t("Receive low stock notification.")}.
                      </p>
                      <FormGroup>
                    <Label for="tax">{t("Tax")}</Label>
                    <Input
                      id="tax"
                      name="tax"
                      placeholder={t("No Tax")}
                      type="number"
                      className="product-form-group"
                    />
                  </FormGroup>
                    </Form>
                  </div>
                </div> */}
                <div className="clg4 col-12 ">
                  <div
                    className="product-bottomcard-main clg3 col-12"
                    style={isEditing ? { display: "none" } : {}}
                  >
                    <h1 className="user-heading">{t("Pricing")}</h1>
                    <Form>
                      <div className="row">
                        <FormGroup className="col-12 col-md-12">
                          <Label for="sellingPrice" className="text_1">
                            {t("Selling Price")}
                          </Label>
                          <Input
                            id="sellingPrice"
                            name="sellingPrice"
                            placeholder="AED | 150"
                            type="number"
                            className="product-form-group"
                            value={
                              values.sellingPrice > -1
                                ? values.sellingPrice
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="sellingPrice"
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <h1 className="user-heading">{t("Retail Sales")}</h1>
                        <p className="user-text">
                          {t("Allow sales of this product at checkout")}.
                        </p>
                      </div>
                      <div className="row">
                        <FormGroup className="col-12 col-md-6 col-lg-4">
                          <Label for="costPrice" className="text_1">
                            {t("Cost Price")}
                          </Label>
                          <Input
                            id="costPrice"
                            name="costPrice"
                            placeholder="AED | 150"
                            type="number"
                            className="product-form-group"
                            value={
                              values.costPrice > -1 ? values.costPrice : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="costPrice"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-6 col-lg-4">
                          <Label for="maxPrice" className="text_1">
                            {t("Max Price")}
                          </Label>
                          <Input
                            id="maxPrice"
                            name="maxPrice"
                            placeholder="AED | 150"
                            type="number"
                            className="product-form-group"
                            value={values.maxPrice > -1 ? values.maxPrice : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="maxPrice"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-6 col-lg-4">
                          <Label for="shippingCharge" className="text_1">
                            {t("Shipping Charge")}
                          </Label>
                          <Input
                            id="shippingCharge"
                            name="shippingCharge"
                            placeholder="AED | 150"
                            type="number"
                            className="product-form-group"
                            value={
                              values.shippingCharge > -1
                                ? values.shippingCharge
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="shippingCharge"
                          />
                        </FormGroup>
                      </div>
                      <div className="row">
                        <FormGroup className="col-12 col-md-12 col-lg-12">
                          <Label for="shippingCharge" className="text_1">
                            {t("Tax Class")}
                          </Label>
                          <Input
                            id="shippingCharge"
                            name="shippingCharge"
                            placeholder="AED | 150"
                            type="number"
                            className="product-form-group"
                            value={
                              values.shippingCharge > -1
                                ? values.shippingCharge
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="shippingCharge"
                          />
                        </FormGroup>
                      </div>

                      {/* <FormGroup>
                    <Label for="tax">{t("Tax")}</Label>
                    <Input
                      id="tax"
                      name="tax"
                      placeholder={t("No Tax")}
                      type="number"
                      className="product-form-group"
                    />
                  </FormGroup> */}
                      <div className="mt-4">
                        <h1 className="user-heading">
                          {t("Team member commission")}
                        </h1>
                        <p className="user-text">
                          {t(
                            "Calculate team member commission when the product is sold"
                          )}
                          .
                        </p>
                      </div>
                    </Form>
                  </div>
                  {/* <div
                    className="save-btn-float clg5 col"
                    onClick={isAddProductInProgress ? null : handleSubmit}
                  >
                    <PrimaryButton
                      // style={{width:"180%",color:"black"}}
                      className="clg7"
                      type="submit"
                      title={isEditing ? "Update" : "Upload Product"}
                      isLoading={isAddProductInProgress}
                    />
                  </div> */}
                </div>
              </div>
              <div className="errors-after-sumbit">
                {errorsOnSubmit?.map((error) => (
                  <p>{error}</p>
                ))}
              </div>
              <div
                className="save-btn-float"
                onClick={isAddProductInProgress ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Save"}
                  isLoading={isAddProductInProgress}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
}
