import React from "react";
import i18n from "../../i18n";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = () => {
  return (
    <ToastContainer
      autoClose={2000}
      hideProgressBar
      theme="light"
    ></ToastContainer>
  );
};

const getPosition = () => {
  return document.getElementsByTagName("html")[0].dir === "ltr"
    ? "top-right"
    : "top-left";
};

export const notifySuccess = (text) =>
  toast.success(i18n.t(text), { position: getPosition() });
export const notifyError = (text) =>
  toast.error(i18n.t(text), { position: getPosition() });
export const notifyWarn = (text) =>
  toast.warn(i18n.t(text), { position: getPosition() });

export default Toaster;
