import axios from "axios";
import useConfig from "../hooks/useConfig";
import * as Storage from "../store/LocalStorage";
import { notifyError } from "../assets/elements/Toaster";
import useAuth from "../hooks/useAuth";

export default function FetchServices() {
  const config = useConfig();
  let { user } = useAuth();

  const isNotAuthorized = (status) => {
    if (status === 401) {
      notifyError("Token expired! please login again");
      Storage.remove(config.sessionKey);
      window.location.reload();
    }
  };

  const AxiosServices = {
    AxiosService: function (
      API,
      payload,
      authNeeded = true,
      type = "POST",
      concurrencyStamp,
      accessToken,
      fileFlag,
      blob
    ) {
      let authData = {};
      if (authNeeded) {
        authData = {
          token: user?.token ?? accessToken,
        };
      }
      let header = {
        "content-type": "application/json",
        Authorization: "Bearer " + authData.token,
      };
      if (fileFlag)
        header = {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authData.token,
        };

      return axios({
        method: type,
        url: API,

        headers: concurrencyStamp
          ? {
              ...header,
              "x-coreplatform-concurrencystamp": concurrencyStamp,
            }
          : header,
        params:
          type === "GET" || type === "DELETE"
            ? {
                ...payload,
              }
            : {},
        data: payload,
        ...(blob ? { responseType: "blob" } : {}),
      })
        .then(function (response) {
          var resData = response.data;
          let headers = response.headers;
          let totalCount =
            headers["x-coreplatform-total-records"] &&
            headers["x-coreplatform-total-records"];
          let publicId = headers["public-id"] && headers["public-id"];
          let fileName = headers["file-name"] && headers["file-name"];
          let token = headers["token"] && headers["token"];
          if (!resData) {
            return {
              status: false,
              success: true,
              data: {},
              ...(totalCount ? { totalCount } : {}),
              ...(publicId ? { publicId } : {}),
              ...(fileName ? { fileName } : {}),
              ...(token ? { token } : {}),
            };
          }
          const apiResponse = resData;
          return {
            status: true,
            data: apiResponse,
            success: true,
            ...(totalCount ? { totalCount } : {}),
            ...(publicId ? { publicId } : {}),
            ...(fileName ? { fileName } : {}),
            ...(token ? { token } : {}),
          };
        })
        .catch(function (res) {
          let data = res?.response || {};
          let status = data.status;
          isNotAuthorized(status);
          return {
            status: false,
            success: false,
            message: res?.message,
            ...res?.response?.data,
          };
        });
    },
  };
  return AxiosServices;
}
