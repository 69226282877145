import React from "react";
import "./Style.scss";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoader } from "../loaders";

export default function PrimaryButton({ isLoading, title,  ...props }) {
  const { t } = useTranslation();
  return (
    <div className="save-btn-div">
      <Button disabled={isLoading} className="save-btn " {...props}>
        {isLoading ? <ButtonLoader /> : t(title)}
      </Button>
    </div>
  );
}
