import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import HeadingMain from "../../assets/elements/HeadingMain";
import { ButtonLoader } from "../../assets/loaders";
import { Formik } from "formik";
import "./UserStatusChange.scss";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { useTranslation } from "react-i18next";

const UserStatusChange = ({
  open,
  setStatusClicked,
  updateUserStatus,
  userCurrentStatus,
  isUpdateStatusInProgress,
}) => {
  const [modal, setModal] = useState(open);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { t } = useTranslation();

  const schema = {
    changeStatusSchema: Yup.object().shape({
      status: Yup.string().required(t("Status")+" "+t("is required")),
    }),
  
    rejectedSchema: Yup.object().shape({
      status: Yup.string().required(t("Status")+" "+t("is required")),
      remark: Yup.string().required(t("Remark")+" "+t("is required")),
    }),
  };

  const onStatusChange = (selected) => {
    setSelectedStatus(selected.target.value);
  };

  const toggle = () => {
    setStatusClicked(false);
    setModal(!modal);
  };
  const statusOptions = ["approved","rejected", "blocked"].filter(
    (item) => {
      return (
        !(userCurrentStatus === "active" && item === "approved") &&
        item !== userCurrentStatus
      );
    }
  );
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          <HeadingMain text="Change user status" />
        </ModalHeader>
        <ModalBody>
          <div className="status-change-main">
            <Formik
              initialValues={{
                status: "",
                remark: "",
              }}
              onSubmit={(values) => {
                updateUserStatus(values);
              }}
              validationSchema={
                selectedStatus === "rejected"
                  ? schema.rejectedSchema
                  : schema.changeStatusSchema
              }
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                touched,
                handleSubmit,
              }) => (
                <>
                  <div>
                    <FormGroup className="login-input-group">
                      <Label for="status">{t("Select Status")}</Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        class="user-form-group"
                        value={values.status}
                        onChange={(e) => {
                          handleChange(e);
                          onStatusChange(e);
                        }}
                        onBlur={handleBlur}
                        style={{ textTransform: "capitalize" }}
                      >
                        <option value="">{t("Select Status")}</option>
                        {statusOptions &&
                          statusOptions.length > 0 &&
                          statusOptions.map((item) => <option value={item}>{t(item)}</option>)}
                      </Input>
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="status"
                      />
                    </FormGroup>
                    {values.status === "rejected" && (
                      <FormGroup>
                        <Label for="remark">{t("Remarks")}</Label>
                        <Input
                          id="remark"
                          name="remark"
                          placeholder={t("Enter Remark")}
                          type="textarea"
                          className="user-form-group"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remark}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="remark"
                        />
                      </FormGroup>
                    )}
                  </div>
                  <div class="btn-flex-main">
                    <Button type="submit" color="danger" onClick={handleSubmit}>
                      {isUpdateStatusInProgress ? <ButtonLoader /> : t("Submit")}
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      {t("Close")}
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserStatusChange;
