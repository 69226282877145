import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function CustomModal({children, toggleFunction, open, size,title}) {
  return (
      <Modal isOpen={open} toggle={toggleFunction} size={size}>
        <ModalHeader toggle={toggleFunction}>
          {title}
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </Modal>
  );
}
