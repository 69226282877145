import React from "react";
import { Form } from "react-bootstrap";
import "./ChatUi.scss";
import useAuth from "../../hooks/useAuth";

export default function ChatUi({ notifications }) {
  const { user } = useAuth();
  const role = user.role;
  return (
    <div className="chat-ui-main">
      <div className="chat-box">
        <Form className="form">
          {notifications && notifications.length > 0 ? (
            notifications.map((eachCommunication, index) => {
              return (
                <div key={index}>
                  {eachCommunication.source === role ? (
                    <>
                      {eachCommunication.message && (
                        <div className="chat outgoing">
                          <div className="details">
                            <p>
                              {eachCommunication.message}
                              {eachCommunication.document}
                              {eachCommunication.datetime ? (
                                <span className="datetime">
                                  {eachCommunication.datetime}
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {eachCommunication.message && (
                        <div className="chat incoming">
                          <div className="details">
                            <p>
                              {eachCommunication.message}
                              {eachCommunication.document}
                              {eachCommunication.datetime ? (
                                <span className="datetime-outgoing">
                                  {eachCommunication.datetime}
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <p className="no-communication">No Communications Found</p>
          )}
        </Form>
      </div>
    </div>
  );
}
