import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";

const LazyComponent = lazy(() => import("./Designation"));

export default function Designation() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
