import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";
const LazyComponent = lazy(() => import("./Catering.jsx"));
const LazyComponentCategory = lazy(() => import("./RestaurantCategory.jsx"));
const LazyComponentType = lazy(() => import("./RestaurantType.jsx"));
const LazyComponentDetails = lazy(() => import("./RestaurantDetails.jsx"));

export default function Catering() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}

export const RestaurantCategory = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponentCategory />
    </Suspense>
  );
}

export const RestaurantType = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponentType />
    </Suspense>
  );
}

export const RestaurantDetails = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponentDetails />
    </Suspense>
  );
}
