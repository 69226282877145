import React, { useState, useEffect } from "react";
import "./AddSupplier.scss";
import { Form, Input, Label, FormGroup } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import FirebaseSerives from "../../firebase/FirebaseServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import MainServices from "../../services/MainServices";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import closeicon from "../../assets/images/icons/close.svg";
import { useNavigate } from "react-router-dom";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import LocationInMap from "../../assets/map/LocationInMap";

const addSupplierSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is Required"),
  email: Yup.string()
    .required("Email is Required")
    .email("Please enter a valid Email"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Atleast six(6) Characters"),
  phone: Yup.string()
    .required("Phone is required")
    .matches("^[1-9]{1}[0-9]{9}", "Mobile must be valid one")
    .max(10)
    .min(10),
});

export default function AddSupplier() {
  const { t } = useTranslation();
  const { saveUser, getCountries, getStatesByCountry } = MainServices();

  const formikFieldsSchema = ["fullName", "number", "email", "password"];
  const {getAvailableLanguages, getFormikValuesForAllLanguages} = useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const formikValues = getFormikValuesForAllLanguages(formikFieldsSchema);

  const [addSupplierInProgress, setAddSupplierInProgress] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const navigate = useNavigate();

  const addUserData = async (values) => {
    setAddSupplierInProgress(true);
    await FirebaseSerives.Register({
      email: values.email,
      password: values.password,
    })
      .then(async (res) => {
        if (res === "auth/email-already-in-use") {
          notifyError("Email already exists. please use a new mail!");
          setAddSupplierInProgress(false);
        } else {
          notifySuccess("Added successfully!");
          await addSupplierData({
            name: values.fullName,
            phone: values.phone,
            token: res.accessToken,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAddSupplierInProgress(false);
      });
  };

  const addSupplierData = async (details) => {
    await FirebaseSerives.Update(details)
      .then(async (res) => {
        await saveUser({
          name: details.name,
          role: "supplier",
          mobileNumber: `${details.phone}`,
        }, details.token);
        setAddSupplierInProgress(false);
      })
      .catch((err) => {
        setAddSupplierInProgress(false);
        console.log(err);
      });
  };

  const getAndSetCountryList = ({filters}) => {
    const details ={
      filters
    }
    getCountries(details).then((res) => {
      setCountryList(res.data);
    });
  };

  const getAndSetStateList = (countryId) => {
    getStatesByCountry({ countryId }).then((res) => {
      setStateList(res.data);
    });
  };

  const handleChangeCountry = (countryId) => {
    //const countryId = e.target.value;
    if (!countryId) {
      setStateList([]);
      return;
    }
    getAndSetStateList(countryId);
  };

  useEffect(() => {
    //getAndSetAddress();
    const filters = [
      {
        key: "status", eq: "active"
      }
    ]
    getAndSetCountryList({filters});
    // eslint-disable-next-line
  }, []);

  return (
    <div className="supplier-main">
      <HeadingMain text="Add New Supplier">
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      <Formik
        initialValues={formikValues || {
          fullName: "",
          number: "",
          email: "",
          password: "",
        }}
        onSubmit={(values) => {
          addUserData(values);
        }}
        validationSchema={addSupplierSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <>
            <div className="supplier-topcard-main">
              <h1 className="supplier-heading">{t("Suplier Details")}</h1>
              <Form className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <FormGroup>
                    <Label for="supName">{t("Supplier Name")}</Label>
                    <Input
                      id="supName"
                      name="supName"
                      placeholder={t("Enter supplier's name")}
                      type="text"
                      className="user-form-group"
                    />
                  </FormGroup>
                  {availableLanguages.map(language => {
                    return <>
                      <FormGroup>
                        <Label for="supName">{`${t("Supplier Name")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                        <Input
                          name={"supName_"+language.value}
                          placeholder={`${t("Enter supplier's name")} ${t("in")} ${t(language.name)} (${language.symbol})`}
                          type="text"
                          className="user-form-group"
                        />
                      </FormGroup>
                    </>
                  })}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <FormGroup>
                    <Label for="supDesc">{t("Supplier Description")}</Label>
                    <Input
                      id="supDesc"
                      name="supDesc"
                      placeholder={t("Enter supplier's description")}
                      type="text"
                      className="user-form-group"
                    />
                  </FormGroup>
                  {availableLanguages.map(language => {
                    return <>
                      <FormGroup>
                        <Label for="supDesc">{`${t("Supplier Description")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                        <Input
                          name={"supDesc_"+language.value}
                          placeholder={`${t("Enter supplier's description")} ${t("in")} ${t(language.name)} (${language.symbol})`}
                          type="text"
                          className="user-form-group"
                        />
                      </FormGroup>
                    </>
                  })}
                </div>
              </Form>
            </div>
            <div class="class-suppliercard-flex">
              <div className="supplier-bottomcard-main">
                <h1 className="supplier-heading">{t("Contact Information")}</h1>
                <p>
                  {t(
                    "Important client information will only be visible to you and team members"
                  )}
                  .
                </p>
                <Form>
                  <FormGroup>
                    <Label for="fullName">{t("Full Name")}</Label>
                    <Input
                      id="fullName"
                      name="fullName"
                      placeholder={t("Enter full name")}
                      type="text"
                      className="user-form-group"
                      value={values.fullName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="fullName"
                    />
                  </FormGroup>
                  
                  {availableLanguages.map(language => {
                    return <>
                      <FormGroup>
                        <Label for="fullName">{`${t("Full Name")} ${t("in")} ${t(language.name)} (${language.symbol})`}</Label>
                        <Input
                          name={"fullName_"+language.value}
                          placeholder={`${t("Enter full name")} ${t("in")} ${t(language.name)} (${language.symbol})`}
                          type="text"
                          className="user-form-group"
                          value={values["fullName_"+language.value]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </>
                  })}
                  <FormGroup>
                    <Label for="phone">{t("Phone Number")}</Label>
                    <Input
                      id="phone"
                      name="phone"
                      placeholder="8965867895"
                      type="number"
                      className="user-form-group"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="phone"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">{t("Email Address")}</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Johndev@gmail.com"
                      type="email"
                      className="user-form-group"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      className="user-form-group"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="website">{t("Website")}</Label>
                    <Input
                      id="website"
                      name="website"
                      placeholder="www.website.com"
                      type="email"
                      className="user-form-group"
                    />
                  </FormGroup>
                </Form>
              </div>

              <div className="supplier-bottomcard-main product-main address-main">
                <h1 className="supplier-heading">{t("Physical Address")}</h1>
                <Form className="row user-topcard-main container">
                  <LocationInMap 
                    values={values} 
                    setFieldValue={setFieldValue} 
                    handleChange={handleChange} 
                    countryList={countryList}
                    stateList={stateList}
                    handleChangeCountry={handleChangeCountry}
                  />
                  <FormGroup className="col-12 short-description customaddress">
                    <Label for="customaddress" className="title">
                      {t("Enter Address")}
                      <p>{values.customaddress?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="customaddress"
                      as="textarea"
                      className="textarea"
                      value={values.customaddress}
                      onChange={handleChange}
                      maxLength={250}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="customaddress"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 short-description">
                          <Label for="customaddress" className="title">
                            {`${t("Enter Address")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["customaddress_" + language.value]
                                ?.length || 0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"customaddress_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["customaddress_" + language.value]}
                            onChange={handleChange}
                            place={`${t("Enter Enter Address")} ${t(
                              "in"
                            )} ${t(language.name)} (${language.symbol})`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="City">{t("City")}</Label>
                    <Input
                      id="city"
                      name="city"
                      placeholder={t("Enter City")}
                      type="text"
                      className="product-form-group"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="city"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                      return (
                      <>
                          <FormGroup className="col-12 col-md-6 col-lg-6">
                              <Label for="City">{`${t("City")} ${t("in")} ${t(
                                  language.name
                                  )} (${language.symbol})`}</Label>
                              <Input
                                  name={"city_" + language.value}
                                  placeholder={`${t("Enter City")} ${t(
                                      "in"
                                      )} ${t(language.name)} (${language.symbol})`}
                                  type="text"
                                  className="product-form-group"
                                  value={values["city_" + language.value]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              <ErrorMessage
                                  errors={errors}
                                  touched={touched}
                                  name={"city_" + language.value}
                              />
                          </FormGroup>
                      </>
                      );
                  })}
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="country">{t("Country")}</Label>
                    <select
                      className="select product-form-group"
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={(e) => {
                        //setFieldValue("country", e.target.value);
                        handleChange(e)
                        handleChangeCountry(e.target.value);
                        //
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("state", "");
                        }
                      }
                    >
                      <option value="">--Select--</option>
                      {countryList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="country"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="state">{t("State")}</Label>

                    <select
                      className="select product-form-group"
                      id="state"
                      name="state"
                      type="select"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">--Select--</option>
                      {stateList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="state"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="postalcode">{t("Postal Code")}</Label>
                    <Input
                      id="postalcode"
                      name="postalcode"
                      className="product-form-group"
                      placeholder={t("Enter Postal Code")}
                      value={values.postalcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage errors={errors} touched={touched} name="postalcode" />
                  </FormGroup>
                </Form>
              </div>
            </div>
            
            <div className="save-btn-float">
              <PrimaryButton
                type="submit"
                onClick={() => handleSubmit(values)}
                title= "Save"
                isLoading={addSupplierInProgress}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
