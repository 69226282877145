import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";

const LazyComponent = lazy(() => import("./productType.jsx"));


export default function ProductType() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent /> 
    </Suspense>
  );
}
