import React, { useEffect, useState } from "react";
import IconButton from "../../assets/elements/IconButton";
import TableSearch from "../../assets/elements/TableSearch";
import HeadingMain from "../../assets/elements/HeadingMain";
import CustomPagination from "../../assets/elements/Pagination";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import CustomTable from "../../assets/elements/Table";
import "./FAQList.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DeleteModal from "../../assets/elements/DeleteModal";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import {
  filtersToUrlParams,
  filtersToUri,
  paramsToFilters,
  paramsToFiltersObj,
} from "../../assets/functions/filters";
import MainServices from "../../services/MainServices";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import editicon from "../../assets/images/icons/edit-curved.svg";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";

import { urlToFileName } from "../../assets/functions/helpers";

export default function FAQList() {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [descriptionCountArray, setDescriptionCountArray] = useState([0]);
  const [totalCount, setTotalCount] = useState(0);
  const [faqList, setFaqList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const { getBrands, saveBrand, updateBrand, fileUpload } = MainServices();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [addBrandInProgress, setAddBrandInProgress] = useState(false);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [currentBrand, setCurrentBrand] = useState("");
  //const [logoFile, setLogoFile] = useState();
  const [sorts, setSorts] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [formikValues, setFormikValues] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const formikFieldsSchema = ["name", "description"];

  //hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { deBounce } = TimingFunctions();
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const addFAQSchema = Yup.object().shape({
    name: Yup.string().required(t("Title") + " " + t("is required")),
    description: Yup.string().required(
      t("Answer") + " " + t("is required")
    ),
    logo: Yup.mixed().required(t("Image") + " " + t("is required")),
  });

  //apis
  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    setSelectedStatus(filtersObj?.status?.status || "active");
    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    filters =
      filters && filters.length > 0
        ? filters
        : [{ key: "status", eq: "active" }];
    getBrandsList({
      page: pagination.page,
      limit: pagination.limit,
      filters,
      sort: sorts,
    });
    //eslint-disable-next-line
  }, [
    changedParams.limit,
    changedParams.page,
    changedParams.filters,
    changedParams.sort,
    i18n.language,
  ]);

  useEffect(() => {
    let formikValues = getFormikValuesForAllLanguages(formikFieldsSchema);
    setFormikValues(formikValues);
    // eslint-disable-next-line
  }, []);

  const getBrandsList = async ({ page, limit, filters, sort }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      sorting: sort,
    };
    await getBrands(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = resData?.totalCount || 0;
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                _?.brandLanguages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                ..._,
                languageSpecificDetailsObject,
              };
            });
            setFaqList(resData);
            setTotalCount(total);
            setSearchLoading(false);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addBrand = async (values) => {
    setAddBrandInProgress(true);
    setErrorsOnSubmit([]);

    console.log("formikValues", values);
    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    console.log(languages);

    let logoUploadResponse = await fileUpload(values.logo);
    let logoFileURL = logoUploadResponse?.fileName;
    if (!logoFileURL) {
      setErrorsOnSubmit((oldValues) => [
        ...oldValues,
        logoUploadResponse.details.map(
          (item) => item.name + " " + item.message
        ),
      ]);
      return;
    }
    const details = {
      status: "active",
      name: values.name,
      description: values.description,
      logo: logoFileURL,
      languages: languages,
    };
    await saveBrand(details)
      .then((res) => {
        if (res.success) {
          setAddBrandInProgress(false);
          getBrandsList({
            page: pagination.page,
            limit: pagination.limit,
          });
          notifySuccess("Brand Added Successfully!");
          setModal(!modal);
        } else {
          setAddBrandInProgress(false);
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details.map((item) => item.name + " " + item.message)
            )
          );
          notifyError("Error occurred!");
        }
      })
      .catch((error) => {
        setAddBrandInProgress(false);
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        console.log(error);
      });
  };

  const updateBrandList = async (values) => {
    setAddBrandInProgress(true);
    let logoUploadResponse = "";
    let logoFileId = undefined;
    if (typeof values.logo !== "string") {
      logoUploadResponse = await fileUpload(values.logo);
      logoFileId = logoUploadResponse?.fileName;
    }
    if (typeof values.logo === "string") {
      let filename = urlToFileName(values.logo);
      logoFileId = filename;
    }
    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      languages.push(newObj);
    });

    const details = {
      name: values.name,
      description: values.description,
      status: "active",
      logo: logoFileId,
      languages,
    };
    await updateBrand(
      details,
      currentBrand.publicId,
      currentBrand.concurrencyStamp
    )
      .then((res) => {
        if (res.success) {
          setAddBrandInProgress(false);
          openAddBrandModal("");
          getBrandsList({ page: pagination.page, limit: pagination.limit });
          notifySuccess("Brand Updated Successfully!");
        } else {
          setAddBrandInProgress(false);
          notifyError("Error occured!");
        }
      })
      .catch((error) => {
        setAddBrandInProgress(false);
        notifyError("Error occured!");
        console.log(error);
      });
  };

  //fillters
  const onNameSort = () => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  const onSearch = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let msorts = filtersToUri(sorts);
    setSelectedStatus(e.target.value);
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  //pagination functions
  const onPageChange = (page) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  //Modal function
  const openAddBrandModal = (selectedBrand) => {
    if (selectedBrand) {
      let values = {};
      let descriptionCount = [selectedBrand.description?.length];
      let langauges = selectedBrand?.brandLanguages || [];
      langauges.forEach((_) => {
        values[`name_${_.locale}`] = _.name;
        values[`description_${_.locale}`] = _.description;
        descriptionCount.push(_.description?.length);
      });
      setDescriptionCountArray(descriptionCount);
      setFormikValues({ ...formikValues, ...values });
      setCurrentBrand(selectedBrand);
      //setLogoFile(prodcutDescriptionImage);
    } else {
      setCurrentBrand("");
      setFormikValues(getFormikValuesForAllLanguages(formikFieldsSchema));
      setDescriptionCountArray([0]);
      //setLogoFile(null);
    }
    setModal(!modal);
  };

  const deleteBrand = async (brandDetails) => {
    setAddBrandInProgress(true);
    console.log(brandDetails);
    const details = {
      name: brandDetails?.name,
      description: brandDetails?.description,
      status: "inactive",
    };

    await updateBrand(
      details,
      currentBrand.publicId,
      currentBrand.concurrencyStamp
    )
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        setAddBrandInProgress(false);
      });
  };

  const cancelButtonModal = () => {
    setDeleteModal(!deleteModal);
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <Link onClick={() => openAddBrandModal(row)}>
          <img src={editicon} alt="edit" className="action-icon" />
        </Link>

        <Link
          onClick={() => {
            handleDeleteButton(row);
          }}
        >
          <img src={deleteicon} alt="delete" className="action-icon" />
        </Link>
      </div>
    );
  };

  const faqTitleTd = (row) => {
    if (!row) return;

    return (
      <div className="brand-name-container">  
        <p
          style={{
            margin: 0,
          }}
        >
          {row.languageSpecificDetailsObject?.name || row.name}
        </p>
      </div>
    );
  };

  const faqDescriptionTd = (row) => {
    if (!row) return;
    return row.languageSpecificDetailsObject?.description
      ? row.languageSpecificDetailsObject?.description
      : row.description;
  };

  const columns = [
    {
      field: "name",
      text: "Title",
      customStyle: faqTitleTd,
    },
    {
      field: "description",
      text: "Answer",
      customStyle: faqDescriptionTd,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const handleDeleteButton = (selectedBrand) => {
    if (selectedBrand) {
      setCurrentBrand(selectedBrand);
    } else {
      setCurrentBrand("");
    }
    setDeleteModal(!deleteModal);
  };

  return (
    <div className="faq-list-main">
      <HeadingMain text="FAQ" />
      <div className="faq-table-main">
        <div className="table-flex-main">
          <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            searchLoading={searchLoading}
          >
            <div
              className={`filter-grey-main name-sort ${
                sorts?.name ? "sort-hightlight" : ""
              }`}
              onClick={onNameSort}
            >
              <span className="title">{t("FAQ Category")} (A-Z)</span>
              <span>
                <BsArrowUp
                  style={{
                    color: sorts?.name?.name === "asc" ? "#d60000" : "#CCBFDD",
                  }}
                />
                <BsArrowDown
                  style={{
                    color: sorts?.name?.name === "desc" ? "#d60000" : "#CCBFDD",
                  }}
                />
              </span>
            </div>

            <div>
              <Input
                className="select"
                onChange={onStatusChange}
                value={selectedStatus}
                type="select"
              >
                <option>{t("Select Status")}</option>
                <option value="active">{t("Active")} </option>
                <option value="inactive">{t("Inactive")} </option>
              </Input>
            </div>
          </TableSearch>
          <div className="add-voucher-btn-margin">
            <IconButton
              onClick={() => {
                openAddBrandModal("");
              }}
              title={t("Add FAQ")}
              icontype="add"
            ></IconButton>
          </div>
        </div>
        {faqList && faqList.length > 0 ? (
          <div>
            <CustomTable
              columns={columns}
              data={faqList}
              className="faq-table-head"
            />

            <CustomPagination
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              totalCount={totalCount}
              onPageChange={onPageChange}
              onRowsPerPage={onRowsChange}
            />
          </div>
        ) : (
          <div className="no-data-div">
            <p>{t("No Brands found")}</p>
          </div>
        )}
      </div>

      {/* Add Brand Modal */}
      <Modal
        isOpen={modal}
        size="lg"
        centered
        toggle={() => openAddBrandModal("")}
      >
        <ModalHeader
          toggle={() => openAddBrandModal("")}
          className="modal-header-custom"
        >
          {t("Add a FAQ")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addBrand-main">
            <p className="heading-text">{t("Please enter details in English")}</p>

            <div className="addBrand-input-form">
              <Formik
                initialValues={{
                  ...formikValues,
                  name: currentBrand.name || "",
                  description: currentBrand.description || "",
                  logo: currentBrand.image || "",
                  cropImage: currentBrand.image || "",
                  languages: [],
                }}
                onSubmit={(values) => {
                  currentBrand ? updateBrandList(values) : addBrand(values);
                }}
                validationSchema={addFAQSchema}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                }) => (
                  <Form>
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <Label for="brand-name">{t("FAQ Title")}</Label>
                          <Input
                            id="brand-name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Enter")+" "+t("FAQ Title")}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="name"
                          />
                        </Row>
                      </div>
                    </FormGroup>
                    {availableLanguages.map((language) => {
                      return (
                        <>
                          <FormGroup>
                            <div className="input-container">
                              <Row>
                                <Label
                                  for={"brand-name" + language.value}
                                >{`${t("Title")} ${t("in")} ${t(
                                  language.name
                                )} (${language.symbol})`}</Label>
                                <Input
                                  id="brand-name"
                                  name={"name_" + language.value}
                                  value={values["name_" + language.value]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={`${t("Enter")} ${t("FAQ Title")} ${t(
                                    "in"
                                  )} ${t(language.name)} (${language.symbol})`}
                                />
                              </Row>
                            </div>
                          </FormGroup>
                        </>
                      );
                    })}
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <div className="input-title">
                            <Label for="brand-description">
                              {t("FAQ Answer")}
                            </Label>
                            <p>{descriptionCountArray[0] || 0}/100</p>
                          </div>

                          <Field
                            className="input-textarea"
                            name="description"
                            id="brand-description"
                            as="textarea"
                            cols="30"
                            rows="5"
                            value={values.description}
                            placeholder={t("Enter")+" "+t("FAQ Answer")}
                            onChange={(e) => {
                              handleChange(e);
                              const newDescriptionCountArray = [
                                ...descriptionCountArray,
                              ];
                              newDescriptionCountArray[0] =
                                e.target.value.length;
                              setDescriptionCountArray(
                                newDescriptionCountArray
                              );
                            }}
                            maxLength={100}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="description"
                          />
                        </Row>
                      </div>
                    </FormGroup>
                    {availableLanguages.map((language, index) => {
                      return (
                        <>
                          <FormGroup>
                            <div className="input-container">
                              <Row>
                                <div className="input-title">
                                  <Label for="brand-description">
                                    {`${t("FAQ Answer")} ${t("in")} ${t(
                                      language.name
                                    )} (${language.symbol})`}
                                  </Label>
                                  <p>
                                    {descriptionCountArray[index + 1] || 0}/100
                                  </p>
                                </div>

                                <Field
                                  className="input-textarea"
                                  name={"description_" + language.value}
                                  id="brand-description"
                                  as="textarea"
                                  cols="30"
                                  rows="5"
                                  value={
                                    values["description_" + language.value]
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                    const newDescriptionCountArray = [
                                      ...descriptionCountArray,
                                    ];
                                    newDescriptionCountArray[index + 1] =
                                      e.target.value.length;
                                    setDescriptionCountArray(
                                      newDescriptionCountArray
                                    );
                                  }}
                                  maxLength={100}
                                  placeholder={`${t(
                                    "Enter")} ${t("FAQ Answer")} ${t("in")} ${t(language.name)} (${
                                    language.symbol
                                  })`}
                                />
                              </Row>
                            </div>
                          </FormGroup>
                        </>
                      );
                    })}
                    {/* <FormGroup>
                      <div className="input-container">
                        <Row>
                          <Label>{t("Upload Brand Logo")}</Label>
                          <UploadEachDocument
                            handleChangeDocument={handleChangeLogo}
                            setFieldValue={setFieldValue}
                            documentFile={values.logo}
                            fileName={t("logo")}
                            acceptType="image/*"
                          />
                        </Row>
                      </div>

                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="logo"
                      />
                    </FormGroup>
                    {values.cropImage && (
                      <ImageCropper
                        image={values.cropImage}
                        onFileChange={onGetCroppedImage}
                        setFieldValue={setFieldValue}
                      />
                    )} */}

                    <div className="errors-after-sumbit">
                      {errorsOnSubmit.map((error) => (
                        <p>{error}</p>
                      ))}
                    </div>

                    <div className="save-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Save"
                        isLoading={addBrandInProgress}
                      />
                    </div>
                    <div className="save-btn-float"></div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <DeleteModal
        handleDeleteButton={handleDeleteButton}
        deleteModal={deleteModal}
        currentRow={currentBrand}
        cancelButtonModal={cancelButtonModal}
        deleteRow={deleteBrand}
      />
    </div>
  );
}
