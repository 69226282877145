import React, { useEffect, useState } from "react";
import "./OrderInvoice.scss";
import CustomTable from "../../assets/elements/Table";
import MainServices from "../../services/MainServices";
import { LazyLoader } from "../../assets/loaders";
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import logoMain from "../../assets/images/balloon.png";
import moment from "moment";
import editIcon from "../../assets/images/icons/edit.svg";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { HiOutlineDownload } from "react-icons/hi";
import { PiChatText } from "react-icons/pi";
import Back_Arrow from "../../assets/images/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoIcon from "../../assets/images/icons/Group1.svg";

export default function OrderInvoice() {
  const navigate = useNavigate();
  const { getOrderById } = MainServices();
  const [order, setOrder] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const type = new URLSearchParams(location.search).get("type");
  const [orderItems, setOrderItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const { t, i18n } = useTranslation();
  const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const { orderItemUpdateStatus, generatePdf } = MainServices();
  const [fileData, setFileData] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const userLanguage = i18n.language;

  // validation schema
  const orderProductStatusSchema = Yup.object().shape({
    status: Yup.string().required(t("Select status")),
  });

  const onStatusChangeModal = (selected) => {
    setSelectedOrder(selected);
    setModal(!modal);
  };

  const noteModalToggle = (selected) => {
    setSelectedOrder(selected);
    setNoteModal(!noteModal);
  };

  const photoshootTypeStyle = (row) => {
    if (!row) return;
    return (
      <div
        style={{ cursor: "pointer", color: "#00f" }}
        onClick={() =>
          navigate({
            pathname: "/photography/photography-details",
            search: `?publicId=${row?.productPublicId}`,
          })
        }
      >
        <b>{order?.photoshootType}</b>
      </div>
    );
  };

  const bookingDateStyle = (row) => {
    if (!row) return;
    return moment(order?.bookingDate).format("DD/MM/YYYY");
  };

  const bookingTimeStyle = (row) => {
    if (!row) return;
    return moment(order?.bookingTime, "HH:mm:ss").format("LT");
  };

  const totalPriceStyle = (row) => {
    if (!row) return;
    return order?.totalPayableAmount;
  };

  const orderProductStatusStyle = (row) => {
    if (!row) return;
    return (
      <div style={{ textTransform: "capitalize" }}>
        {row?.orderProductStatus.replace(/-/g, " ")}
      </div>
    );
  };

  const productNameStyle = (row) => {
    if (!row) return;
    return (
      <div
        style={{ cursor: "pointer", color: "#00f" }}
        onClick={() =>
          navigate({
            pathname: "/products/ProductDescription",
            search: `?productId=${row?.productPublicId}`,
          })
        }
      >
        <b>{row?.title}</b>
      </div>
    );
  };

  const cateringNameStyle = (row) => {
    if (!row) return;
    return (
      <div
        style={{ cursor: "pointer", color: "#00f" }}
        onClick={() =>
          navigate({
            pathname: "/catering/restaurant-details",
            search: `?publicId=${row?.productPublicId}`,
          })
        }
      >
        <b>{row?.name}</b>
      </div>
    );
  };

  const shootSpotStyle = (row) => {
    if (!row) return;
    return (
      <div style={{ textTransform: "capitalize" }}>{order?.shootSpot}</div>
    );
  };

  const orderProductStatusArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Place",
      value: "order-placed",
    },
    {
      label: "Order Accept",
      value: "order-accepted",
    },
    {
      label: "Order Pack",
      value: "order-packed",
    },
    {
      label: "Order Dispatched",
      value: "order-dispatched",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderPlacedArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Accept",
      value: "order-accepted",
    },
    {
      label: "Order Pack",
      value: "order-packed",
    },
    {
      label: "Order Dispatched",
      value: "order-dispatched",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderAcceptedArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Pack",
      value: "order-packed",
    },
    {
      label: "Order Dispatched",
      value: "order-dispatched",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderPackedArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Dispatched",
      value: "order-dispatched",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderDispatchedArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const outForDeliveryArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderDeliveredArray = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  useEffect(() => {
    getOrdersList();
  }, []);

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="d-flex gap-2 align-items-center">
        <div className="action-btn-main">
          <img
            src={editIcon}
            alt="balloons edit"
            style={{
              cursor: "pointer",
            }}
            onClick={() => onStatusChangeModal(row)}
          />
        </div>
        {row?.message && (
          <div onClick={() => noteModalToggle(row)}>
            <PiChatText
              style={{
                height: "25px",
                color: "#f92323",
                width: "25px",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const product_columns = [
    {
      field: "title",
      text: "Items",
      customStyle: productNameStyle,
    },
    {
      field: "sellingPrice",
      text: "Unit Price",
    },
    {
      field: "quantity",
      text: "Qty",
    },
    {
      field: "totalSellingPrice",
      text: "Amount",
    },
    {
      field: "orderProductStatus",
      text: "Order Status",
      customStyle: orderProductStatusStyle,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const catering_columns = [
    {
      field: "name",
      text: "Names",
      customStyle: cateringNameStyle,
    },
    {
      field: "bookingDate",
      text: "Booking Date",
      customStyle: bookingDateStyle,
    },
    {
      field: "bookingTime",
      text: "Booking Time",
      customStyle: bookingTimeStyle,
    },
    {
      field: "totalSellingPrice",
      text: "Total Amount",
      customStyle: totalPriceStyle,
    },
    {
      field: "orderProductStatus",
      text: "Order Status",
      customStyle: orderProductStatusStyle,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const photoshoot_columns = [
    {
      field: "photoshootType",
      text: "Types",
      customStyle: photoshootTypeStyle,
    },
    {
      field: "bookingDate",
      text: "Booking Date",
      customStyle: bookingDateStyle,
    },
    {
      field: "bookingTime",
      text: "Booking Time",
      customStyle: bookingTimeStyle,
    },
    {
      field: "shootSpot",
      text: "Shoot Spot",
      customStyle: shootSpotStyle,
    },
    {
      field: "totalSellingPrice",
      text: "Total Amount",
      customStyle: totalPriceStyle,
    },
    {
      field: "orderProductStatus",
      text: "Order Status",
      customStyle: orderProductStatusStyle,
    },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const getOrdersList = async () => {
    setSearchLoading(true);
    const details = {};
    await getOrderById(details, orderId)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let products = resData.data ? resData.data : {};
            if (products?.transactionType) {
              products.transactionType = JSON.parse(products?.transactionType);
            }
            console.log(products);
            if (products?.orderProducts && products?.orderProducts.length > 0) {
              let orderProducts = products?.orderProducts || [];
              setOrderItems(orderProducts);
            } else if (
              products?.orderRestaurants &&
              products?.orderRestaurants.length > 0
            ) {
              let orderRestaurants = products?.orderRestaurants || [];
              setOrderItems(orderRestaurants);
            } else if (
              products?.orderPhotoshoots &&
              products?.orderPhotoshoots.length > 0
            ) {
              let orderPhotoshoots = products?.orderPhotoshoots || [];
              setOrderItems(orderPhotoshoots);
            }
            setOrder(products);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  const orderStatusUpdate = async (values) => {
    console.log(selectedOrder);
    setAddCategoryLoading(true);
    let orderId = selectedOrder?.orderId;
    let publicId = selectedOrder?.publicId;
    let concurrencyStamp = selectedOrder?.concurrencyStamp;
    const details = {
      orderProductStatus: values.status,
      orderId,
      type,
    };
    await orderItemUpdateStatus(details, publicId, concurrencyStamp)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            getOrdersList();
            setModal(!modal);
          }
        }
        setAddCategoryLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAddCategoryLoading(false);
      });
  };

  const onDownloadFile = async () => {
    setDownloadLoading(true);

    const details = {
      orderId: orderId,
    };
    await generatePdf(details, userLanguage)
      .then((res) => {
        setFileData(res.data);
        const fileData = res.data;
        if (fileData.invoice) {
          const a = document.createElement("a");
          a.href = fileData.invoice;
          a.target = "_blank";
          a.download = "filename.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setDownloadLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // onExportFile();
  };

  return (
    <div className="order-invoice-main">
      {searchLoading ? (
        <LazyLoader />
      ) : (
        <div>
          <div className="d-flex gap-3 mb-3  justify-content-between">
            <div className="box-squar pointer" onClick={() => navigate(-1)}>
              <img
                src={Back_Arrow}
                alt="backArrow"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mb-4" style={{ textAlign: "right" }}>
              <div
                className="download-icon-main"
                onClick={() => onDownloadFile("")}
              >
                {downloadLoading ? (
                  <TailSpin
                    height="1.5rem"
                    color={"red"}
                    radius="2"
                    wrapperClass="loader-button"
                    visible={true}
                    width="1em"
                  />
                ) : (
                  <HiOutlineDownload />
                )}
              </div>
            </div>
          </div>
          <div className="order-invoice-card-main">
            <div className="text-center mb-5">
              <p className="order-invoice-header-main m-0">Orders</p>
              <div className="order-invoice-logo-main">
                <img alt="Mventus Logo" src={LogoIcon} width="130px"/>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="width-half">
                <p className="order-invoice-subheader-main">Order Details</p>
                <div className="mb-1">
                  <span>
                    <b>Order No : </b>
                  </span>
                  <span>{order?.id || " "}</span>
                </div>
                <div className="mb-1">
                  <span>
                    <b>Order Date : </b>
                  </span>
                  <span>
                    {moment(order?.createdAt).format("DD-MM-YYYY") || " "}
                  </span>
                </div>
                {/* <div>
                  <span>
                    <b>Due Date : </b>
                  </span>
                  <span>20/11/23</span>
                </div> */}
              </div>
              {(order?.type === "product") ? (
                <div className="width-half">
                  <p className="order-invoice-subheader-main">
                    To :{" "}
                    {order?.shippingAddress?.firstName +
                      order?.shippingAddress?.lastName || " "}
                  </p>
                  <p className="mb-1">
                    {`${order?.shippingAddress?.apartment}, ${order?.shippingAddress?.address}` ||
                      " "}
                  </p>
                  <p className="mb-1">
                    {`${order?.shippingAddress?.city}, ${order?.shippingAddress?.postalCode}, ${order?.shippingAddress?.state}` ||
                      " "}
                  </p>
                  <p>{order?.shippingAddress?.mobileNumber || " "}</p>
                </div>
              ) : order?.type === "catering" ? (
                <div className="width-half">
                  <p className="order-invoice-subheader-main">
                    To : {order?.firstName}
                  </p>
                  <p className="mb-1">
                    {`${order?.shippingAddress?.venue}, ${order?.shippingAddress?.address}` ||
                      " "}
                  </p>
                  <p>{order?.mobileNumber || " "}</p>
                </div>
              ) : order?.type === "photoshoot" ? (
                <div className="width-half">
                  <p className="order-invoice-subheader-main">
                    To : {order?.firstName || " "}
                  </p>
                  <p className="mb-1">
                    {order?.shippingAddress?.address || " "}
                  </p>
                  <p>{order?.mobileNumber || " "}</p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="my-5">
              <CustomTable
                data={orderItems}
                columns={
                  type === "catering"
                    ? catering_columns
                    : type === "photoshoot"
                    ? photoshoot_columns
                    : product_columns
                }
              />
            </div>

            {order?.transactionType ? (
              <div className="d-flex justify-content-between">
                <div className="width-half">
                  <p className="order-invoice-subheader-main">Payment Method</p>
                  <div>
                    <span>
                      <b>Card No : </b>
                    </span>
                    <span>
                      {" "}
                      XXXX XXXX {order?.transactionType?.card?.last_four}{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Payment Method : </b>
                    </span>
                    <span>{order?.transactionType?.paymentMethod}</span>
                  </div>
                  <div>
                    <span>
                      <b>Payment Type : </b>
                    </span>
                    <span>{order?.transactionType?.paymentType}</span>
                  </div>
                </div>
                <div className="width-half">
                  <hr />
                  <div style={{ width: "250px" }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>SUB TOTAL : </b>
                      </div>
                      <div>
                       SAR{" "}
                        {order?.totalItemsPrice
                          ? (+order?.totalItemsPrice).toFixed(2)
                          : 0}
                      </div>
                    </div>

                    {order.tax && (
                      <div>
                        {Object.keys(order.tax).length > 0 &&
                          Object.keys(order.tax).map((eachKey) => {
                            if (
                              !(
                                eachKey === "productType" ||
                                eachKey === "producttype" ||
                                eachKey === "product Type" ||
                                eachKey === "product type" ||
                                eachKey === "ProductType" ||
                                eachKey === "Producttype" ||
                                eachKey === "Product Type" ||
                                eachKey === "Product type"
                              )
                            ) {
                              return (
                                <div
                                  style={{ textTransform: "capitalize" }}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <div>
                                    <b>{eachKey} : </b>
                                  </div>
                                  <div className="flip-card-right-flex">
                                   SAR{" "}
                                    {order?.tax
                                      ? (+order?.tax[eachKey]).toFixed(2)
                                      : "-"}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    )}

                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <b>GRAND TOTAL : </b>
                      </div>
                      <div>
                       SAR{" "}
                        {order?.totalPayableAmount
                          ? (+order?.totalPayableAmount).toFixed(2)
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <div className="width-half">
                  <hr />

                  <div style={{ width: "250px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <b>SUB TOTAL : </b>
                      </div>
                      <div>
                       SAR{" "}
                        {order?.totalItemsPrice
                          ? (+order?.totalItemsPrice).toFixed(2)
                          : 0}
                      </div>
                    </div>

                    {order.tax && (
                      <div>
                        {Object.keys(order.tax).length > 0 &&
                          Object.keys(order.tax).map((eachKey) => {
                            if (
                              !(
                                eachKey == "productType" ||
                                eachKey == "producttype" ||
                                eachKey == "product Type" ||
                                eachKey == "product type" ||
                                eachKey == "ProductType" ||
                                eachKey == "Producttype" ||
                                eachKey == "Product Type" ||
                                eachKey == "Product type"
                              )
                            ) {
                              return (
                                <div
                                  style={{ textTransform: "capitalize" }}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <div>
                                    <b>{eachKey} : </b>
                                  </div>
                                  <div className="flip-card-right-flex">
                                   SAR{" "}
                                    {order?.tax
                                      ? (+order?.tax[eachKey]).toFixed(2)
                                      : "-"}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    )}

                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <b>GRAND TOTAL : </b>
                      </span>
                      <span>
                       SAR{" "}
                        {order?.totalPayableAmount
                          ? (+order?.totalPayableAmount).toFixed(2)
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={modal}
        size="md"
        centered
        toggle={() => onStatusChangeModal("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => onStatusChangeModal("")}
          className="modal-header-custom"
        >
          Status Change
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="d-flex justify-content-between">
              <p className="heading-text">
                {t("Please enter details in English")}
              </p>
            </div>
            <div className="addCategory-input-form">
              <Formik
                initialValues={{
                  status: "",
                }}
                validationSchema={orderProductStatusSchema}
                onSubmit={(values) => {
                  orderStatusUpdate(values);
                }}
              >
                {({ values, handleBlur, handleChange, errors, touched }) => (
                  <Form>
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <Label for="status">{t("Select Status")}</Label>
                        </Row>
                        <Input
                          className="select"
                          name="status"
                          id="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.status}
                          type="select"
                        >
                          {selectedOrder.orderProductStatus === "order-placed"
                            ? orderPlacedArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : selectedOrder.orderProductStatus ===
                              "order-accepted"
                            ? orderAcceptedArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : selectedOrder.orderProductStatus ===
                              "order-packed"
                            ? orderPackedArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : selectedOrder.orderProductStatus ===
                              "order-dispatched"
                            ? orderDispatchedArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : selectedOrder.orderProductStatus ===
                              "out-for-delivery"
                            ? outForDeliveryArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : selectedOrder.orderProductStatus ===
                              "order-delivered"
                            ? orderDeliveredArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })
                            : orderProductStatusArray.map((each) => {
                                return (
                                  <option value={each?.value}>
                                    {each?.label}
                                  </option>
                                );
                              })}
                        </Input>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="status"
                        />
                      </div>
                    </FormGroup>

                    <div className="save-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Save"
                        isLoading={addCategoryLoading}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={noteModal}
        size="md"
        centered
        toggle={() => noteModalToggle("")}
        className="add-category-modal"
      >
        <ModalHeader
          toggle={() => noteModalToggle("")}
          className="modal-header-custom"
        >
          Message :
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="addCategory-main">
            <div className="addCategory-input-form">
              <Formik initialValues={{}}>
                {({ values, handleBlur, handleChange }) => (
                  <Form>
                    <FormGroup>
                      <div className="input-container">
                        <Row>
                          <div
                            style={{
                              textTransform: "capitalize",
                              textAlign: "center",
                            }}
                          >
                            <p className="m-0">{selectedOrder?.message}</p>
                          </div>
                        </Row>
                      </div>
                    </FormGroup>

                    <div className="save-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Close"
                        onClick={() => noteModalToggle("")}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
