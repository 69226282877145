import React from "react";
import "./Style.scss";
import { useTranslation } from "react-i18next";

export default function Tabs(props) {
  const { t } =  useTranslation();

  return (
    <div {...props} className={`tabs-main ${props.className}`}>
      {t(props.title)}
    </div>
  );
}
