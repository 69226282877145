import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "./ViewDocumentLarge.scss";

const ViewDocumentLarge = ({ open, setViewDocumentInLarge, document }) => {
  const [modal, setModal] = useState(open);

  const toggle = () => {
    setViewDocumentInLarge(false);
    setModal(!modal);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        wrapClassName="view-large"
        size="xl"
      >
        <ModalBody>
          {document.type && document.type.includes("image/") ? (
            <img
              src={URL.createObjectURL(document)}
              alt="Document"
              className="previewdoc"
            />
          ) : document?.url?.includes(".pdf") ? (
            <iframe src={document.url} width="100%" height="800px"></iframe>
          ) : (
            <img src={document.url} alt="Document" className="previewdoc" />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ViewDocumentLarge;
