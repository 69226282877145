import React from "react";
import "./DeleteModal.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoader } from "../loaders";

const DeleteModal = ({
  deleteModal,
  handleDeleteButton,
  currentRow,
  cancelButtonModal,
  deleteRow,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={deleteModal} toggle={handleDeleteButton} centered>
        <ModalHeader toggle={handleDeleteButton}>{t("Delete")}</ModalHeader>
        <ModalBody>
          <div className="delete-popup-container">
            <div>
              <p className="inner-container-text">
                Are you sure? Do you really  want to delete this item:<br />
                "{currentRow.name}"
              </p>
              <div className="action-btn">
                <button
                  className="action-buttons cancel-btn"
                  onClick={() => {
                    cancelButtonModal();
                  }}
                >
                  Cancel
                </button>
                <Button
                  className="action-buttons delete-btn"
                  onClick={() => {
                    deleteRow();
                  }}
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : "Delete"}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteModal;
