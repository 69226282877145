import React, { useState, useEffect, useRef } from "react";
import { Input, Label, FormGroup, Row } from "reactstrap";
import SampleImage from "../../assets/images/profileUpload.png";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import "./Profile.scss";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import { Formik, Form } from "formik";
import MainServices from "../../services/MainServices";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { LazyLoader } from "../../assets/loaders";
import useAuth from "../../hooks/useAuth";
import { notifySuccess, notifyError } from "../../assets/elements/Toaster";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import UploadDocuments from "./UploadDocuments";
import AddressSection from "./AddressSection";
import axios from "axios";

const updateProfileSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .required("Email is Required")
    .email("Please enter a valid Email"),
  mobilenumber: Yup.string()
    .required("Phone is required")
    .matches(/^[6-9]\d{7,}$/, "Mobile Number must be valid one"),
});

const updatePasswordSchema = Yup.object().shape({
  currentpassword: Yup.string()
    .required("Current password is required")
    .min(
      6,
      "Password must contain 6 or more characters with at least one of each: uppercase, lowercase, number, and special"
    )
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least 1 lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least 1 uppercase letter"
    )
    .matches(/^(?=.*\d)/, "Password must contain at least 1 number")
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Password must contain at least 1 special character"
    ),

  newpassword: Yup.string()
    .required("New Password is required")
    .min(
      6,
      "Password must contain 6 or more characters with at least one of each: uppercase, lowercase, number, and special"
    )
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least 1 lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least 1 uppercase letter"
    )
    .matches(/^(?=.*\d)/, "Password must contain at least 1 number")
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Password must contain at least 1 special character"
    ),

  verifypassword: Yup.string()
    .required("Verify Password is required")
    .oneOf([Yup.ref("newpassword"), null], "Verify Passwords must match")
    .min(6, "Atleast six(6) Characters"),
});

const Profile = () => {
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [verifyPasswordVisible, setVerifyPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const [currentProfileData, setCurrentProfileData] = useState(null);
  //const [currentAddress, setCurrentAddress] =  useState(null);
  const [isCurrentProfileLoading, setIsCurrentProfileLoading] = useState(false);
  const [isUpdateProfileInProgress, setIsUpdateProfileInProgress] =
    useState(false);
  const [isUpdatePasswordInProgress, setIsUpdatePasswordInProgress] =
    useState(false);
  //const [isUpdateAddressInProgress, setIsUpdateAddressInProgress] = useState(false)
  const { user, updateUserContext } = useAuth();
  //const [countryList, setCountryList] = useState([]);
  //const [stateList, setStateList] = useState([]);

  //Apis
  const { getUserProfile, updateUser, fileUpload } = MainServices();
  const [imageFile, setImageFile] = useState();
  const [documentFiles, setDocumentFiles] = useState({});
  const imageUploader = useRef();
  //const documentsUploader = useRef();
  const [crDocument, setCrDocument] = useState();
  const [vatDocument, setVatDocument] = useState();
  const { userChangePassword } = MainServices();
  const handleChangeImage = (e) => {
    // const fileUploaded = event.target.files[0];
    // setFile(fileUploaded);
    setImageFile(e.target.files[0]);
  };
  const handleImageUploadClick = () => {
    imageUploader.current.click();
  };

  const handleChangeDocument = (e) => {
    if (!e.target.files) {
      return;
    }
    let selectedFile = e.target.files[0];
    selectedFile["field"] = e.target.name;
    setDocumentFiles((prev) => {
      return { ...prev, [e.target.name]: selectedFile };
    });
  };

  /* const viewCrDocumetFile = async (id) => {
    if(!id) return
    /* await viewFile(id).then((res) => {
      if(res.data)
        setCrDocument(res.data);
    }); 
  }; 

  const viewVatDocumetFile = async (id) => {
    if(!id) return
    /* await viewFile(id).then((res) => {
      if(res.data)
        setVatDocument(res.data);
    }); 
  };*/

  const getAndSetProfileData = async () => {
    setIsCurrentProfileLoading(true);
    await getUserProfile().then((res) => {
      setCurrentProfileData(res.data);
      if (res.data) {
        setCrDocument(res?.data?.supplier?.crDocumentIdFile);
        setVatDocument(res?.data?.supplier?.vatDocumentIdFile);
      }
      setIsCurrentProfileLoading(false);
    });
  };

  /* const getAndSetCountryList = () => {
    getCountries().then(res =>
      {
        console.log(res);
        setCountryList(res.data);
        //getAndSetStateList(res.data[4]);
      })
  }

  const getAndSetStateList = (countryId) => {
    getStatesByCountry({countryId}).then(res => {
      console.log(res);
      setStateList(res.data);
    })
  }

  const getAndSetAddress = async () => {
    await getUserAddress().then((res) => {
      console.log("Address: ", res)
      const lastIndex = res.data.length-1
      setCurrentAddress(res?.data[lastIndex]);
      getAndSetCountryList();
      getAndSetStateList(res?.data[lastIndex]?.countryId)
    });
  };


  const handleChangeCountry = (e, values) => {
    console.log(e)
    console.log(e.target.selectedOptions[0].value);
    console.log(e.target.selectedOptions[0].id);
    const countryId = e.target.selectedOptions[0].id;
    getAndSetStateList(countryId);
  }  */

  useEffect(() => {
    setIsCurrentProfileLoading(true);
    getAndSetProfileData();
    // eslint-disable-next-line
  }, []);

  const uploadFile = async (file) => {
    return fileUpload(file);
  };

  const updateUserProfile = async (values) => {
    setIsUpdateProfileInProgress(true);
    let crDocumentRes = {};
    let vatDocumentRes = {};

    if (documentFiles?.cr) {
      crDocumentRes = await uploadFile(documentFiles.cr).then((res) => res);
    }
    if (documentFiles?.vat) {
      vatDocumentRes = await uploadFile(documentFiles.vat).then((res) => res);
    }
    console.log("crres", crDocumentRes);
    let concurrencyStamp = currentProfileData.concurrencyStamp;
    // Retrieve latest concurrency stamp
    await getUserProfile().then(
      (res) => (concurrencyStamp = res.data?.concurrencyStamp)
    );
    let crDocumentId = "";
    let vatDocumentId = "";
    if (crDocumentRes) crDocumentId = crDocumentRes?.fileName;
    if (vatDocumentRes) vatDocumentId = vatDocumentRes?.fileName;
    const details = {
      name: values.name,
      /* latitude: values.latitude.toString(),
      longitude: values.longitude.toString(),
      address: values.fulladdress,
      postalCode: values.postalcode,
      city: values.city,
      state: values.state,
      country: values.country, */
      crDocumentId,
      vatDocumentId,
    };
    console.log(details);
    updateUser(details, currentProfileData.publicId, concurrencyStamp)
      .then((res) => {
        if (res.success) {
          setIsUpdateProfileInProgress(false);
          getAndSetProfileData();
          getUserProfile().then((res) => {
            setCurrentProfileData(res.data);
            //viewCrDocumetFile(crDocumentId);
            //viewVatDocumetFile(vatDocumentId);
            const accessToken = user.token;
            updateUserContext({ ...res.data, token: accessToken });
            notifySuccess("Profile updated successfully!");
          });
        } else {
          notifyError("Update failed!");
          setIsUpdateProfileInProgress(false);
        }
      })
      .catch((error) => {
        notifyError("Update failed!");
        setIsUpdateProfileInProgress(false);
      });
  };

  /* const updateUserAddress = async (values) => {
    console.log("In address")
    console.log(values)
    setIsUpdateAddressInProgress(true);
    const countryDetails = countryList.find(item => 
      item.name === values.country
    )
    let countryId = "";
    countryId = countryDetails?.id;
    console.log("stateList", stateList)
    const stateDetails = stateList.find(item =>
      item.name === values.state
    )
    let stateId = "";
    stateId = stateDetails?.id
    console.log("stateDetails", stateDetails)
    console.log("stateId", stateId)
    const details = {
      latitude: values.latitude.toString(),
      longitude: values.longitude.toString(),
      address: values.fulladdress,    
      countryId,
      stateId,
      city: values.city,
      postalCode: values.postalcode,   
    };
    setIsUpdateAddressInProgress(false);
     saveAddress(details)
      .then(() => {
        setIsUpdateAddressInProgress(false);
        notifySuccess("Address updated successfully!");
        getAndSetAddress();
      })
      .catch((error) => {
        notifyError("Update failed!");
        setIsUpdateAddressInProgress(false);
      }); 
  } */

  const updateUserPassword = async (values) => {
    setIsUpdatePasswordInProgress(true);

    const details = {
      email: user.email,
      currentPassword: values.currentpassword,
      newPassword: values.newpassword,
    };

    await axios
      .all([userChangePassword(details, user.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            resData?.message?.code === "auth/wrong-password"
              ? notifyError("Current Password is wrong")
              : notifySuccess("Password Change Successfully");
            setIsUpdatePasswordInProgress(false);
          } else {
            notifyError(message);
            setIsUpdatePasswordInProgress(false);
          }
        } else {
          setIsUpdatePasswordInProgress(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in change password");
      });
  };
  return (
    <div className="profile-main">
      <HeadingMain text="Profile" />

      {isCurrentProfileLoading === true ? (
        <LazyLoader />
      ) : (
        currentProfileData && (
          <>
            <div className="profile-input-form">
              <Formik
                initialValues={{
                  name: currentProfileData.name || "",
                  mobilenumber: currentProfileData.mobileNumber || "",
                  email: currentProfileData?.email || "",
                }}
                onSubmit={(values) => {
                  updateUserProfile(values);
                }}
                validationSchema={updateProfileSchema}
                enableReinitialize
              >
                {({ values, errors, handleBlur, handleChange, touched }) => (
                  <>
                    <Form>
                      <div className="personal-details formbox">
                        <div className="textdescription">
                          <p className="title">{t("Personal details")}</p>
                          <p>
                            {t(
                              "Please enter your personal details, like full name mobile number, your location, email address, etc"
                            )}
                            .
                          </p>
                        </div>
                        <div className="inputsection">
                          <div className="profile-image-div">
                            <img
                              src={
                                imageFile
                                  ? URL.createObjectURL(imageFile)
                                  : SampleImage
                              }
                              alt="profile"
                            />
                            <span
                              className="change"
                              onClick={handleImageUploadClick}
                              onDrag={handleChangeImage}
                            >
                              {t("Change")}
                            </span>
                            <input
                              type="file"
                              ref={imageUploader}
                              onChange={handleChangeImage}
                              accept="image/*"
                              hidden
                            />
                          </div>
                          <Row>
                            <FormGroup>
                              <Label for="name">{t("Name")}</Label>
                              <Input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="name"
                              />
                            </FormGroup>
                          </Row>
                          <FormGroup>
                            <Label for="mobilenumber">
                              {t("Mobile Number")}
                            </Label>
                            <Input
                              disabled
                              className="disabled"
                              id="mobilenumber"
                              name="mobilenumber"
                              value={values.mobilenumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="mobilenumber"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="email">{t("Email Address")}</Label>
                            <Input
                              disabled
                              className="disabled"
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="email"
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <UploadDocuments
                        /* values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur} */
                        handleChangeDocument={handleChangeDocument}
                        documentFiles={documentFiles}
                        existingDocuments={[
                          { name: "CR", file: crDocument },
                          { name: "VAT", file: vatDocument },
                        ]}
                      />
                      <div className="save-btn-float">
                        <PrimaryButton
                          type="submit"
                          title="Save"
                          isLoading={isUpdateProfileInProgress}
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>

              {/* <Formik
                initialValues={{
                  currentaddress:
                    userSelectedMapDetails.address ||
                    currentAddress?.address ||
                    "",
                  latitude:
                    userSelectedMapDetails.latitude ||
                    currentAddress?.latitude ||
                    "",
                  longitude:
                    userSelectedMapDetails.longitude ||
                    currentAddress?.longitude ||
                    "",
                  fulladdress:
                    currentAddress?.address ||
                    userSelectedMapDetails.address ||
                    "",
                   country:
                    currentAddress?.country ,
                  state:
                    stateList.find(item => item.name === currentAddress?.state) ?
                    currentAddress.state : '',   
                  city:
                    currentAddress?.city ||
                    userSelectedMapDetails.city ||
                    "",
                  postalcode:
                    currentAddress?.postalCode ||
                    userSelectedMapDetails.postalcode ||
                    "",
            
                }}
                onSubmit={(values) => {
                  updateUserAddress(values);
                }}
                //validationSchema={updateAddressSchema}
                enableReinitialize
              >
                {({ values, errors, handleBlur, handleChange, setFieldValue, touched }) => (
                  <>
                    <Form>
                      <div className="address formbox">
                        <div className="textdescription">
                          <p className="title">{t("Address")}</p>
                          <p>
                            "It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English.
                          </p>
                          <div className="existing-preview">
                            <div>
                              
                            </div>

                          </div>
                        </div>
                        <div className="inputsection">
                          <Row className="g-2">
                            <FormGroup>
                              <Label for="location">{t("Location")}</Label>
                              <div
                                className="input-icon-group location"
                                onClick={openMap}
                              >
                                <Input
                                  id="location"
                                  name="location"
                                  type="text"
                                  disabled
                                  placeholder={t("Your Location")}
                                  value={values.currentaddress + "   "}
                                  onBlur={handleBlur}
                                />
                                <img
                                  src={mapicon}
                                  alt="icon"
                                  focusable
                                  className="icon map"
                                />
                              </div>
                            </FormGroup>
                          </Row>
                          <FormGroup>
                            <Label for="fulladdress">{t("Full Address")}</Label>
                            <Input
                              id="fulladdress"
                              name="fulladdress"
                              value={values.fulladdress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          
                          <FormGroup>
                            <Label for="country">{t("Country")}</Label>
                            <Input
                              className="select"
                              id="country"
                              name="country"
                              value={values.country}
                              type="select"
                              onChange={(e) => 
                                {
                                  handleChangeCountry(e, values);
                                  handleChange(e);
                                  setFieldValue("state", "");
                                }
                                
                              }
                              onBlur={handleBlur}
                            >
                              <option>--Select--</option>
                            {
                              countryList.map((item) => 
                                {
                                  return <option key={item.id} id={item.id}>{item.name}</option>
                                }
                              )
                            }                          
                            </Input>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="country"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="state">{t("State")}</Label>
                            
                            <Input
                              className="select"
                              id="state"
                              name="state"
                              type="select"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>--Select--</option>
                            {
                              stateList.map((item) => 
                                {
                                  return <option key={item.id} id={item.id}>{item.name}</option>
                                }
                              )
                            }
                      
                            </Input>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="state"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="city">{t("City")}</Label>
                            <Input
                              id="city"
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="city"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="postalcode">{t("Postal Code")}</Label>
                            <Input
                              id="postalcode"
                              name="postalcode"
                              value={values.postalcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="save-btn-float">
                        <PrimaryButton
                          type="submit"
                          title="Save"
                          isLoading={isUpdateAddressInProgress}
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik> */}
              <AddressSection
                user={user}
                updateUser={updateUser}
                getUserProfile={getUserProfile}
                getAndSetProfileData={getAndSetProfileData}
                currentProfileData={currentProfileData}
                setCurrentProfileData={setCurrentProfileData}
                updateUserContext={updateUserContext}
              />

              <Formik
                initialValues={{
                  currentpassword: "",
                  newpassword: "",
                  verifypassword: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  updateUserPassword(values);
                  resetForm();
                }}
                validationSchema={updatePasswordSchema}
              >
                {({ values, errors, handleBlur, handleChange, touched }) => (
                  <>
                    <Form>
                      <div className="password formbox">
                        <div className="textdescription">
                          <p className="title">{t("Change Password")}</p>
                          <p>
                            {t(
                              "To make an update, enter your existing password followed by a new one. If you don't know your existing password, you can log out and use the Reset Password link on the Log In page"
                            )}
                          </p>
                        </div>
                        <div className="inputsection">
                          <FormGroup>
                            <Label for="currentpassword">
                              {t("Current Password")}
                            </Label>
                            <div className="input-icon-group">
                              <Input
                                id="currentpassword"
                                name="currentpassword"
                                value={values.currentpassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={
                                  currentPasswordVisible ? "text" : "password"
                                }
                              />
                              <span
                                className="icon"
                                onClick={() =>
                                  setCurrentPasswordVisible(
                                    !currentPasswordVisible
                                  )
                                }
                              >
                                {currentPasswordVisible ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </span>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="currentpassword"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="newpassword">{t("New Password")}</Label>
                            <div className="input-icon-group">
                              <Input
                                id="newpassword"
                                name="newpassword"
                                value={values.newpassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={newPasswordVisible ? "text" : "password"}
                              />
                              <span
                                className="icon"
                                onClick={() =>
                                  setNewPasswordVisible(!newPasswordVisible)
                                }
                              >
                                {newPasswordVisible ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </span>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="newpassword"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="verifypassword">
                              {t("Verify Password")}
                            </Label>
                            <div className="input-icon-group">
                              <Input
                                id="verifypassword"
                                name="verifypassword"
                                value={values.verifypassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={
                                  verifyPasswordVisible ? "text" : "password"
                                }
                              />
                              <span
                                className="icon"
                                onClick={() =>
                                  setVerifyPasswordVisible(
                                    !verifyPasswordVisible
                                  )
                                }
                              >
                                {verifyPasswordVisible ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </span>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="verifypassword"
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="save-btn-float">
                        <PrimaryButton
                          type="submit"
                          title="Save"
                          isLoading={isUpdatePasswordInProgress}
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Profile;
