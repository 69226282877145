import React, { useState } from "react";
import "./AddEmployee2.scss";
import Select from "react-select";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/icons/logo.png";
import * as Yup from "yup";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import axios from "axios";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Balloon from "../../assets/images/balloon.png";
import Google from "../../assets/images/icons/google-logo.png";
import Facebook from "../../assets/images/icons/facebook-logo.png";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { notifySuccess, notifyError } from "../../assets/elements/Toaster";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import MainService from "../../services/MainServices";
import HeadingMain from "../../assets/elements/HeadingMain";
import closeicon from "../../assets/images/icons/close.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import IconButton from "../../assets/elements/IconButton";
import MainServices from "../../services/MainServices";
import TimingFunctions from "../../assets/functions/TimingFunctions";
const registerSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^[6-9]\d{7,}$/, "Please enter a valid phone"),

  password: Yup.string()
    .required("Password is required")
    .min(
      6,
      "Password must contain 6 or more characters with at least one of each: uppercase, lowercase, number, and special"
    )
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least 1 lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least 1 uppercase letter"
    )
    .matches(/^(?=.*\d)/, "Password must contain at least 1 number")
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Password must contain at least 1 special character"
    ),

  verifyPassword: Yup.string()
    .required("Verify Password is required")
    .oneOf([Yup.ref("password"), null], "Verify Passwords must match")
    .min(6, "Atleast six(6) Characters"),
});

export default function Register() {
  const [type, setType] = useState("password");
  const [verifyType, setVerifyType] = useState("password");
  const [errorMessageOnRegister, setErrorMessageOnRegister] = useState();
  const [isRegisterInProgress, setIsRegisterInProgress] = useState();
  const navigate = useNavigate();
  const { userRegistration } = MainService();
  const onChangeType = (_) => {
    _ === "password"
      ? setType(type === "password" ? "text" : "password")
      : setVerifyType(verifyType === "password" ? "text" : "password");
  };

  const userRegisterAndLogin = async (values) => {
    setIsRegisterInProgress(true);
    setErrorMessageOnRegister("");
    const details = {
      email: values.email,
      password: values.password,
      name: values.name,
      mobileNumber: values.phone,
      role: "supplier",
    };
    await axios
      .all([userRegistration(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess("User Registered Successfully");
            navigate({
              pathname: "/otp-verification",
              search: `?emailAddress=${values.email}&mobileNumber=${values.phone}&pageType=register`,
            });
            setIsRegisterInProgress(false);
          } else {
            let errors = resData?.details || [];
            if (errors.length > 0) {
              notifyError(errors[0]?.message);
            }
            setIsRegisterInProgress(false);
          }
        } else {
          setIsRegisterInProgress(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessageOnRegister(err);
        setIsRegisterInProgress(false);
      });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#EDF0F7",
      // padding: "6px 5px",
      marginBottom: "12px",
      paddingLeft: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      zIndex: "2",
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#f5f2ed" : "white",
        color: "#000000",
        cursor: "pointer",
        padding: "10px 10px 10px 20px",
        //textTransform: "capitalize",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "5px",
    }),
  };
  // let isEditing = publicId ? true : false;
  const [activeTab, setActiveTab] = useState(0);
  const [activeTypeTab, setActiveTypeTab] = useState(0);
  const [activeTopnBottomTab, setActiveTopnBottomTab] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const {
    getBrands,
  
  } = MainServices();

  const getBrandList = async (filters) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getBrands(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((brand) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                brand?.brandLanguages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                ...brand,
                label: languageSpecificDetailsObject?.name || brand.name,
                value: brand.id,
              };
            });
            return resData;
          }
        } else {
          console.log("Brands Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };
 
  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback(brandList);
    }
    let filter = [
      {
        key: "status",
        eq: "active",
      },
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getBrandList(filter);

    callback(options);
  };
  const { t, i18n } = useTranslation();
  const handleSaveCustomer = async (values) => {
    setActiveTab(activeTab + 1);
  };
  const { deBounce } = TimingFunctions();
  const loadDebounceOptions = (inputValue, callback) => {
    deBounce(() => loadOptions(inputValue, callback), 1000);
  };


  return (
    <div className="product-main">
      <HeadingMain text="Add Employee">
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      <div className="tabs-row">
        <Tabs
          selectedIndex={activeTab}
          onSelect={(index) => setActiveTab(index)}
          selectedTabClassName="activetab"
        >
          <TabList className="add-customer-tabs">
            <Tab
              className={`add-customer-each-tab ${
                activeTab === 0 ? "active" : "inactive"
              }`}
              //  onClick={() => handleTabChange(0)}
              //onClick={revenueByDay}
            >
              <div className="from-page-number">01</div>
              <p>Personal Information</p>
            </Tab>
            <Tab
              className={`add-customer-each-tab ${
                activeTab === 1 ? "active" : "inactive"
              }`}
              //onClick={revenueByWeek}
            >
              <div className="from-page-number">02</div>
              <p>Legal Information</p>
            </Tab>
          </TabList>
          <TabPanel>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  mobileNumber: "",
                }}
                onSubmit={(values) => {
                  handleSaveCustomer(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="user-topcard-main">
                        <div className="header">
                          <h1 className="topcard-heading">
                            Personal Information
                          </h1>
                        </div>
                        <br />
                        <div className="row">
                          <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                            <Label for="title" className="headings">Employee Name</Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="employee name"
                              type="text"
                              className="product-form-group"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="title"
                            />
                          </FormGroup>
                          <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                            <Label for="title" className="headings">Contact Number</Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="+91 00000 00000"
                              type="text"
                              className="product-form-group"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="title"
                            />
                          </FormGroup>

                          <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                            <Label for="title" className="headings">Email Address</Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="sample@gmail.com"
                              type="text"
                              className="product-form-group"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="title"
                            />
                          </FormGroup>
                          <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                            <Label for="title" className="headings">Date of Birth</Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="dd/mm/yyyy"
                              type="text"
                              className="product-form-group"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="title"
                            />
                          </FormGroup>
                        </div>
                        <div className="row">
                        <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="brandId">{t("Gender")}</Label>
                      <AsyncSelect
                        loadOptions={loadDebounceOptions}
                        styles={customStyles}
                        defaultOptions={brandList}
                        placeholder="Select..."
                        onChange={(selected) =>
                          setFieldValue("brandId", selected)
                        }
                        name="brandId"
                        value={values.brandId}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="brandId"
                      />
                    </FormGroup>

                    <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                      <Label for="brandId">{t("Designation")}</Label>
                      <AsyncSelect
                        loadOptions={loadDebounceOptions}
                        styles={customStyles}
                        defaultOptions={brandList}
                        placeholder="Select..."
                        onChange={(selected) =>
                          setFieldValue("brandId", selected)
                        }
                        name="brandId"
                        value={values.brandId}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="brandId"
                      />
                    </FormGroup>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end mt-3">
                        <IconButton
                          className="back-btn"
                          title="Back"
                          style={{
                            width: "200px",
                            backgroundColor: "black",
                            color: "white",
                            marginRight: "10px",
                          }}
                        ></IconButton>
                        <IconButton
                          className="next-btn"
                          title="Next"
                          style={{
                            width: "200px",
                            backgroundColor: "#56E20A",
                            color: "black",
                          }}
                        ></IconButton>
                      </div>
                    </Form>
                    <div className="class-usercard-flex"></div>
                    <div className="errors-after-sumbit">
                      {/* {errorsOnSubmit?.map((error) => (
                          <p>{error}</p>
                        ))} */}
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  mobileNumber: "",
                }}
                onSubmit={(values) => {
                  handleSaveCustomer(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="user-topcard-main">
                      <div className="header">
                        <h1 className="topcard-heading">Login Details </h1>
                      </div>
                      <br />
                      <div className="row">
                        <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                          <Label for="title" className="headings">Login Id</Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="Enter login ID"
                            type="text"
                            className="product-form-group"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="title"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                          <Label for="title" className="headings">Create Password</Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="*******"
                            type="text"
                            className="product-form-group"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="title"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-sm-6 col-md-3 col-lg-3">
                          <Label for="title" className="headings">Confirm Password</Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="*******"
                            type="text"
                            className="product-form-group"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="title"
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="class-usercard-flex"></div>
                    <div className="errors-after-sumbit">
                      {/* {errorsOnSubmit?.map((error) => (
                          <p>{error}</p>
                        ))} */}
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <IconButton
                        className="back-btn"
                        title="Back"
                        style={{
                          width: "200px",
                          backgroundColor: "black",
                          color: "white",
                          marginRight: "10px",
                        }}
                      ></IconButton>
                      <IconButton
                        className="next-btn"
                        title="Next"
                        style={{
                          width: "200px",
                          backgroundColor: "#56E20A",
                          color: "black",
                        }}
                      ></IconButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
