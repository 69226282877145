import { React } from "react";
import "./AddVoucher.scss";
import { Form, Input, Label, FormGroup } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { useTranslation } from "react-i18next";

export default function AddVoucher() {
  const { t } =  useTranslation()
  return (
    <div className="voucher-main">
      <div className="vouchercard-main">
        <Form>
          <div style={{ textAlign: "center", paddingBottom: "20px" }}>
            <h1 className="voucher-heading-main">{t("Create Voucher")}</h1>
          </div>
          <FormGroup>
            <Label for="induceProduct">{t("Include Product")}</Label>
            <Input
              id="induceProduct"
              name="induceProduct"
              placeholder={t("All Products")}
              type="text"
              className="voucher-form-group"
            />
          </FormGroup>

          <div className="row">
            <FormGroup className=" col-12 col-md-6 col-lg-6">
              <Label for="value">{t("Value")}</Label>
              <Input
                id="value"
                name="value"
                placeholder={t("Enter Value")}
                type="number"
                className="voucher-form-group"
              />
            </FormGroup>

            <FormGroup className="col-12 col-md-6 col-lg-6">
              <Label for="retailPrice">{t("Retail Price")}</Label>
              <Input
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder={t("Enter Retail Price")}
                className="voucher-form-group"
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Label for="validFor">{t("Valid For")}</Label>
            <Input
              id="validFor"
              name="validFor"
              type="select"
              className="voucher-form-group"
            >
              <option>6 {t("Months")}</option>
              <option>12 {t("Months")}</option>
              <option>1 {t("Year")}</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="voucherName">{t("Voucher Name")}</Label>
            <Input
              id="voucherName"
              name="voucherName"
              type="text"
              placeholder={t("Gift Voucher")}
              className="voucher-form-group"
            ></Input>
          </FormGroup>

          <div className="save-btn-float">
            <PrimaryButton title="Save" />
          </div>
        </Form>
      </div>
    </div>
  );
}
