import React, { useEffect, useRef, useState } from "react";
import "./QuotationOverview.scss";
import Back_Arrow from "../../assets/images/icons/arrow-left.svg";
import ArrowDown from "../../assets/images/icons/Big_Arrow_Down.svg";
import PrinterPDF from "../../assets/images/icons/PrinterPDF.svg";
import Share from "../../assets/images/icons/Share.svg";
import Zudur from "../../assets/images/icons/zudur.svg";
import LineDashed from "../../assets/images/icons/LineDashed.svg";
import axios from "axios";
import MainServices from "../../services/MainServices";
import { notifyWarn } from "../../assets/elements/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { notifySuccess } from "../../assets/elements/Toaster";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ButtonLoader } from "../../assets/loaders";
import numberToWords from "number-to-words";
import { Dropdown } from "react-bootstrap";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import Error from "../../assets/elements/ErrorMessage";
import { FormGroup, Input, Label } from "reactstrap";
import helpers from "../../utils/helpers";
import * as Yup from "yup";
import useConfig from "../../hooks/useConfig";
import editicon from "../../assets/images/icons/edit-curved.svg";

const addMobileSchema = Yup.object().shape({
  value: Yup.string()
    .required("Mobile is required")
    .length(10, "Mobile Number must be of 10 digits"),
});
const addEmailSchema = Yup.object().shape({
  value: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email"),
});

const QuotationOverview = () => {
  const pdfRef = useRef();
  const [pageLoading, setPageLoading] = useState(false);
  const {
    getQuotationByEnquiryId,
    downloadQuotation,
    AddWhatsApp,
    getSettings,
    updateQuotation,
  } = MainServices();
  const [invoices, setInvoices] = useState({});
  const location = useLocation();
  const enquiryId = new URLSearchParams(location.search).get("enquiryId");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [addFile, setAddFileModal] = useState(false);
  const [addEmail, setAddEmailModal] = useState(false);
  const [uploadLoading, setUploadLoding] = useState(false);
  const [signatureDate, setSignatureData] = useState({});
  const [termsData, setTermsData] = useState({});
  const [logoData, setLogoData] = useState({});
  const navigate = useNavigate();
  const { settingImages } = useConfig();
  const [updateQuotationLoading, setUpdateQuotationLoading] = useState(0);

  const handleShareClick = (shareOption) => {
    if (shareOption === "WhatsApp") {
      // Open WhatsApp modal directly without additional JavaScript
      document.getElementById("whatsappModalTrigger").click();
    } else {
      // Handle other share options as needed
    }
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };
  useEffect(() => {
    enquiryId && getCreateInvoiceModel();
    getSignature();
    getLogo();
    getTermsAndConditions();
  }, []);

  const getSignature = async () => {
    const details = {};
    await axios
      .all([getSettings(details, "signature")])
      .then((res) => {
        if (res) {
          const resData = res[0];
          if (resData.success) {
            const sigData = resData?.data || {};
            setSignatureData(sigData);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };
  const getLogo = async () => {
    const details = {};
    await axios
      .all([getSettings(details, "logo")])
      .then((res) => {
        if (res) {
          const resData = res[0];
          if (resData.success) {
            const logodata = resData?.data || {};
            setLogoData(logodata);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };
  const getTermsAndConditions = async () => {
    const details = {};
    await axios
      .all([getSettings(details, "term-and-condition")])
      .then((res) => {
        if (res) {
          const resData = res[0];
          if (resData.success) {
            const termsData = resData?.data || {};
            const content = JSON.parse(termsData.content);
            setTermsData(content);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  //apis
  const getCreateInvoiceModel = async () => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getQuotationByEnquiryId(details, enquiryId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetail = resData.data ? resData.data : [];

            console.log(userDetail);
            setInvoices(userDetail);
            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting invoice Details");
      });
  };

  const getInvoice = async (invoiceNo) => {
    setButtonLoading(true);

    const details = {
      quotationId: invoices?.publicId,
    };
    await axios
      .all([downloadQuotation(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let invocieDetails = resData.data ? resData?.data : {};
            const a = document.createElement("a");
            a.href = invocieDetails?.quotationFilequotationFile;
            a.target = "_blank";
            a.download = invocieDetails?.quotationFilequotationFile
              ?.split("/")
              .pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setButtonLoading(false);
          } else {
            if (message) notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting invoice Details");
      });
  };

  const parsedValue = parseInt(invoices?.grossAmount);
  console.log(typeof parsedValue);

  const onAddFile = (selected) => {
    console.log("HERE");
    if (selected) {
      //   setUploadProductFile(selected);
    } else {
      //  setUploadProductFile("");
    }
    setAddFileModal(!addFile);
    console.log("ADD", addFile);
  };
  const onAddEmail = (selected) => {
    console.log("HERE");
    if (selected) {
      //   setUploadProductFile(selected);
    } else {
      //  setUploadProductFile("");
    }
    setAddEmailModal(!addEmail);
    console.log("ADDEMAIL", addEmail);
  };

  const Whatsappsubmit = async (values) => {
    setUploadLoding(true);
    console.log("WHATSAPPVALUESSS", values);
    await AddWhatsApp(values)
      .then((res) => {
        console.log(res);
        notifySuccess("PDF Sent Successfully!");
        onAddFile();
        setUploadLoding(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Emailsubmit = async (values) => {
    setUploadLoding(true);
    console.log("WHATSAPPVALUESSS", values);
    await AddWhatsApp(values)
      .then((res) => {
        console.log(res);
        notifySuccess("PDF Sent Successfully!");
        onAddEmail();
        setUploadLoding(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="quotation-overview-main">
      <div className="topbar d-flex align-items-center justify-content-between w-100 mb-3">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <div className="box-squar pointer" onClick={() => navigate(-1)}>
            <img src={Back_Arrow} alt="backArrow" />
          </div>
        </div>
        <div className=" d-flex align-items-center justify-containt-center gap-2">
          <div className="print box-rect">
            <div
              className="d-flex align-items-center justify-content-center gap-2 pointer"
              onClick={() => getInvoice(invoices?.invoiceNo)}
            >
              {buttonLoading ? (
                <ButtonLoader color={"blue"} />
              ) : (
                <>
                  <img src={PrinterPDF} alt="" className="" />
                  <span>Download</span>
                </>
              )}
            </div>
            {/* <img src={ArrowDown} alt="" class="bg-white rounded p-2" /> */}
          </div>

          {/* <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="share-dropdown">
              <div className="d-flex align-items-center justify-content-center gap-2 pointer">
                <img src={Share} alt="" className="" />
                <span>Share</span>
                <img
                  src={ArrowDown}
                  alt=""
                  class="bg-white rounded pt-2 ps-5"
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={onAddFile}>WhatsApp</Dropdown.Item>

              <Dropdown.Item onClick={onAddEmail}>Email</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      {pageLoading ? (
        <pageLoading />
      ) : (
        <div
          className="container bg-white rounded w-75 p-5 mt-2 my-5"
          ref={pdfRef}
        >
          <div className="d-flex justify-content-end">
            {updateQuotationLoading ? (
              <ButtonLoader />
            ) : (
              <img
                src={editicon}
                alt="edit"
                className="action-icon"
                onClick={() =>
                  navigate({
                    pathname: "/enquiries/create-quotation",
                    search: `?enquiryId=${enquiryId}&quotationId=${invoices?.publicId}&concurrencyStamp=${invoices?.concurrencyStamp}&isUpdate=true`,
                  })
                }
              />
            )}
          </div>
          <img src={LineDashed} alt="line" style={{ width: "100%" }} />
          <div className="row justify-content-between align-items-baseline">
            <div className="col-6 row">
              <div className="col-6">
                <b>QUOTATION NUMBER : </b>
              </div>
              <div className="text-secondary col-6">
                {invoices?.quotationNo || invoices?.id}
              </div>
            </div>

            <div className="col-6 row">
              <div className="col-6">
                <b>QUOTATION DATE : </b>
              </div>
              <div className="text-secondary col-6">
                {moment(invoices?.invoiceDate).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>

          <img src={LineDashed} alt="line" style={{ width: "100%" }} />

          <div className=" fs-12 ">
            <div className="table-box">
              <table class="table h-25">
                {invoices?.quotationItems &&
                  invoices?.quotationItems?.length > 0 && (
                    <>
                      <thead>
                        <tr style={{ backgroundColor: "#F5F6F8" }}>
                          <th className="col-1 text-center">Sr</th>
                          <th className="col-3">Items/service</th>
                          <th className="col-1 text-center">QTY</th>
                          <th className="col-1 text-center">Rate</th>
                          <th className="col-2 text-center">Discount</th>
                          {invoices.taxAmount &&
                            Object.keys(invoices.taxAmount).length > 0 &&
                            Object.keys(invoices.taxAmount).map((key) => (
                              <th
                                className="col-1 text-center"
                                style={{ textTransform: "uppercase" }}
                              >
                                {key}
                              </th>
                            ))}

                          <th className="col-2 text-center">
                            Discounted Price
                          </th>
                          <th className="col-2 text-center">Amount</th>
                        </tr>
                      </thead>
                      {invoices?.quotationItems?.map((each, index) => {
                        return (
                          <tbody>
                            <tr className="text-secondary">
                              <td className="text-center">{index + 1}</td>
                              <td>{each?.product?.title}</td>
                              <td className="text-center">
                                {each?.quantity || 0}
                              </td>
                              <td className="text-center">
                                ₹ {each?.price || 0}
                              </td>
                              <td className="text-center">
                                {each?.discount || 0} %
                              </td>

                              {each.taxAmount &&
                                Object.keys(each.taxAmount).length > 0 &&
                                Object.keys(each.taxAmount).map((key) => (
                                  <td className="text-center">
                                    ₹ {each?.taxAmount[key] || 0}
                                  </td>
                                ))}
                              <td className="text-center">
                                ₹ {Math.round(each.totalAmount || 0)}
                              </td>
                              <td className="text-center">
                                ₹ {Math.round(each.price * each.quantity || 0)}
                              </td>
                            </tr>
                            <tr className="my-2"></tr>
                            <tr className="my-2"></tr>
                          </tbody>
                        );
                      })}
                    </>
                  )}
              </table>
            </div>
          </div>
          <div className="fs-12">
            <div
              className="d-flex align-items-center justify-content-between fw-bold my-2 px-2 py-1"
              style={{ backgroundColor: "#F5F6F8" }}
            >
              <div>SUBTOTAL</div>
              <div>{invoices?.quotationItems?.length || 0}</div>
              <div>₹ {invoices?.netAmount}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between px-2 py-1">
              <div>Tax Amount</div>
              <div className="w-25">
                {" "}
                {invoices.taxAmount &&
                  Object.keys(invoices.taxAmount).length > 0 &&
                  Object.keys(invoices.taxAmount).map((key) => (
                    <div className="d-flex justify-content-between">
                      <span className="w-60">{key}</span>:
                      <span>₹ {invoices.taxAmount[key]}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between px-2 py-1">
              <div>Additional Charges</div>
              <div className="w-25 ">
              {console.log(invoices)}
                {invoices.addtionalCharges &&
                Object.keys(invoices.addtionalCharges).length > 0 ? (
                  Object.keys(invoices.addtionalCharges).map((key) => (
                    <div className="d-flex justify-content-between">
                      <span
                        style={{ textTransform: "capitalize" }}
                        className="w-60"
                      >
                        {helpers.camelToWords(key)}
                      </span>
                      :<span>₹ {invoices.addtionalCharges[key]}</span>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "end" }}>₹ 0</div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between px-2 py-1">
              <div>Discount Amount</div>
              <div>₹ {invoices?.discountAmount || 0}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between fw-bold my-2 px-2 py-1 border-top-bottom">
              <div>TOTAL AMOUNT</div>
              <div>₹ {invoices?.grossAmount}</div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between px-2 py-1 me-3">
                <div>Recived Amount</div>
                <div>₹ {invoices?.amountPaid || 0}</div>
              </div> */}
          </div>
          <img src={LineDashed} alt="line" style={{ width: "100%" }} />
          <div className="d-flex align-items-center justify-content-between fw-bold my-2 fs-12 px-2 py-1 border-top-bottom">
            <div>
              TOTAL AMOUNT{" "}
              <span className="fw-normal text-secondary">(In Words)</span>{" "}
            </div>
            {/* {console.log(typeof (parseInt(invoices?.taxAmount)+parseInt(invoices?.netAmount) ))}
            <div> {typeof (parseInt(invoices?.taxAmount)+parseInt(invoices?.netAmount) )==='number'  && numberToWords.toWords(parseInt(invoices?.taxAmount)+parseInt(invoices?.netAmount)).toUpperCase()}{'  '} Rupees Only</div> */}
            <div>
              {
                // Check if the parsed value is a valid number
                isNaN(parsedValue) || typeof parsedValue !== "number" ? (
                  <div>Invalid input for conversion to words.</div>
                ) : (
                  <div>
                    {numberToWords.toWords(parsedValue).toUpperCase()} Rupees
                    Only
                  </div>
                )
              }
            </div>
          </div>
          <img src={LineDashed} alt="line" style={{ width: "100%" }} />
          {termsData && termsData.length > 0 && (
            <div>
              <div className="term-heading fw-bold my-2">
                TERMS & CONDITIONS
              </div>
              <div className="text-secondary">
                {termsData.map((each, index) => {
                  return <div>{`${index + 1} . ${each}`}</div>;
                })}
              </div>
            </div>
          )}
          <img src={LineDashed} alt="line" style={{ width: "100%" }} />
          <div className="signature d-flex flex-column align-items-end justify-content-end pe-4 me-4  "></div>

          <div className="row justify-content-between align-items-center">
            <div className="col-8"></div>

            <div className="col-4 row">
              <div className="col-12">
                <div className="sign-box me-4 my-3 text-center"></div>
                <div className="fw-bold my-2">Authorized Signature</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        centered
        show={addFile}
        onHide={onAddFile}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Mobile Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              type: "whatsApp",
              value: "",
              invoiceNo: invoices?.invoiceNo || "",
            }}
            validationSchema={addMobileSchema}
            onSubmit={(values) => {
              Whatsappsubmit(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <FormGroup className="login-input-group">
                  <Label for="value">Enter Phone Number</Label>
                  <Input
                    id="value"
                    name="value"
                    type="number"
                    placeholder="Enter Phone number"
                    class="user-form-group"
                    value={values.value}
                    onChange={(e) => {
                      setFieldValue("value", e.target.value);
                    }}
                    onBlur={handleBlur}
                    // style={{ textTransform: "capitalize" }}
                  ></Input>
                  <Error errors={errors} touched={touched} name="value" />
                </FormGroup>

                <div className="mt-3">
                  <div className="d-flex gap-2 justify-content-end">
                    <Button className="back-icon-btn-main" onClick={onAddFile}>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="btn-with-icon" type="submit">
                      {uploadLoading ? <ButtonLoader /> : "Submit"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={addEmail}
        onHide={onAddEmail}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              type: "whatsApp",
              value: "",
              invoiceNo: invoices?.invoiceNo || "",
            }}
            validationSchema={addEmailSchema}
            onSubmit={(values) => {
              Emailsubmit(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <FormGroup className="login-input-group">
                  <Label for="value">Enter Email Address</Label>
                  <Input
                    id="value"
                    name="value"
                    type="text"
                    placeholder="Enter Mail Id"
                    class="user-form-group"
                    value={values.value}
                    onChange={(e) => {
                      console.log("PHONENUMBERRRRRR", e);
                      // handleChange(e);
                      setFieldValue("value", e.target.value);
                    }}
                    onBlur={handleBlur}
                    // style={{ textTransform: "capitalize" }}
                  ></Input>
                  <Error errors={errors} touched={touched} name="value" />
                </FormGroup>

                <div className="mt-3">
                  <div className="d-flex gap-2 justify-content-end">
                    <Button className="back-icon-btn-main" onClick={onAddFile}>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="btn-with-icon" type="submit">
                      {uploadLoading ? <ButtonLoader /> : "Submit"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QuotationOverview;
