import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  setUserPreferredLanguage,
  getUserPreferredLanguage,
} from "../../store/LocalStorage";
import moment from "moment";
import { useTranslation } from "react-i18next";
import notificationicon from "../../assets/images/icons/notification-header.svg";
import DownArrowicon from "../../assets/images/icons/DownArrow.svg";
import usericon from "../../assets/images/icons/user-header.svg";
import Profile from "../../assets/images/icons/profile.svg";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import { RiCheckDoubleFill } from "react-icons/ri";
import { ButtonLoader } from "../../assets/loaders";
import MainServices from "../../services/MainServices";

export default function Header() {
  const [open, setOpen] = useState(false);
  const [notiOpen, setNotiOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth();
  const { getNotifications, updateNotification } = MainServices();
  const [totalCount, setTotalCount] = useState(3);
  const { getAvailableLanguages } = useFormikDynamicLanguageSchema();
  const [notificationToggle, setNotificationToggle] = useState(false);
  const availableLanguages = getAvailableLanguages();
  const languageSelected =
    getUserPreferredLanguage() || navigator.language.substring(0, 2);
  const [updateNotifications, setUpdateNotifications] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [readNotificationsList, setReadNotificationsList] = useState([]);
  const [unreadNotificationsList, setUnreadNotificationsList] = useState([]);
  const notificationInterval = useRef(null);

  useEffect(() => {
    notificationInterval.current = setInterval(() => {
      getNotificationsList();
    }, 5000);
    return () => {
      clearInterval(notificationInterval.current);
    };
  }, []);

  const getNotificationsList = async () => {
    const details = {
      pageSize: 50,
      pageNumber: 1,
      sorting: [
        {
          key: "createdAt",
          direction: "desc",
        },
      ],
    };
    await getNotifications(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            let notifications = resData.data ? resData.data : [];
            let unreadNotificationsList = [];
            let readNotificationsList = [];
            notifications.map((each) => {
              each?.readStatus === "notRead"
                ? unreadNotificationsList.push(each)
                : readNotificationsList.push(each);
            });
            setNotifications(notifications);
            setReadNotificationsList(readNotificationsList);
            setUnreadNotificationsList(unreadNotificationsList);
            setTotalCount(total);
          }
        }
        //  setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        //  setSearchLoading(false);
      });
  };

  const handleChangeNotificationDetail = async (each) => {
    const details = {
      readStatus: "read",
    };
    await updateNotification(details, each.publicId, each.concurrencyStamp)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            getNotificationsList();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };
  const notiToggle = () => {
    setNotiOpen((prevState) => !prevState);
  };

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  const notificationToggleModal = () => {
    setNotificationToggle(!notificationToggle);
  };

  return (
    <div className="header-main">
      <div className="header-menu">
        {/* {user.role === "supplier" && (
          <div className="status">
            <p>
              {t("Your account status is")}{" "}
              <span className={user.status?.toLowerCase()}>
                {t(user.status)}
              </span>
            </p>
          </div>
        )} */}
        {/* <select
          className="header-select"
          defaultValue={languageSelected}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            setUserPreferredLanguage(e.target.value);
          }}
        >
          <option value="en">ENG</option>
          {availableLanguages.map((language) => {
            return <option value={language.value}>{language.symbol}</option>;
          })}
        </select> */}

        <Dropdown
          className="notification"
          isOpen={notificationToggle}
          toggle={notificationToggleModal}
        >
          <DropdownToggle className="notification-button">
            <span className="notification-icon">
              <img
                src={notificationicon}
                alt="notifications"
                className="icon"
              />
              {unreadNotificationsList &&
                unreadNotificationsList.length > 0 && (
                  <>
                    <span className="notification-count">
                      {unreadNotificationsList && unreadNotificationsList.length
                        ? unreadNotificationsList.length
                        : "0"}
                    </span>
                  </>
                )}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <div className="notification-main-seciton">
              <div className="notification-header">
                <div className="left-section">
                  <h6 className=" header-text">Notifications</h6>
                  <p className=" normal-text-header">
                    You have{" "}
                    {unreadNotificationsList && unreadNotificationsList.length}{" "}
                    unread messages
                  </p>
                </div>
                {notifications && notifications.length > 0 && (
                  <>
                    <div className=" right-section">
                      {updateNotifications ? (
                        <ButtonLoader />
                      ) : (
                        <button
                          className="check-double-fill-button "
                          type="button"
                          onClick={() => {
                            this.vendorUpdateNotifiations();
                          }}
                        >
                          <RiCheckDoubleFill />
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
              <hr className=" divider" />
              <div>
                <ul className=" notification-list">
                  <li className="list-sub-header">NEW</li>
                  <hr className=" divider" />
                  {unreadNotificationsList &&
                  unreadNotificationsList.length > 0 ? (
                    <>
                      {unreadNotificationsList &&
                        unreadNotificationsList.length > 0 &&
                        unreadNotificationsList.map((eachNotification) => {
                          return (
                            <>
                              <li
                                className="notification-list-li-open "
                                onClick={() => {
                                  handleChangeNotificationDetail(
                                    eachNotification
                                  );
                                  navigate({
                                    pathname:
                                      user?.role === "supplier"
                                        ? "/chat"
                                        : "/settings",
                                    search: `?supplierId=${eachNotification?.notificationId}&status=Notifications`,
                                  });
                                  notificationToggleModal();
                                }}
                              >
                                <div className=" notification-list-item ">
                                  <h6 className="list-item-h6">
                                    {eachNotification.notificationType &&
                                      eachNotification.notificationType}
                                    <span className=" normal-text">
                                      &nbsp; {eachNotification.message}
                                    </span>
                                  </h6>
                                  {eachNotification.createdAt &&
                                    eachNotification.createdAt && (
                                      <span className="list-item-timeline">
                                        <i class="fas fa-clock"></i> &nbsp;
                                        {moment(
                                          eachNotification.createdAt
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                      </span>
                                    )}
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <li className="notification-list-li ">
                      <div className=" notification-list-item ">
                        <h6 className="list-item-h6">
                          Notification not available
                        </h6>
                      </div>
                    </li>
                  )}
                </ul>
                <ul className=" notification-list">
                  <hr className=" divider" />
                  <li className="list-sub-header">BEFORE THAT</li>
                  <hr className=" divider" />
                  {readNotificationsList && readNotificationsList.length > 0 ? (
                    <>
                      {readNotificationsList &&
                        readNotificationsList.length > 0 &&
                        readNotificationsList.map((eachNotification) => {
                          return (
                            <>
                              <li
                                onClick={() => {
                                  handleChangeNotificationDetail(
                                    eachNotification
                                  );
                                  navigate({
                                    pathname:
                                      user?.role === "supplier"
                                        ? "/chat"
                                        : "/settings",
                                    search: `?supplierId=${eachNotification?.notificationId}&status=Notifications`,
                                  });
                                  notificationToggleModal();
                                }}
                                className="notification-list-li "
                              >
                                <div className=" notification-list-item ">
                                  <h6 className="list-item-h6">
                                    {eachNotification.notificationType &&
                                      eachNotification.notificationType}
                                    <span className=" normal-text">
                                      &nbsp; {eachNotification.message}
                                    </span>
                                  </h6>
                                  {eachNotification.createdAt &&
                                    eachNotification.createdAt && (
                                      <span className="list-item-timeline">
                                        <i class="fas fa-clock"></i> &nbsp;
                                        {moment(
                                          eachNotification.createdAt
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                      </span>
                                    )}
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <li className="notification-list-li ">
                      <div className=" notification-list-item ">
                        <h6 className="list-item-h6">
                          Notification not available
                        </h6>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>

        <Dropdown className="icon-dropdown" isOpen={open} toggle={toggle}>
          <DropdownToggle tag="span">
            <div className="user-dropdown">
              <img src={Profile} alt="profile.svg" className="icon" />
            </div>
          </DropdownToggle>

          <div className="email-name">
            <p>{user.name}</p>
            <span>{user.email}</span>
          </div>
          <div className="down-arrow" isOpen={open} toggle={toggle}>
            <img src={DownArrowicon} alt="DownArrow" className="icon" />
          </div>
          <DropdownMenu className="user-dropdown-menu">
            <div className="menupointer"></div>
            <div className="options-list">
              {user?.role === "supplier" && (
                <DropdownItem
                  className="item"
                  onClick={() => navigate("/ProfileUser")}
                >
                  <p>{t("Profile")}</p>
                </DropdownItem>
              )}
              {/* <DropdownItem
                className="item"
                onClick={() =>
                  navigate({
                    pathname: "/settings",
                    search: "&status=Account Setup",
                  })
                }
              >
                <p>{t("Settings")}</p>
              </DropdownItem> */}
              <DropdownItem className="item" onClick={onLogout}>
                <p>{t("Logout")}</p>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}
