import React, { useEffect, useRef, useState } from "react";
import "./CustomSelect.scss";

const MultiLevelSelect = ({
  options,
  onChange,
  className,
  multi,
  inputValue,
  placeholder,
  value,
  loadOptions,
  isParentClickable,
}) => {
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [displayOptions, setDisplayOptions] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectRef = useRef();
  const [listPosition, setListPosition] = useState({ top: 0 });

  const onOpenOptions = () => {
    if (!openOptions) {
      setOpenOptions(true);
    }
  };

  const renderUi = (eachoption, parent) => {
    return (
      <>
        {eachoption.children && eachoption.children.length > 0 ? (
          <li
            className="is-parent list-item"
            key={eachoption.label}
            onClick={() => {
              isParentClickable && onSelectOption(eachoption);
            }}
          >
            <span className="parent-label label">{eachoption.label}</span>
            <ul className="ul-list submenu">
              {eachoption.children.map((eachSubOption) =>
                renderUi(eachSubOption)
              )}
            </ul>
          </li>
        ) : (
          <li
            className="no-child-item"
            key={eachoption.label}
            onClick={() => onSelectOption(eachoption)}
          >
            <span className="child-label lable">{eachoption.label}</span>
          </li>
        )}
        {parent && eachoption.children && eachoption.children.length > 0 && (
          <hr className="divider" />
        )}
      </>
    );
  };

  useEffect(() => {
    setDisplayOptions(options);
  }, [options]);
  useEffect(() => {
    if (multi) {
      setSelectedOptions(value);
    } else {
      setSelectedOption(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const onSelectOption = (option) => {
    if (multi) {
      if (selectedOptions && selectedOptions.length > 0) {
        let isExistTrue = false;
        selectedOptions.map((each) => {
          if (each.value === option.value) {
            isExistTrue = true;
          }
        });
        if (!isExistTrue) onChange([...selectedOptions, option]);
      } else {
        onChange([option]);
      }
    } else {
      console.log(option);
      onChange(option);
    }
  };

  const onDeleteOption = (id) => {
    let options = selectedOptions || [];
    options = options.filter((each) => each.value !== id);
    onChange(options);
  };
  const onClearOptions = (id) => {
    onChange([]);
  };

  const calculateListPosition = () => {
    const selectRect = selectRef.current.getBoundingClientRect();
    setListPosition({
      top: selectRect.height + 5,
      width: selectRect.width,
    });
  };

  useEffect(() => {
    calculateListPosition();
  }, [openOptions, selectedOptions]);

  const onLoadOptions = async (value) => {
    if (loadOptions) {
      await loadOptions(value, getDisplayOptions);
    }
  };

  const getDisplayOptions = async (options) => {
    setDisplayOptions(options);
    setLoading(false);
  };

  return (
    <div
      className={`custom-select ${className ? className : ""} `}
      onClick={onOpenOptions}
      ref={selectRef}
    >
      <div className="options-input">
        {selectedOptions &&
          selectedOptions.length > 0 &&
          selectedOptions.map((eachOption) => (
            <div className="selected-option" id={eachOption.value}>
              <span className="selected-option-label">{eachOption.label}</span>
              <div
                role="button"
                class="delete-cross"
                aria-label={eachOption.label}
                onClick={() => onDeleteOption(eachOption.value)}
              >
                <svg
                  height="14"
                  width="14"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  class="css-8mmkcg"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            </div>
          ))}
        <div className="input-div">
          <input
            className="select-input"
            onFocus={onOpenOptions}
            onChange={(e) => {
              onOpenOptions();
              setLoading(true);
              onLoadOptions(e.target.value);
            }}
            onClick={onOpenOptions}
            value={inputValue}
            placeholder={
              !(selectedOption || selectedOptions.length > 0)
                ? placeholder
                  ? placeholder
                  : " Select"
                : ""
            }
          />
        </div>

        {openOptions && (
          <div
            className="custom-list"
            style={{
              position: "absolute",
              top: `${listPosition.top}px`,
              width: `${listPosition.width}px`,
            }}
          >
            {loading ? (
              <div className="no-options">
                <p className="no-options-text">Loading...</p>
              </div>
            ) : displayOptions && displayOptions.length > 0 ? (
              <ul className="ul-list ">
                {displayOptions.map((eachoption) => {
                  return renderUi(eachoption, true);
                })}
              </ul>
            ) : (
              <div className="no-options">
                <p className="no-options-text">No Options</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="delete-arrow-icons">
        {selectedOptions && selectedOptions.length > 0 && (
          <div
            className={`delete-icon ${openOptions ? "active" : ""}`}
            aria-hidden="true"
            onClick={onClearOptions}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="icon-svg"
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </div>
        )}
        <span className="separator"></span>
        <div
          className={`arrow-icon ${openOptions ? "active" : ""}`}
          aria-hidden="true"
          onClick={() => setOpenOptions(!openOptions)}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="icon-svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MultiLevelSelect;
