import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase";

const FirebaseSerives = {
  SignIn: async function (details) {
    const { email, password } = details;
    return await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        return userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        return errorCode;
      });
  },
  Register: async function (details) {
    const { email, password } = details;
    return await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        return userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        return errorCode;
      });
  },
  Update: async function (details) {
    const { name, phone } = details;
    return await updateProfile(auth.currentUser, {
      displayName: name,
      phoneNumber: phone,
    })
      .then(() => {
        return auth.currentUser;
      })
      .catch((error) => {
        const errorCode = error.code;
        return errorCode;
      });
  },
  LogOut: async function () {
    return await signOut()
      .then(() => {
        return;
      })
      .catch((error) => {
        const errorCode = error.code;
        return errorCode;
      });
  },
};

export default FirebaseSerives;
