import React, { useState, useEffect } from "react";
import "./Orders.scss";
import { useTranslation } from "react-i18next";
import edit from "../../assets/images/Edit.svg";
import dele from "../../assets/images/Delete.svg";
import FilterIcon from "../../assets/images/Filtters.svg";
import TableSearch from "../../assets/elements/TableSearch";
import CustomPagination from "../../assets/elements/Pagination";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import { Table } from "reactstrap";
import Nav from "react-bootstrap/Nav";
import ud from "../../assets/images/arrow-3.svg";
import eyes from "../../assets/images/eye-slash.svg";
import arrows from "../../assets/images/TB Arrows.svg";
import {
  filtersToUrlParams,
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
} from "../../assets/functions/filters";
import editIcon from "../../assets/images/icons/edit.svg";
import { useNavigate, useLocation } from "react-router-dom";
import MainServices from "../../services/MainServices";
import SupplierSelect from "../../assets/elements/SupplierSelect";
import HeadingMain from "../../assets/elements/HeadingMain";
import Tab from "../../assets/elements/Tab";
import CustomTable from "../../assets/elements/Table";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Formik, Form } from "formik";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import moment from "moment";
import eyeIcon from "../../assets/images/eye.svg";

export default function AdminOrdersList() {
  const [totalCount, setTotalCount] = useState(0);
  const [auditmaster, setAuditmaster] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedOrderType, setSelectedOrderType] = useState("All");
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [orderStatusMain, setOrderStatusMain] = useState("");
  const [orderProductLoading, setOrderProductLoading] = useState(false);

  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { deBounce } = TimingFunctions();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const status = changedParams?.order_product_status;
  const orderType = changedParams?.orderType;

  const { getOrdersByType, orderItemUpdateStatus, getOrderProductList } =
    MainServices();

    const orderProductStatusModal = (selected) => {
      setSelectedOrder(selected);
      setStatusModal(!statusModal);
    };
  
    //table functions
    const actions = (row) => {
      if (!row) return;
      return (
        <div className="action-btn-main">
          <img
            src={eyeIcon}
            alt="orders view"
            style={{
              cursor: "pointer",
            }}
            onClick={() => orderProductStatusModal(row)}
          />
        </div>
      );
    };

  useEffect(() => {
    //getOrders().then(res => console.log("orderslist: ", res));
  }, []);


  const imageAvatar = (row) => {
    if (!row) return;
    return (
      <div className="avatar-image-main">
        <img src={row.productImage} alt="mventus avatar" />
      </div>
    );
  };

  const orderDate = (row) => {
    if (!row) return;
    return moment(row.createdAt).format("DD/MM/YYYY");
  };

  const orderIdStyle = (row) => {
    if (!row) return;
    return (
      <div
        className="action-btn-main"
        onClick={() =>
          navigate({
            pathname: "/orders/order-details-invoice",
            search: `?orderId=${row.orderId}&type=${
              selectedOrderType ? selectedOrderType : "product"
            }`,
          })
        }
        style={{ cursor: "pointer", color: "#00f" }}
      >
        <b>{row ? row?.id : "-"}</b>
      </div>
    );
  };

  const orderProductStatusArray = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Pending Orders",
      value: "pending",
    },
    {
      label: "Order Delivered",
      value: "order-delivered",
    },
    {
      label: "Order Cancel",
      value: "order-cancelled",
    },
  ];

  const orderProductStatusSubArray = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Order Place",
      value: "order-placed",
    },
    {
      label: "Order Accept",
      value: "order-accepted",
    },
    {
      label: "Order Pack",
      value: "order-packed",
    },
    {
      label: "Order Dispatched",
      value: "order-dispatched",
    },
    {
      label: "Out For Delivery",
      value: "out-for-delivery",
    },
  ];

  const orderTypeTabItems = [
    {
      label: `${t("Product")}`,
      value: "product",
    },
    {
      label: `${t("Catering")}`,
      value: "catering",
    },
    {
      label: `${t("Photoshoot")}`,
      value: "photoshoot",
    },
  ];

  const orderProductStatusStyle = (row) => {
    if (!row) return;
    return (
      <div style={{ textTransform: "capitalize" }}>
        {row?.orderProductStatus.replace(/-/g, " ")}
      </div>
    );
  };

  const itemsNameStyle = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main" style={{ width: "200px" }}>
        {row?.title}
      </div>
    );
  };

  const admin_columns = [
    {
      field: "productImage",
      text: "Images",
      customStyle: imageAvatar,
    },
    {
      field: "id",
      text: "Order ID",
      customStyle: orderIdStyle,
    },
    {
      field: "title",
      text: "Items",
      customStyle: itemsNameStyle,
    },
    {
      field: "createdAt",
      text: "Order Date",
      customStyle: orderDate,
    },
    {
      field: "quantity",
      text: "No. Of Items",
      //customStyle: styledStatus,
    },
    {
      field: "sellingPrice",
      text: "Total Order Price",
      //customStyle: dateFormat,
    },
    {
      field: "orderProductStatus",
      text: "Order Status",
      customStyle: orderProductStatusStyle,
    },
    // {
    //   field: "shipTo",
    //   text: "Ship To",
    // },
    // {
    //   field: "pendingReason",
    //   text: "Pending/Return Reason",
    // },
    // {
    //   field: "deliveryDate",
    //   text: "Delivery Date",
    // },
    // {
    //   field: "paymentMethod",
    //   text: "Payment",
    // },
    // {
    //   field: "discountPrice",
    //   text: "Discount",
    // },
    {
      field: "",
      text: "Actions",
      customStyle: actions,
    },
  ];

  const prepareSearchForNavigate = (anotherParam) => {
    let newSearchAsObject = {
      ...changedParams,
      ...anotherParam,
    };
    let newSearchAsString = filtersToUrlParams({ ...newSearchAsObject });
    return newSearchAsString;
  };

  const onOrderTypeChange = (selected) => {
    let newSearchAsString = prepareSearchForNavigate({ orderType: selected });
    navigate({ search: newSearchAsString });
  };

  // const onStatusChangeModal = (selected) => {
  //   setSelectedOrder(selected);
  //   setModal(!modal);
  // };

  useEffect(() => {
    setSelectedStatus(status);
    //eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    setSelectedOrderType(orderType);
    //eslint-disable-next-line
  }, [orderType]);

  const onSupplierChange = (supplierId) => {
    //let mfilters = filtersToUri(filters);
    //let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: 10,
      //filters: mfilters,
      //sort: msorts,
      ...(supplierId ? { supplierId: supplierId } : {}),
    });
    navigate({ search });
    //setProducts([]);
  };

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let sorts = paramsToFilters(changedParams?.sort, "sort");
    let sortObj = paramsToFiltersObj(changedParams?.sort);
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    changedParams.supplierId
      ? getOrdersList({
          ...changedParams,
          page: pagination.page,
          limit: pagination.limit,
          filters,
          sorts,
          supplierId: changedParams.supplierId,
        })
      : getOrdersList({
          ...changedParams,
          page: pagination.page,
          limit: pagination.limit,
          filters,
          sorts,
        });

    //eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.sort,
    changedParams.supplierId,
    changedParams.order_product_status,
    changedParams.orderType,
  ]);

  console.log(filters);
  useEffect(() => {
    const auditExample = [
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
      {
        orderID: "123456789",
        orderDate: "12-05-2023",
        deliveryDate: "15-08-2023",
        noofItems: "05",
        totalPrice: "2,50,000",
        modePayment: "online",
        taxNumber: "123456789",
      },
    ];
    setAuditmaster(auditExample);
  }, []);

  //pagination functions
  const onPageChange = (page) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      sort: msorts,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let mfilters = filtersToUri(filters);
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  const onSearch = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e, "name"));
    setSearchString(e.target.value);
  };

  const onOrderStatusChange = (e) => {
    setOrderStatusMain(e.target.value);
    let mfilters = filtersToUri(filters);
    mfilters = filtersToUri({
      ...filters,
      order_product_status: {
        order_product_status:
          e.target.value === "pending"
            ? [
                "order-placed",
                "order-accepted",
                "order-packed",
                "order-dispatched",
                "out-for-delivery",
              ]
            : e.target.value,
      },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    setProducts([]);
    navigate({
      search,
    });
  };

  const onSearchFilter = (e, type) => {
    let mfilters = filtersToUri(filters);
    if (type === "name") {
      mfilters = filtersToUri({
        ...filters,
        name: { name: e.target.value },
      });
    }
    if (type === "order_product_status") {
      mfilters = filtersToUri({
        ...filters,
        order_product_status: { order_product_status: e.target.value },
      });
    }
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    setProducts([]);
    navigate({
      search,
    });
  };

  const onNameSort = () => {
    let msorts = filtersToUri({
      ...sorts,
      name: { name: sorts?.name?.name === "asc" ? "desc" : "asc" },
    });
    let search = filtersToUrlParams({
      ...changedParams,
      page: 1,
      limit: pagination.limit,
      sort: msorts,
    });
    navigate({
      search,
    });
    setProducts([]);
  };

  const getOrdersList = async ({ page, limit, filters, sort, orderType }) => {
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      orderType: orderType || "product",
    };

    await getOrderProductList(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let total = +resData?.totalCount || 0;
            let products = resData.data ? resData.data : [];

            products = products.map((each) => {
              return {
                ...each,
                title:
                  (each?.orders &&
                    each?.orders.length > 0 &&
                    each?.orders[0]?.type === "catering") ||
                  each?.orders[0]?.type === "photoshoot"
                    ? each?.name
                    : each?.title,

                sellingPrice:
                  each?.orders &&
                  each?.orders.length > 0 &&
                  each?.orders[0]?.type === "catering"
                    ? each?.orders &&
                      each?.orders.length > 0 &&
                      each?.orders[0]?.totalItemsPrice
                    : each?.sellingPrice,

                quantity:
                  each?.orders &&
                  each?.orders.length > 0 &&
                  each?.orders[0]?.type === "catering"
                    ? each?.orders &&
                      each?.orders.length > 0 &&
                      each?.orders[0]?.event
                    : each?.quantity,

                totalSellingPrice:
                  each?.orders &&
                  each?.orders.length > 0 &&
                  each?.orders[0]?.type === "catering"
                    ? each?.orders &&
                      each?.orders.length > 0 &&
                      each?.orders[0]?.totalPayableAmount
                    : each?.totalSellingPrice,

                orderProductStatus: each?.orderProductStatus.replace(/-/g, " "),
              };
            });
            //setProducts([...products, ...resData]);
            setProducts([...products]);
            setTotalCount(total);
            setOrderProductLoading(false);
          }
        }
        setOrderProductLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setOrderProductLoading(false);
      });
  };

  console.log("selectedOrder asfas", selectedOrder?.orderStatus?.orderStatus);

  const orderStatusUpdate = async (values) => {
    let orderId = "";
    let publicId = "";
    let concurrencyStamp = "";
    orderId =
      selectedOrder.type === "product"
        ? selectedOrder?.orderProducts[0]?.orderId
        : selectedOrder.type === "catering"
        ? selectedOrder?.orderRestaurants[0]?.orderId
        : selectedOrder?.orderPhotoshoots[0]?.orderId;

    publicId =
      selectedOrder.type === "product"
        ? selectedOrder?.orderProducts[0]?.publicId
        : selectedOrder.type === "catering"
        ? selectedOrder?.orderRestaurants[0]?.publicId
        : selectedOrder?.orderPhotoshoots[0]?.publicId;

    concurrencyStamp =
      selectedOrder.type === "product"
        ? selectedOrder?.orderProducts[0]?.concurrencyStamp
        : selectedOrder.type === "catering"
        ? selectedOrder?.orderRestaurants[0]?.concurrencyStamp
        : selectedOrder?.orderPhotoshoots[0]?.concurrencyStamp;

    const details = {
      orderProductStatus: values.status,
      orderId: publicId,
    };
    await orderItemUpdateStatus(details, orderId, concurrencyStamp)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            getOrdersList(pagination.page, pagination.limit, filters, sorts);
          }
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  return (
    <>
      <div className="heading">
        <HeadingMain text="Orders" />
      </div>
      <div className="order-table-main">
        <div className="table-flex-main">
          <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
            searchLoading={searchLoading}
          ></TableSearch>
          <div>
            <Input
              className="select"
              onChange={(e) => {
                onOrderStatusChange(e);
              }}
              value={orderStatusMain}
              type="select"
            >
              {orderProductStatusArray.map((each) => {
                return <option value={each?.value}>{each?.label} </option>;
              })}
            </Input>
          </div>
          {orderStatusMain === "pending" && (
            <div>
              <Input
                className="select"
                onChange={(e) => onSearchFilter(e, "order_product_status")}
                value={filters?.order_product_status?.order_product_status}
                type="select"
              >
                {orderProductStatusSubArray.map((each) => {
                  return <option value={each?.value}>{each?.label} </option>;
                })}
              </Input>
            </div>
          )}
        </div>

        {products && products.length > 0 ? (
          <div>
            <CustomTable
              data={products}
              columns={admin_columns}
              className="custom-table custom-table-with-border"
            />

            <CustomPagination
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              totalCount={totalCount}
              onPageChange={onPageChange}
              onRowsPerPage={onRowsChange}
            />
          </div>
        ) : (
          <div className="no-data-div">
            <p>{t("No Orders found")}</p>
          </div>
        )}

        {/* <Modal
          isOpen={modal}
          size="md"
          centered
          toggle={() => onStatusChangeModal("")}
          className="add-category-modal"
        >
          <ModalHeader
            toggle={() => onStatusChangeModal("")}
            className="modal-header-custom"
          >
            Status Change
          </ModalHeader>
          <ModalBody className="modal-body-custom">
            <div className="addCategory-main">
              <div className="d-flex justify-content-between">
                <p className="heading-text">
                  {t("Please enter details in English")}
                </p>
              </div>
              <div className="addCategory-input-form">
                <Formik
                  initialValues={{
                    status: "",
                  }}
                  // validationSchema={addCategorySchema}
                  onSubmit={(values) => {
                    console.log(values);
                    orderStatusUpdate(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    setFieldValue,
                  }) => (
                    <Form>
                      <FormGroup>
                        <div className="input-container">
                          <Row>
                            <Label for="status">{t("Select Status")}</Label>
                          </Row>
                          <Input
                            className="select"
                            name="status"
                            id="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                            type="select"
                          >
                            <option value="">{t("All")}</option>
                            <option value="order-placed">
                              {t("Order Placed")}{" "}
                            </option>
                            <option value="order-accepted">
                              {t("Order Accepted")}{" "}
                            </option>
                            <option value="order-packed">
                              {t("Order Packed")}{" "}
                            </option>
                            <option value="order-dispatched">
                              {t("Order Dispatched")}{" "}
                            </option>
                            <option value="order-delivered">
                              {t("Order Delivered")}{" "}
                            </option>
                            <option value="order-cancelled">
                              {t("Order Cancelled")}{" "}
                            </option>
                          </Input>
                        </div>
                      </FormGroup>

                      <div className="save-btn-container">
                        <PrimaryButton
                          type="submit"
                          title="Save"
                          isLoading={addCategoryLoading}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
        <Modal
          isOpen={statusModal}
          size="lg"
          centered
          toggle={() => orderProductStatusModal("")}
          className="add-category-modal"
        >
          <ModalHeader
            toggle={() => orderProductStatusModal("")}
            className="modal-header-custom"
          >
            Order Product Status Details
          </ModalHeader>
          <ModalBody className="modal-body-custom">
            <div className="addCategory-main">
              <div className="addCategory-input-form">
                <Formik initialValues={{}}>
                  {({ values, handleBlur, handleChange }) => (
                    <Form>
                      <FormGroup>
                        <div className="input-container">
                          <Row>
                            {selectedOrder ? (
                              <Table borderless responsive> 
                                <thead className="voucher-table-head">
                                  <tr>
                                    <th>{t("Sr.No.")}</th>
                                    <th>{t("Product Status")}</th>
                                    <th>{t("Updated At")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {selectedOrder?.orderStatus?.orderStatus}
                                    </td>
                                    <td>
                                      {moment(selectedOrder?.createdAt).format("DD-MM-YYYY")}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            ) : (
                              <p
                                style={{
                                  fontSize: "18px",
                                  color: "#00f",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                                className="m-0"
                              >
                                Tracking history not found
                              </p>
                            )}
                          </Row>
                        </div>
                      </FormGroup>

                      <div className="save-btn-container">
                        <PrimaryButton
                          type="submit"
                          title="Close"
                          onClick={() => orderProductStatusModal("")}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
