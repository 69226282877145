import React, {useState, useEffect} from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import MainServices from "../../services/MainServices";
import "./UpdateProductPrice.scss";
import { notifySuccess } from "../../assets/elements/Toaster";


const UpdateProductPrice = ({productId, productPriceObjectId, modal, setModal, currentPrice}) => {
  const { t } = useTranslation();
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [isUpdatePriceInProgress, setIsUpdatePriceInProgress] = useState();
  const { updateProductPriceById, getProductById } = MainServices();

  const updatePriceSchema = Yup.object().shape({
    maxPrice: Yup.number().required(`${t("Max Price")} ${t("is required")}`),
    sellingPrice: Yup.number()
      .positive()
      .required(`${t("Selling Price")} ${t("is required")}`),
    //retailPrice: Yup.number().required("Retail price is required"),
    costPrice: Yup.number().required(`${t("Cost Price")} ${t("is required")}`),
    shippingCharge: Yup.number().required(
      `${t("Shipping Charge")} ${t("is required")}`
    ),
    deliveryCharge: Yup.number().required(
      `${t("Delivery Charge")} ${t("is required")}`
    ),
  });

  const toggleUpdatePriceModal = () => {
    setModal(!modal);
  }

  const updatePrice = async (values) => {
    setIsUpdatePriceInProgress(true);
    setErrorsOnSubmit([]);
    let details = {
      productId,
      // + to convert into numbers
      maxPrice: +values.maxPrice,
      sellingPrice: +values.sellingPrice,
      costPrice: +values.costPrice,
      shippingCharge: +values.shippingCharge,
      deliveryCharge: +values.deliveryCharge
    }
    let latestConcurrencyStamp = await getProductById(productId).then(
      (res) => res.data?.productPrice?.concurrencyStamp
    );
    updateProductPriceById(details, productPriceObjectId, latestConcurrencyStamp)
      .then(res => {
        if(res.success)
        {
          setIsUpdatePriceInProgress(false);
          notifySuccess("Updated successfully!");
          setModal(false);
        }
        else {
          setIsUpdatePriceInProgress(false);
          setErrorsOnSubmit(res?.message ? [res.message] : ["Error occured!"]);
        }
      })
      .catch(error => {
        setIsUpdatePriceInProgress(false);
        setErrorsOnSubmit(error);
      })

  }

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        centered
        toggle={() => toggleUpdatePriceModal("")}
      >
        <ModalHeader
          toggle={() => toggleUpdatePriceModal("")}
          className="modal-header-custom"
        >
          {t("Update Price")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="updateProductPrice-main">
            <p className="heading-text">{t("Please enter details in English")}</p>

            <div className="updateProduct-input-form">
              <Formik
                initialValues={{
                  sellingPrice: currentPrice?.sellingPrice || "",
                  maxPrice: currentPrice?.maxPrice || "",
                  costPrice: currentPrice?.costPrice || "",
                  shippingCharge: currentPrice?.shippingCharge || "",
                  deliveryCharge: currentPrice?.deliveryCharge || "",
                }}
                onSubmit={(values) => {
                  updatePrice(values);
                }}
                validationSchema={updatePriceSchema}
                //enableReinitialize
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                }) => (
                  <Form>
                    <div className="product-bottomcard-main">
                  <h6 className="user-heading">{t("Pricing")}</h6>
                  <Form>
                    <div className="row">
                      <FormGroup className="col-12 col-md-6">
                        <Label for="sellingPrice">{t("Selling Price")}</Label>
                        <Input
                          id="sellingPrice"
                          name="sellingPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={
                            values.sellingPrice > -1 ? values.sellingPrice : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="sellingPrice"
                        />
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup className="col-12 col-md-6 col-lg-3">
                        <Label for="costPrice">{t("Cost Price")}</Label>
                        <Input
                          id="costPrice"
                          name="costPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={values.costPrice > -1 ? values.costPrice : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="costPrice"
                        />
                      </FormGroup>
                      <FormGroup className="col-12 col-md-6 col-lg-3">
                        <Label for="maxPrice">{t("Max Price")}</Label>
                        <Input
                          id="maxPrice"
                          name="maxPrice"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={values.maxPrice > -1 ? values.maxPrice : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="maxPrice"
                        />
                      </FormGroup>
                      <FormGroup className="col-12 col-md-6 col-lg-3">
                        <Label for="shippingCharge">
                          {t("Shipping Charge")}
                        </Label>
                        <Input
                          id="shippingCharge"
                          name="shippingCharge"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={
                            values.shippingCharge > -1
                              ? values.shippingCharge
                              : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="shippingCharge"
                        />
                      </FormGroup>
                      <FormGroup className="col-12 col-md-6 col-lg-3">
                        <Label for="deliveryCharge">
                          {t("Delivery Charge")}
                        </Label>
                        <Input
                          id="deliveryCharge"
                          name="deliveryCharge"
                          placeholder="AED | 150"
                          type="number"
                          className="product-form-group"
                          value={
                            values.deliveryCharge > -1
                              ? values.deliveryCharge
                              : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="deliveryCharge"
                        />
                      </FormGroup>
                    </div>
                  
                  </Form>
                </div>

                    <div className="errors-after-sumbit">
                      {errorsOnSubmit.map((error) => (
                        <p>{error}</p>
                      ))}
                    </div>

                    <div className="update-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Update"
                        isLoading={isUpdatePriceInProgress}
                      />
                    </div>
                  </Form>
                  
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateProductPrice;
