import { React } from "react";
import "./AddAddress.scss";
import { Form, Input, Label, FormGroup } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { useTranslation } from "react-i18next";
import AddressOne from "../../assets/images/AddressOne.svg";
import AddressTwo from "../../assets/images/AddressTwo.svg";

export default function AddAddress() {
  const { t } = useTranslation();
  return (
    <div className="address-main">
      <div className="addresscard-main">
        <Form>
          <div style={{ paddingBottom: "20px" }}>
            <h1 className="address-heading-main">{t("New Address")}</h1>
          </div>
          <div className="address-img-flex">
            <div className="address-img-main">
              <img className="address-img" src={AddressOne} alt="" />
            </div>
            <div className="address-img-main">
              <img className="address-img" src={AddressTwo} alt="" />
            </div>
          </div>
          <FormGroup>
            <Label for="address">{t("Address")}</Label>
            <Input
              id="address"
              name="address"
              type="text"
              className="address-form-group"
            />
          </FormGroup>

          <div className="row">
            <FormGroup className=" col-12 col-md-6 col-lg-6">
              <Label for="street">{t("Street")}</Label>
              <Input
                id="street"
                name="street"
                type="text"
                className="address-form-group"
              />
            </FormGroup>

            <FormGroup className="col-12 col-md-6 col-lg-6">
              <Label for="city">{t("City")}</Label>
              <Input
                id="city"
                name="city"
                type="text"
                className="address-form-group"
              />
            </FormGroup>
            <FormGroup className=" col-12 col-md-6 col-lg-6">
              <Label for="region">{t("Region")}</Label>
              <Input
                id="region"
                name="region"
                type="text"
                className="address-form-group"
              />
            </FormGroup>

            <FormGroup className="col-12 col-md-6 col-lg-6">
              <Label for="country">{t("Country")}</Label>
              <Input
                id="country"
                name="country"
                type="text"
                className="address-form-group"
              />
            </FormGroup>
          </div>

          <div className="save-btn-float">
            <PrimaryButton title="Save" />
          </div>
        </Form>
      </div>
    </div>
  );
}
