import { React, useEffect, useState, useRef } from "react";
//import "./AddProduct.scss";
import { Input, Label, FormGroup, Row, Button } from "reactstrap";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import { BsCamera, BsCameraVideo } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import HeadingMain from "../../assets/elements/HeadingMain";
import IconButton from "../../assets/elements/IconButton";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MainServices from "../../services/MainServices";
import { notifyError, notifySuccess } from "../../assets/elements/Toaster";
import * as Yup from "yup";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { Formik, Field, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import closeicon from "../../assets/images/icons/close.svg";
import { ChromePicker } from "react-color";
import AsyncSelect from "react-select/async";
import useFormikDynamicLanguageSchema from "../../hooks/useFormikDynamicLanguageSchema";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import "react-dropdown-tree-select/dist/styles.css";
import { components } from "react-select";
import { urlToFileName } from "../../assets/functions/helpers";
import CustomModal from "../../assets/elements/CustomModal";
import ImageCropper from "../../assets/functions/imageCropper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LocationInMap from "../../assets/map/LocationInMap";
import Menu from "../menu/Menu";

export default function AddRestaurant() {
  const hiddenImageUploader = useRef(null);
  const hiddenVideoUploader = useRef(null);
  const [isLoading, setIsLoading] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const publicId = new URLSearchParams(location.search).get("publicId");
  let isEditing = publicId ? true : false;
  const [isCurrentProductLoaded, setIsCurrentProductLoaded] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const formikFieldsSchema = ["name", "description", "location"];
  const { getAvailableLanguages, getFormikValuesForAllLanguages } =
    useFormikDynamicLanguageSchema();
  const availableLanguages = getAvailableLanguages();
  const [formikValues, setFormikValues] = useState(
    getFormikValuesForAllLanguages(formikFieldsSchema)
  );
  const [currentImage, setCurrentImage] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [cropImageModal, setCropImageModal] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [menuModal, setMenuModal] = useState();

  //hooks
  const { t, i18n } = useTranslation();
  const { deBounce } = TimingFunctions();

  const {
    getRestaurantCategories,
    getRestaurantTypes,
    getBrands,
    getStatesByCountry,
    getCountries,
    saveRestaurant,
    fileUpload,
    getRestaurantDetails,
    updateRestaurantById,
  } = MainServices();

  //functions
  const addRestaurantSchema = Yup.object().shape({
    category: Yup.array()
      .min(1, t("Select a category"))
      .required(t("Select a category")),
    services: Yup.array()
      .min(1, t("Select atleast one"))
      .required(t("Select atleast one")),
    menu: Yup.array()
      .min(1, t("Menu is required"))
      .required(t("Menu is required"))
      .of(
        Yup.object().shape({
          name: Yup.string().required(`${t("Color Name")} ${t("is required")}`),
          categories: Yup.array()
            .min(1, `${t("Atleast one category")} ${t("is required")}`)
            .required(`${t("Atleast one category")} ${t("is required")}`),
          packages: Yup.array()
            .min(1, `${t("Atleast one package")} ${t("is required")}`)
            .required(`${t("Atleast one package")} ${t("is required")}`)
            .of(
              Yup.object().shape({
                members: Yup.string().required(
                  `${t("Person count")} ${t("is required")}`
                ),
                price: Yup.string().required(
                  `${t("Price")} ${t("is required")}`
                ),
              })
            ),
        })
      ),
    name: Yup.string().required(`${t("Restaurant Name")} ${t("is required")}`),
    description: Yup.mixed().required(
      `${t("Description")} ${t("is required")}`
    ),
    image: Yup.mixed().required(`${t("Image")} ${t("is required")}`),
    location: Yup.mixed().required(`${t("Address")} ${t("is required")}`),
    country: Yup.string().required(`${t("Country")} ${t("is required")}`),
    city: Yup.string().required(`${t("City")} ${t("is required")}`),
    latitude: Yup.number().required("Pick a location"),
    longitude: Yup.number().required("Pick a location"),
  });

  const addMenuSchema = Yup.object().shape({
    name: Yup.string().required(`${t("Menu Name")} ${t("is required")}`),
  });

  const getAndSetCountryList = () => {
    getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getAndSetStateList = (countryId) => {
    getStatesByCountry({ countryId }).then((res) => {
      setStateList(res.data);
    });
  };

  const handleChangeCountry = (countryId) => {
    //const countryId = e.target.value;
    if (!countryId) {
      setStateList([]);
      return;
    }
    getAndSetStateList(countryId);
  };

  const handleSave = async (values) => {
    setIsLoading(true);
    setErrorsOnSubmit([]);
    console.log(values);
    if (!values.restaurantImages.length) {
      setErrorsOnSubmit(["Please upload valid image"]);
      setIsLoading(false);
      return;
    }
    let imagesUploadResponse = await fileUpload(values.restaurantImages).catch(
      (error) => setIsLoading(false)
    );
    console.log("imgR----", imagesUploadResponse);
    const responseToArray = imagesUploadResponse?.fileName?.split(",");

    if (!imagesUploadResponse.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      else
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          "Image upload failed: File too large",
        ]);
      // console.log("imgREs", imagesUploadResponse);
      setIsLoading(false);
      return;
    }

    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      // Multi language menu
      const multiLanguageMenu = values.menu.map((menuObj) => {
        const newMenuObj = {};
        newMenuObj["name"] = menuObj["name_" + language.value] || "";

        const newCategoriesInMenu = menuObj.categories;

        // Multi language categories in menu
        const multiLanguageCategories = newCategoriesInMenu?.map(
          (categoryObj) => {
            const newCategoryObj = {};
            newCategoryObj["name"] =
              categoryObj["name_" + language.value] || "";

            const newItemsInCategory = categoryObj.items;

            // Multi language items in category
            const multiLanguageItems = newItemsInCategory?.map((itemObj) => {
              const newItemObj = {};
              newItemObj["name"] = itemObj["name_" + language.value] || "";
              return newItemObj;
            });

            newCategoryObj.items = multiLanguageItems;
            return newCategoryObj;
          }
        );

        newMenuObj.categories = multiLanguageCategories;
        return newMenuObj;
      });
      newObj.menu = multiLanguageMenu;
      languages.push(newObj);
    });

    // English language menu
    const englishMenu = values.menu.map((menuObj) => {
      const newMenuObj = {};
      newMenuObj["name"] = menuObj["name"];
      newMenuObj["packages"] = menuObj["packages"] || [];

      const newCategoriesInMenu = menuObj.categories;

      // English language categories in menu
      const englishCategories = newCategoriesInMenu?.map((categoryObj) => {
        const newCategoryObj = {};
        newCategoryObj["name"] = categoryObj["name"] || "";

        const newItemsInCategory = categoryObj.items;

        // English language items in category
        const englishItems = newItemsInCategory?.map((itemObj) => {
          const newItemObj = {};
          newItemObj["name"] = itemObj["name"] || "";
          return newItemObj;
        });

        newCategoryObj.items = englishItems;
        return newCategoryObj;
      });

      newMenuObj.categories = englishCategories;
      return newMenuObj;
    });

    console.log("formikValues: ", values);
    console.log("menuList", menuList);
    const country_name = countryList?.find(
      (countryObj) => countryObj.id == values.country
    )?.name;
    const city_name = stateList?.find(
      (stateObj) => stateObj.id == values.city
    )?.name;

    const details = {
      name: values.name,
      description: values.description,
      lat: values.latitude,
      lang: values.longitude,
      address: values.currentaddress,
      location: values.location,
      country: Number(values.country),
      country_name,
      city: Number(values.city),
      city_name,
      menu: englishMenu,
      category: values.category?.map((categoryObj) => categoryObj.value),
      services: values.services?.map((serviceObj) => serviceObj.value),
      restaurantImages: responseToArray,
      languages,
      status: "active",
    };
    console.log("details", details);
    saveRestaurant(details)
      .then(async (res) => {
        console.log("rest-add-res", res);
        if (res.success) {
          notifySuccess("New Restaurant Added!");
          setIsLoading(false);
          navigate({
            pathname: "/catering/restaurant-details",
            search: `?publicId=${res.publicId}`,
          });
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleUpdate = async (values) => {
    console.log(values);
    setIsLoading(true);
    setErrorsOnSubmit([]);
    console.log(values);
    if (!values.restaurantImages.length) {
      setErrorsOnSubmit(["Please upload valid product image"]);
      setIsLoading(false);
      return;
    }
    let existingImageURLs = values.restaurantImages.filter(
      (file) => typeof file === "string"
    );
    existingImageURLs = existingImageURLs.map((_) => urlToFileName(_));
    let newImageFiles = values.restaurantImages.filter(
      (file) => typeof file !== "string"
    );
    //console.log("newImg", newImageFiles);
    let imagesUploadResponse = "";
    let responseToArray = existingImageURLs;
    if (newImageFiles.length) {
      imagesUploadResponse = await fileUpload(newImageFiles).catch(() =>
        setIsLoading(false)
      );
      responseToArray = responseToArray.concat(
        imagesUploadResponse.fileName?.split(",")
      );
    }

    if (newImageFiles.length > 0 && !imagesUploadResponse?.success) {
      //notifyError("Image Not Provided!");
      if (imagesUploadResponse?.type === "field-validation")
        setErrorsOnSubmit((oldValues) => [
          ...oldValues,
          imagesUploadResponse.details?.map(
            (item) => item.name + " " + item.message
          ),
        ]);
      //console.log("imgREs", imagesUploadResponse);
      setIsLoading(false);
      return;
    }

    const languages = [];
    availableLanguages.forEach((language) => {
      let newObj = {
        locale: language.value,
      };
      formikFieldsSchema.forEach((field) => {
        newObj = {
          ...newObj,
          [field]: values[field + "_" + language.value],
        };
      });
      // Multi language menu
      const multiLanguageMenu = values.menu.map((menuObj) => {
        const newMenuObj = {};
        newMenuObj["name"] = menuObj["name_" + language.value] || "";

        const newCategoriesInMenu = menuObj.categories;

        // Multi language categories in menu
        const multiLanguageCategories = newCategoriesInMenu?.map(
          (categoryObj) => {
            const newCategoryObj = {};
            newCategoryObj["name"] =
              categoryObj["name_" + language.value] || "";

            const newItemsInCategory = categoryObj.items;

            // Multi language items in category
            const multiLanguageItems = newItemsInCategory?.map((itemObj) => {
              const newItemObj = {};
              newItemObj["name"] = itemObj["name_" + language.value] || "";
              return newItemObj;
            });

            newCategoryObj.items = multiLanguageItems;
            return newCategoryObj;
          }
        );

        newMenuObj.categories = multiLanguageCategories;
        return newMenuObj;
      });
      newObj.menu = multiLanguageMenu;
      languages.push(newObj);
    });

    // English language menu
    const englishMenu = values.menu.map((menuObj) => {
      const newMenuObj = {};
      newMenuObj["name"] = menuObj["name"];
      newMenuObj["packages"] = menuObj["packages"] || [];

      const newCategoriesInMenu = menuObj.categories;

      // English language categories in menu
      const englishCategories = newCategoriesInMenu?.map((categoryObj) => {
        const newCategoryObj = {};
        newCategoryObj["name"] = categoryObj["name"] || "";

        const newItemsInCategory = categoryObj.items;

        // English language items in category
        const englishItems = newItemsInCategory?.map((itemObj) => {
          const newItemObj = {};
          newItemObj["name"] = itemObj["name"] || "";
          return newItemObj;
        });

        newCategoryObj.items = englishItems;
        return newCategoryObj;
      });

      newMenuObj.categories = englishCategories;
      return newMenuObj;
    });

    console.log("formikValues: ", values);
    console.log("menuList", menuList);
    const country_name = countryList?.find(
      (countryObj) => countryObj.id == values.country
    )?.name;
    const city_name = stateList?.find(
      (stateObj) => stateObj.id == values.city
    )?.name;

    const details = {
      name: values.name,
      description: values.description,
      lat: values.latitude,
      lang: values.longitude,
      address: values.currentaddress,
      location: values.location,
      country: Number(values.country),
      country_name,
      city: Number(values.city),
      city_name,
      menu: englishMenu,
      category: values.category?.map((categoryObj) => categoryObj.value),
      services: values.services?.map((serviceObj) => serviceObj.value),
      restaurantImages: responseToArray,
      languages,
      status: "active",
    };
    console.log("details", details);
    let latestConcurrencyStamp = await getRestaurantDetails(publicId).then(
      (res) => res.data.concurrencyStamp
    );

    updateRestaurantById(details, publicId, latestConcurrencyStamp)
      .then(async (res) => {
        console.log("rest-add-res", res);
        if (res.success) {
          notifySuccess("Restaurant Updated!");
          setIsLoading(false);
          navigate({
            pathname: "/catering/restaurant-details",
            search: `?publicId=${publicId}`,
          });
        } else {
          notifyError("Failed!");
          setErrorsOnSubmit((oldValues) =>
            oldValues.concat(
              res.details?.map((item) => item.name + " " + item.message)
            )
          );
          setErrorsOnSubmit((oldValues) => [...oldValues, res.message]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notifyError("Error occurred!");
        setErrorsOnSubmit((oldValues) => [...oldValues, error]);
        setIsLoading(false);
        console.log(error);
      });
  };

  const GroupHeading = (props, setFieldValue, values) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <components.GroupHeading
          {...props}
          onClick={() => {
            //console.log(props);
            setFieldValue("category", [...values.category, props.data]);
          }}
          style={{
            cursor: "pointer",
            color: "#D60000",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: 18,
          }}
        />
      </div>
    );
  };

  const getCategoriesListOptions = async (filters, isFirst) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      filters,
    };
    let results = await getRestaurantCategories(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((category) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                category?.category_languages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                label: languageSpecificDetailsObject?.name || category.name,
                value: category.id,
              };
            });
            isFirst && setCategoryList(resData);
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const getTypesListOptions = async (filters, isFirst) => {
    const details = {
      pageSize: 10,
      pageNumber: 1,
      filters,
    };
    let results = await getRestaurantTypes(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((type) => {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject =
                type?.type_languages?.find(
                  (languageSpecificDetails) =>
                    languageSpecificDetails.locale === i18n.language
                ) || {};

              return {
                label: languageSpecificDetailsObject?.name || type.name,
                value: type.id,
                //...(options.length > 0 ? { options } : {}),
              };
            });
            isFirst && setServiceList(resData);
            return resData;
          }
        } else {
          console.log("Categories Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  };

  const loadOptionsForCategory = async (inputValue, callback) => {
    if (!inputValue) {
      callback(categoryList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    let options = await getCategoriesListOptions(filter);
    callback(options);
  };

  const loadDebounceOptionsForCategory = (inputValue, callback) => {
    deBounce(() => loadOptionsForCategory(inputValue, callback), 1000);
  };

  const loadOptionsForType = async (inputValue, callback) => {
    if (!inputValue) {
      callback(serviceList);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
      {
        key: "status",
        eq: "active",
      },
    ];
    let options = await getTypesListOptions(filter);
    callback(options);
  };

  const loadDebounceOptionsForType = (inputValue, callback) => {
    deBounce(() => loadOptionsForType(inputValue, callback), 1000);
  };

  const getExistingRestaurantData = async () => {
    getRestaurantDetails(publicId)
      .then(async (res) => {
        console.log("res-rest", res);
        if (res.success) {
          const restaurantData = {
            ...res.data,
            city_name: res?.data?.cityName,
            country_name: res?.data?.countryName,
            latitude: res?.data?.lat,
            longitude: res?.data?.lang,
            //languages: res?.data?.restaurantLanguages,
            restaurantImages:
              res?.data?.restaurantImages?.map((obj) => obj.images) || [],
            image: res?.data?.restaurantImages[0]?.images,
            menu: res?.data?.menu,
            category: [],
            services: [],
            location: res?.data?.location,
            currentaddress: res?.data?.address,
            //image: res.data?.productImages.length > 0 ? "image" : "",
          };

          getAndSetStateList(res?.data?.country);
          let categoryList = await getCategoriesListOptions(
            [{ key: "status", eq: "active" }],
            true
          );
          res?.data?.category?.forEach((categoryId) => {
            console.log("ca", categoryList);
            let matchingCategory = categoryList.find((categoryObj) => {
              return categoryObj.value == categoryId;
            });
            restaurantData.category.push(matchingCategory);
          });

          let typeList = await getTypesListOptions(
            [{ key: "status", eq: "active" }],
            true
          );
          res?.data?.services?.forEach((serviceId) => {
            let matchingService = typeList.find((typeObj) => {
              return typeObj.value == serviceId;
            });
            restaurantData.services.push(matchingService);
          });

          // To populate multi-language fields(fixed fields)
          if (res.data?.restaurantLanguages) {
            let restaurantLanguages = res.data?.restaurantLanguages;
            availableLanguages.forEach((language) => {
              let languageSpecificObject = restaurantLanguages.find(
                (languageObject) => languageObject.locale === language.value
              );
              formikFieldsSchema.forEach((formikField) => {
                restaurantData[formikField + "_" + language.value] =
                  languageSpecificObject[formikField];
                //restaurantData[formikField] = productLanguages
              });
            });
          }

          // To populate multi-language menu
          if (res.data?.restaurantLanguages) {
            let restaurantLanguages = res.data?.restaurantLanguages;
            availableLanguages.forEach((language) => {
              let languageSpecificObject = restaurantLanguages.find(
                (languageObject) => languageObject.locale === language.value
              );
              restaurantData.menu = restaurantData.menu?.map(
                (menuObj, menuIndex) => {
                  let newMenuObj = {};
                  newMenuObj.name = menuObj.name;
                  newMenuObj.packages = menuObj.packages;
                  newMenuObj["name_" + language.value] =
                    languageSpecificObject?.menu[menuIndex]?.name;
                  newMenuObj.categories = menuObj?.categories?.map(
                    (categoryObj, catgIndex) => {
                      let newCategoryObj = {};
                      newCategoryObj.name = categoryObj.name;
                      newCategoryObj._id = String(categoryObj.name + catgIndex);
                      newCategoryObj["name_" + language.value] =
                        languageSpecificObject?.menu[menuIndex]?.categories[
                          catgIndex
                        ]?.name;
                      newCategoryObj.items = categoryObj?.items?.map(
                        (itemObj, itemIndex) => {
                          let newItemObj = {};
                          newItemObj._id = String(itemObj.name + itemIndex);
                          newItemObj.name = itemObj.name;
                          newItemObj["name_" + language.value] =
                            languageSpecificObject?.menu[menuIndex]?.categories[
                              catgIndex
                            ]?.items[itemIndex]?.name;
                          return newItemObj;
                        }
                      );
                      return newCategoryObj;
                    }
                  );
                  return newMenuObj;
                }
              );
              console.log("------rD----", restaurantData);
            });
          }

          /* setRestaurantImageFiles(
            res.data?.restaurantImages?.map((imgObject) => imgObject.images)
          ); */
          setFormikValues(restaurantData);
          setIsCurrentProductLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategoriesListOptions([{ key: "status", eq: "active" }], true);
    getTypesListOptions([{ key: "status", eq: "active" }], true);
    getAndSetCountryList();
    /* getBrandOptions([{ key: "status", eq: "active" }], true);
    if (isEditing) {
      getExistingRestaurantData();
    } */
    if (isEditing) {
      getExistingRestaurantData();
    }
    // eslint-disable-next-line
  }, []);

  // Get language specific values when langauge is changed by user
  useEffect(() => {
    getCategoriesListOptions([{ key: "status", eq: "active" }], true);
    getTypesListOptions([{ key: "status", eq: "active" }], true);
    // eslint-disable-next-line
  }, [i18n.language]);

  //async select style
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#f5f2ed",
      padding: "6px 5px",
      marginBottom: "12px",
      paddingLeft: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      zIndex: "2",
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#f5f2ed" : "white",
        color: "#000000",
        cursor: "pointer",
        padding: "10px 10px 10px 20px",
        //textTransform: "capitalize",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "5px",
    }),
  };

  const handleChooseVideo = (e) => {
    // const fileUploaded = event.target.files[0];
    // setFieldValue("restaurantImages", fileUploaded);
    //console.log("images", values.restaurantImages);
    //setFieldValue("restaurantImages", oldFiles => [...oldFiles, ...e.target.files]);
  };
  const handleImageUploaderClick = () => {
    hiddenImageUploader.current.click();
  };
  const handleVideoUploaderClick = () => {
    hiddenVideoUploader.current.click();
  };

  const handleChooseImage = (e) => {
    setCurrentImage(e.target.files[0] || "");
    if (e.target.files[0]) setCropImageModal(true);
  };

  const onGetCroppedImage = (setFieldValue, image) => {
    setCroppedImage(image);
  };

  const onCropImageModal = (set) => {
    setCropImageModal(false);
    setCurrentImage("");
  };

  const onCropSave = (oldImages, setFieldValue) => {
    const newImages = [...oldImages, croppedImage];
    setFieldValue("restaurantImages", newImages);
    onCropImageModal();
  };

  return (
    <div className="add-restaurant-main product-main">
      <HeadingMain text={isEditing ? "Edit Restaurant" : "Add New Restaurant"}>
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      {(isEditing ? isCurrentProductLoaded : true) && (
        <Formik
          initialValues={{
            name: formikValues.name,
            image: "",
            category: [],
            services: [],
            city: "",
            country: "",
            restaurantImages: [],
            currentaddress: "",
            location: "",
            menu: [],
            longitude: "",
            latitude: "",
            description: "",
            ...formikValues,
            // To match user language if a label exists for that language
            // brandId: formikValues?.brandId?.value ? brandList.find(brand => brand.id === formikValues?.brandId?.value) : "",
          }}
          onSubmit={(values) => {
            isEditing ? handleUpdate(values) : handleSave(values);
          }}
          validationSchema={addRestaurantSchema}
          //enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="uploaders">
                <div className="container">
                  <div className="file-uploader">
                    <div
                      className="product-file-uploader"
                      onClick={handleImageUploaderClick}
                      onDrag={handleChooseImage}
                    >
                      <div className="camera-icon-bg">
                        <BsCamera className="camera-icon" />
                      </div>
                    </div>
                    <input
                      type="file"
                      name="image"
                      ref={hiddenImageUploader}
                      onChange={(e) => {
                        //handleChange(e);
                        handleChooseImage(e);
                        setFieldValue("image", e.target.files[0]?.name || "");
                      }}
                      accept="image/*"
                      hidden
                    />
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name="image"
                    />
                    <h1 className="file-uploader-heading">{t("Photos")}</h1>
                    <p className="file-uploader-text">
                      {t("Click above to upload photos")}
                    </p>
                  </div>
                  {values.restaurantImages?.length > 0 && (
                    <div className="uploaded-images">
                      {values.restaurantImages.map((file, index) => {
                        if (!file) return null;
                        return (
                          <div
                            key={
                              typeof file === "string"
                                ? file + index
                                : file.name + index + file.lastModified
                            }
                            className="image-box"
                          >
                            {typeof file === "string" ? (
                              <img
                                className="view-image"
                                src={file}
                                alt="product"
                              />
                            ) : (
                              <img
                                className="view-image"
                                src={URL.createObjectURL(file)}
                                alt="product"
                              />
                            )}

                            <img
                              src={deleteicon}
                              alt="delete"
                              style={{
                                objectFit: "contain",
                                width: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let newImageFiles = [
                                  ...values.restaurantImages,
                                ];
                                newImageFiles[index] = null;
                                newImageFiles = newImageFiles.filter(
                                  (file) => file !== null
                                );
                                if (newImageFiles.length === 0)
                                  setFieldValue("image", "");
                                setFieldValue(
                                  "restaurantImages",
                                  newImageFiles
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {currentImage && (
                    <Modal
                      isOpen={cropImageModal}
                      toggle={onCropImageModal}
                      size="lg"
                    >
                      <ModalHeader toggle={onCropImageModal}>
                        Crop Image
                      </ModalHeader>
                      <ModalBody>
                        {currentImage && (
                          <ImageCropper
                            image={currentImage}
                            onFileChange={onGetCroppedImage}
                            setFieldValue={setFieldValue}
                            imageUpdate={false}
                            aspectRatio={16 / 9}
                          />
                        )}

                        <div className="modal-btn-div">
                          <PrimaryButton
                            title="Save"
                            onClick={() =>
                              onCropSave(values.restaurantImages, setFieldValue)
                            }
                          />
                          <PrimaryButton
                            title="Cancel"
                            onClick={onCropImageModal}
                          />
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
                <div className="line" />
                <div className="container">
                  <div className="file-uploader">
                    <div
                      className="product-file-uploader"
                      onClick={handleVideoUploaderClick}
                      onDrag={handleChooseVideo}
                    >
                      <div className="camera-icon-bg">
                        <BsCameraVideo className="camera-icon" />
                      </div>
                    </div>
                    <input
                      type="file"
                      name="image"
                      ref={hiddenVideoUploader}
                      onChange={(e) => {
                        //handleChange(e);
                        handleChooseVideo(e);
                        //console.log("imgE", e);
                        //console.log("imgUp", hiddenImageUploader);
                        //console.log("ee", e)
                        //setFieldValue("image", e.target.files[0]?.name || "");
                      }}
                      accept="video/*"
                      hidden
                      multiple
                    />
                    <h1 className="file-uploader-heading">{t("Videos")}</h1>
                    <p className="file-uploader-text">
                      {t("Click above to upload videos")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="user-topcard-main">
                <h1 className="topcard-heading">{t("Basic Details")}</h1>
                <Form className="row">
                  <Row>
                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="category">{t("Select Category")}</Label>
                      <AsyncSelect
                        defaultOptions={categoryList}
                        isMulti
                        onChange={(selectedCategories) =>
                          setFieldValue("category", selectedCategories)
                        }
                        loadOptions={loadDebounceOptionsForCategory}
                        className="select-dropdown"
                        styles={customStyles}
                        components={{
                          GroupHeading: (props) =>
                            GroupHeading(props, setFieldValue, values),
                        }}
                        value={values.category}
                        onBlur={() => setFieldTouched("category", true)}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="category"
                      />
                      <br />
                    </FormGroup>

                    <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <Label for="services">{t("Select Services")}</Label>
                      <AsyncSelect
                        defaultOptions={serviceList}
                        isMulti
                        onChange={(selectedItems) =>
                          setFieldValue("services", selectedItems)
                        }
                        loadOptions={loadDebounceOptionsForType}
                        className="select-dropdown"
                        styles={customStyles}
                        components={{
                          GroupHeading: (props) =>
                            GroupHeading(props, setFieldValue, values),
                        }}
                        value={values.services}
                        onBlur={() => setFieldTouched("services", true)}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="services"
                      />
                      <br />
                    </FormGroup>
                  </Row>

                  <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <Label for="name">{t("Restaurant Name")}</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder={`${t("Enter")} ${t("Restaurant Name")}`}
                      type="text"
                      className="product-form-group"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="name"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <Label for="name">{`${t("Restaurant Name")} ${t(
                            "in"
                          )} ${t(language.name)} (${language.symbol})`}</Label>
                          <Input
                            name={"name_" + language.value}
                            placeholder={`${t("Enter")} ${t(
                              "Restaurant Name"
                            )} ${t("in")} ${t(language.name)}`}
                            type="text"
                            className="product-form-group"
                            value={values["name_" + language.value]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <div>
                    <br />
                  </div>
                  <FormGroup className="col-12 col-sm-6 short-description">
                    <Label for="description" className="title">
                      {t("Description")}
                      <p>{values.description?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="description"
                      as="textarea"
                      className="textarea"
                      value={values.description}
                      onChange={handleChange}
                      maxLength={250}
                      placeholder={`${t("Enter")} ${t("Description")}`}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="description"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 short-description">
                          <Label for="description" className="title">
                            {`${t("Description")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["description_" + language.value]
                                ?.length || 0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"description_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["description_" + language.value]}
                            onChange={handleChange}
                            placeholder={`${t("Enter")} ${t("Description")} ${t(
                              "in"
                            )} ${t(language.name)}`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  <div>
                    <br />
                  </div>
                </Form>
              </div>
              <div className="user-topcard-main">
                <div className="d-flex justify-content-between">
                  <h1 className="topcard-heading">{t("List Of Menu")}</h1>
                  <IconButton
                    icontype="add"
                    title="Add New Menu"
                    onClick={() => {
                      setMenuModal(true);
                    }}
                  />
                </div>
                <Form className="row">
                  {values.menu &&
                    values.menu.map((menuObject, index) => {
                      return (
                        <div
                          className="my-2 py-2"
                          style={{
                            border: "1px solid gray",
                            borderRadius: "16px",
                          }}
                        >
                          <Menu
                            editable={true}
                            menuNumber={index + 1}
                            menuObject={menuObject}
                            updateMenu={(categories) => {
                              const modifiedMenuList = [...values.menu];
                              if (modifiedMenuList[index])
                                modifiedMenuList[index]["categories"] = [
                                  ...categories,
                                ];
                              setFieldValue("menu", modifiedMenuList);
                            }}
                            deleteMenu={(menuIndex) => {
                              let modifiedMenuList = [...values.menu];
                              // Assign empty object at user cilcked index on delete
                              if (modifiedMenuList[menuIndex]) {
                                modifiedMenuList[menuIndex] = {};
                              }
                              // Filter menu and omit empty objects in menu
                              // Clicked menu will be removed in final list
                              modifiedMenuList = modifiedMenuList.filter(
                                (menuObj) => menuObj.name
                              );
                              setFieldValue("menu", modifiedMenuList);
                            }}
                            categories={menuObject.categories || []}
                            setFieldValue={setFieldValue}
                          />
                          <IconButton
                            icontype="add"
                            title="Add Package"
                            onClick={() => {
                              const newMenu = [...values.menu];
                              if (newMenu[index].packages)
                                newMenu[index].packages.push({});
                              else newMenu[index].packages = [{}];
                              setFieldValue("menu", newMenu);
                            }}
                          />
                          {values.menu[index]?.packages &&
                            values.menu[index]?.packages.map(
                              (packageObj, packageIndex) => {
                                return (
                                  <div className="d-flex justify-content-between m-2 p-2">
                                    <FormGroup>
                                      <div className="input-container">
                                        <Row>
                                          <Label for="memberscount p-0">
                                            {t("No. Of People")}
                                          </Label>
                                          <Input
                                            id="memberscount"
                                            name="memberscount"
                                            value={packageObj?.members || ""}
                                            onChange={(e) => {
                                              packageObj.members =
                                                e.target.value;
                                              handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            placeholder={t(
                                              "Enter Person Count"
                                            )}
                                          />
                                        </Row>
                                      </div>
                                      {errors.menu &&
                                        errors.menu?.length > 0 &&
                                        errors.menu[index]?.packages &&
                                        Array.isArray(
                                          errors.menu[index].packages
                                        ) && (
                                          <span className="error">
                                            {
                                              errors.menu[index].packages[
                                                packageIndex
                                              ]?.members
                                            }
                                          </span>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                      <div className="input-container">
                                        <Row>
                                          <Label for="price">
                                            {t("Price")}
                                          </Label>
                                          <Input
                                            id="price"
                                            name="price"
                                            value={packageObj?.price || ""}
                                            onChange={(e) => {
                                              packageObj.price = e.target.value;
                                              handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            placeholder={t("Enter Price")}
                                          />
                                        </Row>
                                      </div>
                                      {errors.menu &&
                                        errors.menu?.length > 0 &&
                                        errors.menu[index]?.packages &&
                                        Array.isArray(
                                          errors.menu[index].packages
                                        ) && (
                                          <span className="error">
                                            {
                                              errors.menu[index].packages[
                                                packageIndex
                                              ]?.price
                                            }
                                          </span>
                                        )}
                                    </FormGroup>
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={deleteicon}
                                      alt="delete"
                                      class="item-icon"
                                      onClick={() => {
                                        let newPackages = [
                                          ...values.menu[index]?.packages,
                                        ];
                                        newPackages[packageIndex] = null;
                                        let newMenu = [...values.menu];
                                        newMenu[index].packages =
                                          newPackages.filter(
                                            (packageObj) => packageObj !== null
                                          );
                                        setFieldValue("menu", newMenu);
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )}
                          <br />
                          {errors.menu &&
                            errors.menu?.length > 0 &&
                            errors.menu[index]?.categories &&
                            !Array.isArray(
                              errors.menu[index].getCategoriesListOptions
                            ) && (
                              <span className="error">
                                {errors.menu[index].categories}
                                <br />
                              </span>
                            )}
                          {errors.menu &&
                            errors.menu?.length > 0 &&
                            errors.menu[index]?.packages &&
                            !Array.isArray(errors.menu[index].packages) && (
                              <span className="error">
                                {errors.menu[index].packages}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  <Modal
                    isOpen={menuModal}
                    size="lg"
                    centered
                    toggle={() => setMenuModal(!menuModal)}
                    className="add-category-modal"
                  >
                    <ModalHeader
                      toggle={() => setMenuModal(!menuModal)}
                      className="modal-header-custom"
                    >
                      {`${t("Add Menu")}`}
                    </ModalHeader>
                    <ModalBody className="modal-body-custom">
                      <div className="addCategory-main">
                        <div className="d-flex justify-content-between">
                          <p className="heading-text">
                            {t("Please enter details in English")}
                          </p>
                        </div>
                        <div className="addCategory-input-form">
                          <Formik
                            initialValues={{
                              name: "",
                            }}
                            onSubmit={(modalFormikValues) => {
                              setFieldValue("menu", [
                                ...values.menu,
                                { ...modalFormikValues },
                              ]);
                              setMenuModal(false);
                            }}
                            validationSchema={addMenuSchema}
                          >
                            {({
                              values,
                              errors,
                              handleBlur,
                              handleChange,
                              touched,
                            }) => (
                              <Form>
                                <FormGroup>
                                  <div className="input-container">
                                    <Row>
                                      <Label for="menu-name">
                                        {t("Menu Name")}
                                      </Label>
                                      <Input
                                        id="menu-name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={t("Enter menu name")}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        name="name"
                                      />
                                    </Row>
                                  </div>
                                </FormGroup>
                                {availableLanguages.map((language) => {
                                  return (
                                    <>
                                      <FormGroup>
                                        <div className="input-container">
                                          <Row>
                                            <Label for="menu-name">
                                              {`${t("Menu Name")} ${t(
                                                "in"
                                              )} ${t(language.name)} (${
                                                language.symbol
                                              })`}
                                            </Label>
                                            <Input
                                              id="menu-name"
                                              name={"name_" + language.value}
                                              value={
                                                values["name_" + language.value]
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              placeholder={`${t(
                                                "Enter menu name"
                                              )} ${t("in")} ${t(
                                                language.name
                                              )} (${language.symbol})`}
                                            />
                                          </Row>
                                        </div>
                                      </FormGroup>
                                    </>
                                  );
                                })}

                                <div className="save-btn-container">
                                  <PrimaryButton type="submit" title="Save" />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>

                  {/* <Row>
                    <FormGroup className="col-12 col-lg-8">
                      <Label for="title">{t("Add Categories")}</Label>
                      <div style={{display:"flex", gap:"1em", flexWrap: "wrap", alignItems:'center'}}>
                        <div className="col-12 col-sm-5">
                          <Input
                            id="menuCategory"
                            name="menuCategory"
                            placeholder={`${t("Enter")} ${t("Category Name")}`}
                            type="text"
                            className="product-form-group"
                            value={values.menuCategory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-12 col-sm-5">
                          <Input
                            id="categorySize"
                            name="categorySize"
                            placeholder={`${t("Enter")} ${t("No. of Items")}`}
                            type="number"
                            className="product-form-group"
                            value={values.categorySize >= 0 ? values.categorySize : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <IconButton
                          onClick={() => {
                              if(values.menuCategory && values.categorySize)
                              {
                                let duplicateEntry = menuCategories.find(item => item.categoryName === values.menuCategory)
                                if(!duplicateEntry)
                                  setMenuCategories(oldValues => [...oldValues, {categoryName: values.menuCategory, categorySize: values.categorySize}])
                              }
                                
                            }
                          }
                          title={t("Add")}
                          icontype="add"
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="title"
                      />
                    </FormGroup>
                  </Row>
                  {
                    menuCategories.map((category, index) => {
                      return <FormGroup 
                          style={{
                            border: "1px solid lightgray", 
                            borderRadius: "8px",
                            paddingBlock: "1em"
                          }}
                      >
                        <p><span style={{color: "gray"}}>Category {index+1}: </span><i>{category?.categoryName}</i></p>
                        <div 
                          style={{
                            display: "flex",
                            gap: '1em'
                          }}
                        >
                          <Label>Items: </Label>
                          <div
                            className="col-11"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0.8em",
                            }}
                          >
                            {
                              Array(category?.categorySize).fill("").map((item, itemIndex) => {
                                return <div className="col-2.5">
                                  <Input
                                    name={category?.categoryName+"_item_"+itemIndex}
                                    placeholder={`${t("Enter")} ${t("Item")} ${itemIndex+1}`}
                                    type="text"
                                    className="product-form-group"
                                    value={values[category?.categoryName+"_item_"+itemIndex]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              })
                            }
                          </div>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="title"
                        />
                      </FormGroup>
                    })
                  }
                  <div>
                    <br />
                  </div> */}
                </Form>
                {errors.menu && !Array.isArray(errors.menu) && (
                  <span className="error">{errors.menu}</span>
                )}
              </div>

              <div className="user-topcard-main">
                <h1 className="topcard-heading">{t("Address Details")}</h1>
                <Form className="row">
                  <LocationInMap
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    countryList={countryList}
                    stateList={stateList}
                    handleChangeCountry={handleChangeCountry}
                  />
                  {(
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="latitude"
                    />
                  ) || (
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="longitude"
                    />
                  )}
                  <div>
                    <br />
                  </div>
                  <FormGroup className="col-12 col-sm-6 short-description location">
                    <Label for="location" className="title">
                      {t("Enter Address")}
                      <p>{values.location?.length || 0}/250</p>
                    </Label>
                    <Field
                      name="location"
                      as="textarea"
                      className="textarea"
                      value={values.location}
                      onChange={handleChange}
                      maxLength={250}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="location"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                    return (
                      <>
                        <FormGroup className="col-12 col-sm-6 short-description">
                          <Label for="location" className="title">
                            {`${t("Enter Address")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            <p>
                              {values["location_" + language.value]?.length ||
                                0}
                              /250
                            </p>
                          </Label>
                          <Field
                            name={"location_" + language.value}
                            as="textarea"
                            className="textarea"
                            value={values["location_" + language.value]}
                            onChange={handleChange}
                            place={`${t("Enter Enter Address")} ${t("in")} ${t(
                              language.name
                            )} (${language.symbol})`}
                            maxLength={250}
                          />
                        </FormGroup>
                      </>
                    );
                  })}
                  {/* <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="City">{t("City")}</Label>
                    <Input
                      id="city"
                      name="city"
                      placeholder={t("Enter City")}
                      type="text"
                      className="product-form-group"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="city"
                    />
                  </FormGroup>
                  {availableLanguages.map((language) => {
                      return (
                      <>
                          <FormGroup className="col-12 col-md-6 col-lg-6">
                              <Label for="City">{`${t("City")} ${t("in")} ${t(
                                  language.name
                                  )} (${language.symbol})`}</Label>
                              <Input
                                  name={"city_" + language.value}
                                  placeholder={`${t("Enter City")} ${t(
                                      "in"
                                      )} ${t(language.name)} (${language.symbol})`}
                                  type="text"
                                  className="product-form-group"
                                  value={values["city_" + language.value]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              <ErrorMessage
                                  errors={errors}
                                  touched={touched}
                                  name={"city_" + language.value}
                              />
                          </FormGroup>
                      </>
                      );
                  })} */}
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="country">{t("Country")}</Label>
                    <select
                      className="select product-form-group"
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={(e) => {
                        //setFieldValue("country", e.target.value);
                        handleChange(e);
                        handleChangeCountry(e.target.value);
                        //
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("city", "");
                      }}
                    >
                      <option value="">--Select--</option>
                      {countryList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="country"
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6 col-lg-6">
                    <Label for="city">{t("City")}</Label>

                    <select
                      className="select product-form-group"
                      id="city"
                      name="city"
                      type="select"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">--Select--</option>
                      {stateList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="city"
                    />
                  </FormGroup>
                </Form>
              </div>
              <br />
              <div className="errors-after-sumbit">
                {errorsOnSubmit?.map((error) => (
                  <p>{error}</p>
                ))}
              </div>
              <div
                className="save-btn-float"
                onClick={isLoading ? null : handleSubmit}
              >
                <PrimaryButton
                  type="submit"
                  title={isEditing ? "Update" : "Save"}
                  isLoading={isLoading}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
}
