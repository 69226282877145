import React, { useState } from "react";
import { Table } from "reactstrap";
import "./Vouchers.scss";
import { useNavigate } from "react-router-dom";
import { BsFilter, BsArrowLeftRight } from "react-icons/bs";
import IconButton from "../../assets/elements/IconButton";
import { useTranslation } from "react-i18next";
import TableSearch from "../../assets/elements/TableSearch";
import HeadingMain from "../../assets/elements/HeadingMain";
import CustomPagination from "../../assets/elements/Pagination";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import { filtersToUrlParams } from "../../assets/functions/filters";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import editicon from "../../assets/images/icons/edit-curved.svg";


export default function Vouchers() {
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [totalCount] = useState(0);
  const [searchExpandForMobile, setSearchExpandForMobile] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [filters] = useState({});

  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();

  //pagination functions
  const onPageChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const onRowsChange = (rows) => {
    setPagination({ limit: rows, page: 1 });
  };

  const onSearch = (e) => {
    deBounce(() => onSearchFilter(e));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let search = filtersToUrlParams({ ...filters, name: e.target.value });
    navigate({
      search,
    });
  };

  const onStatusChange = () => {};

  return (
    <div className="voucher-main">
      <HeadingMain text="Vouchers" />
      <div className="voucher-table-main">
        <div className="table-flex-main">
          <TableSearch
            setSearchExpandForMobile={setSearchExpandForMobile}
            searchExpandForMobile={searchExpandForMobile}
            onSearch={onSearch}
            searchString={searchString}
          >
            <div className="filter-grey-main">
              <span className="title">{t("Filters")}</span>
              <span>
                <BsFilter
                  style={{
                    color: "#CCBFDD",
                  }}
                />
              </span>
            </div>
            <div className="filter-grey-main">
              <span className="title">{t("First Name")} (A-Z)</span>
              <span>
                <BsArrowLeftRight
                  style={{ transform: "rotate(90deg)", color: "#CCBFDD" }}
                />
              </span>
            </div>

            <div>
              <select
                className="status-select"
                onChange={onStatusChange}
                value={filters.status}
              >
                <option>{t("Select Status")}</option>
                <option value="active">{t("Active")} </option>
                <option value="inactive">{t("Inactive")} </option>

              </select>
            </div>
          </TableSearch>
          <div className="add-voucher-btn-margin">
            <IconButton
              onClick={() => navigate("/coupons/add-coupon")}
              title={t("Add Voucher")}
              icontype="add"
            ></IconButton>
          </div>
        </div>
        <Table borderless responsive>
          <thead className="voucher-table-head">
            <tr>
              <th>{t("Voucher Name")}</th>
              <th>{t("Product")}</th>
              <th>
                {t("Price")}/{t("Value")}
              </th>
              <th>{t("Sold")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Voucher 1</td>
              <td>Perfume</td>
              <td>Upto 500 AED</td>
              <td>150</td>
              <td>
                <div className="action-btn-main">
                  <img src={editicon} alt="edit" className="action-icon" />
                  <img src={deleteicon} alt="delete" className="action-icon" />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <CustomPagination
          currentPage={pagination.page}
          rowsPerPage={pagination.limit}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onRowsPerPage={onRowsChange}
        />
      </div>
    </div>
  );
}
