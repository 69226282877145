import React, { useState, useEffect, Fragment } from "react";
import "./ProductDescription.scss";
import Select from "react-select";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/icons/logo.png";
import Product1 from "../../assets/images/productImageSlider.jpg";
import Product2 from "../../assets/images/agr-cul (1).jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import Carousel from "react-bootstrap/Carousel";
import {
  Button,
  FormGroup,
  Input,
  Table,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Balloon from "../../assets/images/balloon.png";
import Google from "../../assets/images/icons/google-logo.png";
import Facebook from "../../assets/images/icons/facebook-logo.png";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import { notifySuccess, notifyError } from "../../assets/elements/Toaster";
import PrimaryButton from "../../assets/elements/PrimaryButton";
import MainService from "../../services/MainServices";
import HeadingMain from "../../assets/elements/HeadingMain";
import closeicon from "../../assets/images/icons/close.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import IconButton from "../../assets/elements/IconButton";
import MainServices from "../../services/MainServices";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import UpdateProductPrice from "./UpdateProductPrice";
import deleteicon from "../../assets/images/icons/delete-curved.svg";
import { ButtonLoader } from "../../assets/loaders";
import EditIcon from "../../assets/images/icons/edit.svg";
import eyeIcon from "../../assets/images/eye.svg";

export default function Register() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    getProductDetails,
    updateBatchQuantity,
    getBatchesByProduct,
    updateProductBatchPrice,
    updateProductVariants,
    variantProduct,
    getProducts,
    fileUpload,
  } = MainService();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [variants, setVariants] = useState([]);
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("productId");
  const [productDetails, setProductDetails] = useState({});
  const [currentPrice, setCurrentPrice] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [addVariantProductModal, setAddVariantProductModal] = useState(false);
  const [batchPriceUpdateModal, setBatchPriceUpdateModal] = useState(false);
  const [selectedBatchPrice, setSelectedBatchPrice] = useState("");
  const [batchQuantityUpdateModal, setBatchQuantityUpdateModal] =
    useState(false);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [updateBatchQuantityInProgress, setUpdateBatchQuantityInProgress] =
    useState(false);

  // let isEditing = publicId ? true : false;
  const [activeTab, setActiveTab] = useState(0);
  const [activeTypeTab, setActiveTypeTab] = useState(0);
  const [activeTopnBottomTab, setActiveTopnBottomTab] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const { getBrands } = MainServices();
  const { approveProductByAdmin, getProductTypes } = MainServices();
  const [updatePriceModal, setUpdatePriceModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [variantsUpdateModal, setVariantsUpdateModal] = useState(false);
  const [updateBatchPriceInProgress, setUpdateBatchPriceInProgress] =
    useState(false);

  const handleSaveCustomer = async (values) => {
    setActiveTab(activeTab + 1);
  };
  

  const updateBatchPriceSchema = Yup.object().shape({
    maxPrice: Yup.number()
      .required(`${t("Max Price")} ${t("is required")}`)
      .positive("Negative Numbers are not allowed"),
    sellingPrice: Yup.number()
      .positive("Negative Numbers are not allowed")
      .required(`${t("Selling Price")} ${t("is required")}`),
    costPrice: Yup.number().required(`${t("Cost Price")} ${t("is required")}`),
    discount: Yup.number()
      .required(`${t("Discount Percentage")} ${t("is required")}`)
      .min(0, "Minimum 0 percentage")
      .max(100, "Maximum 100 percentage"),
  });

  const updateBatchQuantitySchema = Yup.object().shape({
    batchQuantity: Yup.number()
      .required(`${t("Quantity")} ${t("is required")}`)
      .positive("Negative Numbers are not allowed"),
  });

  const updateVariantsSchema = Yup.object().shape({
    variants: Yup.array()
      .of(Yup.string().required("Variant is required"))
      .min(1, "Please add atleast one variant")
      .required("variants required"),
  });

  //apis

  const getProductTypeList = async (filters) => {
    const details = {
      filters: filters || [{ key: "status", eq: "active" }],
    };
    return await getProductTypes(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            // List of language specific type names
            const languageSpecificData = resData?.map((type) => {
              // Language specific product-type name
              const languageSpecificName =
                type.language["label_" + i18n.language] || type.name;
              // Produt-type fields
              const fields = [];

              // Current language identifier
              // Add language identifier if not default language
              const currentLanguageIdentifier =
                i18n.language?.includes("en") === false
                  ? ""
                  : "_" + i18n.language;

              // Iterate fields and add to template
              type.fields?.forEach((fieldObj, index) => {
                const defaultLabel = fieldObj["label" + index];
                const languageSpecificLabel =
                  fieldObj["label" + index + currentLanguageIdentifier];
                fields.push({
                  label: languageSpecificLabel || defaultLabel,
                  englishLabel: defaultLabel,
                  value: "",
                  category: "",
                });
              });

              return {
                name: languageSpecificName,
                id: type.id,
                fields: fields,
              };
            });
            return languageSpecificData;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductTypeDetails = async (productTypeId) => {
    if (productTypeId) {
      return await getProductTypeList().then((typeList) => {
        const matchingTypeObject = typeList.find(
          (type) => type.id == productTypeId
        );
        return matchingTypeObject;
      });
    }
  };

  useEffect(() => {
    // console.log("running")
    if (productId) {
      getBatches(productId);
      getProductDetailsList(productId);
    }
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [i18n.language, updatePriceModal, productId]);

  const getBatches = async (productId) => {
    if (!productId) return;
    const details = {};
    return await getBatchesByProduct(details, productId)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : [];
            setBatches(resData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVariants = async (groupId) => {
    const details = {
      filters: [
        {
          key: "groupId",
          eq: groupId,
        },
        {
          key: "publicId",
          neq: productId,
        },
      ],
    };
    return await getProducts(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : [];
            setVariants(resData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };





  const updatePrice = async (values) => {
console.log("values",values)
    setUpdateBatchPriceInProgress(true);
    setErrorsOnSubmit([]);
    let details = {
      // + to convert into numbers
      maxPrice: +values.maxPrice,
      sellingPrice: +values.sellingPrice,
      costPrice: +values.costPrice,
      originalPrice: +selectedBatchPrice?.originalPrice,
      discount: +values.discount,
     
    };

    updateProductBatchPrice(
      details,
      selectedBatchPrice?.publicId,
      selectedBatchPrice?.concurrencyStamp
    )
      .then((res) => {
        if (res.success) {
          setUpdateBatchPriceInProgress(false);
          notifySuccess("Updated successfully!");
          setBatchPriceUpdateModal(false);
          if (productId) {
            getBatches(productId);
            getProductDetailsList(productId);
          }
        } else {
          setUpdateBatchPriceInProgress(false);
          setErrorsOnSubmit(res?.message ? [res.message] : ["Error occured!"]);
        }
      })
      .catch((error) => {
        setUpdateBatchPriceInProgress(false);
        setErrorsOnSubmit(error);
      });
  };

  const onUpdateBatchQuantity = async (values) => {
    setUpdateBatchQuantityInProgress(true);
    console.log(values);
    setErrorsOnSubmit([]);
    let details = {
      totalQuantity: values.batchQuantity,
    };
    updateBatchQuantity(
      details,
      selectedBatchPrice?.productId,
      selectedBatchPrice?.concurrencyStamp
    )
      .then((res) => {
        if (res.success) {
          setUpdateBatchQuantityInProgress(false);
          notifySuccess("Updated successfully!");
          setBatchQuantityUpdateModal(false);
          if (productId) {
            getBatches(productId);
            getProductDetailsList(productId);
          }
        } else {
          setUpdateBatchQuantityInProgress(false);
          setErrorsOnSubmit(res?.message ? [res.message] : ["Error occured!"]);
        }
      })
      .catch((error) => {
        setUpdateBatchQuantityInProgress(false);
        setErrorsOnSubmit(error);
      });
  };

  const getProductDetailsList = async (productId) => {
    if (!productId) return;
    return await getProductDetails(productId)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : {};
            console.log("resData1", resData);
            // To get lanuage specific info on product-type
            const productTypeTemplate = await getProductTypeDetails(
              resData?.details?.productType
            );
            console.log("i18n", i18n.language);
            if (i18n.language?.includes("en") === false) {
              let languageSpecificDetailsObject = {};
              languageSpecificDetailsObject = resData?.productLanguages?.find(
                (languageSpecificDetails) =>
                  languageSpecificDetails.locale === i18n.language
              );
              languageSpecificDetailsObject &&
                Object.keys(languageSpecificDetailsObject).forEach((key) => {
                  if (!languageSpecificDetailsObject[key]) {
                    delete languageSpecificDetailsObject[key];
                  }
                });
              resData = { ...resData, ...languageSpecificDetailsObject };

              // Language specific labels for product type fields
              let languageSpecificFieldData = { ...resData?.details };
              // Iterate through details object
              for (const [fieldLabelEnglish, fieldValue] of Object.entries(
                resData?.details
              )) {
                // Get language specific field label from product-type template
                let languageSpecificFieldLabel =
                  productTypeTemplate?.fields.find(
                    (typeObj) =>
                      typeObj.englishLabel?.toLowerCase() ===
                      fieldLabelEnglish.toLocaleLowerCase()
                  )?.label;
                // Add lanugage specific label and value to temporary object
                // and remove older english label to avoid duplicates
                if (languageSpecificFieldLabel) {
                  languageSpecificFieldData[languageSpecificFieldLabel] =
                    fieldValue;
                  delete languageSpecificFieldData[fieldLabelEnglish];
                }
              }
              // Replace details object with language specific product type labels and values
              resData.details = languageSpecificFieldData;
            }
            resData.productTypeName = productTypeTemplate?.name;
            let groupId = resData?.groupId && resData?.groupId;
            getVariants(groupId);
            setProductDetails(resData);
            setCurrentPrice(resData?.productPrice);
            setProductImages(
              resData.productImages?.map((imageObj) => imageObj.images)
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateVariants = async (values) => {
    if (!productDetails?.groupId) {
      notifyError("No groupid for this Product");
      return;
    }
    setButtonLoading(true);
    const details = {
      variants: values.variants,
    };
    return await updateProductVariants(details, productDetails?.groupId)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : {};
            // To get lanuage specific info on product-type
            notifySuccess("Variants updated");
            setButtonLoading(false);
            onVariantUpdateModal();
            getProductDetailsList(productId);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  const addProductVariant = async (values) => {
    setButtonLoading(true);
    const variants_data = values?.variants_data || {};
    if (
      variants_data?.product_images &&
      variants_data?.product_images.length > 0
    ) {
      try {
        let variantImageResponse = await fileUpload(
          values?.variants_data?.product_images
        );
        const imageArray = variantImageResponse?.fileName?.split(",") || [];
        variants_data.product_images = imageArray;
      } catch (error) {
        console.error("Error uploading variant image:", error);
      }
    }
    const var_details = variants_data.variant || {};
    variants_data.variant = {};
    Object.keys(var_details).map((eachKey) => {
      if (eachKey && var_details[eachKey]) {
        let key = eachKey.toLowerCase();
        variants_data.variant[key] = var_details[eachKey].toLowerCase();
      }
    });
    console.log(variants_data);
    const details = {
      variantsData: variants_data,
      productId,
    };
    await variantProduct(details)
      .then(async (res) => {
        if (res) {
          let resData = res;
          if (res.success) {
            resData = resData.data ? resData.data : {};
            // To get lanuage specific info on product-type
            notifySuccess("Variant Product Added");
            setButtonLoading(false);
            onAddVariantProductModal();
            getProductDetailsList(productId);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  //functions
  const onBatchPriceUpdate = (selected) => {
    if (selected) {
      console.log(selected);
      setSelectedBatchPrice(selected.inventory);
    } else {
      setSelectedBatchPrice("");
    }
    setBatchPriceUpdateModal(!batchPriceUpdateModal);
  };

  const onBatchQuantityUpdate = (selected) => {
    if (selected) {
      setSelectedBatchPrice(selected.inventory);
    } else {
      setSelectedBatchPrice("");
    }
    setBatchQuantityUpdateModal(!batchQuantityUpdateModal);
  };

  const onVariantUpdateModal = () => {
    setVariantsUpdateModal(!variantsUpdateModal);
  };

  const onAddVariantProductModal = () => {
    setAddVariantProductModal(!addVariantProductModal);
  };





  return (
    <div className="product-main">
      {updatePriceModal && productId && (
        <UpdateProductPrice
          modal={updatePriceModal}
          setModal={setUpdatePriceModal}
          productId={productId}
          productPriceObjectId={productDetails?.productPrice?.publicId}
          currentPrice={productDetails?.productPrice}
        />
      )}

      <HeadingMain text="Product Details">
        <div className="close-icon" onClick={() => navigate(-1)}>
          <img src={closeicon} alt="close" />
        </div>
      </HeadingMain>
      <div className="tabs-row">
        <div
          className="user-topcard-main "
          // style={{ height: "697.32px" }}
        >
          <div className="d-flex gap-1">
            <div className="col-5 px-2">
              <Carousel>
                {productImages.map((img, index) => {
                  return (
                    <Carousel.Item
                      interval={1000}
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={img}
                        alt=""
                        style={{
                          width: "auto", // Fixed width
                          height: "auto", // Fixed height
                          objectFit: "cover",
                          maxWidth: "90%",
                          maxHeight: "90%",
                          // Adjust to cover the entire container
                        }}

                        //      style={{width:"475px"}}
                      />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              {/* <Carousel.Item interval={500}>
                                <img
                                  src={Product2}
                                  alt=""
                                  className="ImageSlider"
                                  style={{width:"475px"}}
                                />
                                <Carousel.Caption></Carousel.Caption>
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  src={Product1}
                                  alt=""
                                  className="ImageSlider"
                                  style={{width:"475px"}}
                                />
                                <Carousel.Caption></Carousel.Caption>
                              </Carousel.Item> */}

              {/* <img src={Product1} alt="" className="ImageSlider" /> */}
            </div>
            <div className="col-7">
              <div className="d-flex justify-content-between">
                <h2 className="Agripro">{productDetails?.title}</h2>
                <p className="Instock">
                  <span style={{ fontWeight: "700", fontSize: "24px" }}>
                    {productDetails?.quantity ? productDetails.quantity : 0}
                  </span>{" "}
                  Instock
                </p>
              </div>
              <p className="paragraphAgripro">
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetails?.description,
                  }}
                />
              </p>
              <hr />
              {productDetails?.content &&
                productDetails?.content.length > 0 && (
                  <Fragment>
                    <h3 className="Agripro">Product info</h3>
                    <div className="unorder">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetails?.content,
                        }}
                      />
                    </div>
                    <div></div>
                    <hr />
                  </Fragment>
                )}
              <div className="product-features">
                <h3 className="">Features</h3>

                <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <span>{t("Brand")}:</span>
                  </div>
                  <div className="col">:</div>
                  <div className="col Agr">
                    <span>{productDetails?.brand?.name}</span>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <p> <span>{t("Color")}:</span></p>
                  </div>
                  <div className="col">:</div>
                  <div className="col Agr"> <span>{productDetails?.color || ""}</span></div>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <p>
                      <span>{t("Product Type")}:</span>
                    </p>
                  </div>
                  <div className="col">:</div>
                  <div
                    className="col Agr"
                    style={{ textTransform: "capitalize" }}
                  >
                    {productDetails?.productType || ""}
                  </div>
                </div>
                {productDetails?.details &&
                  Object.keys(productDetails.details).map((eachKey) => {
                    if (eachKey === "productType") return null;
                    const nestedObject = productDetails.details[eachKey];

                    // Check if the value is an object (nested object)
                    if (
                      typeof nestedObject === "object" &&
                      nestedObject !== null
                    ) {
                      return;
                      // (
                      //   <div className="product-details-bottom-flex" key={eachKey}>
                      //     <span>{eachKey}:</span>
                      //     <ul>
                      //       {Object.entries(nestedObject).map(([nestedKey, nestedValue]) => (
                      //         <li key={nestedKey}>
                      //           <strong>{nestedKey}: </strong>
                      //           {nestedValue}

                      //         </li>
                      //       ))}
                      //     </ul>
                      //   </div>
                      // );
                    }

                    return (
                      <div className="d-flex justify-content-between">
                        <div className="col Brand">
                          <p className="text-capitalize">
                            {" "}
                            <span>{eachKey}:</span>
                          </p>
                        </div>
                        <div className="col colon-seperator">:</div>
                        <div className="col Agr">
                          {" "}
                          <span>{productDetails.details[eachKey]}</span>
                        </div>
                      </div>
                    );
                  })}
                <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <p>
                      <span>{t("Price")}</span>
                    </p>
                  </div>
                  <div className="col">:</div>
                  <div className="col Agr">
                    {" "}
                    <span>{productDetails?.costPrice}</span>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <p>Displacement</p>
                  </div>
                  <div className="col">:</div>
                  <div className="col Agr">212 ml</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col Brand">
                    <p>Model No</p>
                  </div>
                  <div className="col">:</div>
                  <div className="col Agr">APC100FQ</div>
                </div> */}

                {user.role !== "admin" && (
                  <div className="product-details-right-edit-main">
                    {/* <Button
                      className="product-details-right-edit-btn"
                      onClick={() => {
                        setUpdatePriceModal(true);
                      }}
                    >
                      {t("Edit Price")}
                    </Button> */}
                    <Button
                      className="product-details-right-edit-btn"
                      onClick={() => {
                        navigate({
                          pathname: "/products/add-product",
                          search: `?productId=${productId}`,
                        });
                      }}
                    >
                      {t("Edit Details")}
                    </Button>

                    <Button
                      className="product-details-right-edit-btn"
                      onClick={onVariantUpdateModal}
                    >
                      {t("Edit Variants")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {productDetails?.variants && productDetails?.variants.length > 0 && (
            <div className="mt-4">
              <div className="d-flex justify-content-between my-2">
                <h3>Variants</h3>
                <Button
                  className="btn btn-secondary"
                  onClick={onAddVariantProductModal}
                >
                  Add Variant
                </Button>
              </div>
              <Table bordered responsive>
                <thead className="voucher-table-head">
                  <tr>
                    <th>{t("Sr no.")}</th>
                    {productDetails.variants.map((each) => {
                      return <th>{t(each)}</th>;
                    })}
                    <th>{t("Quantity")}</th>
                    <th>{t("Cost Price")}</th>
                    <th>{t("Selling Price")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {variants.map((eachVar, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {productDetails?.variants &&
                        productDetails?.variants.length > 0 &&
                        productDetails.variants.map((each) => {
                          return (
                            <td>
                              {eachVar.details ? eachVar.details[each] : ""}
                            </td>
                          );
                        })}
                      <td>{eachVar?.quantity || 0}</td>
                      <td>{eachVar?.costPrice}</td>
                      <td>{eachVar?.sellingPrice}</td>
                      <td>
                        <div className="d-flex">
                          <div
                            onClick={() =>
                              navigate({
                                pathname: "/products/add-product",
                                search: `?productId=${eachVar?.publicId}`,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={EditIcon}
                              alt="not found"
                              className="me-1"
                            />
                          </div>

                          <div
                            onClick={() =>
                              navigate({
                                pathname: "/products/ProductDescription",
                                search: `?productId=${eachVar?.publicId}`,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={eyeIcon}
                              alt="not found"
                              className="me-1"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {batches && batches.length > 0 && (
            <div className="mt-4">
              <h3>Batches</h3>
              <Table bordered responsive>
                <thead className="voucher-table-head">
                  <tr>
                    <th>{t("Sr no.")}</th>
                    <th>{t("Batch Code")}</th>
                    {/* <th>{t("Barcode")}</th> */}
                    <th>{t("Quantity")}</th>
                    <th>{t("Cost Price")}</th>
                    <th>{t("Selling Price")}</th>
                    <th>{t("Warranty")}</th>
                    <th>{t("Offer")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {batches.map((eachProduct, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{eachProduct?.batchId}</td>
                      {/* <td>
                        <img
                          src={barCodeUrl + eachProduct?.barcodeImage}
                          style={{ height: "50px", width: "150px" }}
                        />
                      </td> */}
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">
                            {eachProduct.inventory?.quantityInHand
                              ? eachProduct.inventory?.quantityInHand
                              : "0"}
                          </p>
                          <div
                            onClick={() => onBatchQuantityUpdate(eachProduct)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={EditIcon}
                              alt="not found"
                              className="me-1"
                            />
                          </div>
                        </div>
                      </td>
                      <td>{eachProduct?.inventory?.maxPrice}</td>
                      <td>{eachProduct?.inventory?.sellingPrice}</td>
                      <td>{eachProduct?.product?.warranty}</td>
                      <td>{eachProduct?.inventory?.discount}</td>
                     
                      <td>
                        <div
                          onClick={() => onBatchPriceUpdate(eachProduct)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={EditIcon}
                            alt="not found"
                            className="me-1"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>

        {/* <div className="d-flex justify-content-end mt-3">
                        <IconButton
                          className="back-btn"
                          title="Back"
                          style={{
                            width: "200px",
                            backgroundColor: "black",
                            color: "white",
                            marginRight: "10px",
                          }}
                        ></IconButton>
                       
                      </div>
                    */}
        {/* <div className="class-usercard-flex"></div> */}
        {/* <div className="errors-after-sumbit">
                      {errorsOnSubmit?.map((error) => (
                          <p>{error}</p>
                        ))}
                    </div> */}
      </div>
      <Modal
        isOpen={variantsUpdateModal}
        size="md"
        centered
        toggle={() => onVariantUpdateModal("")}
      >
        <ModalHeader
          toggle={() => onVariantUpdateModal("")}
          className="modal-header-custom"
        >
          {t("Update variants")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="updateProductPrice-main">
            <div className="updateProduct-input-form">
              <Formik
                initialValues={{
                  variants: productDetails?.variants || [],
                }}
                onSubmit={(values) => {
                  updateVariants(values);
                }}
                validationSchema={updateVariantsSchema}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  touched,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="color-selector-header d-flex gap-3">
                      <Label for="color-selector">{t("Variants")}</Label>
                      <Button
                        onClick={() => {
                          setFieldValue("variants", [...values.variants, ""]);
                        }}
                        className="btn btn-success"
                      >
                        Add
                      </Button>
                    </div>
                    {values.variants?.length > 0 && (
                      <div className="color-selectors mt-2">
                        {values.variants.map((variant, index) => {
                          return (
                            <>
                              <FormGroup className="col-12">
                                <div className="color-box d-flex gap-2">
                                  <Input
                                    className="color-name"
                                    name={`variants.${index}`}
                                    placeholder={
                                      t("Enter Name of variant") +
                                      " " +
                                      (index + 1)
                                    }
                                    value={variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />

                                  <img
                                    src={deleteicon}
                                    alt="delete"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      const newArray = [...values.variants];
                                      newArray[index] = null;
                                      setFieldValue(
                                        "variants",
                                        newArray.filter((item) => item !== null)
                                      );
                                    }}
                                  />
                                </div>
                                {errors.variants &&
                                  touched.variants &&
                                  touched.variants[index] &&
                                  errors.variants[index] && (
                                    <span className="error">
                                      {errors.variants[index]}
                                    </span>
                                  )}
                              </FormGroup>
                            </>
                          );
                        })}
                      </div>
                    )}
                    {errors.variants &&
                      touched.variants &&
                      typeof errors.variants === "string" && (
                        <span className="error">{errors.variants}</span>
                      )}

                    <div className="d-flex gap-2 justify-content-end">
                      <Button className="btn btn-success" type="submit">
                        {buttonLoading ? <ButtonLoader /> : "Update"}
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={onVariantUpdateModal}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={addVariantProductModal}
        size="lg"
        centered
        toggle={() => onAddVariantProductModal("")}
        className="variant-modal"
      >
        <ModalHeader
          toggle={() => onAddVariantProductModal("")}
          className="modal-header-custom"
        >
          {t("Add Product variant")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="updateProductPrice-main">
            <div className="updateProduct-input-form">
              <Formik
                initialValues={{
                  variants: productDetails?.variants || [],
                  variants_data: {
                    variant: {},
                    isSamePrice: true,
                    sellingPrice: 0,
                    costPrice: 0,
                    maxPrice: 0,
                    shippingCharge: 0,
                    tax: 0,
                    deliveryCharge: 0,
                    product_images: [],
                  },

                  // product: {
                  //   costPrice: "",
                  // }
                }}

                onSubmit={(values) => {

                
                  addProductVariant(values);
                }}
                // validationSchema={updateVariantsSchema}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  touched,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="color-selector-header d-flex gap-3">
                      <Label for="color-selector">{t("Variants")}</Label>
                    </div>
                    {values.variants?.length > 0 && (
                      <div className="color-selectors mt-2">
                        {values.variants.map((variant, index) => {
                          return (
                            <>
                              <FormGroup className="col-12">
                                <div className="color-box d-flex gap-2">
                                  <Input
                                    className="color-name"
                                    name={`variants.${index}`}
                                    placeholder={
                                      t("Enter Name of variant") +
                                      " " +
                                      (index + 1)
                                    }
                                    value={variant}
                                    disabled
                                  />
                                </div>
                                {errors.variants &&
                                  touched.variants &&
                                  touched.variants[index] &&
                                  errors.variants[index] && (
                                    <span className="error">
                                      {errors.variants[index]}
                                    </span>
                                  )}
                              </FormGroup>
                            </>
                          );
                        })}
                      </div>
                    )}
                    {values?.variants &&
                      values?.variants?.length > 0 &&
                      values.variants.map((eachV) => (
                        <FormGroup className="col-12">
                          <Label style={{ textTransform: "capitalize" }}>
                            {eachV}
                          </Label>
                          <Input
                            name={`variants_data.variant.${eachV}`}
                            placeholder={eachV}
                            type="text"
                            className="product-form-group"
                            value={values?.variants_data?.variant[eachV]}
                            onChange={(e) => {
                              setFieldValue(
                                `variants_data.variant.${eachV}`,
                                e.target.value
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                      ))}

                    <div className="pricing-details mt-2">
                      <div className="file-uploader">
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => {
                            let images =
                              [...values?.variants_data?.product_images] || [];

                            let file = e.target.files[0];

                            const reader = new FileReader();

                            reader.onload = function () {
                              const image = new Image();
                              image.src = reader.result;

                              image.onload = function () {
                                // Example: Change aspect ratio to 16:9
                                const newAspectRatio = 12 / 16;
                                const newWidth = image.height * newAspectRatio;

                                const canvas = document.createElement("canvas");
                                canvas.width = newWidth;
                                canvas.height = image.height;

                                const context = canvas.getContext("2d");
                                context.drawImage(
                                  image,
                                  0,
                                  0,
                                  newWidth,
                                  image.height
                                );

                                canvas.toBlob((blob) => {
                                  const modifiedFile = new File(
                                    [blob],
                                    file.name,
                                    { type: file.type }
                                  );
                                  images.push(modifiedFile);
                                  setFieldValue(
                                    `variants_data.product_images`,
                                    images || []
                                  );
                                }, file.type);
                              };
                            };
                            reader.readAsDataURL(file);
                          }}
                          accept="image/*"
                        />
                        <h3 className="file-uploader-heading">
                          {t("Product Photos")}
                        </h3>
                        <p className="file-uploader-text">
                          {t("Click above to upload photos")}
                        </p>
                      </div>
                      {values?.variants_data?.product_images?.length > 0 && (
                        <div className="uploaders ">
                          <div className="container">
                            <div className="uploaded-images d-flex gap-2 justify-content-start">
                              {values?.variants_data?.product_images.map(
                                (file, imageIndex) => {
                                  if (!file) return null;
                                  return (
                                    <div
                                      key={
                                        typeof file === "string"
                                          ? file
                                          : file.name + file.lastModified
                                      }
                                      className="image-box"
                                    >
                                      {typeof file === "string" ? (
                                        <img
                                          className="view-image"
                                          src={file}
                                          alt="product"
                                        />
                                      ) : (
                                        <img
                                          className="view-image"
                                          src={URL.createObjectURL(file)}
                                          alt="product"
                                          style={{
                                            aspectRatio: "12/16",
                                            objectFit: "contain",
                                          }}
                                        />
                                      )}

                                      <img
                                        src={deleteicon}
                                        alt="delete"
                                        style={{
                                          objectFit: "contain",
                                          width: "15px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          let newImageFiles = [
                                            ...values?.variants_data
                                              ?.product_images,
                                          ];
                                          newImageFiles[imageIndex] = null;
                                          newImageFiles = newImageFiles.filter(
                                            (file) => file !== null
                                          );
                                          setFieldValue(
                                            `variants_data.product_images`,
                                            newImageFiles
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <FormGroup className="d-flex gap-2">
                        <Label>Same Price as Product</Label>
                        <input
                          checked={values?.variants_data?.isSamePrice}
                          type="checkbox"
                          onChange={(e) => {
                            setFieldValue(
                              `variants_data.isSamePrice`,
                              e.target.checked
                            );
                          }}
                        />
                      </FormGroup>
                      {!values?.variants_data?.isSamePrice && (
                        <div>
                          <div className="row">
                            <FormGroup className="col-12 col-md-6">
                              <Label for="sellingPrice">
                                {t("Selling Price")}
                              </Label>
                              <Input
                                id="sellingPrice"
                                name={`variants_data.sellingPrice`}
                                placeholder="AED | 150"
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.sellingPrice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="variants_data.sellingPrice"
                              />
                            </FormGroup>
                            <FormGroup className="col-12 col-md-6">
                              <Label for="tax">{t("Tax")}</Label>
                              <Input
                                id="tax"
                                name={`variants_data.tax`}
                                placeholder={t("Tax")}
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.tax}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </div>
                          {console.log("variants_data", values)}
                          <div className="row">
                            <FormGroup className="col-12 col-md-6 ">
                              <Label for="costPrice">{t("Cost Price")}</Label>
                              <Input
                                id="costPrice"
                                name={`variants_data.costPrice`}
                                placeholder="AED | 150"
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.costPrice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="variants_data.costPrice"
                              />
                            </FormGroup>
                            <FormGroup className="col-12 col-md-6">
                              <Label for="maxPrice">{t("Max Price")}</Label>
                              <Input
                                id="maxPrice"
                                name={`variants_data.maxPrice`}
                                placeholder="AED | 150"
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.maxPrice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="variants_data.maxPrice"
                              />
                            </FormGroup>
                            <FormGroup className="col-12 col-md-6 ">
                              <Label for="shippingCharge">
                                {t("Shipping Charge")}
                              </Label>
                              <Input
                                id="shippingCharge"
                                name={`variants_data.shippingCharge`}
                                placeholder="AED | 150"
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.shippingCharge}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="variants_data.shippingCharge"
                              />
                            </FormGroup>
                            <FormGroup className="col-12 col-md-6 ">
                              <Label for="deliveryCharge">
                                {t("Delivery Charge")}
                              </Label>
                              <Input
                                id="deliveryCharge"
                                name={`variants_data.deliveryCharge`}
                                placeholder="AED | 150"
                                type="number"
                                className="product-form-group"
                                value={values?.variants_data?.deliveryCharge}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="variants_data.deliveryCharge"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex gap-2 justify-content-end">
                      <Button className="btn btn-success" type="submit">
                        {buttonLoading ? <ButtonLoader /> : "Add"}
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={onAddVariantProductModal}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={batchPriceUpdateModal}
        size="lg"
        centered
        toggle={() => setBatchPriceUpdateModal("")}
      >
        <ModalHeader
          toggle={() => setBatchPriceUpdateModal("")}
          className="modal-header-custom"
        >
          {t("Update Price")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="updateProductPrice-main">
            <p className="heading-text">
              {t("Please enter details in English")}
            </p>

            <div className="updateProduct-input-form">
              <Formik
                initialValues={{
                  sellingPrice: selectedBatchPrice?.sellingPrice || 0,
                  maxPrice: selectedBatchPrice?.maxPrice || 0,
                  costPrice: selectedBatchPrice?.costPrice || 0,
                  discount: selectedBatchPrice?.discount || 0,
                  // warranty: selectedBatchPrice?. warranty,
                }}
                onSubmit={(values) => {
                  updatePrice(values);
                }}
                validationSchema={updateBatchPriceSchema}
                //enableReinitialize
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  touched,
                }) => (
                  <Form>
                    <div className="product-bottomcard-main">
                      <h6 className="user-heading">{t("Pricing")}</h6>
                      <Form>
                        <div className="row">
                          <FormGroup className="col-12 col-md-6">
                            <Label for="sellingPrice">
                              {t("Selling Price1")}
                            </Label>
                            <Input
                              id="sellingPrice"
                              name="sellingPrice"
                              placeholder="INR | 150"
                              type="number"
                              className="product-form-group"
                              value={
                                values.sellingPrice > -1
                                  ? values.sellingPrice
                                  : ""
                              }
                              onChange={(e) => {
                                handleChange(e);
                                const discountPrice =
                                  100 -
                                  (+e.target.value /
                                    +selectedBatchPrice.originalPrice) *
                                    100;
                                setFieldValue(
                                  "discount",
                                  +e.target.value >=
                                    +selectedBatchPrice.originalPrice
                                    ? 0
                                    : discountPrice.toFixed(2)
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="sellingPrice"
                            />
                          </FormGroup>
                        </div>
                        
                        <div className="row">
                          <FormGroup className="col-12 col-md-6 col-lg-4">
                            <Label for="costPrice">{t("Cost Prices")}</Label>
                            <Input
                              id="costPrice"
                              name="costPrice"
                              placeholder="INR | 150"
                              type="number"
                              className="product-form-group"
                              value={
                                values.costPrice > -1 ? values.costPrice : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="costPrice"
                            />
                          </FormGroup>
                          <FormGroup className="col-12 col-md-6 col-lg-4">
                            <Label for="maxPrice">{t("Max Price")}</Label>
                            <Input
                              id="maxPrice"
                              name="maxPrice"
                              placeholder="INR | 150"
                              type="number"
                              className="product-form-group"
                              value={
                                values.maxPrice > -1 ? values.maxPrice : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="maxPrice"
                            />
                          </FormGroup>
                          

                          {/* <FormGroup className="col-12 col-md-6 col-lg-4">
                            <Label for="warranty">{t("Warranty")}</Label>
                            <Input
                              id="warranty"
                              name="warranty"
                              placeholder=""
                              
                              className="product-form-group"
                              value={
                                values.warranty > -1 ? values.warranty : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="warranty"
                            />
                          </FormGroup> */}
                          <FormGroup className="col-12 col-md-6 col-lg-4">
                            <Label for="discount">{t("Discount")}</Label>
                            <Input
                              id="discount"
                              name="discount"
                              placeholder="INR | 150"
                              type="number"
                              className="product-form-group"
                              value={
                                values.discount > -1 ? values.discount : ""
                              }
                              onChange={(e) => {
                                handleChange(e);
                                // Calculate and set sellingPrice based on costPrice and discount
                                const sellingPrice =
                                  selectedBatchPrice.originalPrice *
                                  (1 - e.target.value / 100);
                                setFieldValue("sellingPrice", sellingPrice);
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="discount"
                            />
                          </FormGroup>
                        </div>
                      </Form>
                    </div>

                    <div className="errors-after-sumbit">
                      {errorsOnSubmit.map((error) => (
                        <p>{error}</p>
                      ))}
                    </div>

                    <div className="update-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Update"
                        isLoading={updateBatchPriceInProgress}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={batchQuantityUpdateModal}
        size="md"
        centered
        toggle={() => setBatchQuantityUpdateModal("")}
      >
        <ModalHeader
          toggle={() => setBatchQuantityUpdateModal("")}
          className="modal-header-custom"
        >
          {t("Update Quantity")}
        </ModalHeader>
        <ModalBody className="modal-body-custom">
          <div className="updateProductPrice-main">
            <p className="heading-text">
              {t("Please enter details in English")}
            </p>

            <div className="updateProduct-input-form">
              <Formik
                initialValues={{
                  batchQuantity: selectedBatchPrice?.quantityInHand || 0,
                }}
                onSubmit={(values) => {
                  onUpdateBatchQuantity(values);
                }}
                validationSchema={updateBatchQuantitySchema}
                //enableReinitialize
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  touched,
                }) => (
                  <Form>
                    <div className="product-bottomcard-main">
                      <Form>
                        <div className="row">
                          <FormGroup className="col-12">
                            <Label for="batchQuantity">{t("Quantity")}</Label>
                            <Input
                              id="batchQuantity"
                              name="batchQuantity"
                              placeholder="INR | 150"
                              type="number"
                              className="product-form-group"
                              value={
                                values.batchQuantity > -1
                                  ? values.batchQuantity
                                  : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="batchQuantity"
                            />
                          </FormGroup>
                        </div>
                      </Form>
                    </div>

                    <div className="errors-after-sumbit">
                      {errorsOnSubmit.map((error) => (
                        <p>{error}</p>
                      ))}
                    </div>

                    <div className="update-btn-container">
                      <PrimaryButton
                        type="submit"
                        title="Update"
                        isLoading={updateBatchQuantityInProgress}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
