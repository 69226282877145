import React from "react";
import Header from "./Header";
import Sidebar from "./SideBar";
import './Layout.scss'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useTranslation } from "react-i18next";

export default function AuthLayout(props) {
  const { t } = useTranslation();
  const sideBarItems = [
    "dashboard",
    "categories",
    "products",
    "product-type",
    "catering",
    "photography",
    "brand",
    "users",
    "orders",
    "vouchers",
    "settings",
    "Faq-Category",
    "countries",
    "states",
    "FAQ",
    "Restaurant Category",
    "Restaurant Type",
    "Photography Category",
    "profile",
    "menu",
    "policies",
    "Opening Hours",
  ];
  return (
    <div className="auth-layout">
      {sideBarItems.map(item => {
        return <Tooltip key={item} 
        anchorId={item} 
        content={t(item.charAt(0).toUpperCase() + item.slice(1))} 
        place="right"  
        style={
          {
            zIndex:'3', 
            backgroundColor:'white',
            opacity: '1',
            color: 'black', 
            fontFamily:"Montserrat", 
            fontWeight: "600",
            fontSize: "1.25rem",
            textTransform: "capitalize"
        }}/>
      })}
      <div className="sidebar-layout">
        <Sidebar />
      </div>
      <div className="content-main">
        <div className="header-layout">
          <Header />
        </div>
        <div className="content-layout">{props.children}</div>
      </div>
    </div>
  );
}
